import { NumericFormat } from "react-number-format";

interface WeightProps {
  children: number;
}

export function Weight(props: WeightProps) {
  return (
    <NumericFormat
      value={props.children}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={0}
      suffix={"lb"}
    />
  );
}
