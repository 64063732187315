import { RightOutlined } from "@ant-design/icons";
import { CollapseGroupHeader } from "../CollapseGroupHeader";
import { CollapsibleContainer } from "../CollapsibleContainer";
import { HTMLAttributes, ReactNode } from "react";

interface TimelineCollapseHeaderProps extends HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode;
  active: boolean;
  children: ReactNode;
}

export function TimelineCollapseHeader(props: TimelineCollapseHeaderProps) {
  const { active, children, icon = <RightOutlined /> } = props;
  return (
    <CollapsibleContainer {...props}>
      <CollapseGroupHeader active={active}>
        {icon}
        {children}
      </CollapseGroupHeader>
    </CollapsibleContainer>
  );
}
