import chroma from "chroma-js";

export function stringToColor(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}

const isLightColor = (color: string): boolean => {
  try {
    // luminance() returns a value between 0 (black) and 1 (white)
    const luminance = chroma(color).luminance();
    // You can adjust this threshold as needed
    return luminance > 0.5;
  } catch (error) {
    console.error("Error processing color:", error);
    return false; // Default to treating invalid colors as dark
  }
};

export function stringToForegroundColor(s: string): string {
  const backgroundColor = stringToColor(s);
  const isLight = isLightColor(backgroundColor);
  return isLight ? "#000000" : "#FFFFFF";
}

export function foregroundColorForColor(backgroundColor: string): string {
  const isLight = isLightColor(backgroundColor);
  return isLight ? "#000000" : "#FFFFFF";
}
