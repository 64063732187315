import { Link } from "react-router-dom";
import { ButtonRow } from "../../../Components/ButtonRow";
import { NoteAuthorHeadline } from "../../../Components/NotesBase/NoteHeadline";
import { NoteMessage } from "../../../Components/NotesBase/NoteMessage";
import Spacer from "../../../Spacer";
import { CompanyNoteElementProps } from "../CompanyNote";
import { ErrorCompanyNoteElement } from "../ErrorCompanyNoteElement";

export function SystemCompanyNote(props: CompanyNoteElementProps) {
  const { note } = props;

  const metadata = JSON.parse(note.metadata!);
  const identifier = metadata.identifier;

  if (identifier === "pickup-delivery-lead-created") {
    const referralCompanyId = metadata.data.referralCompanyId;
    const referralShipmentId = metadata.data.referralShipmentId;
    return (
      <>
        <NoteAuthorHeadline author={note.author} headline={note.subject} />
        <NoteMessage>{note.body}</NoteMessage>
        <Spacer height={8} />
        <ButtonRow style={{ columnGap: "10px" }}>
          {referralCompanyId && (
            <Link to={`/view-company?companyId=${referralCompanyId}`}>
              View Referral Company
            </Link>
          )}
          {referralShipmentId && (
            <Link to={`/view-shipment?shipmentId=${referralShipmentId}`}>
              View Referral Shipment
            </Link>
          )}
        </ButtonRow>
      </>
    );
  }

  return <ErrorCompanyNoteElement {...props} />;
}
