import { createContext } from "react";
import { CallData } from "./CallData";

export interface CallDataContextProps {
  callData: CallData | null;
  startCall: (_?: Partial<CallData>) => void;
  endCall: () => void;
  setSaving: (_: boolean) => void;
  handleLogCallDataChange: (key: keyof CallData, value: unknown) => void;
}

export const CallDataContext = createContext<CallDataContextProps>({
  callData: null,
  startCall: () => {},
  endCall: () => {},
  handleLogCallDataChange: () => {},
  setSaving: () => {},
});
