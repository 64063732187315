import { Button, Menu, MenuItemProps, Modal, Tag } from "antd";
import { CSSProperties, useState } from "react";
import { useCopyToClipboard } from "../Hooks/useCopyToClipboard";
import HorizontalStack from "./HorizontalStack";
import { Json } from "./Json";
import Stack from "./Stack";
import { ButtonType } from "../Types/AntHelperTypes";

interface ViewRawButtonProps {
  data: any;
  style?: CSSProperties;
  type?: ButtonType;
}

export function ViewRawButton(props: ViewRawButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Raw Data"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
      >
        <Json data={props.data} />
      </Modal>
      <Button
        type={props.type ?? "link"}
        style={props.style}
        onClick={showModal}
      >
        View Raw Data
      </Button>
    </>
  );
}

interface ViewRawMenuItemProps {
  data: any;
  style?: CSSProperties;
}

export function ViewRawMenuItem(props: ViewRawMenuItemProps & MenuItemProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onCopy = useCopyToClipboard(props.data || "");

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <HorizontalStack align="spread" style={{ marginRight: "16px" }}>
            <div>Raw Data</div>
            <Button type="default" onClick={onCopy}>
              Copy to clipboard
            </Button>
          </HorizontalStack>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
      >
        <Stack align="left" style={{ overflowX: "auto" }}>
          <Json data={props.data} />
        </Stack>
      </Modal>
      <Menu.Item
        key="viewraw"
        style={props.style}
        onClick={showModal}
        {...props}
      >
        <Tag color="red">Technical</Tag> View Raw Data
      </Menu.Item>
    </>
  );
}
