import { Tag, Tooltip } from "antd";
import { isBlank } from "@freightsimple/shared";
import { isDateInThePast } from "../../Helpers/isDateInThePast";

interface CompanySalesCallsSnoozedTagProps {
  snoozeSalesCallsUntil: string | undefined | null;
}

export function CompanySalesCallsSnoozedTag(
  props: CompanySalesCallsSnoozedTagProps,
) {
  if (!props.snoozeSalesCallsUntil) {
    return <></>;
  }
  if (isBlank(props.snoozeSalesCallsUntil)) {
    return <></>;
  }

  if (isDateInThePast(props.snoozeSalesCallsUntil)) {
    return <></>;
  }

  return (
    <Tooltip title={`Until : ${props.snoozeSalesCallsUntil}`}>
      <Tag color="purple">Sales Calls Snoozed</Tag>
    </Tooltip>
  );
}
