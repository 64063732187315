import { LogSalesCallFormValues } from "../../Screens/ViewCompanyScreenComponents/LogSalesCall/LogSalesCallForm";

export const initLogCallData: CallData = {
  state: "init",
  companyId: undefined,
  companyNoteId: undefined,
  salesContactId: undefined,

  significant: true,
  whatHappend: "",
  notes: "",

  temperature: undefined,
  shipmentFrequency: undefined,

  controlFreight: undefined,
  saving: false,
};

export interface CallData extends LogSalesCallFormValues {
  state: "saved" | "edited" | "init";
  companyId: string | undefined;
  companyNoteId: string | undefined;
  saving: boolean | undefined;
}
