import { Note } from "@freightsimple/generated-apollo-openapi-client";
import { NoteHeadline } from "../NotesBase/NoteHeadline";
import { NoteMessage } from "../NotesBase/NoteMessage";
import { ButtonRow } from "../ButtonRow";
import { ViewNoteIntercomMenuItem } from "../../Screens/ViewShipmentScreenComponents/ViewNoteEmailMenuItem";

interface IntercomNoteElementProps {
  note: Note;
}

function formatIntercomBody(body: string | undefined) {
  if (body === undefined) {
    return "";
  }
  return body
    .split("\n")
    .map((line, index) => <div key={index}>{line}</div>)
    .slice(0, 4);
}

export function IntercomNoteElement(props: IntercomNoteElementProps) {
  const { note } = props;
  const openFrontUrl = "https://app.frontapp.com/open";
  const link = !props.note.link?.includes(openFrontUrl)
    ? `${openFrontUrl}/${props.note.link}`
    : props.note.link;

  return (
    <>
      <NoteHeadline>{note.headline}</NoteHeadline>
      <NoteMessage>{formatIntercomBody(note.body)}</NoteMessage>
      <ButtonRow>
        <ViewNoteIntercomMenuItem note={note} />
        {props.note.link && <a href={link}>View in Fron2t</a>}
      </ButtonRow>
    </>
  );
}
