import {
  CalendarDataPoint,
  CalendarDataResponse,
  ShipmentReport,
  ShipmentReportInvoiceWarningState,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";

import { Form, message, Switch, Tabs, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import { CreatedAt } from "../../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import { Loading } from "../../Components/Loading";
import { Percentage } from "../../Components/Percentage";
import { ShipmentStatusTag } from "../../Components/ShipmentStatusTag";
import Stack from "../../Components/Stack";
import { StatsCalendar } from "../../Components/StatsCalendar";
import { GraphType } from "../../Components/GraphType";
import { ViewShipmentButton } from "../../Components/ViewShipmentButton";
import { Volume } from "../../Components/Volume";
import { Weight } from "../../Components/Weight";
import { calculatePercentageOfTrailer } from "../../Helpers/calculatePercentageOfTrailer";
import { isNotBlank } from "@freightsimple/shared";
import { stringToColor } from "../../Helpers/stringToColor";
import Spacer from "../../Spacer";

import { MapComponent } from "../../Components/MapComponent";
import { CompanyTabProps } from "../ViewCompanyScreen";
import {
  AndMore,
  MoreDimensionsInfo,
  QuoteSummaryComponent,
} from "../ViewQuotesScreen";
import dayjs from "dayjs";
import { Search, TabPane } from "../../Types/AntHelperTypes";
import { ShipmentStateDropdown } from "../../Components/ShipmentStateDropdown";

const { Title } = Typography;

interface ShipmentReportsTableProps {
  data: Array<ShipmentReport>;
}

function ShipmentReportsMap(props: ShipmentReportsTableProps) {
  return <MapComponent shipments={props.data} />;
}

function ShipmentReportsCalendar(props: ShipmentReportsTableProps) {
  function convertShipmentReportsToCalendarDataQuoted(): CalendarDataResponse {
    const data = props.data.map(function (sr): CalendarDataPoint {
      return {
        day: dayjs(sr.quotedAt).format("YYYY-MM-DD"),
        value: 1,
      };
    });

    const earliestDate = dayjs(
      props.data.sort(function (a, b) {
        return dayjs(a.quotedAt).valueOf() - dayjs(b.quotedAt).valueOf();
      })[0].quotedAt,
    ).format("YYYY-MM-DD");

    return { earliestDate, data };
  }

  function convertShipmentReportsToCalendarDataBooked(): CalendarDataResponse {
    const data = props.data
      .filter((sr) => sr.booked)
      .map(function (sr): CalendarDataPoint {
        return {
          day: dayjs(sr.bookedAt).format("YYYY-MM-DD"),
          value: 1,
        };
      });

    const earliestDate = dayjs(
      props.data.sort(function (a, b) {
        return dayjs(a.bookedAt).valueOf() - dayjs(b.bookedAt).valueOf();
      })[0].bookedAt,
    ).format("YYYY-MM-DD");

    return { earliestDate, data };
  }

  function convertShipmentReportsToCalendarDataMoneyIn(): CalendarDataResponse {
    const data = props.data
      .filter((sr) => sr.booked)
      .map(function (sr): CalendarDataPoint {
        return {
          day: dayjs(sr.bookedAt).format("YYYY-MM-DD"),
          value: sr.moneyInCad ?? 0,
        };
      });

    const earliestDate = dayjs(
      props.data.sort(function (a, b) {
        return dayjs(a.bookedAt).valueOf() - dayjs(b.bookedAt).valueOf();
      })[0].bookedAt,
    ).format("YYYY-MM-DD");

    return { earliestDate, data };
  }

  const dataQuoted = convertShipmentReportsToCalendarDataQuoted();
  const dataBooked = convertShipmentReportsToCalendarDataBooked();
  const dataMoneyIn = convertShipmentReportsToCalendarDataMoneyIn();

  return (
    <div>
      <Title level={4}>Number Quotes:</Title>
      <StatsCalendar
        data={dataQuoted}
        maxValue={100}
        defaultGraphType={GraphType.Calendar}
      />

      <Spacer height={32} />
      <Title level={4}>Number Booked:</Title>
      <StatsCalendar
        data={dataBooked}
        maxValue={100}
        defaultGraphType={GraphType.Calendar}
      />

      <Spacer height={32} />
      <Title level={4}>Money In:</Title>
      <StatsCalendar
        data={dataMoneyIn}
        maxValue={100}
        defaultGraphType={GraphType.Calendar}
        prefix="$"
      />
    </div>
  );
}

function ShipmentReportsTable(props: ShipmentReportsTableProps) {
  const columns: DataTableColumn<ShipmentReport>[] = [
    {
      key: "company",
      show: true,
      title: "Shipments",
      render: (o) => (
        <Stack
          align="left"
          style={{
            borderLeft: `8px solid ${stringToColor(o.shipmentHash!)}`,
            paddingLeft: "16px",
          }}
        >
          <div style={{ fontWeight: 500 }}>
            <HorizontalStack>
              {o.companyName}
              <Spacer width={4} />
              <span>
                {o.insuranceRequested ? (
                  <Tag color="purple">Insurance</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.deletedDescription ? (
                  <Tag color="red">Deleted : {o.deletedDescription}</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.tags?.map((s) => (
                  <Tag key={s} color="orange">
                    {s}
                  </Tag>
                ))}
                {o.problems?.map((s) => (
                  <Tag key={s} color="magenta">
                    🚩 {s}
                  </Tag>
                ))}
              </span>
              <span>
                {o.manualQuotingRequested ? (
                  <Tag color="blue">🦸‍♀️ Manual Quoting Requested</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.invoiceWarningState !==
                ShipmentReportInvoiceWarningState.NoWarning ? (
                  <Tag color="red">{o.invoiceWarningState}</Tag>
                ) : (
                  ""
                )}
              </span>
            </HorizontalStack>
          </div>
          <div>
            {o.pickupCityState} to {o.deliveryCityState}{" "}
          </div>
          <Spacer height={4} />
          <ShipmentStatusTag status={o.state} />
          <Spacer height={16} />
          <HorizontalStack style={{ fontSize: "12px" }}>
            <div>Units:</div>
            <Spacer width={2} />
            <div>{o.totalHandlingUnits!}</div>
            <Spacer width={8} />
            <div>Weight:</div>
            <Spacer width={2} />
            <Weight>{o.totalWeight!}</Weight>
            <Spacer width={8} />
            <div>Volume:</div>
            <Spacer width={2} />
            <Volume>{o.totalVolume!}</Volume>
            <Spacer width={8} />
            <div>Trailer:</div>
            <Percentage>
              {calculatePercentageOfTrailer(o.lineItems!)}
            </Percentage>
            <MoreDimensionsInfo shipmentReport={o} />
          </HorizontalStack>
          <div>{o.lineItems![0].description}</div>
          <Spacer height={8} />
          <div style={{ fontSize: "12px", color: Colors.LightText }}>
            <CreatedAt timestamp={o.quotedAt} />
          </div>
        </Stack>
      ),
      sorter: function (a, b) {
        return dayjs(a.quotedAt).valueOf() - dayjs(b.quotedAt).valueOf();
      },
    },
    {
      key: "quotes",
      show: true,
      title: "Quotes",
      render: (o) => (
        <HorizontalStack spacing={16} style={{ marginLeft: "-16px" }}>
          {o.quotes!.length === 0 && <div>No quotes</div>}
          {o.quotes!.slice(0, 4).map(function (q) {
            return <QuoteSummaryComponent key={q.quoteId!} quote={q} />;
          })}
          <AndMore key="andmore" quotes={o.quotes!.slice(4)} />
        </HorizontalStack>
      ),
    },
    {
      key: "action",
      show: true,
      title: "Action",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipmentId!} />
        </ButtonRow>
      ),
    },
  ];

  return <DataTable pagination={false} columns={columns} data={props.data} />;
}

export function CompanyShipmentsTab(props: CompanyTabProps) {
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<ShipmentReport[]>();
  const companyId = props.data.company.companyId!;
  const [onlyShowBooked, setOnlyShowBooked] = useState(false);
  const [onlyShowAdditionalCharges, setOnlyShowAdditionalCharges] =
    useState(false);
  const [filter, setFilter] = useState<string>();

  const [shipmentStateFilter, setShipmentStateFilter] =
    useState<ShipmentState>();

  async function refresh() {
    setLoading(true);

    try {
      const companiesApi = await createCompaniesApi();
      const response = await companiesApi.getShipmentReportsForCompany({
        companyId,
      });
      setReports(response);
    } catch {
      message.error("Something went wrong");
    }
    setLoading(false);
  }

  useEffect(function () {
    refresh();
  }, []);

  if (loading || reports === undefined) {
    return <Loading />;
  }

  function filterReports(reports: ShipmentReport[]): ShipmentReport[] {
    function applyBookedFilter(r: ShipmentReport): boolean {
      if (onlyShowBooked) {
        return r.booked!;
      } else {
        return true;
      }
    }

    function applyShipmentStateFilter(r: ShipmentReport): boolean {
      if (!r.state) return false;
      if (!shipmentStateFilter || shipmentStateFilter.length === 0) return true;
      return shipmentStateFilter.includes(r.state);
    }

    function applyAdditionalChargeFilter(r: ShipmentReport): boolean {
      if (onlyShowAdditionalCharges) {
        return r.numberCustomerInvoices! > 1;
      } else {
        return true;
      }
    }

    function applyGeneralFilter(r: ShipmentReport): boolean {
      if (filter && isNotBlank(filter)) {
        return JSON.stringify(r).includes(filter);
      } else {
        return true;
      }
    }

    return reports
      .filter(applyBookedFilter)
      .filter(applyAdditionalChargeFilter)
      .filter(applyGeneralFilter)
      .filter(applyShipmentStateFilter);
  }

  return (
    <Stack align="left">
      <HorizontalStack align="spread" verticalAlign="middle">
        <HorizontalStack style={{ marginRight: "30px" }}>
          <Form.Item label="Only show booked" style={{ marginBottom: 0 }}>
            <Switch checked={onlyShowBooked} onChange={setOnlyShowBooked} />
          </Form.Item>
          <Spacer width={20} />
          <Form.Item
            label="Only show additional charges"
            style={{ marginBottom: 0 }}
          >
            <Switch
              checked={onlyShowAdditionalCharges}
              onChange={setOnlyShowAdditionalCharges}
            />
          </Form.Item>
          <Spacer width={20} />
          <Form.Item label="Filter" style={{ marginBottom: 0 }}>
            <Search
              value={filter}
              placeholder="Filter by anything"
              allowClear
              onChange={function (e) {
                setFilter(e.target.value);
              }}
            />
          </Form.Item>
          <Spacer width={20} />
          <Form.Item label="Shipment State" style={{ marginBottom: 0 }}>
            <ShipmentStateDropdown
              mode="multiple"
              value={shipmentStateFilter}
              setValue={setShipmentStateFilter}
            />
          </Form.Item>
        </HorizontalStack>
      </HorizontalStack>

      <Tabs style={{ width: "100%" }}>
        <TabPane tab={`List Shipments`} key="listshipments">
          <ShipmentReportsTable data={filterReports(reports)} />
        </TabPane>
        <TabPane tab={`Map View`} key="shipmentsmap">
          <ShipmentReportsMap data={filterReports(reports)} />
        </TabPane>
        <TabPane tab={`Calendar View`} key="shipmentscalendar">
          <ShipmentReportsCalendar data={filterReports(reports)} />
        </TabPane>
      </Tabs>
    </Stack>
  );
}
