import { FreightClaimState } from "@freightsimple/generated-apollo-openapi-client";
import { TagProps } from "antd";

export function freightClaimStateTagColor(
  state: FreightClaimState | undefined,
): TagProps["color"] {
  switch (state) {
    case FreightClaimState.Created:
      return "blue";
    default:
      return "default";
  }
}
