import {
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Checkbox, message, Modal, Typography } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import { InfoBubble } from "../../Components/InfoBubble";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";

interface PutShipmentOnHoldButtonProps {
  shipment: Shipment;
  onRefresh: () => Promise<void>;
}

const { Text } = Typography;

export function PutShipmentOnHoldButton(props: PutShipmentOnHoldButtonProps) {
  const title = "✋ Mark On Hold";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentApi = useShipmentsApi();
  const [doNotCancelPickup, setDoNotCancelPickup] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setCancelling(true);
    try {
      const shipmentId = props.shipment.shipmentId!;

      const shipmentApi = await createShipmentApi();

      await shipmentApi.markShipmentOnHold({
        shipmentId,
        doNotCancelPickup,
      });

      await props.onRefresh();

      if (doNotCancelPickup) {
        message.success("Shipment Marked On Hold. Pickup was not cancelled.");
      } else {
        message.success(
          "Shipment Marked On Hold. Pickup cancellation requested.",
        );
      }

      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
    setCancelling(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (props.shipment.state !== ShipmentState.BookingConfirmed) {
    return <></>;
  }

  function okText() {
    if (doNotCancelPickup) {
      return "Yes, mark on hold (keep pickup as is)";
    } else {
      return "Yes, mark on hold (will email carrier ✉️)";
    }
  }

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        okText={okText()}
        onCancel={handleCancel}
        confirmLoading={cancelling}
        width={600}
        destroyOnClose
      >
        <Stack align="left">
          <HorizontalStack>
            <Checkbox
              checked={doNotCancelPickup}
              onChange={function (e) {
                setDoNotCancelPickup(e.target.checked);
              }}
            >
              <div>
                ✉️ Do not send cancel pickup email{" "}
                <InfoBubble overlay="Normally marking the shipment on hold will email the carrier asking them to cancel the pickup. However if you have already phoned/emailed to cancel, then check this to avoid sending another email." />
              </div>
            </Checkbox>
          </HorizontalStack>

          <Spacer height={32} />

          <>
            <Text>
              This will mark shipment the shipment on hold. It does the
              following steps
            </Text>
            <ol>
              <li>Creates a monday task</li>
              <li>Mark the shipment as as on hold</li>
              {!doNotCancelPickup && <li>Emails the carrier</li>}
              {doNotCancelPickup && (
                <li>
                  <s>Emails the carrier</s>
                </li>
              )}
              <li>Resets the carrier pickup number</li>
              <li>Closes the monday task if everything was successful</li>
            </ol>
          </>
        </Stack>
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
