import Colors from "../../Components/Colors";

interface AcceptancePercentageProps {
  children: number;
}

function colorForAcceptance(value: number) {
  if (value > 70) {
    return Colors.DarkGreen;
  } else if (value > 30) {
    return Colors.Orange;
  }
  return Colors.Red;
}

export function AcceptancePercentage(props: AcceptancePercentageProps) {
  return (
    <div style={{ color: colorForAcceptance(props.children) }}>
      {props.children.toFixed(1)}%
    </div>
  );
}
