import { CarrierNameAndIdentifier } from "@freightsimple/generated-apollo-openapi-client";
import { Select } from "antd";
import { useState } from "react";
import { useCarriersApi } from "../Apis/Apis";
import { isPhone } from "../Helpers/isPhone";
import { getCarrierDisplayNameFromCarrierIdentifier } from "../Helpers/serviceProperties";
import { useOnce } from "../Hooks/useOnce";
import CarrierLogo from "./CarrierLogo";
import Colors from "./Colors";
import HorizontalStack from "./HorizontalStack";

const { Option } = Select;

interface CarrierFilterProps {
  setCarrierIdentifier: (carrierIdentifier: string | undefined) => void;
  allowClear?: boolean;
  carrierIdentifier: string | null | undefined;
  disabled?: boolean;
}

export function CarrierFilter(props: CarrierFilterProps) {
  const createCarrierApi = useCarriersApi();
  const [carriers, setCarriers] = useState<
    CarrierNameAndIdentifier[] | undefined
  >();

  useOnce(async function () {
    const carrierApi = await createCarrierApi();
    const response = await carrierApi.listCarriers();
    setCarriers(response);
  });

  if (carriers === undefined) {
    return <></>;
  }

  return (
    <Select
      allowClear={props.allowClear === undefined ? true : props.allowClear}
      showSearch
      disabled={props.disabled}
      style={{ width: isPhone ? 170 : 220 }}
      value={props.carrierIdentifier}
      placeholder="Carrier"
      optionFilterProp="children"
      onSelect={function (carrierIdentifier: string) {
        props.setCarrierIdentifier(carrierIdentifier);
      }}
      onClear={function () {
        props.setCarrierIdentifier(undefined);
      }}
      filterOption={(input, option) =>
        // @ts-expect-error - Might be null
        option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        // @ts-expect-error - Might be null
        optionA.value
          // @ts-expect-error - Might be null
          .toLowerCase()
          // @ts-expect-error - Might be null
          .localeCompare(optionB.value.toLowerCase())
      }
    >
      {carriers.map(function (c) {
        return (
          <Option key={c.identifier} value={c.identifier}>
            <HorizontalStack
              verticalAlign="middle"
              align="center"
              style={{ width: "100%" }}
            >
              <HorizontalStack style={{ width: "100%" }} align="spread">
                <div
                  style={{
                    fontSize: "11px",
                    color: Colors.NormalText,
                    maxWidth: "calc(100% - 80px)",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "1.2",
                    minHeight: "32px",
                  }}
                >
                  {getCarrierDisplayNameFromCarrierIdentifier(c.identifier)}
                </div>
                <div style={{ padding: "8px 0 0 0", maxWidth: "80px" }}>
                  <CarrierLogo
                    carrierIdentifier={c.identifier}
                    brokeredCarrierIdentifier={undefined}
                    width={80}
                    height={20}
                    notClickable
                    align="right"
                  />
                </div>
              </HorizontalStack>
            </HorizontalStack>
          </Option>
        );
      })}
    </Select>
  );
}
