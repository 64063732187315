import { ViewQuoteButton } from "../../../Screens/ViewShipmentScreenComponents/ViewQuoteButton";
import Spacer from "../../../Spacer";
import HorizontalStack from "../../HorizontalStack";
import { BaseNote } from "../../Notes/BaseNote";
import { NoteElementProps } from "../../NotesTable";
import { NoteHeadline } from "../NoteHeadline";
import { NoteMessage } from "../NoteMessage";

export function ApolloActionNote(props: NoteElementProps) {
  const { note } = props;

  if (note.beforeMetadata === undefined || note.afterMetadata === undefined) {
    return <BaseNote {...props} />;
  }

  const beforeMetadata = JSON.parse(note.beforeMetadata!);

  if (beforeMetadata.identifier === "selected-quote-change") {
    return <SelectedQuoteChange {...props} />;
  }

  return <BaseNote {...props} />;
}

function SelectedQuoteChange(props: NoteElementProps) {
  const { note } = props;
  const beforeMetadata = JSON.parse(note.beforeMetadata!);
  const afterMetadata = JSON.parse(note.afterMetadata!);

  const beforeQuoteId = beforeMetadata?.quoteId;
  const afterQuoteId = afterMetadata?.quoteId;

  return (
    <>
      <NoteHeadline>{note.headline}</NoteHeadline>
      <NoteMessage>{note.message}</NoteMessage>
      <HorizontalStack>
        {beforeQuoteId !== undefined && (
          <ViewQuoteButton
            quoteId={beforeQuoteId}
            text="View Previous Quote"
            type="link"
            style={{ paddingLeft: 0 }}
          />
        )}
        {beforeQuoteId === undefined && <div>Could not find before quote</div>}

        <Spacer width={8} />
        {afterQuoteId !== undefined && (
          <ViewQuoteButton
            quoteId={afterQuoteId}
            text="View New Quote"
            type="link"
            style={{ paddingLeft: 0 }}
          />
        )}
        {afterQuoteId === undefined && <div>Could not find after quote</div>}
      </HorizontalStack>
    </>
  );
}
