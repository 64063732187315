import { EmailDescription } from "@freightsimple/generated-apollo-openapi-client";
import { message } from "antd";
import { useState } from "react";
import { useCompaniesApi, useSupportEmailApi } from "../../../Apis/Apis";
import { useOnce } from "../../../Hooks/useOnce";
import { SupportAgent } from "../../ViewCompanyScreen";

export function useSendCompanyVerifiedEmail(companyId: string) {
  const createSupportEmailApi = useSupportEmailApi();
  const createCompaniesApi = useCompaniesApi();
  const [to, setTo] = useState<string[] | null>(null);

  useOnce(fetchCompanyUsers);

  async function fetchCompanyUsers() {
    if (companyId === undefined) return;
    try {
      const companiesApi = await createCompaniesApi();
      const response = await companiesApi.getCompanyUsers({ companyId });
      const emails = response.map((user) => user.email);
      setTo(emails);
    } catch (e) {
      console.error(e);
    }
  }

  async function onFetchPreview(
    supportAgent: SupportAgent,
  ): Promise<EmailDescription | undefined> {
    if (companyId === undefined || !to) {
      message.error("Oops. Something went wrong.");
      return;
    }
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewCompanyVerifiedEmail({
        companyId,
        to,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(supportAgent: SupportAgent): Promise<boolean> {
    if (companyId === undefined || !to) {
      message.error("Oops. Something went wrong. Could not send the email!");
      return false;
    }
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendCompanyVerifiedEmail({
        companyId,
        to,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
      return true;
    } catch {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  const hash = JSON.stringify(to);

  return { onFetchPreview, to, setTo, hash, onSend };
}
