import { Button, Modal, message } from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import { CompanyTabProps } from "../ViewCompanyScreen";

export function ClaudeEvaluateCompanyButton(props: CompanyTabProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const title = `Claude`;
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);
  const companyId = props.data.company.companyId!;

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleGenerate() {
    setLoading(true);
    const companiesApi = await createCompaniesApi();
    try {
      await companiesApi.generateClaudeCompanyAnalysis({
        companyId,
      });
      await props.onRefresh();
      message.success("Timestamp updated");
    } catch (e) {
      message.error(
        `Oops. Something went wrong ${e}. Maybe try reloading and seeing if it worked?`,
      );
    }
    setLoading(false);
    setIsModalVisible(false);
  }

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <HorizontalStack align="spread">
            <div>Claude Analysis</div>
            <Button
              style={{ marginRight: "24px" }}
              type="primary"
              onClick={handleGenerate}
              loading={loading}
            >
              Generate Analysis
            </Button>
          </HorizontalStack>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
      >
        {props.data.company.claudeAnalysis && (
          <div
            dangerouslySetInnerHTML={{
              __html: props.data.company.claudeAnalysis,
            }}
          />
        )}
        {!props.data.company.claudeAnalysis && (
          <div style={{ color: Colors.LightText }}>No analysis yet</div>
        )}
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
