import { Note } from "@freightsimple/generated-apollo-openapi-client";
import { NoteHeadline } from "../NotesBase/NoteHeadline";
import { NoteMessage } from "../NotesBase/NoteMessage";

interface BaseNoteProps {
  note: Note;
}

export function BaseNote({ note }: BaseNoteProps) {
  return (
    <>
      <NoteHeadline>{note.headline}</NoteHeadline>
      <NoteMessage>{note.message}</NoteMessage>
    </>
  );
}
