export function stringSorter(
  a: string | null | undefined,
  b: string | null | undefined,
) {
  if (a && b) {
    return b.localeCompare(a);
  } else if (a) {
    return -1; // a.name is defined, but b.name is not
  } else if (b) {
    return 1; // b.name is defined, but a.name is not
  } else {
    return 0; // Both names are undefined
  }
}
