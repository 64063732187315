export function isEmpty(list: any[] | undefined | null): boolean {
  if (list === null) {
    return true;
  }

  if (list === undefined) {
    return true;
  }

  if (list.length < 1) {
    return true;
  }

  return false;
}

export function isNotEmpty(list: any[] | undefined | null): boolean {
  return !isEmpty(list);
}
