import { MenuItemProps } from "antd/lib/menu/MenuItem";
import { ViewJsonMenuItem } from "../../Components/ViewJsonMenuItem";
import { TabProps } from "./TabProps";

export function ViewPageLoadStatsMenuItem(props: TabProps & MenuItemProps) {
  return (
    <ViewJsonMenuItem
      {...props}
      data={props.shipmentData.durationInfo}
      title="View Page Load Stats"
    />
  );
}
