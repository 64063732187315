import { ReactNode } from "react";

export interface ComparatorProps<T> {
  left: T;
  right: T;
  compareFn: (left: T, right: T) => boolean;
  onEquals: (element: T) => ReactNode;
  onChanges: (left: T, right: T) => ReactNode;
}

/**
 * Compares two elements and renders different components based on the comparison.
 *
 * It can be used to compare changes in two elements - left element (before changes) & right element (after changes)
 *
 * @param left element
 * @param right element
 * @param compareFn function that compares the left and right elements (it must return a boolean)
 *    return `true` if `left` and `right` elements are equal
 *    return `false` if `left` and `right` elements are different
 * @param onEquals function that renders a component when the left and right elements are equal
 * @param onChanges function that renders a component when the left and right elements are not equal (changes)
 */
export function Comparator<T>({
  left,
  right,
  compareFn,
  onEquals,
  onChanges,
}: ComparatorProps<T>) {
  const areEqual = compareFn(left, right);

  if (areEqual) {
    return <span style={{ marginInline: "5px" }}>{onEquals(left)}</span>;
  }

  return <span>{onChanges(left, right)}</span>;
}
