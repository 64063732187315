import {
  Button,
  ButtonProps,
  Menu,
  MenuItemProps,
  Modal,
  Tooltip,
  Typography,
} from "antd";
import { ReactNode, useState } from "react";
import Spacer from "../Spacer";
import { TextArea } from "../Types/AntHelperTypes";

const { Text } = Typography;

interface ConfirmWithTextAreaBaseProps {
  onConfirm: (text: string) => void;
  question: string;
  okText: string;
  disabled?: boolean;
  children: ReactNode;
  tooltip: string;
  icon?: ReactNode;
  placeholder: string;
}

export function ConfirmButtonWithTextArea(
  props: ConfirmWithTextAreaBaseProps & ButtonProps,
) {
  return (
    <ConfirmBase
      {...props}
      button={(children, onClick) => (
        <Button
          type={props.type}
          icon={props.icon}
          disabled={props.disabled}
          onClick={onClick}
        >
          {children}
        </Button>
      )}
    />
  );
}

interface AddButtonProps {
  button: (children: ReactNode, onClick: () => void) => ReactNode;
}

export function ConfirmMenuItemWithTextArea(
  props: ConfirmWithTextAreaBaseProps & MenuItemProps,
) {
  return (
    <ConfirmBase
      {...props}
      button={(children, onClick) => (
        <Menu.Item {...props} onClick={onClick} disabled={props.disabled}>
          {children}
        </Menu.Item>
      )}
    />
  );
}

function ConfirmBase(props: ConfirmWithTextAreaBaseProps & AddButtonProps) {
  const [processing, setProcessing] = useState(false);
  const [text, setText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setProcessing(true);
    await props.onConfirm(text);
    setProcessing(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        okButtonProps={{
          loading: processing,
        }}
        okText={props.okText}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={600}
        closable={false}
      >
        <Text style={{ fontSize: "18px" }}>{props.question}</Text>
        <Spacer height={32} />
        <TextArea
          style={{ width: "600px", height: "100px" }}
          value={text}
          onChange={function (e) {
            setText(e.target.value);
          }}
          placeholder={props.placeholder}
        />
      </Modal>
      <Tooltip placement="bottom" title={props.tooltip}>
        {props.button(props.children, showModal)}
      </Tooltip>
    </>
  );
}
