import { useState } from "react";

import { Button } from "antd";
import { ConfirmButtonWithTextArea } from "../../Components/ConfirmButtonWithTextArea";
import {
  isCompanyStarred,
  starCompany,
  unstarCompany,
} from "../../Helpers/starredCompanies";
import { CompanyTabProps } from "../ViewCompanyScreen";

export function StarCompanyButton(props: CompanyTabProps) {
  const companyId = props.data.company.companyId!;
  const [, setLastAlterTime] = useState(new Date().valueOf());

  const isStarred = isCompanyStarred(companyId);

  if (isStarred) {
    return (
      <Button
        onClick={function () {
          unstarCompany(companyId);
          setLastAlterTime(new Date().valueOf());
          props.onRefresh();
        }}
      >
        ⭐️ Unstar Company
      </Button>
    );
  } else {
    return (
      <ConfirmButtonWithTextArea
        onConfirm={async function (reason) {
          starCompany(companyId, reason);
          setLastAlterTime(new Date().valueOf());
          props.onRefresh();
        }}
        placeholder="Enter reason you are starring this"
        question="Are you sure you want to star this?"
        okText="Yes, star it"
        tooltip="This will star the company for you on your local browser. No one else will see this"
      >
        ⭐️ Star Company
      </ConfirmButtonWithTextArea>
    );
  }
}
