interface BooleanCellProps {
  children: boolean | undefined;
}

function boolToEmoji(value: boolean | undefined): string {
  return value ? "✅" : "❌";
}

export function BooleanCell(props: BooleanCellProps) {
  return <div>{boolToEmoji(props.children)}</div>;
}
