import {
  ShipmentState,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";

import { Button, message, notification, Popconfirm, Tooltip } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { isBlank } from "@freightsimple/shared";
import dayjs from "dayjs";

interface BookPickupButtonProps {
  shipmentData: ViewShipmentDataForApollo;
  onRefresh: () => Promise<void>;
}

export function BookPickupButton(props: BookPickupButtonProps) {
  const createShipmentApi = useShipmentsApi();
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!;
  async function onConfirm() {
    const today = dayjs().startOf("day");
    const pickupDate = dayjs(props.shipmentData.shipment.shipment.pickupDate);
    if (pickupDate.isBefore(today)) {
      message.warning("Pickup Date is before today");
      return;
    }

    try {
      const shipmentApi = await createShipmentApi();
      const response = await shipmentApi.bookPickup({
        shipmentId,
      });

      if (response.successfullyRan) {
        notification.success({
          message: "Successfully booked!",
          description: response.message,
        });
      } else {
        notification.error({
          message:
            "Problem with booking. Maybe look at the request audit logs?!",
          description: response.message,
        });
      }

      await props.onRefresh();
    } catch (e: any) {
      message.error(`Oops, something went wrong : ${e.toString()}`);
    }
  }

  if (
    props.shipmentData.shipment.shipment.state !==
      ShipmentState.BookingConfirmed &&
    props.shipmentData.shipment.shipment.state !==
      ShipmentState.BookingRequested &&
    props.shipmentData.shipment.shipment.state !== ShipmentState.OnHold
  ) {
    return <></>;
  }

  const supportsAutomaticPickup =
    props.shipmentData._configuration.supportsAutomaticPickup;

  const tooltip = supportsAutomaticPickup
    ? "Use this if you need to book a pickup. It will electronicially communicate with the carrier"
    : "Use this if you need to book a pickup. It will either send an email, or open a Monday task with instructions";

  function title(): string {
    if (isBlank(props.shipmentData.shipment.shipment.carrierPickupNumber)) {
      return "Are you sure to book a pickup?";
    } else {
      return `Are you sure to book a pickup? Looks like there might be a pickup already booked under pickup ${props.shipmentData.shipment.shipment.carrierPickupNumber}`;
    }
  }

  return (
    <Popconfirm
      title={title()}
      onConfirm={onConfirm}
      okText="Yes, book the pickup"
      cancelText="Cancel"
    >
      <Tooltip placement="bottom" title={tooltip}>
        <Button danger type="primary">
          Book Pickup
        </Button>
      </Tooltip>
    </Popconfirm>
  );
}
