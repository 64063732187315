import { HTMLAttributes } from "react";

export function NoteMessage({
  children,
  style,
  ...rest
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        fontSize: "14px",
        fontWeight: "400",
        whiteSpace: "pre-wrap",
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
