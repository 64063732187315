import { InvoiceAuditProblemResolutionState } from "@freightsimple/generated-apollo-openapi-client";
import { assertNever } from "../../Helpers/assertNever";

export function colorForInvoiceAuditProblemResolutionState(
  resolutionState: InvoiceAuditProblemResolutionState | undefined,
): string {
  switch (resolutionState) {
    case InvoiceAuditProblemResolutionState.Resolved:
      return "green";
    case InvoiceAuditProblemResolutionState.Pending:
      return "yellow";
    case InvoiceAuditProblemResolutionState.Unresolved:
      return "red";
    case undefined:
      return "gray";
    default:
      assertNever(resolutionState);
  }
}
