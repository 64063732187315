import { Tag, message } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import { TabProps } from "./TabProps";

export function RetryInvoiceApprovalMenuItem(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const createShipmentsApi = useShipmentsApi();
  async function onConfirm() {
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.retryInvoiceApproval({
        shipmentId,
      });
      await props.onRefresh();
      message.success("Done");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  return (
    <ConfirmMenuItem
      onConfirm={onConfirm}
      question="Are you sure you want to re-process the current set of invoices?"
      okText={"Yes"}
      tooltip={
        "Use this if we had a bug and the invoice approval wasn't done properly, or if we changed some logic and want to reprocess"
      }
    >
      <Tag color="red">Advanced</Tag> Retry Invoice Approval
    </ConfirmMenuItem>
  );
}
