import { ShipmentReport } from "@freightsimple/generated-apollo-openapi-client";
import mapboxgl from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import HorizontalStack from "../Components/HorizontalStack";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { convertShipmentReportsToMapData, MapData } from "../Hooks/useMapData";
import {
  QuoteRowCompanyColumn,
  QuoteRowQuotesColumn,
} from "../Screens/ViewQuotesScreen";
import { Feature, LineString, Point } from "geojson";

// This is our public key, so not a big deal being in the code
// since it's public on the website anyway
mapboxgl.accessToken =
  "pk.eyJ1IjoiY2hyaXN0b3BoZXJzdG90dCIsImEiOiJjazdmZnZ1eDIwMmR0M25wcnMxOWMzNTIzIn0.LBCxe4yd1d0SohHTSvzSmg";

interface MapComponentProps {
  shipments: ShipmentReport[];
}

export function MapComponent(props: MapComponentProps) {
  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | undefined>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const data = convertShipmentReportsToMapData(props.shipments);
  const [hoveredShipmentReportId, setHoveredShipmentReportId] = useState<
    string | undefined
  >();

  const hoveredShipmentReport = props.shipments.find(
    (s) => s.shipmentId === hoveredShipmentReportId,
  );

  useEffect(() => {
    async function initializeMap() {
      const currentMapContainer = mapContainer.current;
      if (currentMapContainer == null) {
        return;
      }

      const mapToInitialize = new mapboxgl.Map({
        container: currentMapContainer,
        style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
        center: [-90, 44],
        zoom: 3,
      });

      mapToInitialize.resize();

      mapToInitialize.addControl(new mapboxgl.NavigationControl());

      setMapLoaded(true);
      setMap(mapToInitialize);

      function onClick(e: mapboxgl.MapMouseEvent) {
        if (!e.features || e.features.length === 0) return;

        const url = e.features[0].properties?.url;
        if (url) {
          window.open(url, "_new");
        }
      }

      function onMouseEnter(e: mapboxgl.MapMouseEvent) {
        if (!e.features || e.features.length === 0) return;

        const shipmentReportId = e.features[0].properties?.shipmentReportId;
        if (shipmentReportId) {
          console.log("onMouseEnter", { shipmentReportId });
          setHoveredShipmentReportId(shipmentReportId);
        }
        // Change the cursor style as a UI indicator.
        mapToInitialize.getCanvas().style.cursor = "pointer";
      }

      function onMouseLeave() {
        mapToInitialize.getCanvas().style.cursor = "";
      }

      mapToInitialize.on("mouseenter", "bookedPoints", onMouseEnter);
      mapToInitialize.on("mouseenter", "notBookedPoints", onMouseEnter);
      mapToInitialize.on("mouseleave", "bookedPoints", onMouseLeave);
      mapToInitialize.on("mouseleave", "notBookedPoints", onMouseLeave);
      mapToInitialize.on("click", "bookedPoints", onClick);
      mapToInitialize.on("click", "notBookedPoints", onClick);
    }

    if (map === undefined) {
      initializeMap();
    }
  }, []);

  function setupLocations(data: MapData) {
    const {
      bookedFeatures,
      notBookedFeatures,
      bookedFeaturesCirclePickup,
      notBookedFeaturesCirclePickup,
      bookedFeaturesCircleDelivery,
      notBookedFeaturesCircleDelivery,
    } = data;
    addLayer("bookedPoints", bookedFeatures, "green", 1.0);
    addLayer("notBookedPoints", notBookedFeatures, "red", 0.3);
    addCirclesLayer(
      "bookedPointsCirclesPickup",
      bookedFeaturesCirclePickup,
      "blue",
    );
    addCirclesLayer(
      "notBookedPointsCirclesPickup",
      notBookedFeaturesCirclePickup,
      "blue",
    );
    addCirclesLayer(
      "bookedPointsCirclesDelivery",
      bookedFeaturesCircleDelivery,
      "black",
    );
    addCirclesLayer(
      "notBookedPointsCirclesDelivery",
      notBookedFeaturesCircleDelivery,
      "black",
    );
  }

  async function setupLocationsWrapper() {
    let remaining = 3;

    // We sometimes get exceptions that the style is not loaded. I can't find a way to wait for mapbox to properly have styles loaded
    // So let's just catch and retry to work around it
    function retry(f: () => void) {
      try {
        f();
      } catch (e) {
        console.error(`!!!! got error ${e}`);
        remaining--;
        if (remaining > 0) {
          setTimeout(function () {
            retry(f);
          }, 500);
        } else {
          console.error("Ran out of retries");
        }
      }
    }

    retry(function () {
      setupLocations(data);
    });
  }

  function addLayer(
    id: string,
    features: Feature<LineString>[],
    color: string,
    opacity: number,
  ) {
    const sourceId = `${id}Source`;
    if (map?.getLayer(id)) {
      map?.removeLayer(id);
    }

    if (map?.getSource(sourceId)) {
      map?.removeSource(sourceId);
    }

    map?.addSource(sourceId, {
      type: "geojson",
      lineMetrics: true,
      data: {
        type: "FeatureCollection",
        features: features,
      },
    });

    map?.addLayer({
      id: id,
      type: "line",
      source: sourceId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": color,
        "line-width": 2,
        "line-opacity": opacity,
        "line-gradient": [
          "interpolate",
          ["linear"],
          ["line-progress"],
          0,
          "#ddd",
          1,
          color,
        ],
      },
    });
  }

  function addCirclesLayer(
    id: string,
    features: Feature<Point>[],
    color: string,
  ) {
    const sourceId = `${id}Source`;
    if (map?.getLayer(id)) {
      map?.removeLayer(id);
    }

    if (map?.getSource(sourceId)) {
      map?.removeSource(sourceId);
    }

    map?.addSource(sourceId, {
      type: "geojson",
      lineMetrics: true,
      data: {
        type: "FeatureCollection",
        features: features,
      },
    });

    map?.addLayer({
      id: id,
      type: "circle",
      source: sourceId,
      paint: {
        "circle-color": color,
        "circle-radius": 2,
      },
    });
  }

  useEffect(() => {
    if (map === undefined) {
      return;
    }

    if (!mapLoaded) {
      map.on("load", function () {
        setupLocationsWrapper();
        setMapLoaded(true);
      });
    } else {
      setupLocationsWrapper();
    }
  }, [JSON.stringify(data), map]);

  return (
    <div>
      <div
        ref={mapContainer}
        style={{
          width: "100vw",
          height: "calc(100vh - 96px)",
          position: "absolute",
        }}
      ></div>
      {hoveredShipmentReport && (
        <div
          style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            borderTop: "1px solid #aaa",
            padding: "16px",
            height: "200px",
          }}
        >
          <HorizontalStack verticalAlign="top">
            <QuoteRowCompanyColumn shipmentReport={hoveredShipmentReport} />
            <QuoteRowQuotesColumn shipmentReport={hoveredShipmentReport} />
            <ViewShipmentButton
              shipmentId={hoveredShipmentReport.shipmentId!}
            />
          </HorizontalStack>
        </div>
      )}
    </div>
  );
}
