import { HTMLAttributes } from "react";
import Colors from "../Colors";

export function NoteSubmessage({
  children,
  style,
  ...rest
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        fontSize: "12px",
        fontWeight: "400",
        color: Colors.LightText,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
