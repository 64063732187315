import {
  ApolloMapCompany,
  ApolloMapQuery,
  ApolloMapQueryBookingStatus,
  GeoFilter,
  QuoteFilterType,
  ShipmentReport,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";

import { Popover, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMapsApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import Colors from "../Components/Colors";
import { DownloadCarrierComparisonButton } from "../Components/DownloadCarrierComparisonLink";
import HorizontalStack from "../Components/HorizontalStack";
import Stack from "../Components/Stack";
import { useOnce } from "../Hooks/useOnce";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";

import { AllShipmentsScreenCalendarTab } from "./AllShipmentComponents/AllShipmentsScreenCalendarTab";
import { AllShipmentsScreenHeader } from "./AllShipmentComponents/AllShipmentsScreenHeader";
import { AllShipmentsScreenListTab } from "./AllShipmentComponents/AllShipmentsScreenListTab";
import { AllShipmentsScreenMapTab } from "./AllShipmentComponents/AllShipmentsScreenMapTab";
import { AllShipmentsScreenQueryViewer } from "./AllShipmentComponents/AllShipmentsScreenQueryViewer";
import { AllShipmentsScreenStats } from "./AllShipmentComponents/AllShipmentsScreenStats";
import { useGeneralQuoteFilter } from "./AllShipmentComponents/Helpers/quoteFilter";
import dayjs from "dayjs";
import { AllShipmentsScreenViewType } from "./AllShipmentComponents/AllShipmentsScreenViewType";

export function AllShipmentsScreen() {
  const [currentView, setCurrentView] = useState<AllShipmentsScreenViewType>(
    AllShipmentsScreenViewType.List,
  );
  const urlQuery = useQuery();
  function potentiallyParseInt(s: string): number | undefined {
    const result = parseInt(s);
    if (isNaN(result)) {
      return undefined;
    } else {
      return result;
    }
  }
  function defaultQuery(): ApolloMapQuery {
    return {
      // General Tab
      freeTextQuery: "",
      bookingStatus:
        (urlQuery.bookingStatus as ApolloMapQueryBookingStatus) ??
        ApolloMapQueryBookingStatus.Any,
      shipmentState: (urlQuery.shipmentState as ShipmentState) ?? undefined,
      quoteFilter: (urlQuery.quoteFilter as QuoteFilterType) ?? undefined,
      startDate:
        (urlQuery.startDate as string) ??
        dayjs().subtract(30, "days").format("YYYY-MM-DD"),
      endDate: (urlQuery.endDate as string) ?? dayjs().format("YYYY-MM-DD"),

      // Company Tab
      companyId: (urlQuery.companyId as string) ?? undefined,
      companyShipmentFrequency:
        (urlQuery.companyShipmentFrequency as string) ?? undefined,
      companyFirstQuotedDateStartDate:
        (urlQuery.companyFirstQuotedDateStartDate as string) ?? undefined,
      companyFirstQuotedDateEndDate:
        (urlQuery.companyFirstQuotedDateEndDate as string) ?? undefined,

      // Location Tab
      geoFilter: (urlQuery.geoFilter as GeoFilter) ?? undefined,
      pickupLocationFilterIdentifier:
        (urlQuery.pickupLocationFilterIdentifier as string) ?? undefined,
      pickupCity: (urlQuery.pickupCity as string) ?? undefined,
      pickupLocationType: (urlQuery.pickupLocationType as string) ?? undefined,
      deliveryLocationFilterIdentifier:
        (urlQuery.deliveryLocationFilterIdentifier as string) ?? undefined,
      deliveryCity: (urlQuery.deliveryCity as string) ?? undefined,
      deliveryLocationType:
        (urlQuery.deliveryLocationType as string) ?? undefined,

      // Shipment Details Tab
      minimumShipmentWeight:
        potentiallyParseInt(urlQuery.minimumShipmentWeight as string) ??
        undefined,
      maximumShipmentWeight:
        potentiallyParseInt(urlQuery.maximumShipmentWeight as string) ??
        undefined,
      minimumNumberHandlingUnits:
        potentiallyParseInt(urlQuery.minimumNumberHandlingUnits as string) ??
        undefined,
      maximumNumberHandlingUnits:
        potentiallyParseInt(urlQuery.maximumNumberHandlingUnits as string) ??
        undefined,
      minimumVolume:
        potentiallyParseInt(urlQuery.minimumVolume as string) ?? undefined,
      maximumVolume:
        potentiallyParseInt(urlQuery.maximumVolume as string) ?? undefined,

      // Carrier Options Tab
      bookedCarrierIdentifier:
        (urlQuery.bookedCarrierIdentifier as string) ?? undefined,
      lowestRateCarrierIdentifier:
        (urlQuery.lowestRateCarrierIdentifier as string) ?? undefined,
      hadQuotesFromCarrierIdentifier:
        (urlQuery.hadQuotesFromCarrierIdentifier as string) ?? undefined,
      inCarrierCoverageAreaCarrierIdentifier:
        (urlQuery.inCarrierCoverageAreaCarrierIdentifier as string) ??
        undefined,

      // Accessorials Tab
      pickupLiftGateRequired:
        urlQuery.pickupLiftGateRequired === "true"
          ? true
          : urlQuery.pickupLiftGateRequired === "false"
            ? false
            : undefined,
      deliveryLiftGateRequired:
        urlQuery.deliveryLiftGateRequired === "true"
          ? true
          : urlQuery.deliveryLiftGateRequired === "false"
            ? false
            : undefined,
      pickupInsideRequired:
        urlQuery.pickupInsideRequired === "true"
          ? true
          : urlQuery.pickupInsideRequired === "false"
            ? false
            : undefined,
      deliveryInsideRequired:
        urlQuery.deliveryInsideRequired === "true"
          ? true
          : urlQuery.deliveryInsideRequired === "false"
            ? false
            : undefined,
      dangerous:
        urlQuery.dangerous === "true"
          ? true
          : urlQuery.dangerous === "false"
            ? false
            : undefined,
      protectFromFreezingRequired:
        urlQuery.protectFromFreezingRequired === "true"
          ? true
          : urlQuery.protectFromFreezingRequired === "false"
            ? false
            : undefined,
      deliveryAppointmentRequired:
        urlQuery.deliveryAppointmentRequired === "true"
          ? true
          : urlQuery.deliveryAppointmentRequired === "false"
            ? false
            : undefined,
    };
  }

  const [query, setQuery] = useState(defaultQuery());

  const createMapsApi = useMapsApi();

  const [companies, setCompanies] = useState<ApolloMapCompany[] | undefined>();

  useOnce(async function () {
    const mapsApi = await createMapsApi();
    const response = await mapsApi.getCompanies();
    setCompanies(response);
  });

  const [loading, setLoading] = useState(false);

  const [unfiltered, setUnfilteredShipments] = useState<ShipmentReport[]>([]);
  const navigate = useNavigate();
  const quoteFilter = useGeneralQuoteFilter(query.quoteFilter);

  const shipments = useMemo(
    function () {
      return unfiltered.filter(quoteFilter);
    },
    [unfiltered, quoteFilter],
  );

  useEffect(
    function () {
      let url = `/all-shipments?`;
      url += `bookingStatus=${query.bookingStatus}`;
      if (query.freeTextQuery) {
        url += `&freeTextQuery=${encodeURIComponent(query.freeTextQuery)}`;
      }
      if (query.companyId) {
        url += `&companyId=${query.companyId}`;
      }
      if (query.companyShipmentFrequency) {
        url += `&companyShipmentFrequency=${query.companyShipmentFrequency}`;
      }
      if (query.bookedCarrierIdentifier) {
        url += `&bookedCarrierIdentifier=${query.bookedCarrierIdentifier}`;
      }
      if (query.hadQuotesFromCarrierIdentifier) {
        url += `&hadQuotesFromCarrierIdentifier=${query.hadQuotesFromCarrierIdentifier}`;
      }
      if (query.lowestRateCarrierIdentifier) {
        url += `&lowestRateCarrierIdentifier=${query.lowestRateCarrierIdentifier}`;
      }
      if (query.inCarrierCoverageAreaCarrierIdentifier) {
        url += `&inCarrierCoverageAreaCarrierIdentifier=${query.inCarrierCoverageAreaCarrierIdentifier}`;
      }
      if (query.pickupLocationFilterIdentifier) {
        url += `&pickupLocationFilterIdentifier=${query.pickupLocationFilterIdentifier}`;
      }
      if (query.deliveryLocationFilterIdentifier) {
        url += `&deliveryLocationFilterIdentifier=${query.deliveryLocationFilterIdentifier}`;
      }
      if (query.pickupCity) {
        url += `&pickupCity=${query.pickupCity}`;
      }
      if (query.deliveryCity) {
        url += `&deliveryCity=${query.deliveryCity}`;
      }
      if (query.startDate) {
        url += `&startDate=${query.startDate}`;
      }
      if (query.endDate) {
        url += `&endDate=${query.endDate}`;
      }
      if (query.minimumShipmentWeight) {
        url += `&minimumShipmentWeight=${query.minimumShipmentWeight}`;
      }
      if (query.maximumShipmentWeight) {
        url += `&maximumShipmentWeight=${query.maximumShipmentWeight}`;
      }
      if (query.minimumNumberHandlingUnits) {
        url += `&minimumNumberHandlingUnits=${query.minimumNumberHandlingUnits}`;
      }
      if (query.maximumNumberHandlingUnits) {
        url += `&maximumNumberHandlingUnits=${query.maximumNumberHandlingUnits}`;
      }
      if (query.minimumVolume) {
        url += `&minimumVolume=${query.minimumVolume}`;
      }
      if (query.maximumVolume) {
        url += `&maximumVolume=${query.maximumVolume}`;
      }
      if (query.geoFilter) {
        url += `&geoFilter=${query.geoFilter}`;
      }
      if (query.quoteFilter) {
        url += `&quoteFilter=${query.quoteFilter}`;
      }
      if (query.companyFirstQuotedDateStartDate) {
        url += `&companyFirstQuotedDateStartDate=${query.companyFirstQuotedDateStartDate}`;
      }
      if (query.companyFirstQuotedDateEndDate) {
        url += `&companyFirstQuotedDateEndDate=${query.companyFirstQuotedDateEndDate}`;
      }
      if (query.shipmentState) {
        url += `&shipmentState=${query.shipmentState}`;
      }

      // Add new location type params
      if (query.pickupLocationType) {
        url += `&pickupLocationType=${query.pickupLocationType}`;
      }
      if (query.deliveryLocationType) {
        url += `&deliveryLocationType=${query.deliveryLocationType}`;
      }

      // Add new accessorial params
      if (query.pickupLiftGateRequired !== undefined) {
        url += `&pickupLiftGateRequired=${query.pickupLiftGateRequired}`;
      }
      if (query.deliveryLiftGateRequired !== undefined) {
        url += `&deliveryLiftGateRequired=${query.deliveryLiftGateRequired}`;
      }
      if (query.pickupInsideRequired !== undefined) {
        url += `&pickupInsideRequired=${query.pickupInsideRequired}`;
      }
      if (query.deliveryInsideRequired !== undefined) {
        url += `&deliveryInsideRequired=${query.deliveryInsideRequired}`;
      }
      if (query.dangerous !== undefined) {
        url += `&dangerous=${query.dangerous}`;
      }
      if (query.protectFromFreezingRequired !== undefined) {
        url += `&protectFromFreezingRequired=${query.protectFromFreezingRequired}`;
      }
      if (query.deliveryAppointmentRequired !== undefined) {
        url += `&deliveryAppointmentRequired=${query.deliveryAppointmentRequired}`;
      }

      navigate(url, {
        replace: true,
      });
    },

    [JSON.stringify(query)],
  );

  async function load() {
    setLoading(true);
    const mapsApi = await createMapsApi();
    const response = await mapsApi.generateMap({ apolloMapQuery: query });
    setUnfilteredShipments(response);
    setLoading(false);
  }

  useEffect(
    function () {
      load();
    },

    [JSON.stringify(query)],
  );

  return (
    <Stack align="left" style={{ width: "100%" }}>
      <AllShipmentsScreenHeader
        currentView={currentView}
        setCurrentView={setCurrentView}
        query={query}
        setQuery={setQuery}
        shipments={shipments}
        loading={loading}
        companies={companies}
      />
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255, 255, 255, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <HorizontalStack
        align="right"
        style={{ width: "100%", paddingRight: "4px", marginTop: "4px" }}
      >
        <AllShipmentsScreenStats shipments={shipments} query={query} />
        <Spacer width={8} />
        <ButtonRow>
          <Popover
            content={
              <Stack align="left" style={{ marginLeft: "-16px" }}>
                <DownloadCarrierComparisonButton quotes={shipments} />
              </Stack>
            }
            title="Available Reports"
            trigger="hover"
            placement="left"
          >
            <span
              style={{
                color: Colors.Blue,
                cursor: "pointer",
                fontSize: "11px",
                fontWeight: 600,
              }}
            >
              Available Reports
            </span>
          </Popover>
          <Spacer width={8} />
          <AllShipmentsScreenQueryViewer query={query} />
        </ButtonRow>
      </HorizontalStack>
      {currentView === AllShipmentsScreenViewType.List && (
        <AllShipmentsScreenListTab shipments={shipments} />
      )}
      {currentView === AllShipmentsScreenViewType.Map && (
        <AllShipmentsScreenMapTab query={query} shipments={shipments} />
      )}
      {currentView === AllShipmentsScreenViewType.Calendar && (
        <AllShipmentsScreenCalendarTab query={query} shipments={shipments} />
      )}
    </Stack>
  );
}
