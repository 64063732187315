import { CompanyNoteType } from "@freightsimple/generated-apollo-openapi-client";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { CompanyTabProps } from "../ViewCompanyScreen";
import { CompanyNoteAuthorDropdown } from "./CompanyNoteAuthorDropdown";
import { CompanyNoteTypeDropdown } from "./CompanyNoteTypeDropdown";
import { CompanyNotesTimeline } from "./CompanyNotes/CompanyNotesTimeline";
import { CompanyNotesSignificantFilter } from "./CompanyNotesSignificantDropdown";
import { AddInternalCompanyNoteButton } from "./CompanyNotes/AddInternalCompanyNoteButton";

export function CompanyNotesTab(props: CompanyTabProps) {
  const [noteTypeFilter, setNoteTypeFilter] = useState<CompanyNoteType>();
  const [noteAuthorFilter, setNoteAuthorFilter] = useState<string>();
  const [freeFilter, setFreeFilter] = useState<string>();
  const [collapse, setCollapse] = useState(false);
  const [significantFilter, setSignificantFilter] = useState<boolean>();

  return (
    <Stack align="left">
      <ButtonRow>
        <Form.Item>
          <Button
            onClick={() => {
              setCollapse((prev) => !prev);
            }}
          >
            Toggle Collapse
          </Button>
        </Form.Item>
        <Form.Item label="Filter">
          <Input
            value={freeFilter}
            onChange={function (e) {
              setFreeFilter(e.currentTarget.value);
            }}
          ></Input>
        </Form.Item>

        <Form.Item label="Filter by note type">
          <CompanyNoteTypeDropdown
            mode="multiple"
            value={noteTypeFilter}
            setValue={setNoteTypeFilter}
          />
        </Form.Item>
        <Form.Item label="Filter by significant notes">
          <CompanyNotesSignificantFilter
            value={significantFilter}
            setValue={setSignificantFilter}
          />
        </Form.Item>
        <Form.Item label="Filter by author">
          <CompanyNoteAuthorDropdown
            value={noteAuthorFilter}
            setValue={setNoteAuthorFilter}
            notes={props.data.companyNotes}
          />
        </Form.Item>
        <Form.Item>
          <AddInternalCompanyNoteButton
            companyId={props.data.company.companyId}
            onRefresh={props.onRefresh}
          />
        </Form.Item>
      </ButtonRow>
      <Spacer height={16} />
      <CompanyNotesTimeline
        origin="company-notes-tab"
        data={props.data}
        collapse={collapse}
        onRefresh={props.onRefresh}
        notes={props.data.companyNotes}
        noteTypeFilter={noteTypeFilter}
        noteAuthorFilter={noteAuthorFilter}
        significantFilter={significantFilter}
        freeFilter={freeFilter}
      />
    </Stack>
  );
}
