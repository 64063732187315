import { CompanyNote } from "@freightsimple/generated-apollo-openapi-client";
import { Select } from "antd";
import { groupBy } from "../../Helpers/groupBy";

interface CompanyNoteAuthorDropdownProps {
  value: string | undefined;
  setValue: (_: string | undefined) => void;
  notes: CompanyNote[];
}

export function CompanyNoteAuthorDropdown(
  props: CompanyNoteAuthorDropdownProps,
) {
  const groupedByAuthor = groupBy(props.notes, (o) => o.author ?? "No author");
  const authors = groupedByAuthor
    .map((o) => o.key)
    .filter(function (o) {
      return o.trim() !== "" && o !== "-";
    })
    .sort(function (a, b) {
      return a.localeCompare(b);
    });
  return (
    <Select
      mode="multiple"
      value={props.value}
      onChange={props.setValue}
      style={{ width: 270 }}
      allowClear
    >
      {authors.map(function (author, i) {
        return (
          <Select.Option value={author} key={i}>
            {author}
          </Select.Option>
        );
      })}
    </Select>
  );
}
