import { createContext, Dispatch, SetStateAction } from "react";

export interface ListComparatorContextI<T> {
  leftList: T[];
  rightList: T[];
  changes: number;
  deletions: number;
  additions: number;
  setChanges: Dispatch<SetStateAction<number>>;
  setDeletions: Dispatch<SetStateAction<number>>;
  setAdditions: Dispatch<SetStateAction<number>>;
}

export const ListComparatorContext = createContext<ListComparatorContextI<any>>(
  {
    leftList: [],
    rightList: [],
    changes: 0,
    deletions: 0,
    additions: 0,
    setChanges: () => {},
    setDeletions: () => {},
    setAdditions: () => {},
  },
);
