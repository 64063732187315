import {
  InvoiceAuditProblemConfidence,
  InvoiceAuditProblemFault,
} from "@freightsimple/generated-apollo-openapi-client";
import { assertNever } from "../../Helpers/assertNever";

export function describeInvoiceAuditConfidence(
  confidence: InvoiceAuditProblemConfidence,
  fault: InvoiceAuditProblemFault,
): string {
  switch (fault) {
    case InvoiceAuditProblemFault.Carrier: {
      switch (confidence) {
        case InvoiceAuditProblemConfidence.High:
          return "Carrier has admitted fault";
        case InvoiceAuditProblemConfidence.Medium:
          return "We have evidence the carrier is at fault";
        case InvoiceAuditProblemConfidence.Low:
          return "No evidence, but suspect the carrier is at fault";
        case undefined:
          return "gray";
        default:
          assertNever(confidence);
      }
      break;
    }

    case InvoiceAuditProblemFault.Customer: {
      switch (confidence) {
        case InvoiceAuditProblemConfidence.High:
          return "Sufficient Evidence for Additional Charge";
        case InvoiceAuditProblemConfidence.Medium:
          return "Possible Additional Charge, but needs more evidence";
        case InvoiceAuditProblemConfidence.Low:
          return "Gather more evidence";
        case undefined:
          return "gray";
        default:
          assertNever(confidence);
      }
      break;
    }

    case InvoiceAuditProblemFault.Freightsimple: {
      switch (confidence) {
        case InvoiceAuditProblemConfidence.High:
          return "Definitely us";
        case InvoiceAuditProblemConfidence.Medium:
          return "Probably us";
        case InvoiceAuditProblemConfidence.Low:
          return "Maybe us?";
        case undefined:
          return "gray";
        default:
          assertNever(confidence);
      }
      break;
    }

    case InvoiceAuditProblemFault.Unknown: {
      return "";
    }

    default:
      assertNever(fault);
  }
}
