import { InterlineContext } from "@freightsimple/generated-apollo-openapi-client";
import { Form, Input, Menu, message, Modal } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { CarrierFilter } from "../../Components/CarrierFilter";
import { getCarrierDisplayNameFromCarrierIdentifier } from "../../Helpers/serviceProperties";
import Spacer from "../../Spacer";
import { TabProps } from "./TabProps";

interface EditInterlineInfoMenuButtonProps extends TabProps {
  interlineContext: InterlineContext;
}

export function EditInterlineInfoMenuButton(
  props: EditInterlineInfoMenuButtonProps,
) {
  const shipment = props.shipmentData.shipment.shipment;
  function title(): string {
    if (props.interlineContext === InterlineContext.Advance) {
      return `Edit Advance Interline Info`;
    } else if (props.interlineContext === InterlineContext.Beyond) {
      return `Edit Beyond Interline Info`;
    } else {
      throw new Error("Should not be here");
    }
  }

  function getProNumber(): string {
    if (props.interlineContext === InterlineContext.Advance) {
      return shipment.advanceInterlineProNumber ?? "";
    } else if (props.interlineContext === InterlineContext.Beyond) {
      return shipment.beyondInterlineProNumber ?? "";
    } else {
      throw new Error("Should not be here");
    }
  }

  function getCarrierPickupNumber(): string {
    if (props.interlineContext === InterlineContext.Advance) {
      return shipment.advanceInterlineCarrierPickupNumber ?? "";
    } else if (props.interlineContext === InterlineContext.Beyond) {
      return shipment.beyondInterlineCarrierPickupNumber ?? "";
    } else {
      throw new Error("Should not be here");
    }
  }

  function getCarrierIdentifier(): string {
    if (props.interlineContext === InterlineContext.Advance) {
      return shipment.advanceInterlineCarrierIdentifier ?? "";
    } else if (props.interlineContext === InterlineContext.Beyond) {
      return shipment.beyondInterlineCarrierIdentifier ?? "";
    } else {
      throw new Error("Should not be here");
    }
  }

  function getCarrierDisplayName(): string {
    if (props.interlineContext === InterlineContext.Advance) {
      return shipment.advanceInterlineCarrierDisplayName ?? "";
    } else if (props.interlineContext === InterlineContext.Beyond) {
      return shipment.beyondInterlineCarrierDisplayName ?? "";
    } else {
      throw new Error("Should not be here");
    }
  }

  const shipmentId = props.shipmentData.shipmentId;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);
  const [proNumber, setProNumber] = useState(getProNumber());
  const [carrierPickupNumber, setCarrierPickupNumber] = useState(
    getCarrierPickupNumber(),
  );
  const [carrierIdentifier, setCarrierIdentifier] = useState(
    getCarrierIdentifier(),
  );
  const [carrierDisplayName, setCarrierDisplayName] = useState(
    getCarrierDisplayName(),
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);
    const shipmentApi = await createShipmentApi();
    try {
      await shipmentApi.updateInterlineInformation({
        shipmentId,
        interlineProNumber: proNumber,
        interlineCarrierIdentifier: carrierIdentifier,
        interlineCarrierPickupNumber: carrierPickupNumber,
        interlineCarrierDisplayName: carrierDisplayName,
        interlineContext: props.interlineContext,
      });
      await props.onRefresh();
      message.success("Information updated");
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={title()}
        visible={isModalVisible}
        confirmLoading={loading}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
      >
        <div>
          Enter optionally an interline pro number or carrier pickup number. If
          this is already a carrier we work with, select from the list.
          Otherwise just enter the carrier's name as the interline display name{" "}
        </div>
        <Spacer height={32} />
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Interline Pro Number">
            <Input
              value={proNumber}
              onChange={function (e) {
                setProNumber(e.target.value);
              }}
              style={{ width: "400px" }}
            />
          </Form.Item>
          <Form.Item label="Interline Carrier Pickup Number">
            <Input
              value={carrierPickupNumber}
              onChange={function (e) {
                setCarrierPickupNumber(e.target.value);
              }}
              style={{ width: "400px" }}
            />
          </Form.Item>
          <Form.Item label="Interline Carrier">
            <CarrierFilter
              setCarrierIdentifier={function (
                carrierIdentifier: string | undefined,
              ): void {
                if (carrierIdentifier !== undefined) {
                  setCarrierIdentifier(carrierIdentifier);
                  setCarrierDisplayName(
                    getCarrierDisplayNameFromCarrierIdentifier(
                      carrierIdentifier,
                    ),
                  );
                } else {
                  setCarrierIdentifier("");
                  setCarrierDisplayName("");
                }
              }}
              carrierIdentifier={carrierIdentifier}
            />
          </Form.Item>
          <Form.Item label="Interline Display Name">
            <Input
              value={carrierDisplayName}
              onChange={function (e) {
                setCarrierDisplayName(e.target.value);
              }}
              style={{ width: "400px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal}>{title()}</Menu.Item>
    </>
  );
}
