import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

interface LinkButtonProps {
  children: ReactNode;
  onClick: () => void;
  style?: CSSProperties;
}

type LinkButtonBodyProps = {
  onClick: () => void;
  style?: CSSProperties;
};

const LinkButtonBody = styled("div")<LinkButtonBodyProps>`
  color: #1890ff;
  cursor: pointer;
  &:hover {
    filter: brightness(120%);
  }
`;

export function LinkButton(props: LinkButtonProps) {
  return (
    <LinkButtonBody style={props.style} onClick={props.onClick}>
      {props.children}
    </LinkButtonBody>
  );
}
