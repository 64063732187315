import {
  Company,
  LogSalesCallInput,
  SalesContact,
} from "@freightsimple/generated-apollo-openapi-client";
import { Modal, message } from "antd";
import { useCompaniesApi } from "../../../Apis/Apis";
import { ButtonRow } from "../../../Components/ButtonRow";
import HorizontalStack from "../../../Components/HorizontalStack";
import Stack from "../../../Components/Stack";
import { UseDisplayReturn } from "../../../Hooks/useDisplay";
import { LogSalesCallForm, LogSalesCallFormValues } from "./LogSalesCallForm";
import { useState } from "react";

interface InboundLogSalesCallModalProps {
  display: UseDisplayReturn;
  company: Company;
  salesContacts: SalesContact[];
  onRefresh: () => Promise<void>;
  companyNoteId?: string;
  salesContactId?: string;
}

export function InboundLogSalesCallModal({
  display,
  company,
  salesContacts,
  onRefresh,
  companyNoteId,
  salesContactId,
}: InboundLogSalesCallModalProps) {
  const createCompaniesApi = useCompaniesApi();

  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState<LogSalesCallFormValues>({
    significant: true,
    notes: "",
    whatHappend: "",
    temperature: company.temperature,
    shipmentFrequency: company.shipmentFrequency,
    salesContactId: salesContactId ?? company.defaultSalesContactId,
  });

  function handleFormChange(key: keyof LogSalesCallFormValues, value: any) {
    setForm((prev) => ({ ...prev, [key]: value }));
  }

  async function handleSubmit(input: LogSalesCallInput) {
    setSaving(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.logSalesCall({
        logSalesCallInput: {
          ...input,
          companyNoteId: companyNoteId,
        },
      });
      await onRefresh();
      message.success("Saved!");
    } catch {
      message.error("Eek. Something went wrong");
    }
    setSaving(false);
    display.hide();
  }

  return (
    <Modal
      title={
        <Stack align="left">
          <span>Log Sales Call</span>
          {company.companyName}
        </Stack>
      }
      open={display.status}
      onCancel={display.hide}
      footer={
        <HorizontalStack align="right">
          <ButtonRow>
            <LogSalesCallForm.SubmitButton loading={saving} />
          </ButtonRow>
        </HorizontalStack>
      }
      width={800}
      destroyOnClose
    >
      <LogSalesCallForm
        company={company}
        salesContacts={salesContacts}
        form={form}
        handleFormChange={handleFormChange}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}
