import { GroupedElements } from "./groupBy";

export interface GroupedShowGroups<K, E> {
  key: K;
  showGroups: ShowGroup<E>[];
}

/**
 * @param grouped Grouped elements
 * @param showElement A function to determine if the element should be showned or not
 */
export function groupCollapsibleElements<K, E>(
  grouped: GroupedElements<K, E>[],
  showElement: (element: E) => boolean,
): GroupedShowGroups<K, E>[] {
  return grouped.map((group: GroupedElements<K, E>) => {
    return {
      key: group.key,
      showGroups: createShowGroups(group.value, showElement),
    };
  });
}

export interface ShowGroup<E> {
  show: boolean;
  elements: E[];
}

/**
 * @param elements Elements to group in show groups
 * @param showElement A function to determine if the element should be showned or not
 */
export function createShowGroups<E>(
  elements: E[],
  showElement: (element: E) => boolean,
): ShowGroup<E>[] {
  if (elements.length === 0) {
    return [];
  }

  /**
   * Create the first show group
   */
  let groupIndex = 0;
  const groups = [
    {
      show: showElement(elements[0]),
      elements: [elements[0]],
    },
  ];

  /**
   * Start the loop with the second element
   * since we already pushed the first element
   */
  for (let index = 1; index < elements.length; index++) {
    const current = elements[index];
    const showCurrentElement = showElement(current);
    const currentGroup = groups[groupIndex];

    /**
     * If the element matches the show status of the current group then we add it
     * else we create a new group with its show status
     */
    if (currentGroup.show === showCurrentElement) {
      currentGroup.elements.push(current);
      continue;
    }

    groups.push({
      show: showCurrentElement,
      elements: [current],
    });
    groupIndex++;
  }
  return groups;
}
