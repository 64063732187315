import { CollapsibleNote } from "../../../Components/NotesBase/CollapseNote";
import { ModifiedGenericNote } from "../../../Components/NotesBase/ModifiedGenericNote";
import { NoteAuthorHeadline } from "../../../Components/NotesBase/NoteHeadline";
import { NoteSubmessage } from "../../../Components/NotesBase/NoteSubmessage";
import {
  BasicCompanyNoteElement,
  CompanyNoteElementProps,
} from "../CompanyNote";

export function ApolloActionCompanyNoteElement(props: CompanyNoteElementProps) {
  const { note, collapseActive } = props;

  const metadata = JSON.parse(note.metadata!);
  const identifier = metadata.identifier;

  if (identifier === "edit-company") {
    return (
      <CollapsibleNote
        collapseActive={collapseActive}
        header={
          <div>
            <NoteAuthorHeadline headline={note.subject} author={note.author} />
            <NoteSubmessage>{note.subMessage}</NoteSubmessage>
          </div>
        }
      >
        <ModifiedGenericNote
          beforeData={metadata.data.before}
          afterData={metadata.data.after}
        />
      </CollapsibleNote>
    );
  }

  return <BasicCompanyNoteElement {...props} />;
}
