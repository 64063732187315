// This import must remain first for proper error tracking initialization
import "./setup/sentry";

import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { ErrorBoundaryView } from "./Components/ErrorBoundaryView";
import { msalConfig } from "./authConfig";
import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css";
import "./setup/dayjs";
import { App } from "./App";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ErrorBoundaryView>
      <App pca={msalInstance} />
    </ErrorBoundaryView>
  </BrowserRouter>,
  // </React.StrictMode>
);
