interface WarningComponentProps {
  marginRight?: number;
  style?: React.CSSProperties;
}

export function WarningComponent(props: WarningComponentProps) {
  return (
    <span
      style={{
        fontFamily:
          '"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        marginRight: props.marginRight,
        ...props.style,
      }}
    >
      {"\u26A0\uFE0F"}
    </span>
  );
}

interface ConditionalWarningComponentProps {
  condition: boolean;
  style?: React.CSSProperties;
}

export function ConditionalWarningComponent(
  props: ConditionalWarningComponentProps,
) {
  if (props.condition) {
    return <WarningComponent marginRight={4} style={props.style} />;
  }
  return null;
}
