import { ReactNode } from "react";
import { CallContextProvider } from "./CallContext/CallContext";
import { RefreshContextProvider } from "./RefreshContextProvider";

export function AppContextProvider({ children }: { children: ReactNode }) {
  return (
    <RefreshContextProvider>
      <CallContextProvider>{children}</CallContextProvider>
    </RefreshContextProvider>
  );
}
