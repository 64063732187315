import {
  CarrierInvoice,
  CarrierInvoiceState,
} from "@freightsimple/generated-apollo-openapi-client";
import { message, Tag } from "antd";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import { TabProps } from "./TabProps";

interface MarkApprovedWhenPaymentPendingCarrierInvoiceMenuItemProps
  extends TabProps {
  carrierInvoice: CarrierInvoice;
}

export function MarkApprovedWhenPaymentPendingCarrierInvoiceMenuItem(
  props: MarkApprovedWhenPaymentPendingCarrierInvoiceMenuItemProps,
) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  async function onConfirm() {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.markPaymentPendingCarrierInvoiceApproved({
        carrierInvoiceId,
      });
      message.success("Invoice returned to approved state");
      props.onRefresh();
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  if (
    props.carrierInvoice.invoiceState !== CarrierInvoiceState.PaymentPending
  ) {
    return <></>;
  }

  return (
    <ConfirmMenuItem
      onConfirm={onConfirm}
      question="Are you sure you want to mark this payment pending invoice as approved? This should only be necessary if it was marked as payment pending (or the Monday payment task was closed) by mistake"
      okText={"Yes, move this invoice back to approved"}
      tooltip={
        "If the Monday payment task was marked as done by mistake, or the invoice was marked as payment pending incorrectly by some other means, then you can use this to undo it and return it to approved state"
      }
    >
      <Tag color="red">Advanced</Tag> Mark Payment Pending Invoice as Approved
    </ConfirmMenuItem>
  );
}
