import { Note, NoteType } from "@freightsimple/generated-apollo-openapi-client";
import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import Colors from "../../Components/Colors";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { groupBy } from "../../Helpers/groupBy";
import { isBlank } from "@freightsimple/shared";
import { stringToColor } from "../../Helpers/stringToColor";
import { unique } from "../../Helpers/unique";
import Spacer from "../../Spacer";
import { TabProps } from "./TabProps";
import dayjs from "dayjs";

interface ViewEmailConversationsTableDataItem {
  subject: string;
  url: string;
  numberEmails: number;
  firstEmailDate: string;
  lastEmailDate: string;
  participants: string[];
}

interface ViewIntercomConversationsTableDataItem {
  messages: string[];
  url: string;
  numberEmails: number;
  firstEmailDate: string;
  lastEmailDate: string;
}

function getParticipants(g: { key: string; value: Note[] }): string[] {
  const list: string[] = [];
  g.value.forEach(function (v) {
    const values = unique([
      v.emailFrom!.emailAddress!,
      ...v.emailTo!.map((e) => e.emailAddress!),
      ...v.emailCc!.map((e) => e.emailAddress!),
    ]);
    values.forEach((o) => list.push(o));
  });
  return unique(list);
}

interface ViewEmailConversationsTableProps extends TabProps {
  filter: string;
}

function ViewEmailConversationsTable(props: ViewEmailConversationsTableProps) {
  function applyFilter(note: Note): boolean {
    if (isBlank(props.filter)) {
      return true;
    } else {
      return JSON.stringify(note)
        .toLowerCase()
        .includes(props.filter.toLowerCase());
    }
  }
  const emailNotes = props.shipmentData.notesTab.notes
    .filter((n) => n.noteType === NoteType.Email)
    .filter(applyFilter);

  const data = groupBy(emailNotes, (n) => n.link!).map(
    function (g): ViewEmailConversationsTableDataItem {
      return {
        subject: g.value[0].emailSubject!,
        url: g.key!,
        numberEmails: g.value.length,
        firstEmailDate: g.value.sort(function (a, b) {
          return dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf();
        })[0].createdAt!,
        lastEmailDate: g.value.sort(function (a, b) {
          return dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf();
        })[0].createdAt!,
        participants: getParticipants(g),
      };
    },
  );

  const columns: DataTableColumn<ViewEmailConversationsTableDataItem>[] = [
    {
      title: "Subject",
      key: "subject",
      render: (o) => (
        <div
          style={{
            paddingLeft: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
            borderLeft: `12px solid ${stringToColor(o.url ?? "")}`,
          }}
        >
          <div style={{ fontWeight: 600 }}>{o.subject}</div>
          <div style={{ color: Colors.LightText, fontSize: "12px" }}>
            {o.participants.join(", ")}
          </div>
        </div>
      ),
    },

    {
      title: "Number Emails",
      key: "numberEmails",
      render: (o) => <div>{o.numberEmails}</div>,
    },

    {
      title: "Dates",
      key: "dates",
      render: (o) => (
        <>
          {o.numberEmails === 1 && (
            <Stack align="left">
              <div>{dayjs(o.lastEmailDate).format("ddd, MMM Do, h:mm a")}</div>
            </Stack>
          )}
          {o.numberEmails > 1 && (
            <Stack align="left">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  marginTop: "8px",
                  marginBottom: "-4px",
                }}
              >
                First Email
              </div>
              <div>{dayjs(o.firstEmailDate).format("ddd, MMM Do, h:mm a")}</div>
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  marginTop: "8px",
                  marginBottom: "-4px",
                }}
              >
                Most Recent Email
              </div>
              <div>{dayjs(o.lastEmailDate).format("ddd, MMM Do, h:mm a")}</div>
            </Stack>
          )}
        </>
      ),
    },

    {
      title: "Actions",
      key: "auditId",
      render: function (o: ViewEmailConversationsTableDataItem) {
        function openLink() {
          window.open(o.url, "_blank");
        }

        return (
          <HorizontalStack>
            <Button onClick={openLink}>View Front Conversation</Button>
          </HorizontalStack>
        );
      },
    },
  ];

  if (data.length === 0) {
    return <></>;
  }

  return (
    <>
      <div>Email Conversations</div>
      <DataTable columns={columns} data={data} pagination={false} />
      <Spacer height={32} />
    </>
  );
}

function ViewIntercomConversationsTable(
  props: ViewEmailConversationsTableProps,
) {
  function applyFilter(note: Note): boolean {
    if (isBlank(props.filter)) {
      return true;
    } else {
      return JSON.stringify(note)
        .toLowerCase()
        .includes(props.filter.toLowerCase());
    }
  }
  const intercomNotes = props.shipmentData.notesTab.notes
    .filter((n) => n.noteType === NoteType.Intercom)
    .filter(applyFilter);

  const data = groupBy(intercomNotes, (n) => n.link!).map(
    function (g): ViewIntercomConversationsTableDataItem {
      return {
        messages: g.value
          .sort(function (a, b) {
            return dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf();
          })
          .slice(0, 5)
          .map((n) => n.message!),
        url: g.key!,
        numberEmails: g.value.length,
        firstEmailDate: g.value.sort(function (a, b) {
          return dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf();
        })[0].createdAt!,
        lastEmailDate: g.value.sort(function (a, b) {
          return dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf();
        })[0].createdAt!,
      };
    },
  );

  const columns: DataTableColumn<ViewIntercomConversationsTableDataItem>[] = [
    {
      title: "Subject",
      key: "subject",
      render: (o) => (
        <div
          style={{
            paddingLeft: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
            borderLeft: `12px solid ${stringToColor(o.url ?? "")}`,
            maxWidth: "600px",
          }}
        >
          {o.messages.map(function (m) {
            return (
              <div key={m} style={{ fontWeight: 600, marginBottom: "8px" }}>
                &gt; {m}
              </div>
            );
          })}
        </div>
      ),
    },

    {
      title: "Number Messages",
      key: "numberEmails",
      render: (o) => <div>{o.numberEmails}</div>,
    },

    {
      title: "Dates",
      key: "dates",
      render: (o) => (
        <>
          {o.numberEmails === 1 && (
            <Stack align="left">
              <div>{dayjs(o.lastEmailDate).format("ddd, MMM Do, h:mm a")}</div>
            </Stack>
          )}
          {o.numberEmails > 1 && (
            <Stack align="left">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  marginTop: "8px",
                  marginBottom: "-4px",
                }}
              >
                First Email
              </div>
              <div>{dayjs(o.firstEmailDate).format("ddd, MMM Do, h:mm a")}</div>
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  marginTop: "8px",
                  marginBottom: "-4px",
                }}
              >
                Most Recent Email
              </div>
              <div>{dayjs(o.lastEmailDate).format("ddd, MMM Do, h:mm a")}</div>
            </Stack>
          )}
        </>
      ),
    },

    {
      title: "Actions",
      key: "auditId",
      render: function (o: ViewIntercomConversationsTableDataItem) {
        function openLink() {
          window.open(o.url, "_blank");
        }

        return (
          <HorizontalStack>
            <Button onClick={openLink}>View Front Conversation</Button>
          </HorizontalStack>
        );
      },
    },
  ];

  if (data.length === 0) {
    return <></>;
  }

  return (
    <>
      <div>Intercom Conversations</div>
      <DataTable columns={columns} data={data} pagination={false} />
      <Spacer height={32} />
    </>
  );
}

export function ViewEmailConversationsButton(props: TabProps) {
  const title = `View Conversations`;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filter, setFilter] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <HorizontalStack align="spread">
            <div>{title}</div>
            <div style={{ marginRight: "32px" }}>
              <Form.Item label="Filter" style={{ width: "400px" }}>
                <Input
                  value={filter}
                  onChange={function (e) {
                    setFilter(e.target.value);
                  }}
                  width={1000}
                />
              </Form.Item>
            </div>
          </HorizontalStack>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1600}
      >
        <ViewEmailConversationsTable {...props} filter={filter} />

        <ViewIntercomConversationsTable {...props} filter={filter} />
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
