import {
  ApolloMapQuery,
  ShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import { MapComponent } from "../../Components/MapComponent";

interface AllShipmentsScreenMapTabProps {
  query: ApolloMapQuery;
  shipments: ShipmentReport[];
}

export function AllShipmentsScreenMapTab(props: AllShipmentsScreenMapTabProps) {
  return (
    <div>
      <MapComponent shipments={props.shipments} />
    </div>
  );
}
