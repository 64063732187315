import { LineItem } from "@freightsimple/generated-apollo-openapi-client";

import { Button, Form, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import Spacer from "../../Spacer";
import { AddLineItemButton } from "./AddLineItemButton";
import { LineItemsTable } from "./LineItemsTable";
import { TabProps } from "./TabProps";
import { TextArea } from "../../Types/AntHelperTypes";

export function EditLineItemsButton(props: TabProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!;
  const createShipmentApi = useShipmentsApi();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lineItems, setLineItems] = useState(
    props.shipmentData.shipment.shipment.lineItems!,
  );
  const [saving, setSaving] = useState(false);

  const [reason, setReason] = useState<string>();

  useEffect(
    function () {
      if (isModalVisible) {
        // Reset the line items each time we open the modal
        setLineItems(props.shipmentData.shipment.shipment.lineItems!);
      }
    },

    [isModalVisible],
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (reason === undefined) {
      message.warning("You need to describe a reason");
      return;
    }

    setSaving(true);
    try {
      const shipmentApi = await createShipmentApi();
      await shipmentApi.updateLineItems({
        reason: reason,
        shipmentId,
        lineItem: lineItems,
      });
      await props.onRefresh();
      setIsModalVisible(false);

      message.success("Saved Line Items");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Edit Line Items"
        visible={isModalVisible}
        okText="Save changes"
        onOk={handleOk}
        onCancel={handleCancel}
        width={1450}
        confirmLoading={saving}
      >
        <LineItemsTable
          lineItems={lineItems}
          editable
          updateLineItems={setLineItems}
        />
        <Spacer height={8} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <AddLineItemButton
            addLineItem={function (newLineItem: LineItem) {
              setLineItems((_lineItems) => [..._lineItems, newLineItem]);
            }}
          />
          <Form layout="horizontal" colon={false}>
            <Form.Item label="Reason">
              <TextArea
                rows={1}
                value={reason}
                onChange={function (e) {
                  setReason(e.target.value);
                }}
                style={{ width: "900px" }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Button onClick={showModal}>Edit</Button>
    </>
  );
}
