import { CarrierInvoiceState } from "@freightsimple/generated-apollo-openapi-client";

export function describeCarrierInvoiceState(
  carrierInvoiceState: CarrierInvoiceState,
) {
  switch (carrierInvoiceState) {
    case CarrierInvoiceState.Issued:
      return `🤔 Issued`;
    case CarrierInvoiceState.Approved:
      return `👍 Approved`;
    case CarrierInvoiceState.PaymentPending:
      return `👌 Payment Pending`;
    case CarrierInvoiceState.PaymentConfirmed:
      return `✅ Payment Confirmed`;
    case CarrierInvoiceState.Voided:
      return `🌑 Voided`;
    default:
      return carrierInvoiceState;
  }
}
