import { FreightClaim } from "@freightsimple/generated-apollo-openapi-client";
import { UseDisplayReturn } from "../../Hooks/useDisplay";
import { Form, message, Modal } from "antd";
import { useState } from "react";
import { useFreightClaimsApi } from "../../Apis/Apis";
import { AdminDropdown } from "./AdminDropdown";

interface ChangeClaimAssigneeModalProps {
  display: UseDisplayReturn;
  claim: FreightClaim;
  onRefresh: () => Promise<void>;
}

export function ChangeClaimAssigneeModal({
  display,
  claim,
  onRefresh,
}: ChangeClaimAssigneeModalProps) {
  const createClaimApi = useFreightClaimsApi();
  const [assignee, setAssignee] = useState(claim.assignee);
  const [loading, setLoading] = useState(false);

  async function handleOk() {
    setLoading(() => true);
    if (!claim.freightClaimId || !assignee) {
      message.error("Oops. Something went wrong!");
      return;
    }
    try {
      const claimApi = await createClaimApi();
      await claimApi.setFreightClaimAssignee({
        claimId: claim.freightClaimId,
        assignee,
      });
      await onRefresh();
      message.success("Claim Assignee Updated!");
    } catch (e) {
      message.error("Oops. Something went wrong!");
      console.error(e);
    }
    display.hide();
    setLoading(() => false);
  }

  function handleCancel() {
    display.hide();
  }
  return (
    <Modal
      title={"Assign"}
      visible={display.status}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      width={800}
      confirmLoading={loading}
    >
      <Form
        style={{ width: "800px" }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 19, offset: 1 }}
        layout="horizontal"
        colon={false}
      >
        <Form.Item label="Claim Assignee">
          <AdminDropdown
            value={assignee}
            setValue={setAssignee}
            counter={undefined}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
