import { IPublicClientApplication } from "@azure/msal-browser";
// MSAL imports
import { AuthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import { Layout } from "antd";
import "./App.css";
import { SalesCallFloatingButton } from "./Components/SalesCallFloatingButton";
import { AppContextProvider } from "./Contexts/AppContext";
import { MenuBar } from "./Navigation/MenuBar";
import { AppRouter } from "./Routing/AppRouter";
import { Content } from "./Types/AntHelperTypes";
import { isAuthenticationDisabled } from "./environment";

type AppProps = {
  pca: IPublicClientApplication;
};

export function App({ pca }: AppProps) {
  if (isAuthenticationDisabled()) {
    return (
      <>
        <MenuBar />
        <Layout className="layout">
          <Content>
            <div className="site-layout-content">
              <AppRouter />
            </div>
          </Content>
        </Layout>
      </>
    );
  }

  return (
    <MsalProvider instance={pca}>
      <MenuBar />
      <Layout className="layout">
        <Content>
          <div className="site-layout-content">
            <AuthenticatedTemplate>
              <AppContextProvider>
                <AppRouter />
                <SalesCallFloatingButton />
              </AppContextProvider>
            </AuthenticatedTemplate>
          </div>
        </Content>
      </Layout>
    </MsalProvider>
  );
}
