import {
  CustomerInvoice,
  CustomerInvoiceState,
  CustomerInvoiceType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, message, Popconfirm, Tooltip } from "antd";
import { useCustomerInvoiceApi } from "../../Apis/Apis";

interface FullCustomerRefundButtonProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

export function FullCustomerRefundButton(props: FullCustomerRefundButtonProps) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!;
  async function confirm() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.voidOrRefundFullInvoice({
        customerInvoiceId,
      });
      message.success("Refund processed");
      await props.onRefresh();
    } catch (e: any) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  const enabled =
    props.customerInvoice.customerInvoiceState ===
      CustomerInvoiceState.Settled ||
    props.customerInvoice.customerInvoiceState ===
      CustomerInvoiceState.Reconciled;

  const isRefundable =
    props.customerInvoice.invoiceType === CustomerInvoiceType.ShipmentCharge ||
    props.customerInvoice.invoiceType === CustomerInvoiceType.AdditionalCharge;

  if (!enabled || !isRefundable) {
    return <></>;
  }

  return (
    <Popconfirm
      title="Are you sure to refund this invoice?"
      onConfirm={confirm}
      okText="Yes, refund this full invoice"
      cancelText="Cancel"
      disabled={!enabled}
    >
      <Tooltip
        placement="topLeft"
        title="Use this if you need to refund the entire invoice. If you are trying to cancel the shipment, it is better to cancel the shipment which will also process a refund"
      >
        <Button disabled={!enabled} danger>
          🔙 Refund Full Invoice
        </Button>
      </Tooltip>
    </Popconfirm>
  );
}
