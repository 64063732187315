import { ProNumberHistory } from "@freightsimple/generated-apollo-openapi-client";
import { Menu, MenuItemProps, Modal } from "antd";
import { useEffect, useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import CarrierLogo from "../../Components/CarrierLogo";
import { CreatedAt } from "../../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import { Loading } from "../../Components/Loading";
import { DeleteProNumberHistoryModal } from "./DeleteProNumberHistoryModal";

function ProNumberHistoryTable(props: ViewProNumberHistoryMenuItemProps) {
  const shipmentId = props.shipmentId;
  const createShipmentApi = useShipmentsApi();
  const [proNumberHistory, setProNumberHistory] = useState<
    ProNumberHistory[] | undefined
  >();

  async function refresh() {
    const shipmentApi = await createShipmentApi();

    const response = await shipmentApi.viewProNumberHistory({ shipmentId });
    setProNumberHistory(response);
  }

  useEffect(
    function () {
      refresh();
    },

    [shipmentId],
  );

  const columns: DataTableColumn<ProNumberHistory>[] = [
    {
      title: "Created At",
      render: (o) => <CreatedAt timestamp={o.createdAt!} />,
    },
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Pro Number",
      render: (o) => <div>{o.proNumber}</div>,
    },
    {
      title: "Reason",
      render: (o) => <div>{o.reason}</div>,
    },
    {
      title: "Admin Email",
      render: (o) => <div>{o.adminEmail}</div>,
    },
    {
      title: "Actions",
      render: (o) => (
        <DeleteProNumberHistoryModal
          proNumberHistory={o}
          onRefresh={async () => {
            refresh();
            props.onRefresh();
          }}
        />
      ),
    },
  ];

  if (proNumberHistory === undefined) {
    return <Loading />;
  }

  return (
    <DataTable pagination={false} columns={columns} data={proNumberHistory} />
  );
}

interface ViewProNumberHistoryMenuItemProps extends MenuItemProps {
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

export function ViewProNumberHistoryMenuItem(
  props: ViewProNumberHistoryMenuItemProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Pro Number History"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <ProNumberHistoryTable {...props} />
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        View Pro Number History
      </Menu.Item>
    </>
  );
}
