import styled from "styled-components";
import Colors from "../../Components/Colors";
import { Dollar } from "../../Components/Dollar";
import Stack from "../../Components/Stack";
import { assertNever } from "../../Helpers/assertNever";
import Spacer from "../../Spacer";
import { AcceptancePercentage } from "./AcceptancePercentage";
import { CellValue } from "./generateCompiledData";
import { ReportType } from "./ReportType";

const CellTitle = styled.div`
  font-size: 9px;
  color: ${Colors.LightText};
  margin-top: 8px;
`;

interface ValueElementProps {
  children: number | undefined;
  delta: number | undefined;
  reportType: ReportType;
  cellValue: CellValue;
}

function colorFromDelta(delta: number | undefined) {
  if (delta === undefined || delta === 0) {
    return "black";
  } else if (delta > 0) {
    return Colors.DarkGreen;
  } else {
    return Colors.Red;
  }
}

interface CellValueElementProps {
  cellValue: CellValue;
}

function CellValueElement(props: CellValueElementProps) {
  return (
    <Stack align="left" style={{ width: "100%" }}>
      <CellTitle style={{ fontWeight: "600" }}>Acceptance:</CellTitle>
      <AcceptancePercentage>
        {props.cellValue.acceptance ?? 0}
      </AcceptancePercentage>
      <Spacer height={8} />
      <CellTitle>Quoted:</CellTitle>
      <div>{props.cellValue.quotes}</div>
      <CellTitle>Booked:</CellTitle>
      <div>{props.cellValue.shipmentsBooked}</div>
      <CellTitle>Revenue:</CellTitle>
      <Dollar>{props.cellValue.revenue}</Dollar>
    </Stack>
  );
}

export function ValueElement(props: ValueElementProps) {
  if (props.children === undefined) {
    return <div>-</div>;
  }

  switch (props.reportType) {
    case ReportType.Revenue:
      return <Dollar>{props.children}</Dollar>;
    case ReportType.ShipmentsBooked:
      return (
        <div style={{ color: colorFromDelta(props.delta) }}>
          {props.children}
        </div>
      );
    case ReportType.Quotes:
      return (
        <div style={{ color: colorFromDelta(props.delta) }}>
          {props.children}
        </div>
      );
    case ReportType.Acceptance:
      if (props.cellValue.quotes === 0) {
        return <>-</>;
      } else {
        return <CellValueElement cellValue={props.cellValue} />;
      }
    default:
      assertNever(props.reportType);
  }
}
