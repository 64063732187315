import { useEffect, useRef } from "react";

interface IsolatedHTMLProps {
  html: string;
}

export const IsolatedHTML: React.FC<IsolatedHTMLProps> = ({ html }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      let shadowRoot: ShadowRoot;

      // Remove any existing shadow root
      if (containerRef.current.shadowRoot) {
        containerRef.current.attachShadow({ mode: "open" });
      }

      // Create a new shadow root if it doesn't exist
      // eslint-disable-next-line prefer-const
      shadowRoot =
        containerRef.current.shadowRoot ||
        containerRef.current.attachShadow({ mode: "open" });

      // Create a container for the HTML content
      const contentContainer = document.createElement("div");
      contentContainer.style.width = "700px";
      contentContainer.innerHTML = html;

      // Clear previous content and append new content
      shadowRoot.innerHTML = "";
      shadowRoot.appendChild(contentContainer);
    }
  }, [html]);

  return <div ref={containerRef} />;
};
