import { Note, NoteType } from "@freightsimple/generated-apollo-openapi-client";
import {
  BoxPlotFilled,
  CloudServerOutlined,
  CommentOutlined,
  MailOutlined,
  MessageOutlined,
  NumberOutlined,
  PhoneOutlined,
  QuestionOutlined,
  RocketOutlined,
  StopOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { CSSProperties } from "react";
import { assertNever } from "../../Helpers/assertNever";
import { colorForNoteIcon } from "./colorForNoteIcon";

interface IconForNoteProps {
  note: Note;
}

export function IconForNote(props: IconForNoteProps) {
  const iconStyle: CSSProperties = {
    color: colorForNoteIcon(props.note),
    fontSize: "24px",
    marginRight: "8px",
    marginLeft: "8px",
  };
  if (!props.note.noteType) {
    return <QuestionOutlined style={iconStyle} />;
  }
  const noteType = props.note.noteType!;
  switch (noteType) {
    case NoteType.ShipmentCancelled:
      return <StopOutlined style={iconStyle} />;
    case NoteType.ShipmentModified:
      return <BoxPlotFilled style={iconStyle} />;
    case NoteType.ApolloAction:
      return <RocketOutlined style={iconStyle} />;
    case NoteType.Dialpad:
      return <PhoneOutlined style={iconStyle} />;
    case NoteType.DashboardAction:
      return <UserOutlined style={iconStyle} />;
    case NoteType.SalesforceCall:
      return <TeamOutlined style={iconStyle} />;
    case NoteType.Email:
      return <MailOutlined style={iconStyle} />;
    case NoteType.Intercom:
      return <CommentOutlined style={iconStyle} />;
    case NoteType.MondayComment:
      return <UnorderedListOutlined style={iconStyle} />;
    case NoteType.ReferenceNumberChanged:
      return <NumberOutlined style={iconStyle} />;
    case NoteType.SystemMessage:
      return <CloudServerOutlined style={iconStyle} />;
    case NoteType.InternalNote:
      return <MessageOutlined style={iconStyle} />;
    case NoteType.SystemErrorUnused:
      return <></>;
    default:
      assertNever(noteType);
  }
}
