import {
  Company,
  LogSalesCallInput,
  QualificationStatus,
  SalesContact,
} from "@freightsimple/generated-apollo-openapi-client";

import { Button, Form, message, Select, Switch } from "antd";
import HorizontalStack from "../../../Components/HorizontalStack";
import Stack from "../../../Components/Stack";
import { SalesContactsDropdown } from "../../SalessComponents/SalesContactsDropdown";
import { ControlFreightDropdown } from "../ControlFreightDropdown";
import { ShipmentFrequencyDropdown } from "../ShipmentFrequencyDropdown";
import { TemperatureDropdown } from "../TemperatureDropdown";
import { buildLogSalesCallInput } from "./buildLogSalesCallInput";
import { TextArea } from "../../../Types/AntHelperTypes";

export interface LogSalesCallFormValues {
  salesContactId?: string | undefined;

  significant: boolean;
  notes: string;
  whatHappend: string;

  temperature?: string | undefined;
  shipmentFrequency?: string | undefined;

  controlFreight?: string | undefined;
}

interface LogSalesCallFormProps {
  onSubmit: (_: LogSalesCallInput) => Promise<void>;
  company: Company;
  salesContacts: SalesContact[];
  form: LogSalesCallFormValues;
  handleFormChange: (key: keyof LogSalesCallFormValues, value: any) => any;
}

function LogSalesCallForm({
  company,
  salesContacts,
  form,
  handleFormChange,
  onSubmit,
}: LogSalesCallFormProps) {
  const input = buildLogSalesCallInput(form, company);

  const isLead = company.qualificationStatus !== QualificationStatus.Qualified;

  async function handleSubmit() {
    if (form.significant && form.notes === "") {
      message.warning("Enter some notes");
      return;
    }

    if (!form.significant && form.notes === "" && form.whatHappend === "") {
      message.warning("Enter notes/what happened");
      return;
    }
    if (form.significant && isLead && form.controlFreight === "") {
      message.warning("Please indicate if they control LTL freight");
      return;
    }

    await onSubmit(input);
  }

  return (
    <Form
      id={"log-sales-call-form"}
      style={{ width: "100%" }}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17, offset: 1 }}
      layout="horizontal"
      colon={false}
      onSubmitCapture={handleSubmit}
    >
      <Form.Item
        label="Significant?"
        tooltip="A significant call is when you have a meaningful interaction
                with a decision maker. A meaningful interaction is when you
                learn something or tell them something."
      >
        <HorizontalStack>
          <Switch
            checked={form.significant}
            onChange={(e) => {
              handleFormChange("significant", e);
            }}
          />
        </HorizontalStack>
      </Form.Item>
      {!form.significant && (
        <>
          <Form.Item label="What happened?">
            <Select
              value={form.whatHappend}
              onChange={(e) => {
                handleFormChange("whatHappend", e);
              }}
            >
              <Select.Option value="Not in today">Not in today</Select.Option>
              <Select.Option value="Told to call back">
                Told to call back
              </Select.Option>
              <Select.Option value="Left voicemail">
                Left voicemail
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Any other notes?">
            <TextArea
              style={{ width: "100%", height: "50px" }}
              value={form.notes}
              onChange={function (e) {
                handleFormChange("notes", e.target.value);
              }}
            />
          </Form.Item>
        </>
      )}
      {form.significant && (
        <Form.Item label="Recap your call">
          <Stack align="left">
            <TextArea
              style={{ width: "100%", height: "150px" }}
              value={form.notes}
              onChange={function (e) {
                handleFormChange("notes", e.target.value);
              }}
              placeholder="Enter call notes here. Use bullet points. **Bold** like this."
            />
            <span style={{ fontSize: "12px", marginTop: "4px" }}>
              <a
                href="https://www.notion.so/freightsimple/Sales-Playbook-2d66299dd8ae422ba570c657e67829d0?pvs=4"
                target="_blank"
                rel="noreferrer"
              >
                What makes good call notes?
              </a>
            </span>
          </Stack>
        </Form.Item>
      )}
      <Form.Item label="Who did you talk to?">
        <SalesContactsDropdown
          contactId={form.salesContactId}
          setContactId={(e) => {
            handleFormChange("salesContactId", e);
          }}
          company={company}
          salesContacts={salesContacts}
        />
      </Form.Item>
      {form.significant && (
        <>
          {company.qualificationStatus !== QualificationStatus.Qualified && (
            <>
              <Form.Item label="Do they control LTL freight?">
                <ControlFreightDropdown
                  value={form.controlFreight}
                  onChange={(e) => {
                    handleFormChange("controlFreight", e);
                  }}
                />
              </Form.Item>
              {input?.shouldQualify && (
                <Form.Item label="Update Frequency">
                  <ShipmentFrequencyDropdown
                    value={form.shipmentFrequency}
                    onChange={(e) => {
                      handleFormChange("shipmentFrequency", e);
                    }}
                  />
                </Form.Item>
              )}
            </>
          )}
          {company.qualificationStatus === QualificationStatus.Qualified && (
            <>
              <Form.Item label="Update Frequency">
                <ShipmentFrequencyDropdown
                  value={form.shipmentFrequency}
                  onChange={(e) => {
                    handleFormChange("shipmentFrequency", e);
                  }}
                />
              </Form.Item>
              <Form.Item label="Update Temperature">
                <TemperatureDropdown
                  value={form.temperature}
                  onChange={(e) => {
                    handleFormChange("temperature", e);
                  }}
                />
              </Form.Item>
            </>
          )}
        </>
      )}
    </Form>
  );
}

function LogSalesCallFormButton({ loading }: { loading?: boolean }) {
  return (
    <Button
      form="log-sales-call-form"
      type="primary"
      htmlType="submit"
      loading={loading}
    >
      Save
    </Button>
  );
}

LogSalesCallForm.SubmitButton = LogSalesCallFormButton;

export { LogSalesCallForm };
