import { MenuItemProps, message } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import { isNotBlank } from "@freightsimple/shared";

interface CancelInvoiceAuditSnoozeMenuItemProps extends MenuItemProps {
  shipmentId: string;
  onRefresh: () => void;
  currentSnoozeDate: string | undefined;
}

export function CancelInvoiceAuditSnoozeMenuItem(
  props: CancelInvoiceAuditSnoozeMenuItemProps,
) {
  const createShipmentsApi = useShipmentsApi();

  async function cancelSnooze() {
    const quotesApi = await createShipmentsApi();
    const shipmentId = props.shipmentId;
    try {
      await quotesApi.cancelInvoiceAuditSnooze({
        shipmentId,
      });
      await props.onRefresh();
      message.success("⏰ Snooze Cnacelled");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  function allowedToCancel(): boolean {
    return isNotBlank(props.currentSnoozeDate);
  }

  if (!allowedToCancel()) {
    return <></>;
  }

  return (
    <ConfirmMenuItem
      onConfirm={cancelSnooze}
      question="Are you sure you want to cancel the snooze"
      okText="Cancel snooze"
      tooltip=""
      {...props}
    >
      🗑 Delete quote
    </ConfirmMenuItem>
  );
}
