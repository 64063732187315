import {
  CompanyCalendarDataResponse,
  CompanyData,
} from "@freightsimple/generated-apollo-openapi-client";
import { Typography } from "antd";
import { useState } from "react";
import { useCalendarStatsApi } from "../../Apis/Apis";
import { Loading } from "../../Components/Loading";
import { StatsCalendar } from "../../Components/StatsCalendar";
import { useOnce } from "../../Hooks/useOnce";

interface CompanyGraphsTabProps {
  companyData: CompanyData;
}

const { Title } = Typography;

export function CompanyGraphsTab(props: CompanyGraphsTabProps) {
  const createCalendarStatsApi = useCalendarStatsApi();
  const companyId = props.companyData.company.companyId!;
  const [data, setData] = useState<CompanyCalendarDataResponse | undefined>();

  async function refresh() {
    const calendarStatsApi = await createCalendarStatsApi();
    const response = await calendarStatsApi.companyDataForCalendars({
      companyId,
    });
    setData(response);
  }

  useOnce(refresh);

  if (data === undefined) {
    return <Loading />;
  }

  return (
    <>
      <Title level={4}>Revenue Booked per Month</Title>
      <StatsCalendar data={data.bookedRevenue} maxValue={5000} prefix="$" />;
      <Title level={4}>Booked per Month</Title>
      <StatsCalendar data={data.numberBooked} maxValue={5000} />;
      <Title level={4}>Quoted per Month</Title>
      <StatsCalendar data={data.numberQuotes} maxValue={5000} />;
      <Title level={4}>Acceptance per Month</Title>
      <StatsCalendar
        data={data.acceptanceRatesPerMonth}
        maxValue={5000}
        prefix="%"
      />
      ;
    </>
  );
}
