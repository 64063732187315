import { assertNever } from "./Helpers/assertNever";
import {
  Environment,
  getEnvironment,
  getEnvironmentOverride,
} from "./Helpers/environment";

export function getAzureClientId(): string {
  const environmentOverride = getEnvironmentOverride();
  if (environmentOverride) {
    return "0a3192a3-2d90-41da-94a7-2f1237117a28";
  }
  const environment = getEnvironment();
  switch (environment) {
    case Environment.Local:
      return "0a3192a3-2d90-41da-94a7-2f1237117a28";
    case Environment.Preprod:
      return "f01f2f25-643f-4686-9e8d-ba90136afeee";
    case Environment.Demo:
      return "886f1e1c-50b3-4f80-95eb-85e9063b7dd2";
    case Environment.Production:
      return "b6ec59f0-e106-4c3c-a856-15a1b63410b9";
    default:
      assertNever(environment);
  }
}

export function apiServerUrl(): string {
  const environment = getEnvironment();
  switch (environment) {
    case Environment.Local:
      return "http://localhost:8080";
    case Environment.Preprod:
      return "https://api.fspreprod.com";
    case Environment.Demo:
      return "https://api.freightsimpledemo.com";
    case Environment.Production:
      return "https://api.freightsimple.com";
    default:
      assertNever(environment);
  }
}

export function redirectUrl(): string {
  const environmentOverride = getEnvironmentOverride();
  if (environmentOverride) {
    return "http://localhost:3001";
  }

  const environment = getEnvironment();
  switch (environment) {
    case Environment.Local:
      return "http://localhost:3001";
    case Environment.Preprod:
      return "https://apollo.fspreprod.com";
    case Environment.Demo:
      return "https://apollo.freightsimpledemo.com";
    case Environment.Production:
      return "https://apollo.freightsimple.com";
    default:
      assertNever(environment);
  }
}

// Keep this as an environment variable that can be set locally
// It's designed for when you are developing offline and
// can't do microsoft authentication
export function isAuthenticationDisabled(): boolean {
  return (
    import.meta.env.VITE_DISABLE_AUTHENTICATION === "true" &&
    getEnvironment() === Environment.Local
  );
}

export function getConfig() {
  return {
    basePath: apiServerUrl(),
  };
}
