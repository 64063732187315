import { AdminTag } from "./AdminTag";
import { AssignInvoiceAuditBase } from "./ViewShipmentScreenComponents/AssignInvoiceAuditMenuItem";

interface InvoiceAuditAssigneeTagProps {
  invoiceAuditAssignee: string | undefined;
  shipmentId: string;
  refresh: () => Promise<void>;
}

export function InvoiceAuditAssigneeTag(props: InvoiceAuditAssigneeTagProps) {
  return (
    <>
      <AssignInvoiceAuditBase
        {...props}
        component={function (showModal: () => void) {
          return (
            <AdminTag email={props.invoiceAuditAssignee} onClick={showModal} />
          );
        }}
      />
    </>
  );
}
