import {
  CalendarDataResponse,
  CalendarDataPoint,
} from "@freightsimple/generated-apollo-openapi-client";
import { Form } from "antd";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { GraphType } from "./GraphType";
import { assertNever } from "../Helpers/assertNever";
import { Loading } from "./Loading";
import { Line, Point } from "@nivo/line";
import { Period } from "../Screens/ViewShipmentScreenComponents/Period";
import { PeriodDropdown } from "../Screens/ViewShipmentScreenComponents/RecentCompanySalesReportScreen";
import dayjs from "dayjs";

interface StatsCalendar2Props {
  data: CalendarDataResponse;
  maxValue: number;
  prefix?: string;
  defaultGraphType?: GraphType;
}

interface TimeChartProps {
  data: CalendarDataPoint[];
  prefix?: string;
}

const Container = styled.div`
  border: 1px solid #eee;
  padding: 24px;
  margin-top: 16px;
  background: white;
  border-radius: 4px;
`;

const ChartContainer = styled.div`
  height: 400px;
  margin-left: -24px;
  margin-right: -24px;
`;

const FormContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 16px;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

function formatNumber(num: number): string {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  if (num >= 10000) {
    return `${(num / 1000).toFixed(0)}k`;
  }
  return num.toLocaleString();
}

function TimeChart(props: TimeChartProps) {
  const { data, prefix } = props;
  const [period, setPeriod] = useState<Period>(Period.Monthly);

  const chartData = useMemo(() => {
    // Group data by period
    const groupedData = data.reduce(
      (acc, point) => {
        const date = dayjs(point.day);
        const year = date.year();

        let periodKey: string;
        let displayDate: string;

        switch (period) {
          case Period.Monthly:
            // Use YYYY-MM format for monthly grouping
            periodKey = date.format("YYYY-MM");
            displayDate = date.startOf("month").format("YYYY-MM-DD");
            break;
          case Period.Quarterly: {
            const quarter = Math.floor(date.month() / 3) + 1;
            periodKey = `${year}-Q${quarter}`;
            displayDate = dayjs(`${year}-${quarter * 3 - 2}-01`).format(
              "YYYY-MM-DD",
            );
            break;
          }
          case Period.Yearly:
            periodKey = date.format("YYYY");
            displayDate = date.startOf("year").format("YYYY-MM-DD");
            break;
          default:
            assertNever(period);
        }

        if (!acc[periodKey]) {
          acc[periodKey] = {
            sum: 0,
            count: 0,
            displayDate,
            periodKey,
          };
        }

        acc[periodKey].sum += point.value;
        acc[periodKey].count += 1;

        return acc;
      },
      {} as Record<
        string,
        {
          sum: number;
          count: number;
          displayDate: string;
          periodKey: string;
        }
      >,
    );

    // Convert grouped data to series format and sort
    return [
      {
        id:
          period === Period.Monthly
            ? "Monthly Total"
            : period === Period.Quarterly
              ? "Quarterly Total"
              : "Yearly Total",
        data: Object.values(groupedData)
          .map((value) => ({
            x: value.displayDate,
            y: value.sum,
            periodKey: value.periodKey,
          }))
          .sort((a, b) => dayjs(a.x).valueOf() - dayjs(b.x).valueOf()),
      },
    ];
  }, [data, period]); // Make sure period is in the dependency array

  return (
    <div>
      <FormContainer>
        <Form.Item label="Period">
          <PeriodDropdown value={period} setValue={setPeriod} />
        </Form.Item>
      </FormContainer>
      <ChartContainer>
        <Line
          data={chartData}
          height={350}
          width={1200}
          margin={{ top: 20, right: 40, bottom: 60, left: 80 }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: period === Period.Yearly ? "year" : "month",
          }}
          yScale={{
            type: "linear",
            min: 0,
            max: "auto",
          }}
          axisBottom={{
            format: (value: string) => {
              const date = dayjs(value);
              switch (period) {
                case Period.Monthly:
                  return date.format("MMM YYYY");
                case Period.Quarterly: {
                  const quarter = Math.floor(date.month() / 3) + 1;
                  return `${date.year()} Q${quarter}`;
                }
                case Period.Yearly:
                  return date.format("YYYY");
                default:
                  assertNever(period);
              }
            },
            tickRotation: -45,
            tickSize: 5,
            tickPadding: 10,
            tickValues:
              period === Period.Yearly
                ? "every 1 year"
                : period === Period.Quarterly
                  ? "every 3 months"
                  : "every 1 month",
          }}
          pointSize={8}
          pointColor="#ffffff"
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          enablePointLabel={true}
          tooltip={({ point }: { point: Point }) => (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            >
              <div>
                {/* @ts-expect-error Label isn't in the type */}
                <strong>{point.data.periodKey}</strong>
              </div>
              <div>{`${prefix ?? ""}${formatNumber(point.data.y as number)}`}</div>
            </div>
          )}
          pointLabel={(point) =>
            `${prefix ?? ""}${formatNumber(point.data.y as number)}`
          }
          useMesh={true}
          curve="monotoneX"
          enableArea={true}
          areaOpacity={0.1}
          theme={{
            grid: {
              line: {
                stroke: "#eee",
                strokeWidth: 1,
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                },
              },
            },
          }}
        />
      </ChartContainer>
    </div>
  );
}

export function StatsCalendar2(props: StatsCalendar2Props) {
  if (!props.data?.data || !props.data.earliestDate) {
    return <Loading />;
  }

  return (
    <Container>
      <TimeChart data={props.data.data} prefix={props.prefix} />
    </Container>
  );
}
