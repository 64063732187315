import { InvoiceAuditProblemFault } from "@freightsimple/generated-apollo-openapi-client";
import { assertNever } from "../../Helpers/assertNever";

export function emojiForInvoiceAuditProblemFault(
  fault: InvoiceAuditProblemFault,
): string {
  switch (fault) {
    case InvoiceAuditProblemFault.Carrier:
      return "🚚";
    case InvoiceAuditProblemFault.Customer:
      return "🤦🏻";
    case InvoiceAuditProblemFault.Freightsimple:
      return "🏢";
    case InvoiceAuditProblemFault.Unknown:
      return "❓";
    default:
      assertNever(fault);
  }
}
