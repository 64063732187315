import {
  Quote,
  QuoteContactsData,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";
import { Menu, Modal, Typography, message, MenuItemProps } from "antd";
import { useEffect, useState } from "react";
import { useQuotesApi } from "../../Apis/Apis";
import { Loading } from "../../Components/Loading";
import { CarrierContacts, CarrierPhoneContacts } from "./CarrierContacts";

const { Title } = Typography;

interface ViewQuoteContactMenuItemProps extends MenuItemProps {
  quote: Quote;
  shipmentData: ViewShipmentDataForApollo;
}

export function ViewQuoteContactMenuItem(props: ViewQuoteContactMenuItemProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const title = `View Quote Contacts`;
  const quoteId = props.quote.quoteId!;

  const [quoteContactsData, setQuoteContactsData] = useState<
    undefined | QuoteContactsData
  >();
  const createQuotesApi = useQuotesApi();

  async function refresh() {
    try {
      const quotesApi = await createQuotesApi();
      const response = await quotesApi.getQuoteContactsData({ quoteId });
      setQuoteContactsData(response);
    } catch {
      message.error(`Oops. Something went wrong`);
    }
  }

  useEffect(
    function () {
      if (isModalVisible) {
        refresh();
      }
    },

    [isModalVisible],
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1200}
      >
        {!quoteContactsData && <Loading />}
        {quoteContactsData && (
          <>
            <div
              style={{
                padding: "32px",
                margin: "16px",
                border: "1px solid #ddd",
              }}
            >
              <Title level={4}>Carrier Phone Numbers</Title>
              <CarrierPhoneContacts
                data={{
                  "Customer Service": [
                    quoteContactsData.carrierCustomerServiceNumber!,
                  ],
                }}
                shipmentData={props.shipmentData}
              />
            </div>
            <div
              style={{
                padding: "32px",
                margin: "16px",
                border: "1px solid #ddd",
              }}
            >
              <Title level={4}>Carrier Shipping Contacts</Title>
              <CarrierContacts
                data={quoteContactsData.carrierShippingContacts}
                shipmentData={props.shipmentData}
              />
            </div>
            <div
              style={{
                padding: "32px",
                margin: "16px",
                border: "1px solid #ddd",
              }}
            >
              <Title level={4}>Carrier Finance Contacts</Title>
              <CarrierContacts
                data={quoteContactsData.carrierBillingContacts}
                shipmentData={props.shipmentData}
              />
            </div>
          </>
        )}
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        {title}
      </Menu.Item>
    </>
  );
}
