import { EmailTemplateGroup } from "@freightsimple/generated-apollo-openapi-client";
import { assertNever } from "../../Helpers/assertNever";

export function describeEmailTemplateGroup(group: EmailTemplateGroup) {
  switch (group) {
    case EmailTemplateGroup.Accounts:
      return "Accounts";
    case EmailTemplateGroup.Basic:
      return "Basic";
    case EmailTemplateGroup.Booking:
      return "Booking";
    case EmailTemplateGroup.Finance:
      return "Finance";
    case EmailTemplateGroup.GeneralInfo:
      return "General Info";
    case EmailTemplateGroup.GettingStarted:
      return "Getting Started";
    case EmailTemplateGroup.Leads:
      return "Leads";
    case EmailTemplateGroup.Quoting:
      return "Quoting";
    case EmailTemplateGroup.Reengage:
      return "Re-engage";
    case EmailTemplateGroup.Tracking:
      return "Tracking";
    default:
      assertNever(group);
  }
}
