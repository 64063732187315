import { ViewShipmentDataForApollo } from "@freightsimple/generated-apollo-openapi-client";
import { Typography } from "antd";
import HorizontalStack from "../../Components/HorizontalStack";
import { DisplayMarkdown } from "../../Components/ViewMarkdownButton";
import { WarningComponent } from "../../Components/WarningComponent";
import Spacer from "../../Spacer";
import { EditCompanyInvoiceAuditNotesButton } from "../ViewCompanyScreenComponents/EditCompanyInvoiceAuditNotesButton";

const { Title } = Typography;

interface CommonCompanyProblemsSectionProps {
  shipmentData: ViewShipmentDataForApollo;
  onPatchShipmentData: (_: ViewShipmentDataForApollo) => void;
}

export function CommonCompanyProblemsSection(
  props: CommonCompanyProblemsSectionProps,
) {
  return (
    <>
      <div style={{ border: "1px solid #ccc", padding: "32px"}}>
        <HorizontalStack
          align="spread"
          verticalAlign="top"
          style={{ width: "100%" }}
        >
          <Title level={4}>
            <WarningComponent /> Common Problems for company{" "}
            {props.shipmentData.shipment.company.companyName}
          </Title>
          <EditCompanyInvoiceAuditNotesButton
            shipmentData={props.shipmentData}
            onPatchShipmentData={props.onPatchShipmentData}
          />
        </HorizontalStack>
        <DisplayMarkdown
          data={
            props.shipmentData.shipment.company.invoiceAuditNotes ?? "No notes"
          }
        />
      </div>

      <Spacer height={32} />
    </>
  );
}
