import { message } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { ConfirmMenuItemWithTextArea } from "../../Components/ConfirmButtonWithTextArea";
import { TabProps } from "./TabProps";

export function ChallengeAllInvoicesMenuItem(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const createShipmentsApi = useShipmentsApi();
  async function onConfirm(reason: string) {
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.markChallengeAllInvoices({
        shipmentId,
        reason,
      });
      await props.onRefresh();
      message.success("Now challenging all invoices");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  return (
    <ConfirmMenuItemWithTextArea
      onConfirm={onConfirm}
      placeholder="Enter reason"
      question="Are you sure you want to mark this shipment to challenge all invoices?"
      okText={"Yes"}
      tooltip={
        "If we never want to auto-approve invoices for this shipment (eg. if there is a dispute) use this option"
      }
    >
      Challenge All Invoices
    </ConfirmMenuItemWithTextArea>
  );
}
