import {
  InvoiceAuditProblem,
  InvoiceAuditProblemNote,
} from "@freightsimple/generated-apollo-openapi-client";
import { Typography } from "antd";
import { useState } from "react";
import { useCarrierInvoiceAuditApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import { DisplayMarkdown } from "../../Components/ViewMarkdownButton";
import { WarningComponent } from "../../Components/WarningComponent";
import { useOnce } from "../../Hooks/useOnce";
import Spacer from "../../Spacer";
import { EditInvoiceAuditProblemTypeNotesButton } from "../ViewCompanyScreenComponents/EditInvoiceAuditProblemTypeNotesButton";
import { describeInvoiceAuditProblemType } from "./describeInvoiceAuditProblemType";

const { Title } = Typography;

interface CommonProblemsForProblemTypeSectionProps {
  invoiceAuditProblem: InvoiceAuditProblem;
}

export function CommonProblemsForProblemTypeSection(
  props: CommonProblemsForProblemTypeSectionProps,
) {
  const { invoiceAuditProblem } = props;
  const [notes, setNotes] = useState<InvoiceAuditProblemNote[]>();
  const createCarrierInvoiceAuditApi = useCarrierInvoiceAuditApi();

  async function update() {
    const carrierInvoiceAuditApi = await createCarrierInvoiceAuditApi();
    const response = await carrierInvoiceAuditApi.getAllInvoiceProblemNotes();
    setNotes(response);
  }

  useOnce(function () {
    update();
  });

  if (notes === undefined) {
    return <></>;
  }

  if (invoiceAuditProblem.type === undefined) {
    return <></>;
  }

  const notesForThisProblemType = notes.find(
    (n) => n.invoiceAuditProblemType === invoiceAuditProblem.type!,
  )?.notes;

  return (
    <>
      <div style={{ border: "1px solid #ccc", padding: "32px"}}>
        <HorizontalStack
          align="spread"
          verticalAlign="top"
          style={{ width: "100%" }}
        >
          <Title level={4}>
            <WarningComponent /> Notes for "
            {describeInvoiceAuditProblemType(invoiceAuditProblem)}" problem
          </Title>
          <EditInvoiceAuditProblemTypeNotesButton
            invoiceAuditProblemType={invoiceAuditProblem.type!}
            notes={notesForThisProblemType}
            onRefreshProblemTypeNotes={update}
          />
        </HorizontalStack>
        <DisplayMarkdown data={notesForThisProblemType ?? "No notes"} />
      </div>

      <Spacer height={32} />
    </>
  );
}
