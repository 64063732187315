import { NumericFormat } from "react-number-format";

interface DensityProps {
  children: number;
}

export function Density(props: DensityProps) {
  return (
    <NumericFormat
      value={props.children}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={2}
      suffix={"pcf"}
    />
  );
}
