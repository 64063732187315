import { LineItem } from "@freightsimple/generated-apollo-openapi-client";
import { Button } from "antd";

interface DeleteLineItemButtonProps {
  index: number;
  updateLineItems: (lineItems: LineItem[]) => void;
  lineItems: LineItem[];
}

export function DeleteLineItemButton(props: DeleteLineItemButtonProps) {
  const lineItems = props.lineItems!;
  function onDelete() {
    const updated = lineItems.filter(function (_li, index) {
      return index !== props.index;
    });

    props.updateLineItems(updated);
  }

  return (
    <>
      <Button onClick={onDelete} danger>
        Delete
      </Button>
    </>
  );
}
