import { Button, Input, Tooltip } from "antd";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";

interface TextListInputProps {
  list: string[];
  setList: Dispatch<SetStateAction<string[]>>;
}

export function TextListInput({ list, setList }: TextListInputProps) {
  function handleChange(event: ChangeEvent<HTMLInputElement>, index: number) {
    const newList = [...list];
    newList[index] = event.target.value;
    setList(newList);
  }

  function handleDelete(index: number) {
    const newList = [...list];
    newList.splice(index, 1);
    setList(newList);
  }

  function handleNew() {
    const newList = [...list];
    newList[newList.length] = "";
    setList(newList);
  }

  return (
    <div style={{ display: "flex" }}>
      <Tooltip title="New">
        <Button
          onClick={handleNew}
          style={{ marginRight: "10px" }}
          icon={<PlusOutlined />}
        />
      </Tooltip>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {list.map((text, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              marginBottom: "10px",
              marginRight: "10px",
            }}
          >
            <Input.Group compact>
              <Tooltip title="Copy">
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(text);
                  }}
                  icon={<CopyOutlined />}
                />
              </Tooltip>
              <Input
                value={list[index]}
                onChange={(e) => handleChange(e, index)}
                style={{ width: "calc(100% - 65px)", minWidth: "100px" }}
              />
              <Tooltip title="Delete">
                <Button
                  onClick={() => {
                    handleDelete(index);
                  }}
                  style={{ color: "red" }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Input.Group>
          </div>
        ))}
      </div>
    </div>
  );
}
