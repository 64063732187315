import { useMsal } from "@azure/msal-react";
import { isAuthenticationDisabled } from "../environment";

export function useCurrentUserEmail() {
  const { instance } = useMsal();

  if (isAuthenticationDisabled()) {
    return "noauth@freightsimple.com";
  }

  const activeAccount = instance.getActiveAccount();
  const email = activeAccount?.username;
  return email;
}
