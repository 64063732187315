import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd";
import { useQuotesApi } from "../../Apis/Apis";
import { ConfirmButton } from "../../Components/ConfirmButton";
import { QuotesTableProps } from "./ManageQuotesTab";

export function DeleteEntireQuoteGroupButton(props: QuotesTableProps) {
  const shipmentId = props.data.shipmentId;
  const quoteGroupName = props.quoteGroupName;
  const createQuotesApi = useQuotesApi();

  async function onDeleteGroup() {
    const quotesApi = await createQuotesApi();

    try {
      await quotesApi.deleteQuoteGroup({
        shipmentId,
        quoteGroupName,
      });
      await props.onRefresh();
      message.success(`🗑 Quote Group ${quoteGroupName} Deleted`);
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  function allowedToDelete() {
    return !props.quotes.some(function (q) {
      return q.quoteId === props.selectedQuoteId;
    });
  }

  if (!allowedToDelete()) {
    return (
      <Tooltip overlay="Cannot delete whole group when one is selected">
        <Button type="link" icon={<DeleteOutlined />} disabled={true}>
          Delete Entire Group
        </Button>
      </Tooltip>
    );
  } else {
    return (
      <ConfirmButton
        type="link"
        icon={<DeleteOutlined />}
        onConfirm={onDeleteGroup}
        question={`Are you sure you want to delete the entire ${quoteGroupName} group?`}
        okText="Yes, delete entire group"
        tooltip="This will delete the entire group"
      >
        Delete Entire Group
      </ConfirmButton>
    );
  }
}
