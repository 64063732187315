import { TrackingSource } from "@freightsimple/generated-apollo-openapi-client";
import { Tag } from "antd";
import Colors from "../../Components/Colors";
import { assertNever } from "../../Helpers/assertNever";

interface TrackingSourceTagProps {
  trackingSource: TrackingSource;
  manualCreatedBy: string | undefined;
}

function describe(
  state: TrackingSource,
  manualCreatedBy: string | undefined,
): string {
  switch (state) {
    case TrackingSource.CustomerService:
      return manualCreatedBy
        ? `Customer Service : ${manualCreatedBy}`
        : `Customer Service`;
    case TrackingSource.Tracking:
      return "Carrier Tracking";
    default:
      assertNever(state);
  }
}

function color(state: TrackingSource) {
  switch (state) {
    case TrackingSource.CustomerService:
      return Colors.Red;
    case TrackingSource.Tracking:
      return Colors.Blue;
    default:
      assertNever(state);
  }
}

function foregroundColor() {
  return "white";
}

export function TrackingSourceTag(props: TrackingSourceTagProps) {
  return (
    <Tag color={color(props.trackingSource)}>
      <div style={{ color: foregroundColor() }}>
        {describe(props.trackingSource, props.manualCreatedBy)}
      </div>
    </Tag>
  );
}
