import {
  CustomerInvoice,
  CustomerInvoiceType,
} from "@freightsimple/generated-apollo-openapi-client";
import { assertNever } from "../../Helpers/assertNever";

export function describeCustomerInvoiceType(
  customerInvoice: CustomerInvoice,
): string {
  const invoiceType = customerInvoice.invoiceType!;
  switch (invoiceType) {
    case CustomerInvoiceType.ShipmentCharge:
      return customerInvoice.disputed
        ? "Disputed Shipment Charge"
        : "Shipment Charge";
    case CustomerInvoiceType.AdditionalCharge:
      return customerInvoice.disputed
        ? "Disputed Additional Charge"
        : "Additional Charge";
    case CustomerInvoiceType.ShipmentRefund:
      return "Shipment Refund";
    case CustomerInvoiceType.AdditionalChargeRefund:
      return "Additional Charge Refund";
    default:
      assertNever(invoiceType);
  }
}
