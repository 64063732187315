import { CompanyNoteType } from "@freightsimple/generated-apollo-openapi-client";
import { assertNever } from "../../Helpers/assertNever";
import { OptionalEnumDropdown } from "../ViewShipmentScreenComponents/EnumDropdown";

export const CompanyNoteTypeDropdown = OptionalEnumDropdown<
  CompanyNoteType,
  typeof CompanyNoteType
>(CompanyNoteType, "Do not Filter", function (et) {
  switch (et) {
    case CompanyNoteType.Call:
      return "Sales Call";
    case CompanyNoteType.ApolloAction:
      return "Apollo Actions";
    case CompanyNoteType.UserAction:
      return "User/Dashboard Actions";
    case CompanyNoteType.ShipmentBooking:
      return "Shipment Booking";
    case CompanyNoteType.QuoteRun:
      return "Quote Run";
    case CompanyNoteType.CreditModified:
      return "Credit Modified";
    case CompanyNoteType.SystemEvent:
      return "System Event";
    case CompanyNoteType.InternalNote:
      return "Internal Note";
    case CompanyNoteType.DialpadRecording:
      return "Dialad Recording";
    default:
      assertNever(et);
  }
});
