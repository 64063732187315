import { InputNumber } from "antd";

interface NumberInputProps {
  value: number | undefined;
  setValue: (value: number) => void;
  prefix?: string;
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
  style?: React.CSSProperties;
  width?: number;
}

export function NumberInput(props: NumberInputProps) {
  return (
    <InputNumber
      value={props.value}
      onChange={function (value: number | null) {
        props.setValue(value ?? 0);
      }}
      prefix={props.prefix}
      addonBefore={props.addonBefore}
      addonAfter={props.addonAfter}
      style={props.style}
    />
  );
}
