import {
  AmyApi,
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";
import { message } from "antd";
import { useAmyApi } from "../../Apis/Apis";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
interface RequestAmyUpdateSharedMenuItemProps {
  shipment: Shipment;
  onRefresh: () => Promise<void>;
}

interface RequestAmyUpdateMenuItemProps {
  name: string;
  onClick: (amyApi: AmyApi) => Promise<void>;
  onRefresh: () => Promise<void>;
  hidden: boolean;
}

function RequestAmyUpdateMenuItem(props: RequestAmyUpdateMenuItemProps) {
  const createAmyApi = useAmyApi();

  async function onClick() {
    try {
      const amyApi = await createAmyApi();
      await props.onClick(amyApi);
      await props.onRefresh();
      message.success(`Success`);
    } catch {
      message.error(`Oops. Something went wrong`);
    }
  }

  if (props.hidden) {
    return <></>;
  }

  return (
    <ConfirmMenuItem
      onConfirm={onClick}
      question={"Are you sure you want to do this? It will email the carrier"}
      okText={`Yes, ${props.name}`}
      tooltip={
        "Use this if for some reason a task wasn't automatically created"
      }
    >
      {props.name}
    </ConfirmMenuItem>
  );
}

export function RequestMissedPickupUpdateMenuItem(
  props: RequestAmyUpdateSharedMenuItemProps,
) {
  const { shipment } = props;
  const shipmentId = shipment.shipmentId!;
  return (
    <RequestAmyUpdateMenuItem
      name="Request Missed Pickup Update from Carrier"
      onClick={async function (amyApi) {
        await amyApi.triggerMissedPickupTask({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={props.shipment.state !== ShipmentState.BookingConfirmed}
    />
  );
}

export function RequestMissedDeliveryUpdateMenuItem(
  props: RequestAmyUpdateSharedMenuItemProps,
) {
  const { shipment } = props;
  const shipmentId = shipment.shipmentId!;
  return (
    <RequestAmyUpdateMenuItem
      name="Request Missed Delivery Update from Carrier"
      onClick={async function (amyApi) {
        await amyApi.triggerMissedDeliveryTask({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={props.shipment.state !== ShipmentState.InTransit}
    />
  );
}

export function RequestTrackingUpdateMenuItem(
  props: RequestAmyUpdateSharedMenuItemProps,
) {
  const { shipment } = props;
  const shipmentId = shipment.shipmentId!;
  return (
    <RequestAmyUpdateMenuItem
      name="Request Tracking Update from Carrier"
      onClick={async function (amyApi) {
        await amyApi.triggerTrackingUpdateTask({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={props.shipment.state !== ShipmentState.InTransit}
    />
  );
}

export function RequestDeliveryDateUpdateMenuItem(
  props: RequestAmyUpdateSharedMenuItemProps,
) {
  const { shipment } = props;
  const shipmentId = shipment.shipmentId!;
  return (
    <RequestAmyUpdateMenuItem
      name="Request Delivery Date Update from Carrier"
      onClick={async function (amyApi) {
        await amyApi.triggerDeliveryDateUpdateTask({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={props.shipment.state !== ShipmentState.InTransit}
    />
  );
}
