import { Alert, Button, message } from "antd";

import { useShipmentsApi } from "../../Apis/Apis";
import { TabProps } from "./TabProps";
import dayjs from "dayjs";

export function AmyPauseBanner(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const createShipmentsApi = useShipmentsApi();
  if (
    props.shipmentData.shipment.shipment.pauseAmyEmailsUntilDate === undefined
  ) {
    return <></>;
  }

  const m = dayjs(props.shipmentData.shipment.shipment.pauseAmyEmailsUntilDate);

  const isPauseDateInThePast = m
    .startOf("day")
    .isBefore(dayjs().startOf("day"));

  if (isPauseDateInThePast) {
    return <></>;
  }

  async function onStartEmailsAgain() {
    try {
      const shipmentsApi = await createShipmentsApi();

      await shipmentsApi.unpauseAmyEmails({ shipmentId });

      await props.onRefresh();
      message.success(`Restarted`);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  return (
    <Alert
      type="warning"
      description={`⏸ Amy emails are paused until after ${m.format(
        "dddd, MMMM Do YYYY",
      )}`}
      action={
        <Button onClick={onStartEmailsAgain} ghost>
          Start emails again
        </Button>
      }
    ></Alert>
  );
}
