import { Row } from "antd";
import { ReactNode } from "react";
import Stack from "./Stack";

interface PanelProps {
  children: ReactNode;
}

export function Panel(props: PanelProps) {
  return (
    <Row>
      <Stack align="left" width="100%">
        <div
          style={{
            width: "100%",
          }}
        >
          {props.children}
        </div>
      </Stack>
    </Row>
  );
}
