import { InfoCircleTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";
import { ReactNode } from "react";

interface InfoBubbleProps {
  overlay: ReactNode;
}

export function InfoBubble(props: InfoBubbleProps) {
  return (
    <Tooltip
      placement="bottom"
      overlay={props.overlay}
      overlayStyle={{ minWidth: "400px" }}
    >
      <InfoCircleTwoTone />
    </Tooltip>
  );
}
