import { Button, Popover, Timeline, Tooltip } from "antd";
import { ReactNode } from "react";
import Colors from "../Components/Colors";
import Stack from "../Components/Stack";

import Spacer from "../Spacer";
import dayjs from "dayjs";
import { ObjectDifference } from "../Helpers/objectDifferences";
import { ArrowRightOutlined, HistoryOutlined } from "@ant-design/icons";
import { ValueDisplay } from "../Components/ValueDisplay";
import { shipmentValuesDisplay } from "../Helpers/shipmentValuesDisplay";
import { spaceOutCamelCase } from "../Helpers/spaceOutCamelCase";

export interface ChangeHistory {
  date?: string;
  by?: string;
  differences: ObjectDifference[];
}

interface ChangeHistoryPopoverProps {
  keys?: string[];
  paths?: string[];
  history: ChangeHistory[];
  children: ReactNode;
}

export function ChangeHistoryPopover(props: ChangeHistoryPopoverProps) {
  function sortByDate2(a: ChangeHistory, b: ChangeHistory) {
    return dayjs(b.date).valueOf() - dayjs(a.date).valueOf();
  }

  function filterDifferences(diff: ObjectDifference): boolean {
    return props.keys?.includes(diff.key) || !!props.paths?.includes(diff.path);
  }

  const history = props.history
    .filter((h) => h.differences.filter(filterDifferences).length > 0)
    .map((m) => ({
      by: m.by,
      date: m.date,
      differences: m.differences.filter(filterDifferences)!,
    }))
    .sort(sortByDate2);

  if (history.length === 0) {
    return props.children;
  }

  const timelineItems = history.map((h, i) => ({
    color: i === 0 ? Colors.Palette.Green[600] : "gray",
    children: (
      <div>
        <Stack align="left">
          <span style={{ fontWeight: 500 }}>
            {dayjs(h.date).format("dddd, MMMM Do YYYY - h:mm A")}
          </span>
          <span style={{ color: Colors.LightText, fontSize: 12 }}>{h.by}</span>
        </Stack>
        <div style={{ padding: "8px" }}>
          {h.differences.map((d) => (
            <div
              key={d.key}
              style={{
                paddingBlock: "2px",
                display: "flex",
                columnGap: "24px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span style={{ color: Colors.LightText }}>
                  {spaceOutCamelCase(d.key)}
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    color: d.left ? "unset" : Colors.VeryLightText,
                  }}
                >
                  <ValueDisplay
                    value={d.left}
                    keyName={d.key}
                    customValueDisplay={shipmentValuesDisplay}
                  />
                </span>
                <ArrowRightOutlined
                  style={{
                    fontSize: "10px",
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                />
                <span
                  style={{
                    color: Colors.Palette.Green[700],
                  }}
                >
                  <ValueDisplay
                    value={d.right}
                    keyName={d.key}
                    customValueDisplay={shipmentValuesDisplay}
                  />
                </span>
              </div>
            </div>
          ))}
        </div>
        {i !== history.length - 1 && <Spacer height={16} />}
      </div>
    ),
  }));

  return (
    <Popover
      trigger="click"
      placement="rightTop"
      content={
        <div style={{ padding: 12 }}>
          <Timeline items={timelineItems} />
        </div>
      }
    >
      {props.children}
      <Tooltip title="Change history">
        <Button ghost type="link" icon={<HistoryOutlined />} />
      </Tooltip>
    </Popover>
  );
}
