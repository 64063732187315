import {
  ApolloMapQuery,
  CalendarDataPoint,
  CalendarDataResponse,
  ShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import dayjs from "dayjs";
import { StatsCalendar } from "../../Components/StatsCalendar";
import { GraphType } from "../../Components/GraphType";
interface AllShipmentsScreenCalendarTabProps {
  query: ApolloMapQuery;
  shipments: ShipmentReport[];
}

export function AllShipmentsScreenCalendarTab(
  props: AllShipmentsScreenCalendarTabProps,
) {
  function convertShipmentReportsToCalendarDataQuoted(): CalendarDataResponse {
    const data = props.shipments.map(function (sr): CalendarDataPoint {
      return {
        day: dayjs(sr.quotedAt).format("YYYY-MM-DD"),
        value: 1,
      };
    });

    const earliestDate = dayjs(
      props.shipments.sort(function (a, b) {
        return dayjs(a.quotedAt).valueOf() - dayjs(b.quotedAt).valueOf();
      })[0].quotedAt,
    ).format("YYYY-MM-DD");

    return { earliestDate, data };
  }

  const dataQuoted = convertShipmentReportsToCalendarDataQuoted();
  return (
    <div style={{ marginLeft: "24px" }}>
      <StatsCalendar
        data={dataQuoted}
        maxValue={1}
        defaultGraphType={GraphType.Calendar}
      />
    </div>
  );
}
