import { Button, Modal } from "antd";
import { useState } from "react";
import HorizontalStack from "./HorizontalStack";
import { Pdf } from "./Pdf";

interface ViewPdfsSideBySideButtonProps {
  document1S3Key: string;
  document2S3Key: string;
  filenamePrefix: string;
  disabled: boolean;
}

export function ViewPdfsSideBySideButton(props: ViewPdfsSideBySideButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={`Comparison`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1700}
      >
        <HorizontalStack>
          <Pdf
            filename={`${props.filenamePrefix}-1.pdf`}
            documentS3Path={props.document1S3Key}
            width={700}
          />
          <Pdf
            filename={`${props.filenamePrefix}-2.pdf`}
            documentS3Path={props.document2S3Key}
            width={700}
          />
        </HorizontalStack>
      </Modal>
      <Button onClick={showModal} disabled={props.disabled}>
        View Side-by-Side
      </Button>
    </>
  );
}
