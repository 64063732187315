import {
  Quote,
  Shipment,
} from "@freightsimple/generated-apollo-openapi-client";
import {
  ObjectDifference,
  objectDifferences,
} from "../Helpers/objectDifferences";
import { spaceOutCamelCase } from "../Helpers/spaceOutCamelCase";
import { ArrayDifferences, arrayDifferences } from "./arrayDifferences";

interface LocationKeyDifferences {
  postalCode: any;
  city: any;
  country: any;
  stateOrProvinceCode: any;
  locationType: any;
  accessorials: any;
}

interface GeneralKeyDifferences {
  exclusiveUseNeeded: any;
  equipmentType: any;
  addInsuranceToShipment: any;
  insuranceAmount: any;
  insuranceCurrency: any;
}

export interface QuoteShipmentKeyDifferences {
  pickupDifferences: ObjectDifference[];
  deliveryDifferences: ObjectDifference[];
  generalDifferences: ObjectDifference[];
  lineItemsDifferences: ArrayDifferences;
  totalDifferencesCount: number;
  lineItemsDifferencesCount: number;
}
export function getQuoteShipmentKeyDifferences(
  quote: Quote,
  shipment: Shipment,
): QuoteShipmentKeyDifferences {
  /**
   * Pickup location differences
   */
  const pickupDifferences = objectDifferences<
    LocationKeyDifferences,
    LocationKeyDifferences
  >(
    {
      postalCode: quote.pickupLocation?.postalCode,
      city: quote.pickupLocation?.city,
      country: quote.pickupLocation?.countryCode,
      stateOrProvinceCode: quote.pickupLocation?.stateOrProvinceCode,
      locationType: quote.pickupLocationType,
      accessorials: quote.pickupAccessorials,
    },
    {
      postalCode: shipment.pickupLocation?.address?.postalCode,
      city: shipment.pickupLocation?.address?.city,
      stateOrProvinceCode:
        shipment.pickupLocation?.address?.stateOrProvinceCode,
      country: shipment.pickupLocation?.address?.countryCode,
      locationType: shipment.pickupLocation?.locationType,
      accessorials: shipment.pickupLocation?.accessorials,
    },
    {
      describeKey: spaceOutCamelCase,
    },
  );

  /**
   * Delivery location differences
   */
  const deliveryDifferences = objectDifferences<
    LocationKeyDifferences,
    LocationKeyDifferences
  >(
    {
      postalCode: quote.deliveryLocation?.postalCode,
      city: quote.deliveryLocation?.city,
      country: quote.deliveryLocation?.countryCode,
      stateOrProvinceCode: quote.deliveryLocation?.stateOrProvinceCode,
      locationType: quote.deliveryLocationType,
      accessorials: quote.deliveryAccessorials,
    },
    {
      postalCode: shipment.deliveryLocation?.address?.postalCode,
      city: shipment.deliveryLocation?.address?.city,
      stateOrProvinceCode:
        shipment.deliveryLocation?.address?.stateOrProvinceCode,
      country: shipment.deliveryLocation?.address?.countryCode,
      locationType: shipment.deliveryLocation?.locationType,
      accessorials: shipment.deliveryLocation?.accessorials,
    },
    {
      describeKey: spaceOutCamelCase,
    },
  );

  /**
   * General differences
   */
  const generalDifferences = objectDifferences<
    GeneralKeyDifferences,
    GeneralKeyDifferences
  >(
    {
      exclusiveUseNeeded: quote.exclusiveUseNeeded ? "Yes" : "No",
      equipmentType: quote.equipmentType,
      addInsuranceToShipment: quote.addInsuranceToShipment ? "Yes" : "No",
      insuranceAmount: quote.insuranceAmount,
      insuranceCurrency: quote.insuranceCurrency,
    },
    {
      exclusiveUseNeeded: shipment.exclusiveUseNeeded ? "Yes" : "No",
      equipmentType: shipment.equipmentType,
      addInsuranceToShipment: shipment.addInsuranceToShipment ? "Yes" : "No",
      insuranceAmount: shipment.insuranceAmount,
      insuranceCurrency: shipment.insuranceCurrency,
    },
    {
      describeKey: spaceOutCamelCase,
    },
  );

  /*
   * Filter the changes that does not affect the rate
   * e.g. 'description' is not a significant change (does not affect the rate) we filter it out
   */
  const lineItemsDifferences = arrayDifferences(
    quote.lineItems!,
    shipment.lineItems!,
    {
      ignoreKeys: [
        "description",
        "handlingUnitType",
        "dangerousUnNumber",
        "dangerousClassification",
        "dangerousPackingGroup",
        "dangerousNumberPackages",
        "dangerousPackagingType",
        "dangerousProperShippingName",
        "dangerousTechnicalName",
      ],
    },
  );

  const lineItemsDifferencesCount =
    lineItemsDifferences.changes.length +
    lineItemsDifferences.additions.length +
    lineItemsDifferences.deletions.length;

  const totalDifferencesCount =
    pickupDifferences.length +
    deliveryDifferences.length +
    generalDifferences.length +
    lineItemsDifferencesCount;

  return {
    pickupDifferences,
    deliveryDifferences,
    generalDifferences,
    lineItemsDifferences,
    totalDifferencesCount,
    lineItemsDifferencesCount,
  };
}
