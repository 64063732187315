import { ButtonRow } from "../../Components/ButtonRow";
import Spacer from "../../Spacer";
import { CrossBorderInfo } from "./CrossBorderInfo";
import { CustomsDelayNotificastionSupportEmailButton } from "./CustomsDelayNotificationSupportEmailButton";
import { EditCustomsBrokerButton } from "./EditCustomsBrokerButton";
import { PauseCustomsDocsRemindersButton } from "./PauseCustomsDocsRemindersOption";
import { TabProps } from "./TabProps";
import { UploadCustomsDocsReminderSupportEmailButton } from "./UploadCustomsDocsReminderSupportEmailButton";

export function CrossBorderTab(props: TabProps) {
  return (
    <>
      <ButtonRow>
        <UploadCustomsDocsReminderSupportEmailButton
          data={props.shipmentData}
        />
        <CustomsDelayNotificastionSupportEmailButton
          data={props.shipmentData}
        />
        <EditCustomsBrokerButton {...props} />
        <PauseCustomsDocsRemindersButton {...props} />
      </ButtonRow>
      <Spacer height={32} />
      <CrossBorderInfo {...props} />
    </>
  );
}
