/**
 * Removes a given prefix from a string if it exists.
 * @param str The input string.
 * @param prefix The prefix to remove.
 * @returns The string with the prefix removed, or the original string if the prefix doesn't exist.
 */
export function removePrefix(str: string, prefix: string): string {
  if (str.startsWith(prefix)) {
    return str.slice(prefix.length);
  }
  return str;
}
