import { Dispatch, SetStateAction, useState } from "react";

export interface UseDisplayReturn {
  status: boolean;
  hide: () => void;
  show: () => void;
  toggle: () => void;
  setStatus: Dispatch<SetStateAction<boolean>>;
}

export const useDisplay = (init = false): UseDisplayReturn => {
  const [status, setStatus] = useState(init);

  const hide = () => {
    setStatus(false);
  };

  const show = () => {
    setStatus(true);
  };

  const toggle = () => {
    setStatus((prev) => !prev);
  };

  return { status, hide, show, toggle, setStatus };
};
