import { ClaudeShipmentConversationItemResult } from "@freightsimple/generated-apollo-openapi-client";
import { SendOutlined } from "@ant-design/icons";
import { Button, Input, List, Modal, Spin, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import { TabProps } from "./TabProps";

export function ClaudeButton(props: TabProps) {
  const [messages, setMessages] = useState<
    ClaudeShipmentConversationItemResult[]
  >([]);
  const [inputText, setInputText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const title = `🤖 Claude`;
  const createShipmentsApi = useShipmentsApi();
  const shipmentId = props.shipmentData.shipmentId;

  async function getMessages() {
    setLoading(true);
    try {
      const shipmentsApi = await createShipmentsApi();
      const response = await shipmentsApi.getClaudeConversationItems({
        shipmentId,
      });
      setMessages(response);
    } catch {
      message.error("Error loading");
    }

    setLoading(false);
  }

  useEffect(
    function () {
      if (isModalVisible) {
        getMessages();
      }
    },
    [isModalVisible],
  );

  const handleSend = async () => {
    if (inputText.trim() === "") return;

    setIsProcessing(true);

    const shipmentsApi = await createShipmentsApi();
    try {
      const sending = shipmentsApi.sendClaudeMessage({
        shipmentId,
        message: inputText,
      });
      setMessages([...messages, { role: "user", html: inputText }]);
      setInputText("");
      await sending;
      await getMessages();
    } catch (e) {
      message.error(
        `Oops. Something went wrong ${e}. Maybe try reloading and seeing if it worked?`,
      );
    }
    setIsProcessing(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <HorizontalStack align="spread">
            <div>Claude Shipment Conversations</div>
          </HorizontalStack>
        }
        open={isModalVisible}
        destroyOnClose={true}
        width={1400}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{ height: "600px", overflowY: "auto", marginBottom: "20px" }}
        >
          <List
            itemLayout="horizontal"
            dataSource={messages}
            renderItem={(message) => (
              <List.Item
                style={{
                  justifyContent:
                    message.role === "user" ? "flex-end" : "flex-start",
                }}
              >
                <div
                  style={{
                    background: message.role === "user" ? "#1890ff" : "#f0f0f0",
                    color: message.role === "user" ? "white" : "black",
                    padding: "8px 12px",
                    borderRadius: "12px",
                    maxWidth: "70%",
                    fontSize: "12px",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: message.html }} />
                </div>
              </List.Item>
            )}
          />
          <div ref={messagesEndRef} />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onPressEnter={handleSend}
            placeholder="Type your message here..."
            disabled={isProcessing}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={handleSend}
            style={{ marginLeft: "10px" }}
            disabled={isProcessing}
          >
            Send
          </Button>
        </div>
        {isProcessing && (
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <Spin /> Processing...
          </div>
        )}
        {loading && messages.length === 0 && (
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <Spin /> Loading messages...
          </div>
        )}
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
