import { FreightClaimState } from "@freightsimple/generated-apollo-openapi-client";

export function describeFreightClaimState(
  state: FreightClaimState | undefined,
): string {
  switch (state) {
    case FreightClaimState.Created:
      return "Created";
    default:
      return "Unknown";
  }
}
