import { SourceMapConsumer } from "source-map-js";

interface ParsedStackLine {
  fnName?: string;
  file: string;
  line: number;
  column: number;
}

async function getSourceMap(stack: string) {
  const scriptUrl = stack.match(/(?:https?:\/\/[^/]+\/)([^:)]+)/)?.[0];

  if (!scriptUrl) return null;

  try {
    const sourceMapUrl = `${scriptUrl}.map`;
    const sourceMapResponse = await fetch(sourceMapUrl);
    const sourceMap = await sourceMapResponse.json();
    console.log("Got source map:", { sourceMap });
    return sourceMap;
  } catch (err) {
    console.error("Failed to fetch source map:", err);
    return null;
  }
}

function parseStackLine(line: string): ParsedStackLine | null {
  // Match both patterns:
  // 1. "at FunctionName (file:line:column)"
  // 2. "at file:line:column"
  // 3. "at minifiedName (file:line:column)"
  const match = line.match(/at (?:(.+?)\s+)?\(?(.+):(\d+):(\d+)/);
  if (!match) return null;

  const [, fnName, file, lineNo, colNo] = match;
  return {
    fnName: fnName || undefined,
    file,
    line: parseInt(lineNo, 10),
    column: parseInt(colNo, 10),
  };
}

function formatParsedLine(parsed: ParsedStackLine): string | null {
  const { fnName, file, line } = parsed;

  // Skip node_modules entries
  if (file.includes("/node_modules/")) {
    return null;
  }

  console.log("Formatting line:", { parsed });
  // Clean up the filename
  const fileName = file.split("/").pop()?.split("?")[0] ?? "unknown";

  // Format the output, keeping the original function name even if minified
  if (fnName) {
    return `at ${fnName} (${fileName}:${line})`;
  }
  return `at ${fileName}:${line}`;
}

async function deobfuscateWithSourceMap(
  parsedLine: ParsedStackLine,
  consumer: SourceMapConsumer,
): Promise<ParsedStackLine> {
  try {
    const position = await consumer.originalPositionFor({
      line: parsedLine.line,
      column: parsedLine.column,
    });

    console.log("Deobfuscating line:", { parsedLine, position });

    if (!position.source) return parsedLine;

    return {
      fnName: position.name,
      file: position.source.replace(window.location.origin, ""),
      line: position.line || parsedLine.line,
      column: position.column || parsedLine.column,
    };
  } catch (err) {
    console.error("Failed to deobfuscate with source map:", err);
    return parsedLine;
  }
}

export async function formatStack(stack: string): Promise<string> {
  if (!stack) return "";

  try {
    const lines = stack.split("\n");
    const sourceMap = await getSourceMap(stack);

    if (sourceMap) {
      const consumer = await new SourceMapConsumer(sourceMap);
      const deobfuscatedLines = await Promise.all(
        lines
          .map(parseStackLine)
          .filter((line): line is ParsedStackLine => line !== null)
          .map(async (parsedLine) => {
            const deobfuscated = await deobfuscateWithSourceMap(
              parsedLine,
              consumer,
            );
            return formatParsedLine(deobfuscated);
          }),
      );

      return deobfuscatedLines
        .filter((line): line is string => line !== null)
        .join("\n");
    }

    // Fall back to basic formatting if no source map
    return lines
      .map(parseStackLine)
      .filter((line): line is ParsedStackLine => line !== null)
      .map(formatParsedLine)
      .filter((line): line is string => line !== null)
      .join("\n");
  } catch (err) {
    console.error("Failed to format stack trace:", err);
    return stack;
  }
}
