import { Select } from "antd";
import SupportAgents from "../../GeneratedFixtures/SupportAgents.json";
import { SupportAgent } from "../ViewCompanyScreen";

const { Option } = Select;

interface SupportAgentDropdownProps {
  onSelect: (supportAgent: SupportAgent) => void;
  supportAgent: SupportAgent;
}

export function SupportAgentDropdown(props: SupportAgentDropdownProps) {
  return (
    <Select
      value={JSON.stringify(props.supportAgent)}
      style={{ width: 150 }}
      onChange={function (newValue) {
        props.onSelect(JSON.parse(newValue));
      }}
    >
      {SupportAgents.map(function (value, index) {
        return (
          <Option value={JSON.stringify(value)} key={index}>
            {value.firstName} {value.lastName}
          </Option>
        );
      })}
    </Select>
  );
}
