import { NoteType } from "@freightsimple/generated-apollo-openapi-client";
import { describeNoteType } from "../../Helpers/describeNoteType";
import { OptionalEnumDropdown } from "./EnumDropdown";

export const NoteTypeDropdown = OptionalEnumDropdown<NoteType, typeof NoteType>(
  NoteType,
  "Do not Filter",
  describeNoteType,
  250,
);
