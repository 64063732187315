import { EquipmentType } from "@freightsimple/generated-apollo-openapi-client";
import Colors from "../Components/Colors";
import { assertNever } from "./assertNever";
export function describeEquipmentType(
  eqipmentType: EquipmentType | undefined | null,
) {
  switch (eqipmentType) {
    case EquipmentType.DryVan:
      return <div>Dry Van 🚚</div>;
    case EquipmentType.Flatbed:
      return <div>Flatbed 🛻</div>;
    case EquipmentType.Reefer:
      return <div>Reefer ❄️</div>;
    case undefined:
      return <div style={{ color: Colors.LightText }}>Any Equipment Type</div>;
    case null:
      return <div style={{ color: Colors.LightText }}>Any Equipment Type</div>;
    default:
      assertNever(eqipmentType);
  }
}
