import { ReactNode } from "react";
import { describeAccessorial } from "../Screens/ViewShipmentScreenComponents/describeAccessorial";
import { describeEquipmentType } from "./describeEquipmentType";
import { EquipmentType } from "@freightsimple/generated-apollo-openapi-client";

export function shipmentValuesDisplay(value: any, key: string): ReactNode {
  if (key.toLocaleLowerCase() === "accessorials" && Array.isArray(value)) {
    if (value.length === 0) {
      return "None";
    }

    return value
      .map(function (accessorial) {
        return describeAccessorial(accessorial);
      })
      .join(", ");
  }

  if (key === "equipmentType") {
    return describeEquipmentType(value as EquipmentType);
  }

  if (key === "openFrom" || (key === "openUntil" && Array.isArray(value))) {
    return value.join(":");
  }
}
