import { CustomerInvoiceState } from "@freightsimple/generated-apollo-openapi-client";
import Colors from "../Components/Colors";
import { assertNever } from "./assertNever";

export function colorForCustomerInvoiceState(state: CustomerInvoiceState) {
  switch (state) {
    case CustomerInvoiceState.Voided:
      return "black";
    case CustomerInvoiceState.Issued:
      return Colors.LightGray;
    case CustomerInvoiceState.SettlementPending:
      return Colors.Blue;
    case CustomerInvoiceState.Settled:
      return Colors.Gold;
    case CustomerInvoiceState.Reconciled:
      return Colors.DarkGreen;
    case CustomerInvoiceState.Deleted:
      return Colors.Red;
    default:
      assertNever(state);
  }
}
