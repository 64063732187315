import { Note, NoteType } from "@freightsimple/generated-apollo-openapi-client";
import { Checkbox, Form, message } from "antd";
import { useEffect, useState } from "react";
import { useAdminLogsApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { NotesTable } from "../Components/NotesTable";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { AdminsDropdown } from "./SalessComponents/AdminsDropdown";
import { NoteTypeDropdown } from "./ViewShipmentScreenComponents/NoteTypeDropdown";

export function AdminLogsScreen() {
  const [adminEmail, setAdminEmail] = useState<string | undefined>();
  const [notes, setNotes] = useState<Note[] | undefined>();
  const [errorHappened, setErrorHappened] = useState(false);
  const [noteTypeFilter, setNoteTypeFilter] = useState<NoteType | undefined>();
  const [showSystemErrors, setShowSystemErrors] = useState<boolean>(false);
  const createAdminLogsApi = useAdminLogsApi();

  async function refresh() {
    if (adminEmail === undefined) {
      return;
    }
    try {
      const adminLogsApi = await createAdminLogsApi();
      const response = await adminLogsApi.getLogsForAdmin({ adminEmail });
      setNotes(response);
    } catch (e) {
      message.error(`Something went wrong : ${e}`);
      setErrorHappened(true);
    }
  }

  useEffect(
    function () {
      refresh();
    },

    [adminEmail],
  );

  return (
    <>
      <Page
        title={`Admin Logs`}
        tags={[]}
        stats={[]}
        extra={[
          <ButtonRow key="filter">
            <Form.Item label="Select Admin">
              <AdminsDropdown email={adminEmail} setEmail={setAdminEmail} />
            </Form.Item>
            <Form.Item>
              <Checkbox
                checked={showSystemErrors}
                onChange={(e) => setShowSystemErrors(e.target.checked)}
              >
                Show System Errors
              </Checkbox>
            </Form.Item>
            <Form.Item label="Filter by note type">
              <NoteTypeDropdown
                mode="multiple"
                value={noteTypeFilter}
                setValue={setNoteTypeFilter}
              />
            </Form.Item>
          </ButtonRow>,
        ]}
      >
        <PageTitle>{`Admin Logs`}</PageTitle>
        {errorHappened && <div>❌ Ooops error happened</div>}
        {notes !== undefined && (
          <NotesTable
            showSystemErrors={showSystemErrors}
            notes={notes}
            noteTypeFilter={noteTypeFilter}
            noteCategoryFilter={undefined}
            noteAuthorFilter={undefined}
            freeFilter={undefined}
          />
        )}
      </Page>
    </>
  );
}
