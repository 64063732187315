import { ReactNode, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import { formatStack } from "../Helpers/formatStack";

const ErrorContainer = styled.div`
  padding: 2.5rem;
  border: 1px solid #ff0000;
  border-radius: 8px;
  margin: 2rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(255, 0, 0, 0.1);
`;

const ErrorHeading = styled.h2`
  color: #ff0000;
  margin: 0 0 0.2rem 0;
  font-size: 2rem;
  font-weight: 600;
`;

const ErrorSubHeading = styled.div`
  color: #444444;
  margin: 0 0 2rem 0;
  font-size: 1rem;
  font-weight: 400;
`;

const SectionHeading = styled.h3`
  color: #333;
  margin: 1.5rem 0 0.5rem 0;
  font-size: 1rem;
  font-weight: 500;
`;

const ErrorText = styled.pre`
  color: #ff0000;
  margin: 0.5rem 0 1.5rem 0;
  padding: 1rem;
  background-color: #fff5f5;
  border-radius: 4px;
  white-space: pre-wrap;
  word-break: break-all;
  font-family: "Consolas", "Monaco", monospace;
  font-size: 0.9rem;
  line-height: 1.5;
`;

const RetryButton = styled.button`
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #cc0000;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.3);
  }
`;

const ReferenceId = styled.div`
  color: #718096;
  font-size: 0.875rem;
  margin-top: 2rem;
`;

interface ErrorBoundaryViewProps {
  children: ReactNode;
}

interface ErrorData {
  error: unknown;
  componentStack: string;
  eventId: string;
  resetError: () => void;
}

interface ErrorBoundaryViewContentsProps {
  errorData: ErrorData;
}

function ErrorBoundaryViewContents(props: ErrorBoundaryViewContentsProps) {
  const { errorData } = props;
  const stack = (errorData.error as Error)?.stack ?? "";
  const componentStack = errorData.componentStack;

  const [formattedStack, setFormattedStack] = useState<string>("");
  const [formattedComponentStack, setFormattedComponentStack] =
    useState<string>("");

  useEffect(() => {
    async function doFormat() {
      setFormattedStack("Loading...");
      setFormattedComponentStack("Loading...");
      const _formattedStack = await formatStack(stack);
      const _formattedComponentStack = await formatStack(componentStack);
      setFormattedStack(_formattedStack);
      setFormattedComponentStack(_formattedComponentStack);
    }
    doFormat();
  }, [stack, componentStack]);

  return (
    <>
      <ErrorContainer role="alert">
        <ErrorHeading>Something went wrong</ErrorHeading>
        <ErrorSubHeading>
          Please make sure to create a Jam / Linear to report this issue
        </ErrorSubHeading>

        <SectionHeading>Error Message:</SectionHeading>
        <ErrorText>
          {errorData.error instanceof Error
            ? errorData.error.message
            : "An unknown error occurred"}
        </ErrorText>

        <SectionHeading>Stack Trace:</SectionHeading>
        <ErrorText>{formattedStack}</ErrorText>

        <SectionHeading>Component Stack:</SectionHeading>
        <ErrorText>{formattedComponentStack}</ErrorText>

        {errorData.eventId && (
          <ReferenceId>Reference ID: {errorData.eventId}</ReferenceId>
        )}

        <RetryButton onClick={errorData.resetError}>Try Again</RetryButton>
      </ErrorContainer>
    </>
  );
}

export function ErrorBoundaryView(props: ErrorBoundaryViewProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={(errorData: ErrorData) => (
        <ErrorBoundaryViewContents errorData={errorData} />
      )}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
