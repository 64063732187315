import {
  DocumentType,
  ShipmentDocument,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Form, Modal, Table } from "antd";
import { useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import { CreatedAt } from "../../Components/CreatedAt";
import { DocumentTypeDropdown } from "../../Components/DocumentTypeDropdown";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { safeColumns } from "../../Helpers/safeColumns";
import Spacer from "../../Spacer";
import { DocumentTypeTag } from "../ViewShipmentScreenComponents/DocumentTypeTag";
import { ViewDocumentButton } from "../ViewShipmentScreenComponents/ViewDocumentButton";
import { ButtonType } from "../../Types/AntHelperTypes";

interface DocumentsTableProps {
  shipmentData: ViewShipmentDataForApollo;
  attachments: ShipmentDocument[];
  onUpdateAttachments: (documents: ShipmentDocument[]) => void;
  documentTypeFilter: DocumentType | undefined;
}
interface ManageAttachmentsButtonProps {
  shipmentData: ViewShipmentDataForApollo;
  attachments: ShipmentDocument[];
  onUpdateAttachments: (documents: ShipmentDocument[]) => void;
  buttonText: string;
  buttonType: ButtonType;
  showThumbnails: boolean;
}

function DocumentsTable(props: DocumentsTableProps) {
  const onRow = (record: ShipmentDocument) => ({
    onClick: (event: React.MouseEvent) => {
      // Check if the click is not on the action button
      if (!(event.target as HTMLElement).closest(".action")) {
        // Record row was either added or removed
        if (
          props.attachments.find(
            (sd) => sd.shipmentDocumentId === record.shipmentDocumentId,
          )
        ) {
          // Already there - so removing
          props.onUpdateAttachments(
            props.attachments.filter(
              (sd) => sd.shipmentDocumentId !== record.shipmentDocumentId,
            ),
          );
        } else {
          // Not there, so adding
          const sd = props.shipmentData.documentsTab.documents.find(
            (d) =>
              d.shipmentDocument.shipmentDocumentId ===
              record.shipmentDocumentId,
          )!.shipmentDocument;
          props.onUpdateAttachments([sd, ...props.attachments]);
        }
      }
    },
  });

  function applyFilter(sd: ShipmentDocument): boolean {
    if (props.documentTypeFilter === undefined) {
      return true;
    }

    return sd.documentType === props.documentTypeFilter;
  }

  function onSelectRows(selectedRows: ShipmentDocument[]) {
    // This parameter is the list of new selected rows in this filter

    if (props.documentTypeFilter === undefined) {
      // No filter, so just update all attachments
      props.onUpdateAttachments(selectedRows);
      return;
    }

    // Keep all the other document types, and the new ones for this document type
    const newAttachments = [
      ...selectedRows,
      ...props.attachments.filter(
        (sd) => sd.documentType !== props.documentTypeFilter,
      ),
    ];
    props.onUpdateAttachments(newAttachments);
  }

  const filteredDocuments = props.shipmentData.documentsTab.documents
    .map((o) => o.shipmentDocument)
    .filter(applyFilter);

  return (
    <Table
      pagination={false}
      rowKey={function (row) {
        return row.shipmentDocumentId!;
      }}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys: props.attachments.map((a) => a.shipmentDocumentId!),
        onChange: (
          _selectedRowKeys: React.Key[],
          _selectedRows: ShipmentDocument[],
        ) => {
          // The selection logic isn't working
          onSelectRows(_selectedRows);
        },
      }}
      onRow={onRow}
      columns={safeColumns<ShipmentDocument>([
        {
          title: "Document Type",
          dataIndex: "documentType",
          key: "documentType",
          render: (documentType: DocumentType, o: ShipmentDocument) => (
            <>
              <DocumentTypeTag documentType={documentType} />
              <Spacer height={8} />
              <ViewDocumentButton
                document={o}
                shipmentData={props.shipmentData}
                showThumbnail
              />
            </>
          ),
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (createdAt) => <CreatedAt timestamp={createdAt} />,
        },
        {
          title: "Actions",
          dataIndex: "shipmentDocumentId",
          key: "shipmentDocumentId",
          render: function (_documentId: string, document: ShipmentDocument) {
            return (
              <ButtonRow className="action">
                <ViewDocumentButton
                  document={document}
                  shipmentData={props.shipmentData}
                />
              </ButtonRow>
            );
          },
        },
      ])}
      dataSource={filteredDocuments}
    />
  );
}

interface ListDocumentsProps {
  shipmentData: ViewShipmentDataForApollo;
  documents: ShipmentDocument[];
}

export function ListDocuments(props: ListDocumentsProps) {
  return (
    <HorizontalStack
      verticalAlign="top"
      spacing={16}
      style={{ marginLeft: "-16px" }}
    >
      {props.documents.map(function (sd) {
        return (
          <Stack
            key={sd.shipmentDocumentId!}
            align="center"
            style={{ width: "140px" }}
          >
            <DocumentTypeTag documentType={sd.documentType!} />
            <Spacer height={8} />
            <ViewDocumentButton
              document={sd}
              shipmentData={props.shipmentData}
              showThumbnail
            />
          </Stack>
        );
      })}
    </HorizontalStack>
  );
}

export function ManageAttachmentsButton(props: ManageAttachmentsButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentTypeFilter, setDocumentTypeFilter] = useState<DocumentType>();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const selectedDocumentsCount = props.attachments.length;
  return (
    <>
      <Modal
        title={`Manage Attachments (${selectedDocumentsCount})`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        okText={`Select Documents (${selectedDocumentsCount})`}
        width={900}
        cancelText={undefined}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Stack align="left">
          <ButtonRow>
            <Form.Item label="Filter Documents">
              <DocumentTypeDropdown
                value={documentTypeFilter}
                setValue={setDocumentTypeFilter}
              />
            </Form.Item>
          </ButtonRow>
          <DocumentsTable
            shipmentData={props.shipmentData}
            attachments={props.attachments}
            onUpdateAttachments={props.onUpdateAttachments}
            documentTypeFilter={documentTypeFilter}
          />
        </Stack>
      </Modal>

      <Stack align="left">
        <Button onClick={showModal} type={props.buttonType}>
          {props.buttonText} ({props.attachments.length})
        </Button>
        {props.showThumbnails && (
          <>
            <Spacer height={16} />
            <ListDocuments
              documents={props.attachments}
              shipmentData={props.shipmentData}
            />
          </>
        )}
      </Stack>
    </>
  );
}
