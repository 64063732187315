import { Button, DatePicker, message, Modal, Tooltip, Typography } from "antd";

import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { CompanyTabProps } from "../ViewCompanyScreen";
import dayjs from "dayjs";

const { Text } = Typography;

export function SnoozeForSalesCallsButton(props: CompanyTabProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createCompaniesApi = useCompaniesApi();
  const [snoozeUntilDateMoment, setSnoozeUntilDateMoment] = useState(dayjs());

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    try {
      const companyId = props.data.company.companyId!;
      const snoozeUntilDate = snoozeUntilDateMoment.format("YYYY-MM-DD");

      const companiesApi = await createCompaniesApi();

      await companiesApi.snoozeSalesCallsUntil({
        companyId,
        snoozeSalesCallsUntilDate: snoozeUntilDate,
      });
      await props.onRefresh();
      message.success(`Sales calls snoozed until ${snoozeUntilDate}`);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Snooze Sales Calls"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        destroyOnClose
      >
        <Stack align="left">
          <Text>
            This allows you to override the regular sales call cadence
          </Text>
          <Spacer height={32} />
          <HorizontalStack>
            <DatePicker
              style={{ width: "200px" }}
              value={snoozeUntilDateMoment}
              onChange={function (m) {
                if (m !== null) {
                  setSnoozeUntilDateMoment(m);
                }
              }}
            />
          </HorizontalStack>
        </Stack>
      </Modal>
      <Tooltip overlay="This will snooze sales calls until a given date">
        <Button onClick={showModal}>⏰ Snooze Sales Calls</Button>
      </Tooltip>
    </>
  );
}
