export function isValidEmail(emailAddress: string): boolean {
  const EMAIL_REGEX = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  if (emailAddress === undefined || emailAddress === "") {
    return false;
  }

  if (emailAddress.length < 4) {
    return false;
  }

  if (!EMAIL_REGEX.test(emailAddress)) {
    return false;
  }

  return true;
}
