import {
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";

import { Button, DatePicker, message, Modal, Tooltip, Typography } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import dayjs from "dayjs";

const { Text } = Typography;

interface SetAppointmentDateButtonProps {
  shipment: Shipment;
  onRefresh: () => Promise<void>;
}

export function SetAppointmentDateButton(props: SetAppointmentDateButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentApi = useShipmentsApi();
  const [appointmentDateMoment, setAppointmentDateMoment] = useState(dayjs());

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    try {
      const shipmentId = props.shipment.shipmentId!;
      const appointmentDate = appointmentDateMoment.format("YYYY-MM-DD");

      const shipmentApi = await createShipmentApi();

      await shipmentApi.setAppointmentDate({
        shipmentId,
        appointmentDate,
      });
      await props.onRefresh();
      message.success(
        `Appointment Set. Amy emails paused until ${appointmentDate}. Expected delivery date possibly updated.`,
      );
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (props.shipment.state !== ShipmentState.InTransit) {
    return <></>;
  }

  return (
    <>
      <Modal
        title="Set Appointment Date"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        destroyOnClose
      >
        <Stack align="left">
          <Text>
            Set an appointment date that is visible to the customer (and pause
            Amy emails)
          </Text>
          <Spacer height={32} />
          <HorizontalStack>
            <DatePicker
              style={{ width: "200px" }}
              value={appointmentDateMoment}
              onChange={function (m) {
                if (m !== null) {
                  setAppointmentDateMoment(m);
                }
              }}
            />
          </HorizontalStack>
        </Stack>
      </Modal>
      <Tooltip overlay="Set a customer visible appointment date. Specifically this will pause the Amy tracking update emails (when it has been more than 3 days since an update). She will still ask when the expected delivery date is in the past or missed deliveries. This will also update the expected delivery date if it is earlier than this appointment date">
        <Button onClick={showModal}>📆 Set Appointment</Button>
      </Tooltip>
    </>
  );
}
