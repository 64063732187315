import { Pdf } from "../Components/Pdf";
import useQuery from "../Hooks/useQuery";

export function ViewDocumentScreen() {
  const query = useQuery();

  const documentS3Path = query.documentS3Path as string;
  const filename = query.filename as string;
  return <Pdf documentS3Path={documentS3Path} filename={filename} />;
}
