import CarriersData from "../GeneratedFixtures/CarriersData.json";

export function serviceProperties(
  carrierIdentifier: string,
  serviceIdentifier: string,
) {
  return CarriersData.find(
    (o) =>
      o.carrierIdentifier === carrierIdentifier &&
      o.serviceIdentifier === serviceIdentifier,
  )!;
}

export function carrierPropertiesAnyService(carrierIdentifier: string) {
  return CarriersData.find((o) => o.carrierIdentifier === carrierIdentifier)!;
}

export function getCarrierDisplayNameFromCarrierIdentifier(
  carrierIdentifier: string,
) {
  return carrierPropertiesAnyService(carrierIdentifier)?.carrierDisplayName;
}
