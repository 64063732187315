import { LineItem } from "@freightsimple/generated-apollo-openapi-client";
import { Button, Modal, message } from "antd";
import { useEffect, useState } from "react";
import { errorMessageForLineItem } from "../../Helpers/errorMessageForLineItem";
import { EditLineItem } from "./EditLineItem";

interface AddLineItemButtonProps {
  addLineItem: (lineItem: LineItem) => void;
}

function emptyLineItem(): LineItem {
  return {
    handlingUnitType: "Pallet",
    temperatureHandling: "NoSpecialHandling",
    isDangerous: false,
    isStackable: false,
  };
}

export function AddLineItemButton(props: AddLineItemButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lineItem, setLineItem] = useState(emptyLineItem());

  useEffect(
    function () {
      if (isModalVisible) {
        // Refresh when the modal is reopened
        setLineItem(emptyLineItem());
      }
    },

    [isModalVisible],
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    const lineItemErrorMessage = errorMessageForLineItem(lineItem);
    if (lineItemErrorMessage !== undefined) {
      message.error(lineItemErrorMessage);
      return;
    }

    props.addLineItem(lineItem);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Add Line Item"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1300}
      >
        <EditLineItem lineItem={lineItem} setLineItem={setLineItem} />
      </Modal>
      <Button onClick={showModal}>Add Line Item</Button>
    </>
  );
}
