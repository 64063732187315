import {
  EquipmentType,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";
import { CreatedAt } from "../../Components/CreatedAt";
import { KeyValues } from "../../Components/KeyValues";
import { WithClipboardLink } from "../../Components/WithClipboardLink";
import { describeEquipmentType } from "../../Helpers/describeEquipmentType";
interface GeneralInfoProps {
  data: ViewShipmentDataForApollo;
}

export function GeneralInfo(props: GeneralInfoProps) {
  const data: Record<string, any> = {};

  const shipment = props.data.shipment.shipment;

  if (shipment.bookedAt !== undefined) {
    data["Carrier"] = (
      <WithClipboardLink>
        {`${props.data.shipment.quote.carrierIdentifier} - ${props.data.shipment.quote.serviceIdentifier}`}
      </WithClipboardLink>
    );
    data[props.data._configuration.mainTrackingNumberName] = (
      <WithClipboardLink>{shipment.proNumber}</WithClipboardLink>
    );
    if (!props.data._configuration.carrierPickupNumberSameAsProNumber) {
      data["Carrier Pickup Number"] = (
        <WithClipboardLink>{shipment.carrierPickupNumber}</WithClipboardLink>
      );
    }
    data["Account Number"] = (
      <WithClipboardLink>{props.data.accountNumber}</WithClipboardLink>
    );
  }
  data["Bill Of Lading Number"] = (
    <WithClipboardLink>{shipment.billOfLadingNumber}</WithClipboardLink>
  );
  data["Quoted At"] = <CreatedAt timestamp={shipment.createdAt!} />;
  if (shipment.bookedAt !== undefined) {
    data["Booked At"] = <CreatedAt timestamp={shipment.bookedAt!} />;
  }

  if (shipment.linearFeetOverride !== undefined) {
    data["Linear Feet Override"] = <div>{shipment.linearFeetOverride}ft</div>;
  }

  data["Equipment Type"] = describeEquipmentType(shipment.equipmentType);

  if (shipment.equipmentType === EquipmentType.Flatbed) {
    data["Tarp Required"] = shipment.tarpRequired ? "Yes" : "No";
  }

  data["Exclusive Use"] = shipment.exclusiveUseNeeded ? "Yes" : "No";

  if (shipment.addInsuranceToShipment) {
    data["Insurance"] =
      `${shipment.insuranceAmount} ${shipment.insuranceCurrency}`;
  }

  return <KeyValues data={data} />;
}
