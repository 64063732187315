import { Search } from "../Types/AntHelperTypes";
import { useLookup } from "./useLookup";

export function SearchInput() {
  const { onSearch, loading } = useLookup();
  return (
    <Search
      placeholder="Quick Lookup"
      onSearch={onSearch}
      style={{ width: 200 }}
      loading={loading}
    />
  );
}
