import { ClaudePrompt } from "@freightsimple/generated-apollo-openapi-client";

import { Button, Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { useTechnicalApi } from "../Apis/Apis";
import Colors from "../Components/Colors";
import Stack from "../Components/Stack";
import { TextArea } from "../Types/AntHelperTypes";
import { isBlank } from "@freightsimple/shared";

interface AddClaudePromptButtonProps {
  onRefresh: () => Promise<void>;

  // If we're editing then set this
  claudePrompt?: ClaudePrompt;
}

export function AddClaudePromptButton(props: AddClaudePromptButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [title, setTitle] = useState<string>(props.claudePrompt?.title ?? "");
  const [identifier, setIdentifier] = useState<string>(
    props.claudePrompt?.identifier ?? "",
  );
  const [body, setBody] = useState<string>(props.claudePrompt?.body ?? "");
  const [loading, setLoading] = useState<boolean>(false);
  const createTechnicalApi = useTechnicalApi();

  const claudePromptId = props.claudePrompt?.claudePromptId;

  const showModal = () => {
    setIsModalVisible(true);
  };

  function description() {
    if (props.claudePrompt) {
      return "Edit Prompt";
    } else {
      return "Add Prompt";
    }
  }

  async function handleOk() {
    if (isBlank(identifier) || isBlank(title) || isBlank(body)) {
      message.warning("Missing");
      return;
    }
    setLoading(true);
    try {
      const technicalApi = await createTechnicalApi();
      await technicalApi.upsertClaudePrompt({
        claudePromptId,
        title,
        identifier,
        body,
      });
      await props.onRefresh();
      message.success(`Success!`);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }

    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <Stack align="left">
            <div>{description()}</div>
            <div style={{ fontSize: "12px", color: Colors.LightText }}>
              Add variables that look like $VARIABLE_NAME and wire those up in
              the code. Identifier named the same as the method in ClaudeService
              (eg. summarizeSalesNotes)
            </div>
          </Stack>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
        confirmLoading={loading}
        okText="Save"
      >
        <Form
          style={{ width: "700px" }}
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 20, offset: 2 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Title">
            <Input
              value={title}
              onChange={function (e) {
                setTitle(e.target.value);
              }}
              style={{ width: "400px" }}
            />
          </Form.Item>
          <Form.Item label="Identifier">
            <Input
              value={identifier}
              onChange={function (e) {
                setIdentifier(e.target.value);
              }}
              style={{ width: "400px" }}
            />
          </Form.Item>
          <Form.Item label="Body">
            <TextArea
              style={{ height: "400px" }}
              value={body}
              onChange={function (e) {
                setBody(e.target.value);
              }}
              placeholder="Type the prompt here!"
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal}>{description()}</Button>
    </>
  );
}
