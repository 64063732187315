import { NoteCategory } from "@freightsimple/generated-apollo-openapi-client";
import { Button, Modal, message } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { NoteCategoryDropdown } from "./NoteCategoryDropdown";
import { TabProps } from "./TabProps";
import { TextArea } from "../../Types/AntHelperTypes";

export function AddInternalNoteButton(props: TabProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const title = "Add Internal Note";
  const [note, setNote] = useState("");
  const [category, setCategory] = useState<NoteCategory>(NoteCategory.Tracking);
  const shipmentId = props.shipmentData.shipmentId;
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  async function onClick() {
    setLoading(true);
    const shipmentApi = await createShipmentApi();
    try {
      await shipmentApi.addInternalNote({
        shipmentId,
        note,
        category,
      });
      await props.onRefresh();
      message.success("Note created");
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={title}
        open={isModalVisible}
        onOk={onClick}
        confirmLoading={loading}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
      >
        <Stack align="left">
          <TextArea
            style={{ width: "600px", height: "100px" }}
            value={note}
            onChange={function (e) {
              setNote(e.target.value);
            }}
            placeholder="Enter note"
          />
          <Spacer height={8} />
          <ButtonRow>
            <NoteCategoryDropdown value={category} setValue={setCategory} />
          </ButtonRow>
        </Stack>
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
