import { Tag } from "antd";

interface PaymentTermsTagProps {
  paymentTermsDays: number;
}

export function PaymentTermsTag(props: PaymentTermsTagProps) {
  if (props.paymentTermsDays === 0) {
    return <></>;
  }

  return (
    <Tag color="blue">
      <div style={{ color: "black" }}>
        On Credit : {props.paymentTermsDays} days
      </div>
    </Tag>
  );
}
