import { LineItem } from "@freightsimple/generated-apollo-openapi-client";
import { Button, Select } from "antd";
import HorizontalStack from "../../Components/HorizontalStack";
import { recommendFreightClassFromDimensions } from "./recommendFreightClassFromDimensions";

const { Option } = Select;

interface FreightClassDropdownProps {
  lineItem: LineItem;
  onChangeFreightClass: (_: string | undefined) => void;
}

function generateFreightClassOption(freightClass: string) {
  return <Option value={freightClass}>Class {freightClass}</Option>;
}

const freightClasses = [
  "50",
  "55",
  "60",
  "65",
  "70",
  "77.5",
  "85",
  "92.5",
  "100",
  "110",
  "125",
  "150",
  "175",
  "200",
  "250",
  "300",
  "400",
  "500",
];

export function FreightClassDropdown(props: FreightClassDropdownProps) {
  const newClass = recommendFreightClassFromDimensions(
    props.lineItem.weightPerHandlingUnit!,
    props.lineItem.length!,
    props.lineItem.width!,
    props.lineItem.height!,
  );

  const label = `Update Freight Class to match dimensions (Class ${newClass.freightClass})`;

  const currentFreightClassIsCorrect =
    newClass.freightClass === props.lineItem.freightClass;

  const updateDisabled =
    props.lineItem.weightPerHandlingUnit === undefined ||
    props.lineItem.length === undefined ||
    props.lineItem.width === undefined ||
    props.lineItem.height === undefined ||
    currentFreightClassIsCorrect;

  function updateFreightClass() {
    props.onChangeFreightClass(newClass.freightClass);
  }

  return (
    <HorizontalStack>
      <Select
        value={props.lineItem.freightClass}
        style={{ width: 200 }}
        onChange={props.onChangeFreightClass}
      >
        {freightClasses.map(generateFreightClassOption)}
      </Select>
      <Button
        type="link"
        onClick={updateFreightClass}
        disabled={updateDisabled}
      >
        {label}
      </Button>
    </HorizontalStack>
  );
}
