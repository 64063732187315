import {
  Note,
  NoteCategory,
  NoteType,
} from "@freightsimple/generated-apollo-openapi-client";

import { IconForNote } from "./Notes/IconForNote";
import { TimelineItemProps, Typography } from "antd";
import { groupBy } from "../Helpers/groupBy";
import {
  groupCollapsibleElements,
  GroupedShowGroups,
} from "../Helpers/groupCollapsibleElements";
import { NoteElement } from "./Notes/NoteElement";
import dayjs from "dayjs";
import { ErrorBoundary } from "react-error-boundary";
import Spacer from "../Spacer";
import { capitalizeFirstLetter } from "../Helpers/capitalizeFirstLetter";
import { ErrorNoteElement } from "./Notes/ErrorNoteElement";
import { NoteTimelineLabel } from "./Notes/NoteTimelineLabel";
import { colorForNoteIcon } from "./Notes/colorForNoteIcon";
import { useGroupedCollapsibleTimelineItems } from "./Timeline/useGroupedCollapsibleTimelineItems";
import { LeftAlignedTimeline } from "./LeftAlignedTimeline";
import { TimelineCollapseHeader } from "./Timeline/TimelineCollapseHeader";

export interface NoteElementProps {
  note: Note;
}

interface NotesTableProps {
  noteTypeFilter: NoteType | NoteType[] | undefined;
  noteAuthorFilter: string | string[] | undefined;
  noteCategoryFilter: NoteCategory | NoteCategory[] | undefined;
  freeFilter: string | undefined;
  showSystemErrors: boolean;
  notes: Note[];
}

function describeNoteType(noteType: NoteType | undefined): string {
  if (!noteType) return "Unknown";
  return noteType.split(/(?=[A-Z])/).join(" ");
}
export function NotesTable(props: NotesTableProps) {
  function filterByNoteType(note: Note): boolean {
    if (
      props.noteTypeFilter === undefined ||
      props.noteTypeFilter.length === 0
    ) {
      return true;
    }

    return note.noteType ? props.noteTypeFilter.includes(note.noteType) : false;
  }

  function filterByNoteCategory(note: Note): boolean {
    if (
      props.noteCategoryFilter === undefined ||
      props.noteCategoryFilter.length === 0
    ) {
      return true;
    }

    return note.category
      ? props.noteCategoryFilter.includes(note.category)
      : false;
  }

  function filterByAuthor(note: Note): boolean {
    if (
      props.noteAuthorFilter === undefined ||
      props.noteAuthorFilter.length === 0
    ) {
      return true;
    }

    return note.author ? props.noteAuthorFilter.includes(note.author) : false;
  }

  function filterSystemErrors(note: Note): boolean {
    if (props.showSystemErrors) {
      return true;
    }
    return note.headline !== "System Error";
  }

  function applyFreeFilter(note: Note): boolean {
    if (props.freeFilter === undefined || props.freeFilter.trim() === "") {
      return true;
    }

    return JSON.stringify(note)
      .toLowerCase()
      .includes(props.freeFilter.toLowerCase());
  }

  function filterNotes(props: NotesTableProps) {
    return props.notes
      .filter(filterByNoteType)
      .filter(filterByNoteCategory)
      .filter(filterByAuthor)
      .filter(applyFreeFilter)
      .filter(filterSystemErrors);
  }

  function sortByDate2(a: Note, b: Note) {
    return dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf();
  }

  function groupByCreationDate2(f: Note) {
    const m = dayjs.utc(f.createdAt).tz("America/Vancouver");
    return m.format("MMMM Do YYYY");
  }

  /**
   * Notes to show after applying the filters
   */
  const notesToShow = filterNotes(props);

  const errorFilteredNotes = props.notes.filter(filterSystemErrors);

  const groupedNotes = groupBy(
    errorFilteredNotes.sort(sortByDate2),
    groupByCreationDate2,
  );

  const groupedCollapsibles = groupCollapsibleElements(groupedNotes, (e) =>
    notesToShow.includes(e),
  );

  console.log("Re render notes table");

  function renderItem(note: Note): TimelineItemProps {
    return {
      dot: (
        <span
          title={`${describeNoteType(
            note.noteType,
          )} - ${capitalizeFirstLetter(note.category)}`}
        >
          <IconForNote note={note} />
          <Spacer height={4} />
          <span
            style={{
              display: "block",
              color: colorForNoteIcon(note),
              fontSize: "10px",
              fontWeight: "400",
            }}
          >
            {capitalizeFirstLetter(note.category)}
          </span>
        </span>
      ),
      label: <NoteTimelineLabel note={note} />,
      children: (
        <div style={{ marginLeft: 24, marginBottom: 24 }}>
          <ErrorBoundary
            FallbackComponent={() => (
              <ErrorNoteElement note={note} metadataError={true} />
            )}
          >
            <NoteElement note={note} />
          </ErrorBoundary>
        </div>
      ),
    };
  }

  function renderGroupHeader(
    group: GroupedShowGroups<string, Note>,
  ): TimelineItemProps {
    return {
      dot: (
        <div style={{ margin: 0 }}>
          <Typography.Title level={2} style={{ margin: 0 }}>
            {group.key}
          </Typography.Title>
          <Typography.Title
            type="secondary"
            level={5}
            style={{ margin: 0, padding: 0 }}
          >
            {dayjs(group.showGroups[0].elements[0].createdAt).format("dddd")}
          </Typography.Title>
        </div>
      ),
      children: (
        <div>
          <Spacer height={60} />
        </div>
      ),
    };
  }

  const items = useGroupedCollapsibleTimelineItems({
    groups: groupedCollapsibles,
    renderItem,
    renderGroupHeader,
    renderShowGroupHeader: (group, i, states, setState) => ({
      dot: (
        <TimelineCollapseHeader
          active={states[i]}
          onClick={() => setState(i, !states[i])}
        >
          {group.elements.length}
          {group.elements.length > 1 ? " notes" : " note"} hidden{" "}
        </TimelineCollapseHeader>
      ),
      children: <Spacer height={60} />,
    }),
  });

  return <LeftAlignedTimeline items={items} />;
}
