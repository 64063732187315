import { TabProps } from "./TabProps";
import { FreightClaimsTable } from "./FreightClaims/FreightClaimsTable";

export function ClaimsTab(props: TabProps) {
  return (
    <FreightClaimsTable
      freightClaims={props.shipmentData.claimsTab.claims}
      {...props}
    />
  );
}
