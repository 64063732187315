import { Location } from "@freightsimple/generated-apollo-openapi-client";
import { Link } from "react-router-dom";
import { ButtonRow } from "../../../Components/ButtonRow";
import { CollapsibleNote } from "../../../Components/NotesBase/CollapseNote";
import { NoteDetailsBox } from "../../../Components/NotesBase/NoteDetailsBox";
import { NoteAuthorHeadline } from "../../../Components/NotesBase/NoteHeadline";
import Spacer from "../../../Spacer";
import { CompanyNoteElementProps } from "../CompanyNote";

export function QuoteRunCompanyNoteElement({
  note,
  collapseActive,
}: CompanyNoteElementProps) {
  const request = JSON.parse(note.metadata!).data.quoteRequest;
  const pickupLocation = request.pickupLocation as Location;
  const deliveryLocation = request.deliveryLocation as Location;

  function describeAddress(location: Location | null): string {
    return `${location?.address?.city}, ${location?.address?.stateOrProvinceCode}, ${location?.address?.postalCode}`;
  }

  return (
    <>
      <CollapsibleNote
        collapseActive={collapseActive}
        header={
          <NoteAuthorHeadline headline={note.subject} author={note.author} />
        }
      >
        <NoteDetailsBox>
          <NoteDetailsBox.LeftRightDetails
            left="Pickup Date"
            right={request.pickupDate}
          />

          <NoteDetailsBox.LeftRightDetails
            left="Pickup Address"
            right={describeAddress(pickupLocation)}
          />

          <NoteDetailsBox.LeftRightDetails
            left="Delivery Address"
            right={describeAddress(deliveryLocation)}
          />
        </NoteDetailsBox>
      </CollapsibleNote>
      <Spacer height={4} />
      <ButtonRow style={{ columnGap: "15px" }}>
        {request && (
          <Link to={`/view-shipment?shipmentId=${request.shipmentId}`}>
            View Shipment
          </Link>
        )}
        {request && (
          <Link
            to={`/view-shipment?shipmentId=${request.shipmentId}&tab=quotes`}
          >
            View Quotes
          </Link>
        )}
      </ButtonRow>
    </>
  );
}
