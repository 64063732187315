import CarrierLogo from "../Components/CarrierLogo";
import HorizontalStack from "../Components/HorizontalStack";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import useQuery from "../Hooks/useQuery";

export function CarrierScreen() {
  const query = useQuery();
  const carrierIdentifier = query.carrierIdentifier as string;
  return (
    <>
      <Page
        title={
          <HorizontalStack>
            <CarrierLogo
              carrierIdentifier={carrierIdentifier}
              brokeredCarrierIdentifier={undefined}
              width={70}
              height={40}
            />
          </HorizontalStack>
        }
        tags={[]}
        stats={
          <></>
          //   <StatsRow>
          //     <Statistic
          //       title="Total Carriers"
          //       prefix=""
          //       value={carrierCount}
          //       style={{
          //         margin: "0 32px",
          //       }}
          //     />
          //     <Statistic
          //       title="Total Services"
          //       value={services.length}
          //       style={{
          //         margin: "0 32px",
          //       }}
          //     />
          //   </StatsRow>
        }
        extra={[]}
      >
        <PageTitle>{`Carrier : ${carrierIdentifier}`}</PageTitle>
      </Page>
    </>
  );
}
