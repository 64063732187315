const salesReps = [
  { name: "Natasha", email: "natasha@freightsimple.com" },
  { name: "Chris", email: "chris@freightsimple.com" },
  { name: "Lucia", email: "lucia@freightsimple.com" },
  { name: "Amy", email: "amy@freightsimple.com" },
];

export function getSalesRepsData() {
  return salesReps;
}
