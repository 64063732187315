import {
  CustomerInvoice,
  CustomerInvoiceState,
  CustomerInvoiceType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Modal, notification } from "antd";
import { useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { CurrencyInput } from "../../Components/CurrencyInput";
import { TabProps } from "./TabProps";

interface PartialCustomerRefundButtonProps extends TabProps {
  customerInvoice: CustomerInvoice;
}

export function PartialCustomerRefundButton(
  props: PartialCustomerRefundButtonProps,
) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!;
  const [amount, setAmount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.reduceOrRefundPartialInvoice({
        customerInvoiceId,
        amount,
      });

      await props.onRefresh();
      notification.success({
        message: `Success`,
        description: <>Refund processed</>,
      });
    } catch (e: any) {
      notification.error({
        message: `Oops. Something went wrong`,
        description: <>{e}</>,
      });
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const enabled =
    props.customerInvoice.customerInvoiceState ===
      CustomerInvoiceState.Settled ||
    props.customerInvoice.customerInvoiceState ===
      CustomerInvoiceState.Reconciled;

  const isRefundable =
    props.customerInvoice.invoiceType === CustomerInvoiceType.ShipmentCharge ||
    props.customerInvoice.invoiceType === CustomerInvoiceType.AdditionalCharge;

  if (!enabled || !isRefundable) {
    return <></>;
  }

  return (
    <>
      <Modal
        title="Partial Refund Customer Invoice"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
      >
        <p>How much do you want to refund the customer for this invoice?</p>
        <CurrencyInput
          amount={amount}
          setAmount={setAmount}
          currency={props.shipmentData.shipment.company.currency!}
        />
      </Modal>
      <Button onClick={showModal}>Partial Refund</Button>
    </>
  );
}
