/**
 * Creates a download of a Blob with the specified filename
 * @param blob The blob to download
 * @param filename The name to save the file as
 */
export function downloadBlob(blob: Blob, filename: string) {
  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);
  
  // Create a temporary link element
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  
  // Append to document, click, and cleanup
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  
  // Release the URL object
  window.URL.revokeObjectURL(url);
} 