import {
  Form,
  Input,
  Menu,
  MenuItemProps,
  message,
  Modal,
  Typography,
} from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { CompanyTabProps } from "../ViewCompanyScreen";
import { useNavigate } from "react-router-dom";
import { WarningComponent } from "../../Components/WarningComponent";

const { Text } = Typography;

export function CombineWithDifferentCompanyMenuItem(
  props: CompanyTabProps & MenuItemProps,
) {
  const originalCompanyId = props.data.company.companyId!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [destinationCompanyId, setDestinationCompanyId] = useState<
    string | undefined
  >();
  const [loading, setLoading] = useState(false);
  const createCompaniesApi = useCompaniesApi();
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (!destinationCompanyId) {
      message.warning("Select a destination company");
      return;
    }

    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      const response = await companiesApi.combineWithAnotherCompany({
        originalCompanyId,
        destinationCompanyId,
      });

      if (response.errorMessage !== undefined) {
        message.error(`Unable to move : ${response.errorMessage}`);
        setLoading(false);
        return;
      }

      message.success(`Successfully moved!`);
      setTimeout(function () {
        navigate(`/view-company?companyId=${destinationCompanyId}`);
      }, 1000);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }

    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Combine with a different company"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        confirmLoading={loading}
        width={1200}
      >
        <div>
          This is useful if we are trying to merge companies together, eg. if a
          new company was created by mistake
        </div>
        <Spacer height={16} />
        <div style={{ fontWeight: 600 }}>Steps:</div>
        <ul>
          <li>Move all shipments and invoices</li>
          <li>Move all company notes</li>
          <li>Move all sales contacts</li>
          <li>Move all dashboard users</li>
          <li>Add a company note to the new company</li>
          <li>
            <WarningComponent /> Delete the original company!
          </li>
        </ul>
        <Spacer height={32} />
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Stack align="left">
            <Text>
              Enter the UUID of the company you want to combine this company's
              data with
            </Text>
          </Stack>
          <Spacer height={32} />
          <Form.Item
            label="Destination Company UUID"
            tooltip="You can get this from the dropdown menu on the destination company Apollo page. UUID will look like d62a64e3-ba8f-4118-8458-c03753936350"
          >
            <Input
              value={destinationCompanyId}
              onChange={function (e) {
                setDestinationCompanyId(e.target.value);
              }}
              style={{ width: "600px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        Combine with a different company
      </Menu.Item>
    </>
  );
}
