import { FreightClaimState } from "@freightsimple/generated-apollo-openapi-client";
import { Tag } from "antd";
import { describeFreightClaimState } from "./describeFreightClaimState";
import { freightClaimStateTagColor } from "./freightClaimStateTagColor";

interface FreightClaimStateTagProps {
  state: FreightClaimState | undefined;
}

export function FreightClaimStateTag({ state }: FreightClaimStateTagProps) {
  return (
    <Tag color={freightClaimStateTagColor(state)}>
      {describeFreightClaimState(state)}
    </Tag>
  );
}
