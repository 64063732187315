import styled from "styled-components";
import Colors from "./Colors";

export const CollapseGroupHeader = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${Colors.LightText};
  padding: 4px 8px;
  border-radius: 4px;

  .anticon {
    font-size: 8pt;
    transform: ${(props) => (props.active ? "rotate(90deg)" : "rotate(0)")};
    transition: transform 300ms ease;
  }
`;
