import {
  CustomerInvoice,
  CustomerInvoiceState,
} from "@freightsimple/generated-apollo-openapi-client";
import { message, Tag } from "antd";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { ConfirmMenuItemWithTextArea } from "../../Components/ConfirmButtonWithTextArea";

interface MarkIssuedSettlementPendingCustomerInvoiceMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
  onCloseParent: () => void;
}

export function MarkIssuedSettlementPendingCustomerInvoiceMenuItem(
  props: MarkIssuedSettlementPendingCustomerInvoiceMenuItemProps,
) {
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!;

  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  async function handleOk(reason: string) {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.markIssuedSettlementPendingInvoice({
        customerInvoiceId,
        reason,
      });
      await props.onRefresh();
      message.success("Invoice marked issued");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    // This is needed becasue the invoice pdf doesn't reload, so would be stale otherwise
    props.onCloseParent();
  }

  if (
    props.customerInvoice.customerInvoiceState !==
    CustomerInvoiceState.SettlementPending
  ) {
    return <></>;
  }

  return (
    <ConfirmMenuItemWithTextArea
      onConfirm={handleOk}
      placeholder="Enter reason"
      question={
        "Are you sure you want to mark the settlement pending invoice as issued? We might want to do this when the credit card payment was stuck in settlement pending because secure verification was triggered which we don't handle"
      }
      okText={"Yes, mark issued"}
      tooltip={
        "This will then regenerate the customer invoice showing the bill is issued. This is useful when the payment didn't complete properly"
      }
    >
      <Tag color="red">Advanced</Tag> Mark Issued Settlement Pending Customer
      Invoice
    </ConfirmMenuItemWithTextArea>
  );
}
