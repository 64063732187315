import { NoteType } from "@freightsimple/generated-apollo-openapi-client";
import { assertNever } from "./assertNever";

export function describeNoteType(type: NoteType | undefined) {
  switch (type) {
    case undefined:
      return "??";
    case NoteType.DashboardAction:
      return "User/Dashboard Actions";
    case NoteType.ApolloAction:
      return "Apollo Actions";
    case NoteType.Email:
      return "Emails";
    case NoteType.MondayComment:
      return "Monday";
    case NoteType.Intercom:
      return "Intercoms";
    case NoteType.SalesforceCall:
      return "Salesforce Call";
    case NoteType.ShipmentModified:
      return "Shipment Modified";
    case NoteType.ReferenceNumberChanged:
      return "Reference Number Changed";
    case NoteType.ShipmentCancelled:
      return "Shipment Cancelled";
    case NoteType.Dialpad:
      return "Dialpad Calls";
    case NoteType.SystemMessage:
      return "System Messages";
    case NoteType.SystemErrorUnused:
      return "System Error Unused";
    case NoteType.InternalNote:
      return "Internal Note";
    default:
      assertNever(type);
  }
}
