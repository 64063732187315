import { Select } from "antd";

interface CampaignSelectProps {
  campaign: string | null | undefined;
  setCampaign: (_: string | undefined) => void;
  disabled?: boolean;
}

export function CampaignSelect(props: CampaignSelectProps) {
  const { campaign, setCampaign } = props;
  const campaigns = [
    "springltl",
    "ltlnovabbc",
    "freightnovabbc",
    "testCampaign",
    "may2021",
    "freight",
    "ltl",
    "freight-autumn-2022",
    "2023-summer-freight-scm",
    "2023-summer-ltl",
    "2023-summer-skag",
  ];
  return (
    <Select
      disabled={props.disabled}
      allowClear
      value={campaign}
      style={{ width: 220 }}
      onChange={function (e) {
        setCampaign(e);
      }}
      placeholder="Filter by campaign"
    >
      {campaigns.map(function (ls) {
        return (
          <Select.Option key={ls} value={ls}>
            {ls}
          </Select.Option>
        );
      })}
    </Select>
  );
}
