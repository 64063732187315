import { Select } from "antd";

interface TriStateBooleanProps {
  value: boolean | undefined;
  setValue: (value: boolean | undefined) => void;
  style?: React.CSSProperties;
}

export function TriStateBoolean(props: TriStateBooleanProps) {
  return (
    <Select
      value={props.value}
      onChange={(value) => {
        props.setValue(value);
      }}
      style={{ width: 220, ...props.style }}
      placeholder={"Any"}
      defaultValue={undefined}
      allowClear={true}
    >
      <Select.Option value={true}>Yes</Select.Option>
      <Select.Option value={false}>No</Select.Option>
    </Select>
  );
}
