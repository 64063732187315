import { ArrowRightOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { CSSProperties, ReactNode } from "react";
import { objectDifferences } from "../../Helpers/objectDifferences";
import { spaceOutCamelCase } from "../../Helpers/spaceOutCamelCase";
import Colors from "../Colors";
import { NoteDetailsBox } from "./NoteDetailsBox";
import { ValueDisplay } from "../ValueDisplay";

interface ModifiedGenericNoteProps<L, R> {
  beforeData: L;
  afterData: R;
  ignoreFields?: string[];
  fields?: string[] | undefined;
  boxStyle?: CSSProperties;
  title?: ReactNode;
  customValueDisplay?: (text: any, key: string) => ReactNode | undefined;
}

export function ModifiedGenericNote<L extends object, R extends object>({
  beforeData,
  afterData,
  ignoreFields = [],
  fields = undefined,
  boxStyle,
  title,
  customValueDisplay,
}: ModifiedGenericNoteProps<L, R>) {
  const differencesResponse = objectDifferences(beforeData, afterData);

  let differences = differencesResponse;

  if (fields) {
    differences = differences.filter((d) => fields.includes(d.key));
  }

  differences = differences.filter((d) => !ignoreFields.includes(d.key));

  if (differences.length === 0) {
    return <></>;
  }

  return (
    <NoteDetailsBox style={boxStyle}>
      {title ? title : <Typography.Title level={3}>Changes</Typography.Title>}
      {differences.map((d) => (
        <NoteDetailsBox.LeftRightDetails
          key={d.key}
          left={spaceOutCamelCase(d.key)}
          right={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  color: d.left ? Colors.LightText : Colors.VeryLightText,
                }}
              >
                <ValueDisplay
                  value={d.left}
                  keyName={d.key}
                  customValueDisplay={customValueDisplay}
                />
              </span>
              <ArrowRightOutlined
                style={{
                  fontSize: "10px",
                  marginRight: "8px",
                  marginLeft: "8px",
                }}
              />
              <span style={{ color: Colors.Palette.Green[700] }}>
                <ValueDisplay
                  value={d.right}
                  keyName={d.key}
                  customValueDisplay={customValueDisplay}
                />
              </span>
            </div>
          }
        />
      ))}
    </NoteDetailsBox>
  );
}
