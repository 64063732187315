import { InvoiceAuditProblemConfidence } from "@freightsimple/generated-apollo-openapi-client";
import { assertNever } from "../../Helpers/assertNever";

export function colorForInvoiceAuditProblemConfidence(
  confidence: InvoiceAuditProblemConfidence | undefined,
): string {
  switch (confidence) {
    case InvoiceAuditProblemConfidence.High:
      return "#444";
    case InvoiceAuditProblemConfidence.Medium:
      return "#999";
    case InvoiceAuditProblemConfidence.Low:
      return "#bbb";
    case undefined:
      return "#eee";
    default:
      assertNever(confidence);
  }
}
