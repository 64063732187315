import { Dollar } from "../../Components/Dollar";
import { assertNever } from "../../Helpers/assertNever";
import { ReportType } from "./ReportType";

interface DeltaElementProps {
  children: number;
  reportType: ReportType;
}

export function DeltaElement(props: DeltaElementProps) {
  switch (props.reportType) {
    case ReportType.Revenue:
      return <Dollar colored>{props.children}</Dollar>;
    case ReportType.ShipmentsBooked:
      return <></>;
    case ReportType.Quotes:
      return <></>;
    case ReportType.Acceptance:
      return <></>;
    default:
      assertNever(props.reportType);
  }
}
