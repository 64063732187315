import dayjs from "dayjs";

export function daysAgo(date: Date | string | undefined): number {
  // If no date is provided, assume current date
  const inputDate = date ? dayjs(date) : dayjs();

  // Calculate the difference in days
  const today = dayjs().startOf("day");
  const daysAgo = today.diff(inputDate.startOf("day"), "days");

  return daysAgo;
}
