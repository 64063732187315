import {
  DocumentType,
  UploadDocumentRequest,
} from "@freightsimple/generated-apollo-openapi-client";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Form, message, Modal, Typography } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { DocumentTypeDropdown } from "../../Components/DocumentTypeDropdown";
import { TabProps } from "./TabProps";
import { Dragger } from "../../Types/AntHelperTypes";
import { blobToBase64 } from "../../Helpers/blobToBase64";

const { Title } = Typography;

export function UploadDocumentsButton(props: TabProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!;
  const createShipmentsApi = useShipmentsApi();

  const [base64, setBase64] = useState<string | undefined>();
  const [documentType, setDocumentType] = useState<DocumentType | undefined>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploading, setUploading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (base64 === undefined) {
      message.warning("Document uploaded");
      return;
    }

    if (documentType === undefined) {
      message.warning("No document type selected");
      return;
    }

    setUploading(true);
    try {
      const shipmentsApi = await createShipmentsApi();

      const request: UploadDocumentRequest = {
        shipmentId,
        documentType,
        body: base64,
      };

      await shipmentsApi.uploadDocument(request);
      await props.onRefresh();
      setIsModalVisible(false);
      message.success("Uploaded");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setUploading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Upload Documents"
        visible={isModalVisible}
        okText="Upload"
        onOk={handleOk}
        confirmLoading={uploading}
        onCancel={handleCancel}
        width={1450}
        destroyOnClose
      >
        <Title level={5}>Upload New Document</Title>
        <Form
          style={{ width: "1200px" }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 19, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Document Type">
            <DocumentTypeDropdown
              value={documentType}
              setValue={setDocumentType}
            />
          </Form.Item>
          <Form.Item label="Document PDF">
            <Dragger
              name="file"
              accept=".pdf"
              multiple={false}
              beforeUpload={async function (info) {
                const array = await info.arrayBuffer();
                const base64 = await blobToBase64(new Blob([array]));
                setBase64(
                  base64.replace("data:application/octet-stream;base64,", ""),
                );
                return false;
              }}
              onChange={function () {}}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag invoice to upload (pdf only)
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal}>Upload New Document</Button>
    </>
  );
}
