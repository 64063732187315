import { ApolloMapQuery } from "@freightsimple/generated-apollo-openapi-client";
import { Modal } from "antd";
import { useState } from "react";
import { Json } from "../../Components/Json";
import Colors from "../../Components/Colors";

interface AllShipmentsScreenQueryViewerProps {
  query: ApolloMapQuery;
}

export function AllShipmentsScreenQueryViewer(
  props: AllShipmentsScreenQueryViewerProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Modal
        title="Query JSON"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width={800}
      >
        <Json data={props.query} />
      </Modal>
      <span
        onClick={() => setIsModalVisible(true)}
        style={{
          color: Colors.Blue,
          cursor: "pointer",
          fontSize: "11px",
          fontWeight: 600,
        }}
      >
        View Query
      </span>
    </>
  );
}
