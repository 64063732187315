import { Input, Modal } from "antd";
import { useState } from "react";
import { LinkButton } from "../../Components/LinkButton";

interface EditSalesEmailSubjectButtonProps {
  subject: string;
  setSubject: (_: string) => void;
}

export function EditSalesEmailSubjectButton(
  props: EditSalesEmailSubjectButtonProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Edit Email Subject"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
      >
        <Input
          value={props.subject}
          onChange={function (e) {
            props.setSubject(e.target.value);
          }}
        />
      </Modal>

      <LinkButton style={{ fontSize: "11px" }} onClick={showModal}>
        Edit
      </LinkButton>
    </>
  );
}
