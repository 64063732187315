import {
  CompanyShipment,
  CompanyShipmentQuote,
  Shipment,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Table, Tag, Typography } from "antd";
import { useState } from "react";
import { BrowserView } from "react-device-detect";
import { useShipmentsApi } from "../Apis/Apis";
import CarrierLogo from "../Components/CarrierLogo";
import Colors from "../Components/Colors";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { ShipmentStatusTag } from "../Components/ShipmentStatusTag";
import Stack from "../Components/Stack";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { Weight } from "../Components/Weight";
import { emojiForCountry } from "../Helpers/emojiForCountry";
import { safeColumns } from "../Helpers/safeColumns";
import {
  getStarredShipmentById,
  unstarAllShipments,
  useStarredShipments,
} from "../Helpers/starredShipments";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";
import { ShipmentProblemTag } from "../Components/ShipmentProblemTag";

const { Title } = Typography;

interface ShipmentTableProps {
  shipments: CompanyShipmentQuote[];
  title: string;
  includePickupNumbers?: boolean;
}

interface QuotedShipmentTableProps {
  shipments: CompanyShipment[];
  title: string;
}

function ShipmentTable(props: ShipmentTableProps) {
  const shipments = props.shipments;

  if (shipments.length === 0) {
    return <></>;
  }

  return (
    <>
      <Title style={{ marginTop: "32px" }} level={4}>
        {props.title}
      </Title>
      <Table
        style={{
          width: "100%",
          border: "1px solid #ccc",
        }}
        columns={safeColumns<CompanyShipmentQuote>([
          {
            title: "State",
            dataIndex: ["company", "companyName"],
            key: "companyName",
            render: function (_text, o) {
              return <ShipmentStatusTag status={o.shipment.state} />;
            },
            width: "230px",
          },
          {
            title: "Booking Company",
            dataIndex: ["company", "companyName"],
            key: "companyName",
            render: function (text, o) {
              return (
                <Stack align="left">
                  <div>{text}</div>
                  <Spacer height={8} />
                  {o.shipment.tags?.map((s) => (
                    <Tag key={s} color="orange">
                      {s}
                    </Tag>
                  ))}
                  {o.shipment.problems?.map((s) => (
                    <ShipmentProblemTag key={s} code={s} />
                  ))}
                </Stack>
              );
            },
            width: "230px",
          },
          {
            title: "Reason",
            dataIndex: ["shipment", "shipmentId"],
            key: "shipmentId",
            render: function (shipmentId) {
              const startedShipments = getStarredShipmentById(shipmentId);
              return <div>{startedShipments?.reason}</div>;
            },
            width: "230px",
          },

          {
            title: "Carrier",
            dataIndex: ["quote", "carrierIdentifier"],
            key: "carrierIdentifier",
            render: (text, o) => (
              <Stack>
                <CarrierLogo
                  carrierIdentifier={text}
                  brokeredCarrierIdentifier={o.quote.brokeredCarrierIdentifier}
                  width={80}
                  height={30}
                />
                <div
                  style={{
                    marginTop: "4px",
                    fontSize: "10px",
                    color: Colors.LightText,
                  }}
                >
                  {o.quote.serviceIdentifier}
                </div>
              </Stack>
            ),
            width: "100px",
          },
          {
            title: props.includePickupNumbers ? "Pickup Number" : "Pro",
            dataIndex: ["shipment", "proNumber"],
            key: "proNumber",
            render: function (_text, csq) {
              const proNumber = csq.shipment.proNumber;
              const pickupNumber = csq.shipment.carrierPickupNumber;

              if (props.includePickupNumbers) {
                return <div>{pickupNumber}</div>;
              }
              return <div>{proNumber}</div>;
            },
            width: "100px",
          },
          {
            title: "Pickup Date",
            dataIndex: ["shipment", "pickupDate"],
            key: "pickupDate",
            render: (text) => <div>{text}</div>,
            width: "100px",
          },
          {
            title: "Details",
            dataIndex: ["shipment"],
            key: "companies",
            render: (shipment: Shipment) => (
              <>
                <Stack align="left">
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      {emojiForCountry(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        shipment.pickupLocation?.address?.countryCode!,
                      )}
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>{shipment.pickupLocation?.businessName}</div>
                      <div
                        style={{
                          marginTop: "-4px",
                          fontSize: "12px",
                          color: Colors.LightText,
                        }}
                      >
                        {shipment.pickupLocation?.address?.city},{" "}
                        {shipment.pickupLocation?.address?.stateOrProvinceCode}
                      </div>
                    </Stack>
                  </HorizontalStack>
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      {emojiForCountry(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        shipment.deliveryLocation?.address?.countryCode!,
                      )}
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>{shipment.deliveryLocation?.businessName}</div>
                      <div
                        style={{
                          marginTop: "-4px",
                          fontSize: "12px",
                          color: Colors.LightText,
                        }}
                      >
                        {shipment.deliveryLocation?.address?.city},{" "}
                        {
                          shipment.deliveryLocation?.address
                            ?.stateOrProvinceCode
                        }
                      </div>
                    </Stack>
                  </HorizontalStack>
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      📦
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>{shipment.lineItems![0].description}</div>
                      <div
                        style={{
                          marginTop: "-4px",
                          fontSize: "12px",
                          color: Colors.LightText,
                        }}
                      >
                        {shipment.totalHandlingUnits} skids,{" "}
                        <Weight>{shipment.totalWeight!}</Weight>
                      </div>
                    </Stack>
                  </HorizontalStack>
                </Stack>
              </>
            ),
            width: "200px",
          },
          {
            title: "Actions",
            dataIndex: ["shipment", "shipmentId"],
            key: "shipment.shipmentId",
            render: function (shipmentId) {
              return <ViewShipmentButton shipmentId={shipmentId} />;
            },
            width: "150px",
          },
        ])}
        dataSource={shipments}
        pagination={false}
      />
    </>
  );
}

function QuotedShipmentTable(props: QuotedShipmentTableProps) {
  let shipments = props.shipments;

  shipments = props.shipments;

  if (shipments.length === 0) {
    return <></>;
  }

  return (
    <>
      <Title style={{ marginBottom: "32px" }} level={4}>
        {props.title}
      </Title>
      <Table
        style={{
          width: "100%",
          border: "1px solid #ccc",
        }}
        columns={safeColumns<CompanyShipment>([
          {
            title: "Quoting Company",
            dataIndex: ["company", "companyName"],
            key: "companyName",
            render: function (text, o) {
              return (
                <Stack align="left">
                  <div>{text}</div>
                  <Spacer height={8} />
                  {o.shipment.tags?.map((s) => (
                    <Tag key={s} color="orange">
                      {s}
                    </Tag>
                  ))}
                  {o.shipment.problems?.map((s) => (
                    <ShipmentProblemTag key={s} code={s} />
                  ))}
                </Stack>
              );
            },
            width: "230px",
          },

          {
            title: "Reason",
            dataIndex: ["shipment", "shipmentId"],
            key: "shipmentId",
            render: function (shipmentId) {
              const startedShipments = getStarredShipmentById(shipmentId);
              return <div>{startedShipments?.reason}</div>;
            },
            width: "230px",
          },

          {
            title: "Pickup Date",
            dataIndex: ["shipment", "pickupDate"],
            key: "pickupDate",
            render: (text) => <div>{text}</div>,
            width: "100px",
          },
          {
            title: "Details",
            dataIndex: ["shipment"],
            key: "companies",
            render: (shipment: Shipment) => (
              <>
                <Stack align="left">
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      {emojiForCountry(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        shipment.pickupLocation?.address?.countryCode!,
                      )}
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>
                        {shipment.pickupLocation?.address?.city},{" "}
                        {shipment.pickupLocation?.address?.stateOrProvinceCode}
                      </div>
                    </Stack>
                  </HorizontalStack>
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      {emojiForCountry(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        shipment.deliveryLocation?.address?.countryCode!,
                      )}
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>
                        {shipment.deliveryLocation?.address?.city},{" "}
                        {
                          shipment.deliveryLocation?.address
                            ?.stateOrProvinceCode
                        }
                      </div>
                    </Stack>
                  </HorizontalStack>
                  <HorizontalStack verticalAlign="top">
                    <div
                      style={{
                        fontSize: "10px",
                        color: Colors.LightText,
                        width: "16px",
                        textAlign: "right",
                        paddingTop: "4px",
                      }}
                    >
                      📦
                    </div>
                    <Spacer width={8} />
                    <Stack align="left">
                      <div>{shipment.lineItems![0].description}</div>
                      <div
                        style={{
                          marginTop: "-4px",
                          fontSize: "12px",
                          color: Colors.LightText,
                        }}
                      >
                        {shipment.totalHandlingUnits} skids,{" "}
                        <Weight>{shipment.totalWeight!}</Weight>
                      </div>
                    </Stack>
                  </HorizontalStack>
                </Stack>
              </>
            ),
            width: "200px",
          },
          {
            title: "Actions",
            dataIndex: ["shipment", "shipmentId"],
            key: "shipment.shipmentId",
            render: function (shipmentId) {
              return <ViewShipmentButton shipmentId={shipmentId} />;
            },
            width: "150px",
          },
        ])}
        dataSource={shipments}
        pagination={false}
      />
    </>
  );
}

export function StarredShipmentsScreen() {
  const createShipmentsApi = useShipmentsApi();
  const [quotedShipments, setQuotedShipments] = useState<
    CompanyShipment[] | undefined
  >(undefined);
  const [bookedShipments, setBookedShipments] = useState<
    CompanyShipmentQuote[] | undefined
  >(undefined);
  const [error, setError] = useState<unknown>();
  const starredShipments = useStarredShipments();
  const [, setLastAlterTime] = useState(new Date().valueOf());

  useOnce(async function () {
    try {
      const shipmentsApi = await createShipmentsApi();
      const response = await shipmentsApi.getShipmentsByIds({
        shipmentIds: starredShipments.map((o) => o.shipmentId),
      });
      setQuotedShipments(response.quotedShipments);
      setBookedShipments(response.bookedShipments);
    } catch (e: unknown) {
      setError(e);
    }
  });

  if (error !== undefined) {
    return <>❌ An error occured : {JSON.stringify(error, null, "\t")}</>;
  } else if (quotedShipments === undefined || bookedShipments === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        <Page
          title="⭐️ Starred Shipments"
          tags={[]}
          extra={[
            <HorizontalStack key="filter" align="right" width="100%">
              <Button
                disabled={starredShipments.length === 0}
                onClick={function () {
                  unstarAllShipments();
                  setLastAlterTime(new Date().valueOf());
                }}
              >
                ❌ Unstar All
              </Button>
            </HorizontalStack>,
          ]}
        >
          <PageTitle>⭐️ Starred Shipments</PageTitle>
          <BrowserView>
            <Spacer height={32} />
          </BrowserView>

          <QuotedShipmentTable
            title="Quoted Shipments"
            shipments={quotedShipments}
          />
          <ShipmentTable title="Booked Shipments" shipments={bookedShipments} />
        </Page>
      </>
    );
  }
}
