import { ReactNode } from "react";
import { useRefreshFunction } from "../Hooks/useRefreshFunction";
import { RefreshContext } from "./RefreshContext";

export function RefreshContextProvider({
  children,
  onRefresh = async () => {},
}: {
  children: ReactNode;
  onRefresh?: () => Promise<any>;
}) {
  const { isRefreshing, setRefresh, refresh } = useRefreshFunction(onRefresh);
  return (
    <RefreshContext.Provider value={{ refresh, isRefreshing, setRefresh }}>
      {children}
    </RefreshContext.Provider>
  );
}
