import { Button, Checkbox, Popover } from "antd";

import { ReactNode } from "react";
import { DataTableColumn } from "./DataTable";
import { CheckboxChangeEvent } from "../Types/AntHelperTypes";
import Stack from "./Stack";

interface ShowHideColumnsPopoverProps<T> {
  children: ReactNode;
  columns: DataTableColumn<T>[];
  columnVisibility: boolean[];
  onChange: (index: number, show: boolean) => void;
}

export function ShowHideColumnsPopover<T>({
  children,
  columns,
  columnVisibility,
  onChange,
}: ShowHideColumnsPopoverProps<T>) {
  return (
    <Popover
      title="Show/Hide Columns"
      placement="right"
      trigger="click"
      content={
        <Stack align="left">
          {columns.map((c, i) => (
            <Checkbox
              key={c.key!}
              onChange={(e: CheckboxChangeEvent) =>
                onChange(i, e.target.checked)
              }
              checked={columnVisibility[i]}
              style={{ marginBottom: "8px" }}
            >
              {c.title} {c.subtitle && <>({c.subtitle})</>}
            </Checkbox>
          ))}
        </Stack>
      }
    >
      <Button
        type="link"
        style={{ margin: 0, padding: 0, marginTop: "10px", fontSize: "12px" }}
        size="small"
      >
        {children}
      </Button>
    </Popover>
  );
}
