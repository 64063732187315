import React from "react";
import { Helmet } from "react-helmet";

interface PageTitleProps {
  children: string;
}

const PageTitle: React.FC<PageTitleProps> = (props: PageTitleProps) => {
  const title = `${props.children}`;
  return <Helmet title={title}></Helmet>;
};
export default PageTitle;
