import {
  CompaniesReport,
  CompaniesReportInvoiceIssueRow,
} from "@freightsimple/generated-apollo-openapi-client";
import { Typography } from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../Apis/Apis";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import PageTitle from "../Components/PageTitle";
import { Panel } from "../Components/Panel";
import Stack from "../Components/Stack";
import { ViewCompanyButton } from "../Components/ViewCompanyButton";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";
import { PaymentTermsTag } from "./ViewShipmentScreenComponents/PaymentTermsTag";
import { PageHeader } from "../Components/PageHeader";

const { Title } = Typography;

interface CompaniesWithInvoiceIssuesTableProps {
  companies: Array<CompaniesReportInvoiceIssueRow>;
}

function CompaniesWithInvoiceIssuesTable(
  props: CompaniesWithInvoiceIssuesTableProps,
) {
  const columns: DataTableColumn<CompaniesReportInvoiceIssueRow>[] = [
    {
      title: "Company Name",
      render: (o) => (
        <Stack align="left">
          <div>{o.company.companyName!}</div>
          <Spacer height={4} />
          <HorizontalStack spacing={8} style={{ marginLeft: "-8px" }}>
            <PaymentTermsTag paymentTermsDays={o.company.paymentTermsDays!} />
          </HorizontalStack>
        </Stack>
      ),
    },
    {
      title: "Warning",
      render: (o) => <div>{o.companyInvoiceWarningState}</div>,
    },
    {
      title: "Actions",
      render: function (o) {
        return (
          <HorizontalStack>
            <ViewCompanyButton companyId={o.company.companyId!} />
          </HorizontalStack>
        );
      },
    },
  ];

  return (
    <DataTable pagination={false} columns={columns} data={props.companies} />
  );
}

export function CompaniesReportScreen() {
  const createCompaniesApi = useCompaniesApi();
  const [companiesReport, setCompaniesReport] = useState<
    undefined | CompaniesReport
  >();
  useOnce(async function () {
    const companiesApi = await createCompaniesApi();
    const response = await companiesApi.companiesReport();
    setCompaniesReport(response);
  });

  if (companiesReport === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        <PageHeader title="Companies Report">
          <PageTitle>Companies Report</PageTitle>
          <Panel>
            <Title level={5}>Invoice Issues</Title>
            <CompaniesWithInvoiceIssuesTable
              companies={companiesReport.companiesWithInvoiceIssues}
            />
          </Panel>
        </PageHeader>
      </>
    );
  }
}
