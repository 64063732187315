import { Select } from "antd";
import Colors from "../../Components/Colors";
import Stack from "../../Components/Stack";
import {
  describeLocationType,
  getLocationTypes,
  nameLocationType,
} from "../../Helpers/locationTypes";
import { SelectProps } from "antd/lib";

interface LocationTypeDropdownProps {
  value: string | undefined;
  setValue: (_: string) => void;
}

export function LocationTypeDropdown(props: LocationTypeDropdownProps) {
  const filterOption = (input: string, option: any) => {
    const type = option?.value;
    if (!type) return false;

    const name = nameLocationType(type).toLowerCase();
    const description = describeLocationType(type).toLowerCase();
    const searchTerm = input.toLowerCase();

    return name.includes(searchTerm) || description.includes(searchTerm);
  };

  const options: SelectProps["options"] = getLocationTypes().map((type) => ({
    label: nameLocationType(type),
    value: type,
    type: type,
  }));

  return (
    <Select
      value={props.value}
      style={{ width: 450 }}
      onChange={function (e) {
        props.setValue(e);
      }}
      showSearch
      filterOption={filterOption}
      options={options}
      optionRender={(o) => (
        <Stack align="left">
          <div>{nameLocationType(o.data.type)}</div>
          <div style={{ color: Colors.LightText, fontSize: "12px" }}>
            {describeLocationType(o.data.type)}
          </div>
        </Stack>
      )}
    />
  );
}
