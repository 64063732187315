import {
  CarrierInvoice,
  CarrierInvoiceState,
  EmailDescription,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";

import { InputNumber, message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import { SupportAgent } from "../ViewCompanyScreen";
import { ExtraTextButton } from "./ExtraTextButton";
import {
  SupportEmailBodyExtraItem,
  SupportEmailButton,
} from "./SupportEmailButton";

interface WeightReportSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
  carrierInvoice: CarrierInvoice;
}

export function WeightReportSupportEmailButton(
  props: WeightReportSupportEmailButtonProps,
) {
  const shipmentId = props.data.shipmentId;
  const carrierInvoice = props.carrierInvoice;
  const carrierInvoiceId = carrierInvoice.carrierInvoiceId!;
  const createSupportEmailApi = useSupportEmailApi();
  const [reportedWeight, setReportedWeight] = useState(0);
  const [extraText, setExtraText] = useState("");

  if (carrierInvoice.invoiceState === CarrierInvoiceState.Voided) {
    return <></>;
  }

  const hash = JSON.stringify({
    carrierInvoiceId,
    reportedWeight,
    extraText,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent,
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewWeightReportEmail({
        shipmentId,
        carrierInvoiceId,
        reportedWeight,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        extraText,
      });
    } catch {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[],
  ): Promise<boolean> {
    if (reportedWeight === 0) {
      message.warning(`Reported weight is zero`);
      return false;
    }

    if (attachmentDocumentIds.length === 0) {
      message.warning(`No attachments. Please attach weight report`);
      return false;
    }

    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendWeightReportEmail({
        shipmentId,
        carrierInvoiceId,
        reportedWeight,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
        extraText,
      });
      return true;
    } catch {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="Weight Report Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
          <SupportEmailBodyExtraItem label="Reported Weight">
            <InputNumber
              value={reportedWeight}
              onChange={function (value: number | null) {
                setReportedWeight(value ?? 0);
              }}
            />
          </SupportEmailBodyExtraItem>
        </>
      }
      shipmentData={props.data}
    />
  );
}
