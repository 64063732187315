import * as React from "react";
import Spacer from "../Spacer";
import HorizontalStack, { AlignOptions } from "./HorizontalStack";
import Stack from "./Stack";
import { WarningComponent } from "./WarningComponent";
import { dashboardBaseUrl } from "../Helpers/dashboardBaseUrl";
import { isNotBlank } from "@freightsimple/shared";

interface CarrierLogoProps {
  carrierIdentifier: string | undefined;
  brokeredCarrierIdentifier: string | undefined;
  width: number;
  height: number;
  align?: AlignOptions;
  notClickable?: boolean;
}

const CarrierLogo: React.FC<CarrierLogoProps> = (props: CarrierLogoProps) => {
  const carrierIdentifier = props.carrierIdentifier;
  const brokeredCarrierIdentifier = props.brokeredCarrierIdentifier;

  if (props.carrierIdentifier === undefined) {
    return (
      <div>
        <WarningComponent /> Missing Carrier!
      </div>
    );
  }

  const style: React.CSSProperties = {};
  if (props.notClickable) {
    style.pointerEvents = "none";
  }

  if (isNotBlank(brokeredCarrierIdentifier)) {
    return (
      <HorizontalStack
        align={props.align || "center"}
        style={{ width: props.width, height: props.height }}
        verticalAlign="middle"
      >
        <a
          style={style}
          href={`/carrier?carrierIdentifier=${props.carrierIdentifier}`}
        >
          <Stack align="center">
            <img
              style={{
                maxHeight: "35px",
                maxWidth: "25px",
              }}
              alt={carrierIdentifier}
              src={`${dashboardBaseUrl()}/carrierLogos/${carrierIdentifier}.png`}
            />
            <Spacer height={3} />
            <img
              style={{
                maxHeight: props.height + "px",
                maxWidth: props.width + "px",
              }}
              alt={brokeredCarrierIdentifier}
              src={`${dashboardBaseUrl()}/carrierLogos/${brokeredCarrierIdentifier}.png`}
            />
          </Stack>
        </a>
      </HorizontalStack>
    );
  } else {
    return (
      <HorizontalStack
        align={props.align || "center"}
        style={{ width: props.width, height: props.height }}
        verticalAlign="middle"
      >
        <a
          style={style}
          href={`/carrier?carrierIdentifier=${props.carrierIdentifier}`}
        >
          <img
            style={{
              maxHeight: props.height + "px",
              maxWidth: props.width + "px",
            }}
            alt={carrierIdentifier}
            src={`${dashboardBaseUrl()}/carrierLogos/${carrierIdentifier}.png`}
          />
        </a>
      </HorizontalStack>
    );
  }
};

export default CarrierLogo;
