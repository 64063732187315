import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Typography,
} from "antd";
import { useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { CurrencyInput } from "../../Components/CurrencyInput";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { formatAsCurrency } from "../../Helpers/numberFormatting";
import Spacer from "../../Spacer";
import { TabProps } from "./TabProps";

const { Text } = Typography;

export function AddAdditionalChargeButton(props: TabProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  const [reason, setReason] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);

  const [saving, setSaving] = useState(false);

  const [processPaymentImmediately, setProcessPaymentImmediately] =
    useState(true);
  const [markDisputed, setMarkDisputed] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (reason === "") {
      return;
    }

    if (amount < 10.0) {
      return;
    }

    try {
      setSaving(true);
      const shipmentId = props.shipmentData.shipmentId;
      const customerInvoiceApi = await createCustomerInvoiceApi();

      await customerInvoiceApi.createInvoice({
        shipmentId,
        reason,
        amount,
        processPaymentImmediately,
        markDisputed,
      });
      await props.onRefresh();
      message.success("Approved");
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const okText = processPaymentImmediately
    ? `Create and Bill Additional Charge (${formatAsCurrency(amount)})`
    : `Create Additional Charge Invoice (${formatAsCurrency(
        amount,
      )}) - will not process payment`;

  const isOnCredit = props.shipmentData.shipment.company.paymentTermsDays! > 0;

  return (
    <>
      <Modal
        title="Create Additional Charge Invoice"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={okText}
        width={1200}
        confirmLoading={saving}
        destroyOnClose
      >
        <Stack align="left">
          <Text>
            This will create an additional charge, create an invoice for it, and
            optionally (if the company is not on credit) immediately charge
            their payment method
          </Text>
          {isOnCredit && (
            <>
              <Spacer height={8} />

              <Alert
                type="error"
                description={`Note: This company is on account. Payment will not be processed automatically, even if selected.`}
                style={{ width: "100%" }}
              />
            </>
          )}
          <Spacer height={32} />
          <Form
            style={{ width: "1000px" }}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            <Form.Item label="Additional Charge Reason">
              <Input
                value={reason}
                onChange={function (e) {
                  setReason(e.target.value);
                }}
                width={400}
              />
            </Form.Item>
            <Form.Item label="Total Amount (Including Tax)">
              <CurrencyInput
                amount={amount}
                setAmount={setAmount}
                currency={props.shipmentData.shipment.company.currency!}
              />
            </Form.Item>
            <Form.Item label="Process Payment Immediately?">
              <Radio.Group
                value={processPaymentImmediately}
                onChange={function (e) {
                  setProcessPaymentImmediately(e.target.value);
                }}
              >
                <Radio.Button value={false}>❌ No</Radio.Button>
                <Radio.Button value={true}>💵 Yes</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Mark Disputed?">
              <HorizontalStack>
                <Radio.Group
                  disabled={processPaymentImmediately}
                  value={markDisputed}
                  onChange={function (e) {
                    setMarkDisputed(e.target.value);
                  }}
                >
                  <Radio.Button value={false}>No</Radio.Button>
                  <Radio.Button value={true}>Yes</Radio.Button>
                </Radio.Group>
                <Spacer width={16} />
                <div>
                  This will prevent the invoice being auto-charged when it is
                  due
                </div>
              </HorizontalStack>
            </Form.Item>
          </Form>
        </Stack>
      </Modal>
      <Button onClick={showModal}>Create Additional Charge Invoice</Button>
    </>
  );
}
