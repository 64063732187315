import { Select } from "antd";
import { emojiForShipmentVolume } from "../../Helpers/emojiForShipmentVolume";

interface ShipmentFrequencyDropdownProps {
  value: string | undefined;
  onChange: (_: string | undefined) => void;
  allowClear?: boolean;
  hideOccasional?: boolean;
  hideSingleShipment?: boolean;
}

export function ShipmentFrequencyDropdown(
  props: ShipmentFrequencyDropdownProps,
) {
  return (
    <Select
      style={{ width: "200px" }}
      value={props.value}
      onChange={props.onChange}
      allowClear={props.allowClear}
    >
      <Select.Option key="Daily">
        {emojiForShipmentVolume("Daily")} Daily
      </Select.Option>
      <Select.Option key="Weekly">
        {emojiForShipmentVolume("Weekly")} Weekly
      </Select.Option>
      {!props.hideOccasional && (
        <Select.Option key="Occasional">
          {emojiForShipmentVolume("Occasional")} Occasional
        </Select.Option>
      )}
      {!props.hideSingleShipment && (
        <Select.Option key="SingleShipment">
          {emojiForShipmentVolume("SingleShipment")} Single Shipment
        </Select.Option>
      )}
      <Select.Option key={undefined}>Not Selected</Select.Option>
    </Select>
  );
}
