import dayjs from "dayjs";

export interface PeriodInfo {
  displayName: string;
  title: string;
  firstDate: string; // First date of the period in YYYY-MM-DD
}

export enum PeriodFormats {
  Monthly = "MMMM YYYY",
  Quarterly = "YYYY [Q]Q",
  Yearly = "YYYY",
}

export function generateMonthInfo(): PeriodInfo[] {
  const months: PeriodInfo[] = [];
  const month = dayjs("2022-01-01");
  for (let i = 0; month.isBefore(dayjs().startOf("month")); i++) {
    month.add(1, "month");
    const monthShortTitle = month.format("MMM YYYY");
    const monthTitle = month.format(PeriodFormats.Monthly);
    const firstDate = month.format("YYYY-MM-DD");
    months.push({ displayName: monthShortTitle, title: monthTitle, firstDate });
  }
  return months;
}

export function generateQuarterInfo(): PeriodInfo[] {
  const periods: PeriodInfo[] = [];
  const period = dayjs("2022-01-01");
  for (let i = 0; period.isBefore(dayjs().startOf("quarter")); i++) {
    period.add(1, "quarter");
    const title = period.format(PeriodFormats.Quarterly);
    const firstDate = period.format("YYYY-MM-DD");
    periods.push({ displayName: title, title: title, firstDate });
  }
  return periods;
}

export function generateYearInfo(): PeriodInfo[] {
  const periods: PeriodInfo[] = [];
  const period = dayjs("2020-01-01");
  for (let i = 0; period.isBefore(dayjs().startOf("year")); i++) {
    period.add(1, "year");
    const title = period.format(PeriodFormats.Yearly);
    const firstDate = period.format("YYYY-MM-DD");
    periods.push({ displayName: title, title: title, firstDate });
  }
  return periods;
}
