import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

interface DateRangePickerProps {
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  onChange: (startDate: string, endDate: string) => void;
  onClear: () => void;
  allowClear?: boolean;
}

function generateRanges() {
  const currentYear = dayjs().year();
  const ranges: Record<string, [dayjs.Dayjs, dayjs.Dayjs]> = {
    Today: [dayjs(), dayjs()],
    Yesterday: [dayjs().subtract(1, "days"), dayjs().subtract(1, "days")],
    "Last 7 days": [dayjs().subtract(7, "days"), dayjs()],
    "Last 30 days": [dayjs().subtract(30, "days"), dayjs()],
    "All Time": [dayjs("2021-01-01"), dayjs("2031-12-31")],
  };

  // Add years from 2021 to current year
  for (let year = 2021; year <= currentYear; year++) {
    ranges[year.toString()] = [dayjs(`${year}-01-01`), dayjs(`${year}-12-31`)];
  }

  // Add last 12 months
  for (let i = 0; i < 12; i++) {
    const startDate = dayjs().subtract(i, "months").startOf("month");
    const monthName = startDate.format("MMMM YYYY");
    ranges[monthName] = [startDate, startDate.clone().endOf("month")];
  }

  // Add quarters for last 3 years
  for (let year = currentYear; year > currentYear - 3; year--) {
    for (let quarter = 1; quarter <= 4; quarter++) {
      const startMonth = (quarter - 1) * 3;
      const startDate = dayjs().year(year).month(startMonth).startOf("month");
      const endDate = startDate.clone().add(2, "months").endOf("month");
      ranges[`${year} - Q${quarter}`] = [startDate, endDate];
    }
  }

  return ranges;
}

export function DateRangePicker(props: DateRangePickerProps) {
  return (
    <RangePicker
      value={[
        props.startDate ? dayjs(props.startDate) : null,
        props.endDate ? dayjs(props.endDate) : null,
      ]}
      allowClear={props.allowClear === undefined ? false : props.allowClear}
      ranges={generateRanges()}
      onChange={function (e) {
        if (e === null || e[0] === null || e[1] === null) {
          props.onClear();
        } else {
          const startDate = e[0].format("YYYY-MM-DD");
          const endDate = e[1].format("YYYY-MM-DD");
          props.onChange(startDate, endDate);
        }
      }}
    />
  );
}
