import { Select } from "antd";
import Stack from "../../Components/Stack";

interface OtherRecipientsDropdownProps {
  otherEmails: string[];
  setOtherEmails: (_: string[]) => void;
}

const otherEmails = [
  {
    name: "Chris",
    email: "chris@freightsimple.com",
  },

  {
    name: "Natasha",
    email: "natasha@freightsimple.com",
  },

  {
    name: "Support",
    email: "support@freightsimple.com",
  },
];

export function OtherRecipientsDropdown(props: OtherRecipientsDropdownProps) {
  return (
    <Select
      value={props.otherEmails}
      onChange={function (_otherEmails) {
        props.setOtherEmails(_otherEmails);
      }}
      mode="multiple"
      allowClear
      style={{ width: "300px" }}
    >
      {otherEmails.map(function (c) {
        return (
          <Select.Option key={c.email} value={c.email}>
            <Stack align="left">
              <div>{c.name}</div>
            </Stack>
          </Select.Option>
        );
      })}
    </Select>
  );
}
