import { Configuration, PopupRequest } from "@azure/msal-browser";
import { getAzureClientId, redirectUrl } from "./environment";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: getAzureClientId(),
    authority:
      "https://login.microsoftonline.com/f5f70d62-2296-4bcd-a80e-24b44a7db656/",
    redirectUri: redirectUrl(),
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [`${getAzureClientId()}/.default`],
};
