import { Broker } from "@freightsimple/generated-apollo-openapi-client";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { CountryCodeDropdown } from "../../Components/CountryCodeDropdown";
import Spacer from "../../Spacer";
import { TabProps } from "./TabProps";

const { Title } = Typography;

function emptyBroker(): Broker {
  return {};
}

export function EditCustomsBrokerButton(props: TabProps) {
  const title = `Edit Customs Broker`;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [broker, setBroker] = useState(
    props.shipmentData.shipment.shipment.broker || emptyBroker(),
  );
  const shipmentId = props.shipmentData.shipmentId;
  const [saving, setSaving] = useState(false);
  const createShipmentsApi = useShipmentsApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (broker.address?.addressLine?.trim() === "") {
      message.warning("Address Line is missing");
      return;
    }

    if (broker.address?.city?.trim() === "") {
      message.warning("City is missing");
      return;
    }

    if (broker.address?.postalCode?.trim() === "") {
      message.warning("Postal Code is missing");
      return;
    }

    if (broker.address?.postalCode?.trim() === "") {
      message.warning("State/Province is missing");
      return;
    }

    if (broker.businessName?.trim() === "") {
      message.warning("Business Name is missing");
      return;
    }

    if (broker.contact?.contactName?.trim() === "") {
      message.warning("Contact Name is missing");
      return;
    }

    const phoneNumber = broker.contact?.phoneNumber;

    if (phoneNumber === undefined || phoneNumber.trim() === "") {
      message.warning("Phone number is missing");
      return;
    }

    if (phoneNumber.length < 10) {
      message.warning("Phone number is too short");
      return;
    }

    if (phoneNumber.length > 10) {
      message.warning("Phone number is too long");
      return;
    }

    if (broker.contact?.emailAddress?.trim() === "") {
      message.warning("Email Address is missing");
      return;
    }

    setSaving(true);
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.updateCustomsBroker({ shipmentId, broker });
      await props.onRefresh();
      message.success(`Broker saved. Documents regenerated`);
      setIsModalVisible(false);
    } catch {
      message.error(`Ooops Something went wrong`);
    }

    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (props.shipmentData.shipment.shipment.broker === undefined) {
    return <></>;
  }

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1450}
        confirmLoading={saving}
      >
        <Alert
          type="warning"
          description="Be very careful with this. Editing will not notify the carrier of
        any changes. It will generate the bill of lading. If you want to notify the carrier about changes, 'Attach' and use the dashboard view"
        />
        <Spacer height={32} />
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 32, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Row style={{ width: "100%" }}>
            <Col span={8}>
              <Title level={5}>Broker Name</Title>
              <Form.Item label="Business Name">
                <Input
                  value={broker.businessName!}
                  onChange={function (e) {
                    setBroker({
                      ...broker,
                      businessName: e.target.value,
                    });
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Title level={5}>Address</Title>
              <Form.Item label="Address Line 1">
                <Input
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  value={broker.address?.addressLine!}
                  onChange={function (e) {
                    setBroker({
                      ...broker,
                      address: {
                        ...broker.address,
                        addressLine: e.target.value,
                      },
                    });
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item label="Address Line 2">
                <Input
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  value={broker.address?.addressLine2!}
                  onChange={function (e) {
                    setBroker({
                      ...broker,
                      address: {
                        ...broker.address,
                        addressLine2: e.target.value,
                      },
                    });
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item label="City">
                <Input
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  value={broker.address?.city!}
                  onChange={function (e) {
                    setBroker({
                      ...broker,
                      address: {
                        ...broker.address,
                        city: e.target.value,
                      },
                    });
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item label="State/Province">
                <Input
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  value={broker.address?.stateOrProvinceCode!}
                  onChange={function (e) {
                    setBroker({
                      ...broker,
                      address: {
                        ...broker.address,
                        stateOrProvinceCode: e.target.value,
                      },
                    });
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item label="Postal Code">
                <Input
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  value={broker.address?.postalCode!}
                  onChange={function (e) {
                    setBroker({
                      ...broker,
                      address: {
                        ...broker.address,
                        postalCode: e.target.value,
                      },
                    });
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item label="Country">
                <CountryCodeDropdown
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  value={broker.address?.countryCode!}
                  setValue={function (newValue) {
                    setBroker({
                      ...broker,
                      address: {
                        ...broker.address,
                        countryCode: newValue,
                      },
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Spacer width={96} />
            <Col span={8}>
              <Title level={5}>Contact</Title>
              <Form.Item label="Contact Name">
                <Input
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  value={broker.contact?.contactName!}
                  onChange={function (e) {
                    setBroker({
                      ...broker,
                      contact: {
                        ...broker.contact,
                        contactName: e.target.value,
                      },
                    });
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item label="Phone">
                <Input
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  value={broker.contact?.phoneNumber!}
                  onChange={function (e) {
                    setBroker({
                      ...broker,
                      contact: {
                        ...broker.contact,
                        phoneNumber: e.target.value.replace(/\D/g, ""),
                      },
                    });
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item label="Extension">
                <Input
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  value={broker.contact?.phoneNumberExtension!}
                  onChange={function (e) {
                    setBroker({
                      ...broker,
                      contact: {
                        ...broker.contact,
                        phoneNumberExtension: e.target.value,
                      },
                    });
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  value={broker.contact?.emailAddress!}
                  onChange={function (e) {
                    setBroker({
                      ...broker,
                      contact: {
                        ...broker.contact,
                        emailAddress: e.target.value,
                      },
                    });
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
