import { MenuOutlined } from "@ant-design/icons";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Button, Menu, Tooltip } from "antd";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { ButtonRow } from "../Components/ButtonRow";
import Colors from "../Components/Colors";
import HorizontalStack from "../Components/HorizontalStack";
import { SimpleStats } from "../Components/SimpleStats";
import { isPhone } from "../Helpers/isPhone";
import { useStarredCompanies } from "../Helpers/starredCompanies";
import { useStarredShipments } from "../Helpers/starredShipments";
import Spacer from "../Spacer";
import { loginRequest } from "../authConfig";
import { SearchInput } from "./SearchInput";
import { Header, ItemType } from "../Types/AntHelperTypes";
import { getConfig, isAuthenticationDisabled } from "../environment";
import { getEnvironmentOverride } from "../Helpers/environment";
import { useCurrentUserEmail } from "../Hooks/useCurrentUserEmail";

function LoginButton() {
  const { instance } = useMsal();

  async function login() {
    await instance.loginRedirect(loginRequest);
  }

  return <Button onClick={() => login()}>Login</Button>;
}

function isHalloween(): boolean {
  // Create date in PST/PDT using the IANA timezone database name
  const pstDate = new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });

  // Convert the string back to a Date object
  const date = new Date(pstDate);

  // Get month (0-based, so 9 is October) and day
  const month = date.getMonth();
  const day = date.getDate();

  // Check if it's October 31st
  return month === 9 && day === 31;
}

function getMenuColor() {
  const config = getConfig();

  const environmentOverride = getEnvironmentOverride();
  if (environmentOverride) {
    return "navy";
  }

  if (config.basePath === "https://api.freightsimple.com") {
    if (isHalloween()) {
      return Colors.HalloweenOrange;
    } else {
      return "#4c62ea";
    }
  } else if (config.basePath === "https://api.freightsimpledemo.com") {
    return "black";
  } else if (config.basePath === "https://api.fspreprod.com") {
    return "#108922";
  } else {
    return "purple";
  }
}

function logoUrl() {
  if (isHalloween()) {
    return `/logo-halloween.png`;
  } else {
    return `/logo.png`;
  }
}

function LogoutButton() {
  const { instance } = useMsal();
  const email = useCurrentUserEmail();
  const tooltip = `Logged in as ${email}`;

  function logout() {
    instance.logout();
  }

  return (
    <Tooltip placement="left" title={tooltip}>
      <Menu.Item style={{ color: "white" }} onClick={() => logout()}>
        Logout
      </Menu.Item>
    </Tooltip>
  );
}

interface TopMenuProps {
  items: ItemType[];
  navigate: NavigateFunction;
}

function TopMenuPhone(props: TopMenuProps) {
  return (
    <Menu
      theme="dark"
      mode="inline"
      inlineCollapsed={true}
      items={[
        {
          label: (
            <>
              <MenuOutlined />
            </>
          ),
          key: "submenu",
          children: props.items,
        },
      ]}
      style={{
        width: "24px",
        marginLeft: "8px",
        backgroundColor: "transparent",
      }}
      onClick={function (o) {
        props.navigate(o.key);
      }}
    ></Menu>
  );
}

export function MenuBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = useCurrentUserEmail();
  const path = location.pathname;

  function itemForPath(label: string, url: string): ItemType {
    return {
      label,
      key: url,
      onClick: function () {
        navigate(url);
      },
    };
  }

  function submenu(label: string, items: ItemType[]) {
    return {
      label,
      key: label,
      children: items,
    };
  }

  function divider(): ItemType {
    return {
      type: "divider",
    };
  }

  function permittedToSeeFinancialInfo() {
    return (
      email === "natasha@freightsimple.com" ||
      email === "chris@freightsimple.com"
    );
  }

  function financialMenuItems() {
    const items: ItemType[] = [];

    items.push(itemForPath("Today's Bookings", "/todays-bookings"));
    // TODO. This is a hack. Backend should guard it at some point
    if (permittedToSeeFinancialInfo()) {
      items.push(itemForPath("Profit/Loss", "/profit-loss"));
      items.push(itemForPath("KPIs", "/kpis"));
    }

    items.push(
      itemForPath(
        "Companies Recent Sales Report",
        "/recent-company-sales-report",
      ),
    );
    items.push(itemForPath("Customer Invoices", "/customer-invoices"));
    items.push(itemForPath("Carrier Invoices", "/carrier-invoices"));
    items.push(itemForPath("Open Invoice Audits", "/open-invoice-audits"));
    return items;
  }

  const items: ItemType[] = [];
  items.push(
    submenu("Shipments", [
      itemForPath("Active Shipments", "/active-shipments"),
      divider(),
      itemForPath("All Shipments", "/all-shipments"),
      divider(),
      itemForPath("Claims", "/claims"),
      itemForPath("Recent Manual Quotes", "/recent-manual-quotes"),
    ]),
  );
  items.push(
    submenu("Companies", [
      itemForPath("List Companies", "/list-companies"),
      itemForPath("Sales Contacts", "/list-sales-contacts"),
      itemForPath("Sales Calls", "/list-sales-calls"),
      divider(),
      itemForPath("Companies Report", "/companies-report"),
      itemForPath("Booked vs Quoted Report", "/booked-vs-quoted"),
      divider(),
      itemForPath("Sales Call Graphs", "/sales-stats"),
    ]),
  );
  items.push(
    submenu("Carriers", [
      itemForPath("Carriers", "/carriers"),
      itemForPath("List All Services", "/carriers/services"),
    ]),
  );
  items.push(submenu("Financial", financialMenuItems()));

  items.push(
    submenu("Technical", [
      itemForPath(
        "Upcoming Shipment Tasks",
        "/amy/upcoming-amy-shipment-tasks",
      ),
      divider(),
      itemForPath(
        "Mismatched Customer Invoices",
        "/mismatched-customer-invoices",
      ),
      itemForPath(
        "Mismatched Carrier Invoices",
        "/mismatched-carrier-invoices",
      ),
      divider(),
      itemForPath("Signals", "/signals"),
      divider(),
      itemForPath("Testing", "/testing"),
      divider(),
      itemForPath("Admin Logs", "/admin-logs"),
      divider(),
      itemForPath("Quote Logs by Carrier", "/quote-logs-by-carrier"),
      divider(),
      itemForPath("Email Templates", "/email-templates"),
      divider(),
      itemForPath("Claude Prompts", "/claude-prompts"),
    ]),
  );

  items.push(itemForPath("Help Center", "https://gemini.freightsimple.com"));

  const starredShipments = useStarredShipments();
  if (starredShipments.length > 0) {
    items.push(
      itemForPath(
        `⭐️ Shipments (${starredShipments.length})`,
        "/starred-shipments",
      ),
    );
  }

  const starredCompanies = useStarredCompanies();
  if (starredCompanies.length > 0) {
    items.push(
      itemForPath(
        `⭐️ Companies (${starredCompanies.length})`,
        "/starred-companies",
      ),
    );
  }

  if (path.startsWith("/help-center")) {
    return <></>;
  }

  if (isPhone) {
    return (
      <Header
        style={{
          backgroundColor: getMenuColor(),
          paddingRight: "8px",
          paddingLeft: "8px",
          paddingTop: "0",
          height: "44px",
        }}
      >
        <HorizontalStack align="left">
          <img src={logoUrl()} alt="Logo" width="24" height="24" />

          {isAuthenticationDisabled() && (
            <>
              <TopMenuPhone items={items} navigate={navigate} />
            </>
          )}
          {!isAuthenticationDisabled() && (
            <>
              <AuthenticatedTemplate>
                <TopMenuPhone items={items} navigate={navigate} />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <LoginButton />
              </UnauthenticatedTemplate>
            </>
          )}
        </HorizontalStack>
      </Header>
    );
  }

  return (
    <Header
      style={{
        backgroundColor: getMenuColor(),
        paddingRight: "24px",
        paddingLeft: "16px",
      }}
    >
      <HorizontalStack width="100%" align="spread">
        <HorizontalStack align="left" verticalAlign="middle">
          <img src={logoUrl()} alt="Logo" width="36" height="36" />

          <Spacer width={16} />
          <div style={{ minWidth: 700 }}>
            {isAuthenticationDisabled() && (
              <Menu
                theme="dark"
                mode="horizontal"
                selectedKeys={[path]}
                style={{ backgroundColor: "transparent" }}
                items={items}
                onClick={function (o) {
                  navigate(o.key);
                }}
              ></Menu>
            )}
            {!isAuthenticationDisabled() && (
              <AuthenticatedTemplate>
                <Menu
                  theme="dark"
                  mode="horizontal"
                  selectedKeys={[path]}
                  style={{ backgroundColor: "transparent", minWidth: 900 }}
                  items={items}
                  onClick={function (o) {
                    navigate(o.key);
                  }}
                ></Menu>
              </AuthenticatedTemplate>
            )}
          </div>
        </HorizontalStack>
        <>
          {isAuthenticationDisabled() && (
            <HorizontalStack>
              <SimpleStats />
              <SearchInput />
              <Spacer width={8} />
              <ButtonRow
                extrasMenu={
                  <Menu>
                    <LogoutButton />
                  </Menu>
                }
              >
                <></>
              </ButtonRow>
            </HorizontalStack>
          )}
          {!isAuthenticationDisabled() && (
            <>
              <AuthenticatedTemplate>
                <HorizontalStack>
                  <SimpleStats />
                  <SearchInput />
                  <Spacer width={8} />
                  <ButtonRow
                    extrasMenu={
                      <Menu>
                        <LogoutButton />
                      </Menu>
                    }
                  >
                    <></>
                  </ButtonRow>
                </HorizontalStack>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <LoginButton />
              </UnauthenticatedTemplate>
            </>
          )}
        </>
      </HorizontalStack>
    </Header>
  );
}
