export enum ProfitLossGroupType {
  Company = "Company",
  Carrier = "Carrier",
  LeadSource = "LeadSource",
  Year = "Year",
  Quarter = "Quarter",
  Month = "Month",
  Week = "Week",
  DayOfWeek = "DayOfWeek",
}
