export enum InvoiceAuditContext {
  Customer = "Customer",
  Carrier = "Carrier",
}

export enum OpenInvoiceAuditsGroupType {
  Company = "Company",
  Carrier = "Carrier",
  NextStep = "NextStep",
}

export enum OpenInvoiceAuditsSortType {
  AuditOpenDate = "AuditOpenDate",
}

export enum OpenInvoiceAuditsFilterType {
  /* Important */
  RequiresAction = "RequiresAction",

  /* Specific Actions */
  RequiresActionNewAudit = "RequiresActionNewAudit",
  RequiresActionNewInvoice = "RequiresActionNewInvoice",
  RequiresActionReadCarrierReply = "RequiresActionReadCarrierReply",
  RequiresActionReadCustomerReply = "RequiresActionReadCustomerReply",
  RequiresActionPingCarrier = "RequiresActionPingCarrier",
  RequiresActionEstablishFault = "RequiresActionEstablishFault",
  RequiresActionSendChallengeToCarrier = "RequiresActionSendChallengeToCarrier",
  RequiresActionPingCustomer = "RequiresActionPingCustomer",
  RequiresActionOther = "RequiresActionOther",
  RequiresActionUnknownNextStep = "RequiresActionUnknownNextStep",
  RequiresActionCloseAudit = "RequiresActionCloseAudit",

  /* Other */
  UnresolvedProblems = "UnresolvedProblems",
  AllProblemsResolved = "AllProblemsResolved",
  OurTurn = "OurTurn",
  NobodysTurn = "NobodysTurn",
  NewInvoices = "NewInvoices",

  NotRequiresAction = "NotRequiresAction",

  Snoozed = "Snoozed",
  HighValue = "HighValue",
}
