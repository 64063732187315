import {
  MismatchedInvoices,
  XeroCreditNote,
  XeroInvoice,
} from "@freightsimple/generated-apollo-openapi-client";
import { Alert, Button, Tabs } from "antd";
import { useState } from "react";
import { useCarrierInvoiceApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { StatisticText } from "../Components/StatisticText";
import { StatsRow } from "../Components/StatsRow";
import { formatAsCurrency } from "../Helpers/numberFormatting";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";
import {
  CarrierCreditNotesTable,
  CarrierInvoicesTable,
} from "./CarrierInvoicesScreen";
import { ShipmentsTable } from "./MismatchedCustomerInvoicesScreen";
import {
  ViewCarrierCreditNoteInXeroButton,
  ViewCarrierInvoiceInXeroButton,
} from "./ViewShipmentScreenComponents/ViewCarrierInvoiceInXeroMenuItem";

const { TabPane } = Tabs;

interface XeroInvoicesTableProps {
  xeroInvoices: XeroInvoice[];
}

interface InvoicesAlertProps {
  numProblems: number;
}

function InvoicesAlert(props: InvoicesAlertProps) {
  if (props.numProblems === 0) {
    return (
      <Alert
        type="success"
        description={`Xero and our system match!`}
        style={{ width: "100%" }}
      />
    );
  }

  return (
    <Alert
      type="error"
      description={`There are problems`}
      style={{ width: "100%" }}
    />
  );
}

export function XeroInvoicesTable(props: XeroInvoicesTableProps) {
  const columns: DataTableColumn<XeroInvoice>[] = [
    {
      title: "Invoice Number",
      render: (o) => <div>{o.invoiceNumber}</div>,
    },
    {
      title: "Amount Due",
      render: (o) => <>{formatAsCurrency(o.amountDue, o.currencyCode)}</>,
    },
    {
      title: "Amount Credited",
      render: (o) => <>{formatAsCurrency(o.amountCredited, o.currencyCode)}</>,
    },
    {
      title: "Amount Paid",
      render: (o) => <>{formatAsCurrency(o.amountPaid, o.currencyCode)}</>,
    },
    {
      title: "Contact",
      render: (o) => <>{o.contactName}</>,
    },
    {
      title: "Date",
      render: (o) => <>{o.date}</>,
    },
    {
      title: "Due Date",
      render: (o) => <>{o.dueDate}</>,
    },
    {
      title: "Type",
      render: (o) => <>{o.type}</>,
    },
    {
      title: "Status",
      render: (o) => <>{o.status}</>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <Button href={o.url} target="_blank">
            Stored Link
          </Button>
          <ViewCarrierInvoiceInXeroButton xeroInvoiceId={o.invoiceId} />
        </ButtonRow>
      ),
    },
  ];

  return (
    <DataTable pagination={false} columns={columns} data={props.xeroInvoices} />
  );
}

interface XeroCreditNotesTableProps {
  xeroCreditNotes: XeroCreditNote[];
}

export function XeroCreditNotesTable(props: XeroCreditNotesTableProps) {
  const columns: DataTableColumn<XeroCreditNote>[] = [
    {
      title: "Credit Note Number",
      render: (o) => <div>{o.creditNoteNumber}</div>,
    },
    {
      title: "Remaining Credit",
      render: (o) => <>{formatAsCurrency(o.remainingCredit, o.currencyCode)}</>,
    },
    {
      title: "Amount Applied",
      render: (o) => <>{formatAsCurrency(o.appliedAmount, o.currencyCode)}</>,
    },
    {
      title: "Contact",
      render: (o) => <>{o.contactName}</>,
    },
    {
      title: "Date",
      render: (o) => <>{o.date}</>,
    },
    {
      title: "Due Date",
      render: (o) => <>{o.dueDate}</>,
    },
    {
      title: "Type",
      render: (o) => <>{o.type}</>,
    },
    {
      title: "Status",
      render: (o) => <>{o.status}</>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewCarrierCreditNoteInXeroButton
            xeroCreditNoteId={o.creditNoteID}
          />
        </ButtonRow>
      ),
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.xeroCreditNotes}
    />
  );
}

export function MismatchedCarrierInvoicesScreen() {
  const [mismatchedInvoices, setMismatchedInvoices] = useState<
    MismatchedInvoices | undefined
  >();
  const createCarrierInvoiceApi = useCarrierInvoiceApi();

  async function refresh() {
    const carrierInvoiceApi = await createCarrierInvoiceApi();
    const response = await carrierInvoiceApi.getMismatchedCarrierInvoices();
    setMismatchedInvoices(response);
  }

  useOnce(refresh);

  if (mismatchedInvoices === undefined) {
    return <Loading />;
  }

  const xeroProvisionalInvoices =
    mismatchedInvoices.outstandingXeroInvoices.filter(function (xi) {
      return xi.invoiceNumber.startsWith("Provisional : ");
    });

  const xeroNonProvisionalInvoices =
    mismatchedInvoices.outstandingXeroInvoices.filter(function (xi) {
      return !xi.invoiceNumber.startsWith("Provisional : ");
    });
  const numberProvisionalInvoicesInXero = xeroProvisionalInvoices.length;

  const numberShipmentsMissingInvoices =
    mismatchedInvoices.shipmentsWithoutInvoices.length;

  const numProblems =
    mismatchedInvoices.xeroNonProvisionalInvoicesNotInDb.length +
    mismatchedInvoices.xeroCreditNotesNotInDb.length +
    mismatchedInvoices.dbInvoicesNotInXero.length +
    mismatchedInvoices.dbCreditNotesNotInXero.length;

  return (
    <>
      <Page
        title={`Mismatched Carrier Invoices`}
        tags={[]}
        stats={[]}
        extra={[]}
      >
        <PageTitle>{`Mismatched Carrier Invoices`}</PageTitle>
        <Stack align="left">
          <StatsRow>
            <StatisticText
              title="# Provisional Invoices in Xero"
              value={numberProvisionalInvoicesInXero}
            ></StatisticText>
            <StatisticText
              title="# Shipments Missing Invoices"
              value={numberShipmentsMissingInvoices}
            ></StatisticText>
            <StatisticText
              title="# Outstanding Invoices in Xero"
              value={xeroNonProvisionalInvoices.length}
            ></StatisticText>
            <StatisticText
              title="# Outstanding Invoices in DB"
              value={mismatchedInvoices.outstandingDbInvoices.length}
            ></StatisticText>
          </StatsRow>
          <Spacer height={16} />
          <InvoicesAlert numProblems={numProblems} />
          <Spacer height={16} />
          <Tabs style={{ width: "100%" }}>
            {numProblems > 0 && (
              <TabPane tab={`🚨 Problems`} key="problems">
                <Tabs style={{ width: "100%" }}>
                  {mismatchedInvoices.xeroNonProvisionalInvoicesNotInDb.length >
                    0 && (
                    <TabPane
                      tab={`Xero Non-Provisional Invoices not in DB  (${mismatchedInvoices.xeroNonProvisionalInvoicesNotInDb.length})`}
                      key="xeroNonProvisionalInvoicesNotInDb"
                    >
                      <XeroInvoicesTable
                        xeroInvoices={
                          mismatchedInvoices.xeroNonProvisionalInvoicesNotInDb
                        }
                      />
                    </TabPane>
                  )}
                  {mismatchedInvoices.xeroCreditNotesNotInDb.length > 0 && (
                    <TabPane
                      tab={`Xero Credit Notes not in DB  (${mismatchedInvoices.xeroCreditNotesNotInDb.length})`}
                      key="xeroCreditNotesNotInDb"
                    >
                      <XeroCreditNotesTable
                        xeroCreditNotes={
                          mismatchedInvoices.xeroCreditNotesNotInDb
                        }
                      />
                    </TabPane>
                  )}
                  {mismatchedInvoices.dbInvoicesNotInXero.length > 0 && (
                    <TabPane
                      tab={`DB Invoices not in Xero (${mismatchedInvoices.dbInvoicesNotInXero.length})`}
                      key="dbInvoicesNotInXero"
                    >
                      <CarrierInvoicesTable
                        carrierInvoices={mismatchedInvoices.dbInvoicesNotInXero}
                      />
                    </TabPane>
                  )}
                  {mismatchedInvoices.dbCreditNotesNotInXero.length > 0 && (
                    <TabPane
                      tab={`DB Credit Notes not in Xero (${mismatchedInvoices.dbCreditNotesNotInXero.length})`}
                      key="dbCreditNotesNotInXero"
                    >
                      <CarrierCreditNotesTable
                        carrierCreditNotes={
                          mismatchedInvoices.dbCreditNotesNotInXero
                        }
                      />
                    </TabPane>
                  )}
                </Tabs>
              </TabPane>
            )}
            <TabPane tab={`General Info`} key="generalinfo">
              <Tabs style={{ width: "100%" }}>
                <TabPane
                  tab={`Shipments missing invoices  (${mismatchedInvoices.shipmentsWithoutInvoices.length})`}
                  key="shipmentsWithoutInvoices"
                >
                  <ShipmentsTable
                    shipments={mismatchedInvoices.shipmentsWithoutInvoices}
                  />
                </TabPane>
                <TabPane
                  tab={`Xero Provisional Invoices  (${xeroProvisionalInvoices.length})`}
                  key="xeroProvisionalInvoices"
                >
                  <XeroInvoicesTable xeroInvoices={xeroProvisionalInvoices} />
                </TabPane>
                <TabPane
                  tab={`Outstanding Xero Invoices  (${xeroNonProvisionalInvoices.length})`}
                  key="xeroNonProvisionalInvoices"
                >
                  <XeroInvoicesTable
                    xeroInvoices={xeroNonProvisionalInvoices}
                  />
                </TabPane>
                <TabPane
                  tab={`Outstanding DB Invoices (${mismatchedInvoices.outstandingDbInvoices.length})`}
                  key="outstandingDbInvoices"
                >
                  <CarrierInvoicesTable
                    carrierInvoices={mismatchedInvoices.outstandingDbInvoices}
                  />
                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs>
        </Stack>
      </Page>
    </>
  );
}
