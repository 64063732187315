import { CurrencyCode } from "@freightsimple/generated-apollo-openapi-client";
export function formatAsCurrency(
  value: number | undefined,
  currency: CurrencyCode | string | undefined = undefined,
): string {
  if (value === undefined) {
    return "-";
  }
  const formatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const formatted = formatter.format(value);

  if (currency === undefined) {
    return formatted;
  } else {
    return `${currency}${formatted}`;
  }
}
