import {
  CarrierCreditNote,
  CarrierCreditNoteState,
} from "@freightsimple/generated-apollo-openapi-client";
import { message } from "antd";
import { useState } from "react";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { TabProps } from "./TabProps";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";

interface VoidCarrierCreditNoteMenuItemProps extends TabProps {
  carrierCreditNote: CarrierCreditNote;
}

export function VoidCarrierCreditNoteMenuItem(
  props: VoidCarrierCreditNoteMenuItemProps,
) {
  const carrierCreditNoteId = props.carrierCreditNote.carrierCreditNoteId!;
  const [sending, setSending] = useState(false);

  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  async function onConfirm() {
    setSending(true);
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.voidCarrierCreditNote({
        carrierCreditNoteId,
      });
      await props.onRefresh();
      message.success("Voided!");
    } catch (e: any) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setSending(false);
  }

  if (
    props.carrierCreditNote.carrierCreditNoteState !==
    CarrierCreditNoteState.Approved
  ) {
    return <></>;
  }

  return (
    <ConfirmMenuItem
      onConfirm={onConfirm}
      question="Are you sure you want to void this carrier credit note?"
      okText="Yes, void credit note"
      tooltip="If a credit note was made in mistake, or a claim was rejected, it can be voided"
      loading={sending}
    >
      Void Credit Note
    </ConfirmMenuItem>
  );
}
