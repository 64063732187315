import {
  CompanyShipmentQuote,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";

import { message, Typography } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { useCompaniesApi } from "../../Apis/Apis";
import CarrierLogo from "../../Components/CarrierLogo";
import Colors from "../../Components/Colors";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import { Loading } from "../../Components/Loading";
import Stack from "../../Components/Stack";
import { WarningComponent } from "../../Components/WarningComponent";
import { useOnce } from "../../Hooks/useOnce";
import Spacer from "../../Spacer";
import { InvoiceAuditProblemComponent } from "../OpenInvoiceAuditsScreen";
import dayjs from "dayjs";

const { Title } = Typography;

interface TinyViewShipmentButtonProps {
  shipmentId: string;
}

export function TinyViewShipmentButton(props: TinyViewShipmentButtonProps) {
  const url = `/view-shipment?shipmentId=${props.shipmentId}`;

  function onClick() {
    window.open(url);
  }

  return (
    <div
      style={{ color: Colors.Blue, cursor: "pointer", fontSize: "12px" }}
      onClick={onClick}
    >
      View Shipment
    </div>
  );
}

interface CompanyInvoiceAuditHistoryProps {
  shipmentData: ViewShipmentDataForApollo;
}

export function CompanyInvoiceAuditHistory(
  props: CompanyInvoiceAuditHistoryProps,
) {
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);
  const [shipments, setShipments] = useState<CompanyShipmentQuote[]>();
  const companyId = props.shipmentData.shipment.company.companyId!;

  async function refresh() {
    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      const response = await companiesApi.getBookedShipmentsForCompany({
        companyId,
      });
      setShipments(response);
    } catch {
      message.error("Something went wrong loading company history");
    }
    setLoading(false);
  }

  useOnce(refresh);

  if (loading || shipments === undefined) {
    return <Loading />;
  }

  function filterData(): CompanyShipmentQuote[] {
    if (shipments === undefined) {
      return [];
    }

    return (
      shipments
        // We don't want to include the current shipment in the list of past ones
        .filter(function (csq) {
          return csq.shipment.shipmentId !== props.shipmentData.shipmentId;
        })
        .filter(function (csq) {
          return csq.shipment.invoiceAuditProblems!.length > 0;
        })
        .sort(function (a, b) {
          return (
            dayjs(a.shipment.bookedAt).valueOf() -
            dayjs(b.shipment.bookedAt).valueOf()
          );
        })
    );
  }

  const BookedAt = styled.div`
    font-size: 10px;
    margin-top: 4px;
    color: ${Colors.LightText};
  `;

  const columns: DataTableColumn<CompanyShipmentQuote>[] = [
    {
      title: "Details",
      width: 160,
      render: (o) => (
        <Stack align="left">
          <CarrierLogo
            carrierIdentifier={o.quote.carrierIdentifier}
            brokeredCarrierIdentifier={undefined}
            width={60}
            height={40}
          />
          <BookedAt>
            {dayjs(o.shipment.bookedAt).format("MMMM Do YYYY")}
          </BookedAt>
          <Spacer height={16} />
          <TinyViewShipmentButton shipmentId={o.shipment.shipmentId!} />
        </Stack>
      ),
    },

    {
      title: "Problems",
      render: function (o) {
        if (
          o.shipment.invoiceAuditProblems === undefined ||
          o.shipment.invoiceAuditProblems.length === 0
        ) {
          return (
            <div>
              <WarningComponent /> No problems identified!
            </div>
          );
        } else {
          return (
            <Stack align="left">
              {o.shipment.invoiceAuditProblems.map((p) => (
                <InvoiceAuditProblemComponent
                  key={p.invoiceAuditProblemId}
                  problem={p}
                />
              ))}
            </Stack>
          );
        }
      },
      width: 80,
    },
  ];

  return (
    <>
      <div style={{ border: "1px solid #ccc" }}>
        <Title level={4} style={{ padding: "32px", paddingBottom: "16px" }}>
          <WarningComponent /> Other Audits for{" "}
          {props.shipmentData.shipment.company.companyName}
        </Title>
        <DataTable
          pagination={false}
          columns={columns}
          data={filterData()}
          showHeader={false}
        />
      </div>
      <Spacer height={32} />
    </>
  );
}
