import {
  CarrierInvoiceState,
  CompanyShipmentCarrierInvoice,
  CountryCode,
} from "@freightsimple/generated-apollo-openapi-client";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Switch } from "antd";
import { useState } from "react";
import { CarrierFilter } from "./CarrierFilter";
import { ColumnFormat, ExcelButton } from "./ExcelButton";
import dayjs from "dayjs";
import { getCarrierDisplayNameFromCarrierIdentifier } from "../Helpers/serviceProperties";
import HorizontalStack from "./HorizontalStack";
import Spacer from "../Spacer";
import { getAllUniqueKeys } from "../Helpers/getAllUniqueKeys";

interface DownloadCarrierStatementButtonProps {
  carrierInvoices: CompanyShipmentCarrierInvoice[];
}

interface DownloadCarrierStatementLinkProps
  extends DownloadCarrierStatementButtonProps {
  carrierIdentifier: string | undefined;
  externalVersion: boolean;
  onOk: () => void;
}

const paymentConfirmedDescription = "Payment Confirmed (Cashed) ✅";

function describeInvoiceState(state: CarrierInvoiceState) {
  switch (state) {
    case CarrierInvoiceState.PaymentPending:
      return "Payment Sent (Not yet cashed) ✉️";
    case CarrierInvoiceState.PaymentConfirmed:
      return paymentConfirmedDescription;
    case CarrierInvoiceState.Voided:
      return "Voided ❌";
    case CarrierInvoiceState.Issued:
      return "Audit Open 🔍";
    case CarrierInvoiceState.Approved:
      return "Approved for Payment 👍🏻";
  }
}

function backgroundColorForInvoiceState(state: CarrierInvoiceState): string {
  switch (state) {
    case CarrierInvoiceState.PaymentConfirmed:
      return "#E6FFE6"; // light green
    case CarrierInvoiceState.PaymentPending:
      return "#FFE6CC"; // light orange
    case CarrierInvoiceState.Approved:
      return "#FFFFD1"; // light yellow
    case CarrierInvoiceState.Issued:
      return "#FFE6E6"; // light red
    case CarrierInvoiceState.Voided:
      return "#CCCCCC"; // mid grey
    default:
      return "#FFFFFF"; // white as fallback
  }
}

export function DownloadCarrierStatementLink(
  props: DownloadCarrierStatementLinkProps,
) {
  const internal = props.externalVersion === false;

  function getData() {
    if (props.carrierIdentifier === undefined) {
      return [];
    }

    return props.carrierInvoices
      .filter(function (ci) {
        return ci.carrierInvoice.carrierIdentifier === props.carrierIdentifier;
      })
      .sort(function (a, b) {
        return dayjs(a.shipment.pickupDate!).isBefore(
          dayjs(b.shipment.pickupDate!),
        )
          ? -1
          : 1;
      })
      .map(function (ci) {
        const o = {} as {
          [key: string]: string | undefined | CountryCode | number;
        };

        o["FSREF"] = ci.shipment.billOfLadingNumber;
        o["Invoice Number"] = ci.carrierInvoice.invoiceIdentifier;
        o["Pickup Date"] = ci.shipment.pickupDate;
        o["Days Since Pickup"] = dayjs().diff(
          dayjs(ci.shipment.pickupDate),
          "day",
        );
        o["Delivery Date"] = ci.shipment.actualDeliveryDate;
        o["Days Since Delivery"] = ci.shipment.actualDeliveryDate
          ? dayjs().diff(dayjs(ci.shipment.actualDeliveryDate), "day")
          : undefined;
        o["Pickup"] =
          `${ci.shipment.pickupLocation?.address?.city}, ${ci.shipment.pickupLocation?.address?.stateOrProvinceCode}`;
        o["Delivery"] =
          `${ci.shipment.deliveryLocation?.address?.city}, ${ci.shipment.deliveryLocation?.address?.stateOrProvinceCode}`;
        o["Shipment State"] = ci.shipment.state;
        o["Invoice State Description"] = describeInvoiceState(
          ci.carrierInvoice.invoiceState!,
        );

        o["Invoice State"] = ci.carrierInvoice.invoiceState;

        o["Invoice Amount"] = ci.carrierInvoice.amount;
        o["Invoice Currency"] = ci.carrierInvoice.currency;
        o["Payment Sent Time"] = ci.carrierInvoice.paymentPendingTime;
        o["Cheque Sent?"] = ci.carrierInvoice.chequeSent ? "Yes" : "No";

        if (ci.carrierInvoice.chequeSent) {
          o["Cheque Amount"] = ci.carrierInvoice.chequeAmount;
          o["Cheque Number"] = ci.carrierInvoice.chequeNumber;
          if (internal) {
            o["Cheque URL"] = ci.carrierInvoice.chequeBatchId
              ? `https://my.checkflo.com/web/batchReport.spr?action=batchListDetails&bid=${ci.carrierInvoice.chequeBatchId}`
              : undefined;
          }
        }

        if (internal) {
          o["CarrierInvoiceId"] = ci.carrierInvoice.carrierInvoiceId;
        }
        return o;
      });
  }

  function getFilename() {
    if (props.carrierIdentifier === undefined) {
      return undefined;
    }
    return `${getCarrierDisplayNameFromCarrierIdentifier(
      props.carrierIdentifier,
    )} Statement ${dayjs().format("YYYY-MM-DD")}.xlsx`;
  }

  const columnFormats = {};
  columnFormats[`Invoice Amount`] = {
    type: "currency",
    decimals: 2,
  };
  columnFormats["Cheque Amount"] = {
    type: "currency",
    decimals: 2,
  };
  columnFormats["Cheque Number"] = {
    type: "number",
    decimals: 0,
  };
  columnFormats["Pickup Date"] = {
    type: "date",
  };

  function generateSheets() {
    if (props.carrierIdentifier === undefined) {
      return [];
    }

    const data = getData();
    const columns = getAllUniqueKeys(data);

    // Create detailed data sheet config
    const detailedSheet = {
      name: "Statement Lines",
      data,
      headers: columns,
      columnFormats,
      getRowStyle: (item: any, _index: number) => {
        const style: { color?: string; backgroundColor?: string } = {
          backgroundColor: backgroundColorForInvoiceState(
            item["Invoice State"],
          ),
        };

        // Keep the existing grey text for payment confirmed
        if (item["Invoice State Description"] === paymentConfirmedDescription) {
          style.color = "#aaaaaa";
        }

        return style;
      },
    };

    // Create summary sheet config
    const summaryData = [
      {
        "Total Invoices": data.length,
        "Total Amount": data.reduce(
          (sum, row) => sum + (Number(row["Invoice Amount"]) || 0),
          0,
        ),
        "Payment Sent Count": data.filter(
          (row) => row["Invoice State"] === CarrierInvoiceState.PaymentPending,
        ).length,
        "Payment Sent Amount": data
          .filter(
            (row) =>
              row["Invoice State"] === CarrierInvoiceState.PaymentPending,
          )
          .reduce((sum, row) => sum + (Number(row["Invoice Amount"]) || 0), 0),
        "Payment Confirmed Count": data.filter(
          (row) =>
            row["Invoice State"] === CarrierInvoiceState.PaymentConfirmed,
        ).length,
        "Payment Confirmed Amount": data
          .filter(
            (row) =>
              row["Invoice State"] === CarrierInvoiceState.PaymentConfirmed,
          )
          .reduce((sum, row) => sum + (Number(row["Invoice Amount"]) || 0), 0),
      },
    ];

    const summarySheet = {
      name: "Statement Summary",
      data: summaryData,
      headers: Object.keys(summaryData[0]),
      columnFormats: {
        "Total Amount": { type: "currency", decimals: 2 },
        "Payment Sent Amount": { type: "currency", decimals: 2 },
        "Payment Confirmed Amount": { type: "currency", decimals: 2 },
      } satisfies Record<string, ColumnFormat>,
    };

    return [detailedSheet, summarySheet];
  }

  return (
    <ExcelButton
      generateSheets={generateSheets}
      getFilename={getFilename}
      autoWidth
      boldHeaders
      onOk={props.onOk}
    />
  );
}

export function DownloadCarrierStatementButton(
  props: DownloadCarrierStatementButtonProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [carrierIdentifier, setCarrierIdentifier] = useState<
    string | undefined
  >();
  const [externalVersion, setExternalVersion] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Carrier Statement Excel"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={700}
        footer={
          <HorizontalStack align="right" width="100%">
            <DownloadCarrierStatementLink
              {...props}
              carrierIdentifier={carrierIdentifier}
              externalVersion={externalVersion}
              onOk={handleOk}
            />
          </HorizontalStack>
        }
      >
        <Spacer height={16} />
        <Form
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="Carrier"
            tooltip="Select the carrier to download the statement for"
          >
            <CarrierFilter
              carrierIdentifier={carrierIdentifier}
              setCarrierIdentifier={setCarrierIdentifier}
            />
          </Form.Item>
          <Form.Item
            label="External Version"
            tooltip="Check this if you want to send this to a carrier. It will remove sensitive internal only columns"
          >
            <Switch checked={externalVersion} onChange={setExternalVersion} />
          </Form.Item>
        </Form>
        <Spacer height={16} />
      </Modal>
      <Button
        icon={<DownloadOutlined />}
        onClick={showModal}
        type="link"
        style={{ margin: 0, fontSize: "12px" }}
      >
        Carrier Statement
      </Button>
    </>
  );
}
