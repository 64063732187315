import {
  CustomsDocsState,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";
import { message } from "antd";
import { TabProps } from "./TabProps";
import { useShipmentsApi } from "../../Apis/Apis";
import { useState } from "react";

export function useChangePauseCustomsDocsReminders(props: TabProps) {
  const createShipmentApi = useShipmentsApi();
  const [isLoading, setIsLoading] = useState(false);

  const shipment = props.shipmentData.shipment.shipment;

  const pause = !!shipment.pauseCustomsDocsReminders;

  const allowOption =
    shipment.customsDocsState === CustomsDocsState.Missing &&
    (shipment.state === ShipmentState.BookingConfirmed ||
      shipment.state === ShipmentState.InTransit);

  async function onClick() {
    if (!shipment.shipmentId) {
      message.error("Oops. Something went wrong!");
      return;
    }
    try {
      setIsLoading(() => true);
      const shipmentApi = await createShipmentApi();
      await shipmentApi.changePauseCustomsDocsReminder({
        shipmentId: shipment.shipmentId,
        pause: !pause,
      });
      await props.onRefresh();
      message.success(
        `${pause ? "Paused" : "Activated"} Customs Docs Reminders`,
      );
      setIsLoading(() => false);
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
      setIsLoading(() => false);
    }
  }

  return { shipment, pause, onClick, isLoading, allowOption };
}
