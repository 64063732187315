import { Select } from "antd";
import { PeriodFormats } from "../Screens/FinanceComponents/generatePeriodInfo";
import dayjs from "dayjs";

interface QuarterSelectProps {
  quarter: string | undefined;
  setQuarter: (_: string | undefined) => void;
}

export function QuarterSelect(props: QuarterSelectProps) {
  const { quarter, setQuarter } = props;

  const startingMonth = dayjs().startOf("quarter");

  const months = [
    startingMonth,
    startingMonth.subtract(1, "quarter"),
    startingMonth.subtract(2, "quarter"),
    startingMonth.subtract(3, "quarter"),
    startingMonth.subtract(4, "quarter"),
    startingMonth.subtract(5, "quarter"),
    startingMonth.subtract(6, "quarter"),
    startingMonth.subtract(7, "quarter"),
    startingMonth.subtract(8, "quarter"),
    startingMonth.subtract(9, "quarter"),
    startingMonth.subtract(10, "quarter"),
    startingMonth.subtract(11, "quarter"),
  ];

  return (
    <Select
      allowClear
      value={quarter}
      style={{ width: 220 }}
      onChange={function (e) {
        setQuarter(e);
      }}
      placeholder="Filter by quarter"
    >
      {months.map(function (m) {
        return (
          <Select.Option
            key={m.format(PeriodFormats.Quarterly)}
            value={m.format(PeriodFormats.Quarterly)}
          >
            {m.format(PeriodFormats.Quarterly)}
          </Select.Option>
        );
      })}
    </Select>
  );
}
