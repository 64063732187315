import {
  EmailDescription,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";
import { Form, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { MenuItem } from "../../Types/AntHelperTypes";

interface PreviewPickupEmailMenuItemProps {
  shipmentData: ViewShipmentDataForApollo;
}

const { Text } = Typography;

export function PreviewPickupEmailMenuItem(
  props: PreviewPickupEmailMenuItemProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailDescription, setEmailDescription] = useState<
    EmailDescription | undefined
  >();
  const createShipmentsApi = useShipmentsApi();

  async function onRefresh() {
    const shipmentsApi = await createShipmentsApi();
    const shipmentId = props.shipmentData.shipmentId;
    const response = await shipmentsApi.previewBookPickupEmail({ shipmentId });
    setEmailDescription(response);
  }

  useEffect(
    function () {
      if (isModalVisible) {
        onRefresh();
      }
    },
    [isModalVisible],
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Preview Book Pickup Email"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          {emailDescription !== undefined && (
            <>
              <Form.Item label="Email Addresses">
                <Text>{emailDescription?.addresses.join(", ")}</Text>
              </Form.Item>
              <Form.Item label="Subject">
                <Text>{emailDescription?.subject}</Text>
              </Form.Item>
              <Form.Item label="Email Body">
                <div
                  style={{
                    border: "1px solid #eee",
                    padding: "16px",
                    width: "1100px",
                  }}
                >
                  <div
                    title="Email Preview"
                    dangerouslySetInnerHTML={{
                      __html: emailDescription.emailBody,
                    }}
                  ></div>
                </div>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
      <MenuItem onClick={showModal}>Preview Book Pickup Email</MenuItem>
    </>
  );
}
