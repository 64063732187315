import { Note } from "@freightsimple/generated-apollo-openapi-client";
import { NoteHeadline } from "../NotesBase/NoteHeadline";
import { NoteMessage } from "../NotesBase/NoteMessage";
import { ButtonRow } from "../ButtonRow";
import Spacer from "../../Spacer";

interface DialpadNoteElementProps {
  note: Note;
}

export function DialpadNoteElement(props: DialpadNoteElementProps) {
  const { note } = props;
  return (
    <>
      <NoteHeadline>{note.headline}</NoteHeadline>
      <NoteMessage>{note.message}</NoteMessage>
      <Spacer height={16} />
      <ButtonRow>
        <a
          style={{ color: "#1890ff" }}
          target="_blank"
          href={props.note.link}
          rel="noreferrer"
        >
          View Recording
        </a>
      </ButtonRow>
    </>
  );
}
