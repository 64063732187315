import { Tag, message } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import { TabProps } from "./TabProps";

export function DoNotFetchFurtherCarrierInvoicesMenuItem(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const createShipmentsApi = useShipmentsApi();
  async function onConfirm() {
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.markDoNotFetchFurtherCarrierInvoices({
        shipmentId,
      });
      await props.onRefresh();
      message.success("No longer fetching carrier invoices");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  return (
    <ConfirmMenuItem
      onConfirm={onConfirm}
      question="Are you sure you want to mark this shipment to not try to automatically fetch any further invoices?"
      okText={"Yes"}
      tooltip={
        "If we auto-download invoices from a website/api, this will prevent fetching any more"
      }
    >
      <Tag color="red">Advanced</Tag> Do not fetch further carrier invoices
    </ConfirmMenuItem>
  );
}
