import CarrierLogo from "../../Components/CarrierLogo";
import HorizontalStack from "../../Components/HorizontalStack";
import Spacer from "../../Spacer";

interface InterlineCarrierProps {
  carrierIdentifier: string | undefined;
  carrierDisplayName: string | undefined;
}

export function InterlineCarrier(props: InterlineCarrierProps) {
  if (props.carrierIdentifier) {
    return (
      <HorizontalStack>
        <CarrierLogo
          carrierIdentifier={props.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          height={40}
          width={40}
        />
        <Spacer width={8} />
        <div>{props.carrierDisplayName}</div>
      </HorizontalStack>
    );
  } else {
    return <div>{props.carrierDisplayName}</div>;
  }
}
