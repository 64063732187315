import { Tag } from "antd";
import dayjs from "dayjs";

interface DateProps {
  children: string | undefined;
}

export function Date(props: DateProps) {
  const d = props.children;

  if (d === undefined) {
    return <Tag color="orange">Missing</Tag>;
  }

  const isInThePast = dayjs(d).startOf("day").isBefore(dayjs().startOf("day"));

  const style = isInThePast ? { color: "red" } : {};

  return <div style={style}>{d}</div>;
}
