import { Select } from "antd";

interface ControlFreightDropdownProps {
  value: string | undefined;
  onChange: (_: string | undefined) => void;
}

export function ControlFreightDropdown(props: ControlFreightDropdownProps) {
  return (
    <Select
      style={{ width: "400px" }}
      value={props.value}
      onChange={props.onChange}
      listHeight={400}
    >
      <Select.OptGroup label="No freight">
        <Select.Option key="close lead">
          Does not have or control freight (Close the lead)
        </Select.Option>
      </Select.OptGroup>
      <Select.OptGroup label="Yes, they have or control freight">
        <Select.Option key="🔥 Hot">🔥 Hot</Select.Option>
        <Select.Option key="🌤 Warm">🌤 Warm</Select.Option>
        <Select.Option key="🥶 Cold">🥶 Cold</Select.Option>
        <Select.Option key="☠️ Dead">☠️ Dead (avoid)</Select.Option>
      </Select.OptGroup>
      <Select.OptGroup label="Undetermined">
        <Select.Option key="unknown">
          Unknown (keep lead unchanged)
        </Select.Option>
      </Select.OptGroup>
    </Select>
  );
}
