import { CSSProperties, ReactNode } from "react";
import Colors from "../Colors";
import { ArrowRightOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Grid } from "antd";

const { useBreakpoint } = Grid;

interface LeftRightDifferenceProps {
  left: ReactNode;
  inBetween?: ReactNode;
  right: ReactNode;
}

export function LeftRightDifference({
  left,
  inBetween,
  right,
}: LeftRightDifferenceProps) {
  const screens = useBreakpoint();
  const arrowStyle: CSSProperties = {
    fontSize: "9pt",
    marginInline: "5px",
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: screens.xxl ? "row" : "column-reverse",
      }}
    >
      <div style={{ color: Colors.Palette.Orange[600], wordBreak: "keep-all" }}>
        {left}
      </div>
      <div style={arrowStyle}>
        {inBetween ??
          (screens.xxl ? <ArrowRightOutlined /> : <ArrowUpOutlined />)}
      </div>
      <div style={{ color: Colors.Palette.Green[600], wordBreak: "keep-all" }}>
        {right}
      </div>
    </div>
  );
}
