import ShipmentProblemsList from "../GeneratedFixtures/ShipmentProblems.json";

interface ShipmentProblem {
  code: string;
  name: string;
  description: string;
}

export function getShipmentProblem(code: string): ShipmentProblem {
  if (!ShipmentProblemsList.map((p) => p.code).includes(code)) {
    console.error("Shipment problem not found: " + code);
    return {
      code,
      name: code,
      description: "Description not available",
    };
  }
  return ShipmentProblemsList.find((p) => p.code === code) as ShipmentProblem;
}

export function getShipmentProblems(): ShipmentProblem[] {
  return ShipmentProblemsList as ShipmentProblem[];
}
