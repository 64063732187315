import { groupBy } from "./groupBy";
export function sum<T>(items: Array<T>, f: (_: T) => number) {
  let s = 0;
  items.forEach(function (i) {
    s += f(i);
  });
  return s;
}

export function countIf<T>(items: Array<T>, f: (_: T) => boolean) {
  let s = 0;
  items.forEach(function (i) {
    s += f(i) ? 1 : 0;
  });
  return s;
}

export function hasDuplicates<T, S>(items: Array<T>, f: (_: T) => S): boolean {
  // Basically make an array of only the property we are interested in, then group by it, and see if any groups have more than one item
  return groupBy(
    items
      // Ignore undefined/missing/etc
      .filter((sc) => f(sc))
      .map(f),
    (o) => o,
  ).some((a) => a.value.length > 1);
}
