import {
  ListCompaniesRow,
  ShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import { Form, Row, Select, Switch } from "antd";
import { useState } from "react";
import { useCompaniesApi, useProfitLossApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { CompanyFilter, NameAndId } from "../../Components/CompanyFilter";
import HorizontalStack from "../../Components/HorizontalStack";
import { LeadSourceSelect } from "../../Components/LeadSourceSelect";
import { Loading } from "../../Components/Loading";
import { MonthSelect } from "../../Components/MonthSelect";
import { Page } from "../../Components/Page";
import PageTitle from "../../Components/PageTitle";
import { QuarterSelect } from "../../Components/QuarterSelect";
import Stack from "../../Components/Stack";
import { YearSelect } from "../../Components/YearSelect";
import { assertNever } from "../../Helpers/assertNever";
import { groupBy } from "../../Helpers/groupBy";
import { useOnce } from "../../Hooks/useOnce";
import { RecentCompanySalesReport } from "../FinanceComponents/RecentCompanySalesReport";
import { ReportType } from "../FinanceComponents/ReportType";
import { ReportTypeDropdown } from "../FinanceComponents/ReportTypeDropdown";
import { ShipmentFrequencyDropdown } from "../ViewCompanyScreenComponents/ShipmentFrequencyDropdown";
import { EnumDropdown } from "./EnumDropdown";
import { Period } from "./Period";

export const PeriodDropdown = EnumDropdown<Period, typeof Period>(
  Period,
  function (et) {
    switch (et) {
      case Period.Yearly:
        return "Yearly";
      case Period.Quarterly:
        return "Quarterly";
      case Period.Monthly:
        return "Monthly";
      default:
        assertNever(et);
    }
  },
);

export function RecentCompanySalesReportScreen() {
  const [report, setReport] = useState<ShipmentReport[] | undefined>();
  const createProfitLossApi = useProfitLossApi();
  const [companyIdFilter, setCompanyIdFilter] = useState<string | undefined>();
  const [leadSource, setLeadSource] = useState<string>();
  const [shipmentFrequency, setShipmentFrequency] = useState<
    string | undefined
  >(undefined);
  const [onlyShowLost, setOnlyShowLost] = useState<boolean>(false);
  const [period, setPeriod] = useState<Period>(Period.Monthly);
  const [companyCreationFilter, setCompanyCreationFilter] = useState<string>();
  const [sortByDeltas, setSortByDeltas] = useState(false);
  const [reportType, setReportType] = useState<ReportType>(ReportType.Revenue);
  const createCompaniesApi = useCompaniesApi();
  const [companies, setCompanies] = useState<
    Array<ListCompaniesRow> | undefined
  >(undefined);

  interface PeriodSelectProps {
    periodType: Period;
    value: string | undefined;
    setValue: (_: string | undefined) => void;
  }

  function PeriodSelect(props: PeriodSelectProps) {
    if (props.periodType === Period.Monthly) {
      return <MonthSelect month={props.value} setMonth={props.setValue} />;
    } else if (props.periodType === Period.Quarterly) {
      return (
        <QuarterSelect quarter={props.value} setQuarter={props.setValue} />
      );
    } else if (props.periodType === Period.Yearly) {
      return <YearSelect year={props.value} setYear={props.setValue} />;
    } else {
      return <></>;
    }
  }

  useOnce(async function () {
    const companiesApi = await createCompaniesApi();
    const response = await companiesApi.listAllCompanies();
    setCompanies(response);
  });

  async function refresh() {
    const profitLossApi = await createProfitLossApi();
    const response = await profitLossApi.recentCompanySalesReport();

    setReport(response.lines);
  }

  useOnce(refresh);

  function getCompanyNames(_report: ShipmentReport[]): NameAndId[] {
    return groupBy(_report, (o) => o.companyId).map(function (o) {
      return { id: o.key!, name: o.value[0].companyName! };
    });
  }

  if (report === undefined || companies === undefined) {
    return <Loading />;
  } else {
    return (
      <Page
        title={`Companies Recent Sales`}
        subtitle={`NOTE. Monthly totals might be different from P+L as this is based on quote date`}
        tags={[]}
        extra={[]}
      >
        <PageTitle>Companies Recent Sales</PageTitle>
        <Row style={{ width: "100%" }}>
          <Stack align="left" style={{ width: "100%" }}>
            <HorizontalStack align="right" width="100%">
              <ButtonRow>
                <Form.Item label="Sort by deltas">
                  <Switch checked={sortByDeltas} onChange={setSortByDeltas} />
                </Form.Item>
                <Form.Item label="Filter by company">
                  <CompanyFilter
                    companies={getCompanyNames(report)}
                    onFilter={function (companyId) {
                      setCompanyIdFilter(companyId);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Lead Source">
                  <LeadSourceSelect
                    leadSource={leadSource}
                    setLeadSource={setLeadSource}
                  />
                </Form.Item>
                <Form.Item label="Frequency">
                  <ShipmentFrequencyDropdown
                    value={shipmentFrequency}
                    onChange={setShipmentFrequency}
                    allowClear
                  />
                </Form.Item>
                <Form.Item label="Only show lost?">
                  <Select
                    value={onlyShowLost}
                    onChange={setOnlyShowLost}
                    allowClear
                  >
                    <Select.Option value={true}>
                      Yes - only show lost
                    </Select.Option>
                    <Select.Option value={false}>No - show all</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Period">
                  <PeriodDropdown value={period} setValue={setPeriod} />
                </Form.Item>
                <Form.Item label="Company Creation">
                  <PeriodSelect
                    periodType={period}
                    value={companyCreationFilter}
                    setValue={setCompanyCreationFilter}
                  />
                </Form.Item>
                <Form.Item label="Report Type">
                  <ReportTypeDropdown
                    value={reportType}
                    setValue={setReportType}
                  />
                </Form.Item>
              </ButtonRow>
            </HorizontalStack>
            ,
            <RecentCompanySalesReport
              report={report}
              companies={companies}
              companyIdFilter={companyIdFilter}
              leadSourceFilter={leadSource}
              shipmentFrequencyFilter={shipmentFrequency}
              onlyShowLost={onlyShowLost}
              periodSelected={period}
              companyCreationFilter={companyCreationFilter}
              sortByDeltas={sortByDeltas}
              reportType={reportType}
            />
          </Stack>
        </Row>
      </Page>
    );
  }
}
