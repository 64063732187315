import { DocumentType } from "@freightsimple/generated-apollo-openapi-client";
import {
  EnumDropdown,
  OptionalEnumDropdown,
} from "../Screens/ViewShipmentScreenComponents/EnumDropdown";
import { describeDocumentType } from "../Helpers/describeDocumentType";

export const DocumentTypeDropdown = OptionalEnumDropdown<
  DocumentType,
  typeof DocumentType
>(DocumentType, "No document type selected", describeDocumentType);

export const NonOptionalDocumentTypeDropdown = EnumDropdown<
  DocumentType,
  typeof DocumentType
>(DocumentType, describeDocumentType);
