import { message } from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";

interface MakePrimaryContactMenuItemProps {
  salesContactId: string;
  companyId: string;
  disabled: boolean;
  onRefresh: () => Promise<void>;
}
export function MakePrimaryContactMenuItem(
  props: MakePrimaryContactMenuItemProps,
) {
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);
  const { salesContactId, companyId } = props;

  async function onConfirm() {
    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();

      await companiesApi.updatePrimaryContactForCompany({
        salesContactId,
        companyId,
      });
      await props.onRefresh();
      message.success("Done!");
    } catch {
      message.error("Eek. Something went wrong");
    }
    setLoading(false);
  }

  return (
    <ConfirmMenuItem
      disabled={props.disabled}
      loading={loading}
      tooltip="Will change the primary contact"
      okText="Yes, do it."
      onConfirm={onConfirm}
      question="Are you sure?"
    >
      Set Primary Contact
    </ConfirmMenuItem>
  );
}
