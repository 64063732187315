import { LineItem } from "@freightsimple/generated-apollo-openapi-client";
import { ErrorMessageType } from "../Types/ErrorMessageType";

function errorMessageForDescription(description: string | undefined) {
  if (description === undefined || description.trim() === "") {
    return `Description is missing`;
  }

  return undefined;
}

function errorMessageForFreightClass(FreightClaim: string | undefined) {
  if (FreightClaim === undefined || FreightClaim.trim() === "") {
    return `Freight Class is missing`;
  }

  return undefined;
}

function errorMessageForWeight(weightPerHandlingUnit: number | undefined) {
  if (weightPerHandlingUnit === undefined) {
    return `Weight is missing`;
  }

  if (weightPerHandlingUnit === 0) {
    return `Weight is zero`;
  }

  return undefined;
}

function errorMessageForNumberHandlingUnits(
  numberHandlingUnits: number | undefined,
) {
  if (numberHandlingUnits === undefined) {
    return `Number Handling Units is missing`;
  }

  if (numberHandlingUnits === 0) {
    return `Number Handling Units is zero`;
  }

  if (numberHandlingUnits < 0) {
    return `Number Handling Units is negative`;
  }

  if (numberHandlingUnits > 1000) {
    return `Number Handling Units is too large`;
  }

  return undefined;
}

function errorMessageForDimension(
  dimensionInches: number | undefined,
  dimensionName: string,
) {
  if (dimensionInches === undefined) {
    return `${dimensionName} is missing`;
  }

  if (dimensionInches === 0) {
    return `${dimensionName} is zero`;
  }

  if (dimensionInches < 0) {
    return `${dimensionName} is negative`;
  }

  // 100ft is too big
  if (dimensionInches > 100 * 12) {
    return `${dimensionName} is too large`;
  }

  return undefined;
}

export function errorMessageForLineItem(lineItem: LineItem): ErrorMessageType {
  const numberHandlingUnitsErrorMessage = errorMessageForNumberHandlingUnits(
    lineItem.numberHandlingUnits,
  );

  if (numberHandlingUnitsErrorMessage !== undefined) {
    return numberHandlingUnitsErrorMessage;
  }

  const weightErrorMessage = errorMessageForWeight(
    lineItem.weightPerHandlingUnit,
  );
  if (weightErrorMessage !== undefined) {
    return weightErrorMessage;
  }

  const lengthErrorMessage = errorMessageForDimension(
    lineItem.length,
    "Length",
  );
  if (lengthErrorMessage !== undefined) {
    return lengthErrorMessage;
  }

  const widthErrorMessage = errorMessageForDimension(lineItem.length, "Width");
  if (widthErrorMessage !== undefined) {
    return widthErrorMessage;
  }

  const heightErrorMessage = errorMessageForDimension(
    lineItem.length,
    "Height",
  );
  if (heightErrorMessage !== undefined) {
    return heightErrorMessage;
  }

  const descriptionErrorMessage = errorMessageForDescription(
    lineItem.description,
  );
  if (descriptionErrorMessage !== undefined) {
    return descriptionErrorMessage;
  }

  const freightClassErrorMessage = errorMessageForFreightClass(
    lineItem.freightClass,
  );
  if (freightClassErrorMessage !== undefined) {
    return freightClassErrorMessage;
  }

  return;
}
