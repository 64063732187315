import { EyeInvisibleTwoTone } from "@ant-design/icons";

import { Button, message, Tooltip } from "antd";
import { useQuotesApi } from "../../Apis/Apis";
import { QuotesTableProps } from "./ManageQuotesTab";

export function MakeEntireQuoteGroupHiddenButton(props: QuotesTableProps) {
  const shipmentId = props.data.shipmentId;
  const createQuotesApi = useQuotesApi();

  async function onMakeGroupHidden() {
    const quotesApi = await createQuotesApi();
    const quoteGroupName = props.quoteGroupName;
    try {
      await quotesApi.markQuoteGroupCustomerHidden({
        shipmentId,
        quoteGroupName,
      });
      await props.onRefresh();
      message.success(`🗑 Quote Group ${quoteGroupName} hidden`);
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  function hasSelectedQuote(): boolean {
    return props.quotes.some((q) => q.quoteId === props.selectedQuoteId);
  }

  function allAlreadyHidden(): boolean {
    return props.quotes.every((q) => q.hiddenFromCustomer);
  }

  if (hasSelectedQuote()) {
    return (
      <Tooltip overlay="Cannot hide a group when one is selected">
        <Button
          type="link"
          icon={<EyeInvisibleTwoTone twoToneColor="#ccc" />}
          disabled={true}
        >
          Hide Entire Group
        </Button>
      </Tooltip>
    );
  } else if (allAlreadyHidden()) {
    return <></>;
  } else {
    return (
      <Button
        type="link"
        icon={<EyeInvisibleTwoTone twoToneColor="#ccc" />}
        onClick={onMakeGroupHidden}
      >
        Hide Entire Group
      </Button>
    );
  }
}
