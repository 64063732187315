import { Row, Typography } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";
import { isPhone } from "../Helpers/isPhone";
import HorizontalStack from "./HorizontalStack";
import Stack from "./Stack";
import Colors from "./Colors";

const { Title, Text } = Typography;

const HeaderContainer = styled(Row)`
  width: 100%;
  padding: ${() => (isPhone ? "0" : "16px")};
  background-color: #ebedf9;
`;

interface PageHeaderProps {
  title: ReactNode;
  subtitle?: ReactNode;
  tags?: ReactNode[];
  extra?: ReactNode[];
  children?: ReactNode;
}

export function PageHeader(props: PageHeaderProps) {
  const { title, subtitle, tags = [], extra = [], children } = props;

  if (isPhone) {
    return (
      <HeaderContainer>
        <Stack align="left" style={{ paddingLeft: "6px" }}>
          <Title style={{ marginBottom: "0", marginTop: "2px" }} level={5}>
            {title}
          </Title>
          {subtitle && (
            <>
              <Text style={{ color: Colors.LightText }}>{subtitle}</Text>
            </>
          )}
          {tags.length > 0 && (
            <HorizontalStack spacing={8} style={{ marginLeft: "-8px" }}>
              {tags}
            </HorizontalStack>
          )}
          <Stack align="left">{extra}</Stack>
        </Stack>
        {children}
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer>
      <HorizontalStack width="100%" align="spread">
        <HorizontalStack>
          <Stack align="left">
            <Title style={{ marginBottom: "0" }} level={3}>
              {title}
            </Title>
            {subtitle && (
              <>
                <Text style={{ color: Colors.LightText }}>{subtitle}</Text>
              </>
            )}
            {tags.length > 0 && (
              <HorizontalStack spacing={8} style={{ marginLeft: "-8px" }}>
                {tags}
              </HorizontalStack>
            )}
            <Stack align="left">{extra}</Stack>
          </Stack>
        </HorizontalStack>
        {children}
      </HorizontalStack>
    </HeaderContainer>
  );
}
