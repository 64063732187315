import { ShipmentReport } from "@freightsimple/generated-apollo-openapi-client";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
  VictoryTheme,
} from "victory";
import { assertNever } from "../../Helpers/assertNever";
import { sumMoneyIn, sumMoneyOut, sumProfit } from "./calculations";
import { SummarizeBelowColumn } from "./SummarizeBelowColumn";
type GroupedType = { key: string; value: ShipmentReport[] };

interface ProfitLossGroupByGraphProps {
  data: GroupedType[];
  summarizeBelow: SummarizeBelowColumn;
}

export function ProfitLossGroupByGraph(props: ProfitLossGroupByGraphProps) {
  function extractValue(item: GroupedType) {
    switch (props.summarizeBelow) {
      case SummarizeBelowColumn.MoneyIn:
        return sumMoneyIn(item);
      case SummarizeBelowColumn.MoneyOut:
        return sumMoneyOut(item);
      case SummarizeBelowColumn.Profit:
        return sumProfit(item);
      default:
        assertNever(props.summarizeBelow);
    }
  }
  function convertData() {
    return props.data
      .map(function (item) {
        return {
          key: item.key.replaceAll(" ", "\n"),
          value: extractValue(item),
        };
      })
      .sort(function (a, b) {
        return b.value - a.value;
      });
  }

  function xAxisLabels() {
    return convertData().map(function (item) {
      return "$" + item.value.toFixed();
    });
  }

  return (
    <div style={{ height: "300px", width: "100%" }}>
      <VictoryChart
        // adding the material theme provided with Victory
        theme={VictoryTheme.material}
        domainPadding={20}
        width={1700}
      >
        <VictoryAxis
          dependentAxis
          // tickFormat specifies how ticks should be displayed
          tickFormat={(x) => `$${x / 1000}k`}
          style={{ axis: { stroke: "none" } }}
        />
        <VictoryAxis tickLabelComponent={<VictoryLabel />} />
        <VictoryStack>
          <VictoryBar
            data={convertData()} // data accessor for x values
            x="key"
            // data accessor for y values
            y="value"
            labels={xAxisLabels()}
            labelComponent={
              <VictoryLabel
              // labelPlacement="vertical"
              // textAnchor="start"
              // angle={90}
              // verticalAnchor="start"
              // y={50}
              // textAnchor="start"
              />
            }
          />
        </VictoryStack>
      </VictoryChart>
    </div>
  );
}
