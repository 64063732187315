import { message } from "antd";
import { useQuotesApi } from "../../Apis/Apis";
import { ConfirmButton } from "../../Components/ConfirmButton";
import { TabProps } from "./TabProps";

export function CloseManualQuotingButton(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const createQuotesApi = useQuotesApi();
  async function onConfirm() {
    const quotesApi = await createQuotesApi();
    try {
      await quotesApi.markShipmentManualQuotingComplete({ shipmentId });
      await props.onRefresh();
      message.success("Done");
    } catch (e) {
      message.error(`Oops. Error ${e}`);
    }
  }

  if (!props.shipmentData.shipment.shipment.manualQuotingOpen) {
    return <></>;
  }

  return (
    <ConfirmButton
      onConfirm={onConfirm}
      question={`Are you sure you want to mark manual quoting as complete`}
      okText="Yes, mark complete"
      tooltip="This will mark manual quoting complete. Do this if we are done and the best rate has been communicated to the customer"
      type="link"
    >
      Mark Manual Quoting Complete
    </ConfirmButton>
  );
}
