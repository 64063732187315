import { ShipmentsApi } from "@freightsimple/generated-apollo-openapi-client";
import { Button, message, notification, Tooltip } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ConfirmButton, ConfirmMenuItem } from "../../Components/ConfirmButton";
import { MenuItem } from "../../Types/AntHelperTypes";
interface ShipmentApiActionButtonProps {
  name: string;
  tooltip?: string;
  onClick: (shipmentApi: ShipmentsApi) => Promise<void>;
  onRefresh: () => Promise<void>;
  hidden: boolean;
  confirm?: boolean;
  confirmButtonText?: string;
  confirmQuestion?: string;
}

export function ShipmentApiActionButton(props: ShipmentApiActionButtonProps) {
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  async function onClick() {
    setLoading(true);
    try {
      const shipmentApi = await createShipmentApi();
      await props.onClick(shipmentApi);
      await props.onRefresh();
      message.success(`Success`);
    } catch {
      message.error(`Oops. Something went wrong`);
    }
    setLoading(false);
  }

  if (props.hidden) {
    return <></>;
  }

  if (props.confirm) {
    return (
      <ConfirmButton
        onConfirm={onClick}
        question={props.confirmQuestion || "Are you sure?"}
        okText={props.confirmButtonText || "Yes"}
        tooltip={props.tooltip || ""}
        loading={loading}
      >
        {props.name}
      </ConfirmButton>
    );
  } else {
    return (
      <Tooltip overlay={props.tooltip}>
        <Button loading={loading} onClick={onClick}>
          {props.name}
        </Button>
      </Tooltip>
    );
  }
}

export function ShipmentApiActionMenuItem(props: ShipmentApiActionButtonProps) {
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);

  async function onClick() {
    setLoading(true);
    try {
      const shipmentApi = await createShipmentApi();
      await props.onClick(shipmentApi);
      await props.onRefresh();
      notification.success({
        message: `Success`,
        description: <>{props.name}</>,
      });
    } catch (e: any) {
      notification.error({
        message: `Oops. Something went wrong`,
        description: <>{e.toString()}</>,
      });
    }
    setLoading(false);
  }

  if (props.hidden) {
    return <></>;
  }

  if (props.confirm) {
    return (
      <ConfirmMenuItem
        onConfirm={onClick}
        question={props.confirmQuestion || "Are you sure?"}
        okText={props.confirmButtonText || "Yes"}
        tooltip={props.tooltip || ""}
        loading={loading}
      >
        {props.name}
      </ConfirmMenuItem>
    );
  } else {
    const loadingSuffix = loading ? " (Working)" : "";
    return (
      <MenuItem onClick={onClick}>
        {props.name}
        {loadingSuffix}
      </MenuItem>
    );
  }
}
