import {
  ClaudePrompt,
  EmailTemplate,
} from "@freightsimple/generated-apollo-openapi-client";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useTechnicalApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { AddClaudePromptButton } from "./AddClaudePromptButton";

interface ClaudePromptsTableProps {
  onRefresh: () => Promise<void>;
  claudePrompts: ClaudePrompt[];
}

export function ClaudePromptsTable(props: ClaudePromptsTableProps) {
  const columns: DataTableColumn<ClaudePrompt>[] = [];

  columns.push({
    title: "Title",
    render: (o) => <div>{o.title}</div>,
  });

  columns.push({
    title: "Identifier",
    render: (o) => <div>{o.identifier}</div>,
  });

  columns.push({
    title: "Actions",
    render: (o) => (
      <ButtonRow>
        <AddClaudePromptButton claudePrompt={o} onRefresh={props.onRefresh} />
      </ButtonRow>
    ),
  });

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.claudePrompts}
    />
  );
}

export function ClaudePromptsScreen() {
  const [claudePrompts, setClaudePrompts] = useState<EmailTemplate[]>();
  const [errorHappened, setErrorHappened] = useState(false);
  const createTechnicalApi = useTechnicalApi();

  async function refresh() {
    try {
      const technicalApi = await createTechnicalApi();
      const response = await technicalApi.getAllClaudePrompts();
      setClaudePrompts(response);
    } catch (e) {
      message.error(`Something went wrong : ${e}`);
      setErrorHappened(true);
    }
  }

  useEffect(
    function () {
      refresh();
    },

    [],
  );

  return (
    <>
      <Page
        title={`Claude Prompts`}
        tags={[]}
        stats={[]}
        extra={[
          <ButtonRow key="add">
            <AddClaudePromptButton onRefresh={refresh} />
          </ButtonRow>,
        ]}
      >
        <PageTitle>{`Claude Prompts`}</PageTitle>
        {errorHappened && <div>❌ Ooops error happened</div>}
        {claudePrompts !== undefined && (
          <ClaudePromptsTable
            claudePrompts={claudePrompts}
            onRefresh={refresh}
          />
        )}
      </Page>
    </>
  );
}
