import {
  CustomerContactsTabData,
  NameEmailPhone,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";
import { ReactNode } from "react";
import { EmailLink } from "../../Components/EmailLink";
import { KeyValues } from "../../Components/KeyValues";
import { PhoneLink } from "../../Components/PhoneLink";
import Stack from "../../Components/Stack";
import { WithClipboardLink } from "../../Components/WithClipboardLink";
import { formatPhoneNumberWithExtension } from "../../Helpers/formatPhoneNumber";
import { ChangeUserButton } from "./ChangeUserButton";
import { ChangeUserRole } from "./ChangeUserRole";
import { EditEmergencyContactButton } from "./EditEmergencyContactButton";
import { TabProps } from "./TabProps";

interface CustomerContactsProps extends TabProps {
  data: CustomerContactsTabData;
}

interface CustomerContactProps {
  contact: NameEmailPhone;
  extra: ReactNode | undefined;
  shipmentData: ViewShipmentDataForApollo;
}

function CustomerContact(props: CustomerContactProps) {
  return (
    <Stack align="left">
      <WithClipboardLink>{props.contact.name}</WithClipboardLink>
      <PhoneLink
        phone={props.contact.phone}
        shipmentId={props.shipmentData.shipmentId}
        description={props.contact.name}
      />
      {props.contact.email && (
        <EmailLink email={props.contact.email} data={props.shipmentData} />
      )}
      {props.extra}
    </Stack>
  );
}

export function CustomerContacts(props: CustomerContactsProps) {
  const data: Record<string, any> = {};
  const contacts = props.data;

  function add(
    name: string,
    contact: NameEmailPhone | null | undefined,
    extra?: ReactNode,
  ) {
    if (
      contact &&
      (contact.email !== "" || contact?.name !== "" || contact.phone !== "")
    ) {
      data[name] = (
        <CustomerContact
          contact={contact}
          extra={extra}
          shipmentData={props.shipmentData}
        />
      );
    }
  }

  add(
    "Quoter",
    contacts.quoter,
    <ChangeUserButton
      contact={contacts.quoter}
      role={ChangeUserRole.Quoter}
      {...props}
    />,
  );
  add(
    "Booker",
    contacts.booker,
    contacts.booker ? (
      <ChangeUserButton
        contact={contacts.booker}
        role={ChangeUserRole.Booker}
        {...props}
      />
    ) : (
      <></>
    ),
  );
  add("Pickup", contacts.pickup);
  add("Delivery", contacts.delivery);

  if (contacts.emergency) {
    add(
      "Dangerous Goods\nEmergency",
      {
        phone: formatPhoneNumberWithExtension(
          contacts.emergency.phoneNumber,
          contacts.emergency.phoneNumberExtension,
          "#",
        ),
        name: contacts.emergency.contactName ?? "-",
      },
      <EditEmergencyContactButton
        onRefresh={async function () {
          await props.onRefresh();
        }}
        shipmentId={props.shipmentData.shipmentId}
        companyId={props.shipmentData.shipment.company.companyId!}
        contact={contacts.emergency}
      />,
    );
  }

  return <KeyValues data={data} />;
}
