import {
  ShipmentDocument,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";

export function lookupShipmentDocumentsFromIds(
  shipmentData: ViewShipmentDataForApollo,
  documentsIds: string[] | undefined,
): ShipmentDocument[] | undefined {
  if (documentsIds === undefined) {
    return undefined;
  }

  return documentsIds
    .map(function (documentId) {
      const document = shipmentData.documentsTab.documents.find(
        (d) => d.shipmentDocument.shipmentDocumentId === documentId,
      )?.shipmentDocument;
      return document;
    })
    .filter((o) => o) as ShipmentDocument[];
}
