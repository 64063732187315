import {
  DatePicker,
  Form,
  MenuItemProps,
  message,
  Modal,
  Tooltip,
  Typography,
} from "antd";

import { useState } from "react";

import { useShipmentsApi } from "../Apis/Apis";
import HorizontalStack from "../Components/HorizontalStack";
import Stack from "../Components/Stack";
import { daysAgo } from "../Helpers/daysAgo";
import { isBlank } from "@freightsimple/shared";
import Spacer from "../Spacer";
import dayjs from "dayjs";
import { MenuItem, TextArea } from "../Types/AntHelperTypes";

const { Text } = Typography;

interface SnoozeInvoiceAuditMenuItemProps extends MenuItemProps {
  shipmentId: string;
  currentSnoozeDate: string | undefined;
  onRefresh: () => Promise<void>;
}

export function SnoozeInvoiceAuditMenuItem(
  props: SnoozeInvoiceAuditMenuItemProps,
) {
  function defaultSnoozeDate(): dayjs.Dayjs | undefined {
    // If the snooze was set in the past - or it's not set
    // then suggest tomorrow
    if (
      props.currentSnoozeDate === undefined ||
      daysAgo(props.currentSnoozeDate) > 0
    ) {
      return dayjs().add(1, "days");
    } else {
      // Else use the current snooze date
      return dayjs(props.currentSnoozeDate);
    }
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentsApi = useShipmentsApi();
  const [snoozeUntilDateMoment, setSnoozeUntilDateMoment] = useState<
    dayjs.Dayjs | undefined
  >(defaultSnoozeDate());
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (snoozeUntilDateMoment === undefined) {
      message.warning("Pick a date");
      return;
    }
    if (snoozeUntilDateMoment !== undefined && isBlank(note)) {
      message.warning("If snoozing, you must leave a note explaining why");
      return;
    }

    if (daysAgo(snoozeUntilDateMoment.format("YYYY-MM-DD")) >= 0) {
      message.warning("Can only snooze into the future");
      return;
    }

    setLoading(true);
    try {
      const shipmentId = props.shipmentId;
      const snoozeUntilDate = snoozeUntilDateMoment?.format("YYYY-MM-DD");

      const shipmentsApi = await createShipmentsApi();

      await shipmentsApi.snoozeInvoiceAuditUntil({
        shipmentId,
        snoozeInvoiceAuditUntilDate: snoozeUntilDate,
        note,
      });
      await props.onRefresh();
      message.success(`Invoice audit snoozed until ${snoozeUntilDate}`);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Snooze Invoice Audit"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        destroyOnClose
        confirmLoading={loading}
      >
        <Stack align="left">
          <Text>
            This allows you to snooze an invoice audit. Snooze will be cancelled
            if there is a reply on the customer/carrier audit email threads
          </Text>
          <Spacer height={32} />
          <HorizontalStack>
            <Form
              style={{ width: "700px" }}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 20, offset: 2 }}
              layout="horizontal"
              colon={false}
            >
              <Form.Item label="Note">
                <TextArea
                  style={{ width: "440px", height: "150px" }}
                  value={note}
                  onChange={function (e) {
                    setNote(e.target.value);
                  }}
                  placeholder="Enter the snooze reason here"
                />
              </Form.Item>
              <Form.Item label="Snooze Until">
                <DatePicker
                  style={{ width: "200px" }}
                  value={snoozeUntilDateMoment}
                  onChange={function (m) {
                    if (m !== null) {
                      setSnoozeUntilDateMoment(m);
                    } else {
                      setSnoozeUntilDateMoment(undefined);
                    }
                  }}
                  allowClear
                />
              </Form.Item>
            </Form>
          </HorizontalStack>
        </Stack>
      </Modal>
      <Tooltip
        placement="left"
        overlay="This will snooze invoice audit until a given date"
      >
        <MenuItem onClick={showModal} {...props}>
          ⏰ Snooze Invoice Audit
        </MenuItem>
      </Tooltip>
    </>
  );
}
