import { ColumnsType } from "../Types/AntHelperTypes";
import type { TableProps } from "antd";

// Use this to make sure exceptions don't leak beyond a single render function
// for a table cell

export function safeColumns<RecordType>(
  columns: ColumnsType<RecordType>,
): ColumnsType<RecordType> {
  type RenderFunction<T> = (
    value: T,
    record: RecordType,
    index: number,
  ) => React.ReactNode | TableProps<RecordType>;

  function safetyWrapper<T>(
    fn: RenderFunction<T> | undefined,
  ): RenderFunction<T> | undefined {
    return function (value: T, record: RecordType, index: number) {
      if (fn === undefined) {
        return undefined;
      }
      try {
        return fn(value, record, index);
      } catch (e) {
        console.error(`‼️ Error : ${e}`);
        return <>‼️ Error</>;
      }
    };
  }

  return columns.map(function (value) {
    return { ...value, render: safetyWrapper(value.render) };
  });
}
