import {
  ApolloMapQuery,
  ApolloMapQueryBookingStatus,
  ShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import { FilterOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Tabs } from "antd";
import { useState } from "react";
import { CarrierFilter } from "../../Components/CarrierFilter";
import Colors from "../../Components/Colors";
import { DateRangePicker } from "../../Components/DateRangePicker";
import { GeoFilterDropdown } from "../../Components/GeoFilterDropdown";
import HorizontalStack from "../../Components/HorizontalStack";
import { ShipmentStateDropdown } from "../../Components/ShipmentStateDropdown";
import { TriStateBoolean } from "../../Components/TriStateBoolean";
import Spacer from "../../Spacer";
import { CompaniesDropdown } from "../MapScreenComponents/CompaniesDropdown";
import { LocationDropdown } from "../MapScreenComponents/LocationDropdown";
import { ShipmentFrequencyDropdown } from "../ViewCompanyScreenComponents/ShipmentFrequencyDropdown";
import { QuoteFilterDropdown } from "../ViewQuotesScreen";
import { LocationTypeDropdown } from "../ViewShipmentScreenComponents/LocationTypeDropdown";
import { assertNever } from "../../Helpers/assertNever";

// The regular input number seems to lose the value when we click away
// Not seen that before, so build a new one for this screen

interface InputNumProps {
  value: number | undefined | null;
  onChange: (_: number) => void;
}
function InputNum(props: InputNumProps) {
  return (
    <Input
      value={props.value?.toString()}
      onChange={function (e) {
        const newValue = parseInt(e.target.value);
        props.onChange(newValue);
      }}
      style={{ width: 90 }}
    />
  );
}

interface AllShipmentsScreenFilterButtonProps {
  shipments: ShipmentReport[];
  query: ApolloMapQuery;
  setQuery: (_: ApolloMapQuery) => void;
}

const formProps = {
  layout: "horizontal" as const,
  labelCol: { span: 8, offset: 1 },
  wrapperCol: { span: 12, offset: 1 },
  colon: true,
  style: { marginTop: "24px", width: "100%" },
};

export function AllShipmentsScreenFilterButton(
  props: AllShipmentsScreenFilterButtonProps,
) {
  const { shipments } = props;
  const total = shipments.length;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [query, setQuery] = useState({ ...props.query });

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    props.setQuery(query);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function label() {
    switch (query.bookingStatus) {
      case ApolloMapQueryBookingStatus.Booked:
        return `Shipments (${total})`;
      case ApolloMapQueryBookingStatus.NotBooked:
        return `Quotes (${total})`;
      case ApolloMapQueryBookingStatus.Any:
        return `Shipments & Quotes (${total})`;
      default:
        assertNever(query.bookingStatus);
    }
  }

  return (
    <>
      <Modal
        title={null}
        visible={isModalVisible}
        onOk={handleOk}
        okText="Filter"
        cancelText="Cancel"
        onCancel={handleCancel}
        width={800}
        bodyStyle={{ padding: "24px 32px" }}
        footer={
          <div
            style={{
              padding: "16px 32px",
              marginTop: 0,
              borderTop: "1px solid #f0f0f0",
            }}
          >
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" onClick={handleOk}>
              Filter
            </Button>
          </div>
        }
      >
        <Tabs defaultActiveKey="general" style={{ marginTop: -8 }}>
          <Tabs.TabPane tab="General" key="general">
            <Form {...formProps}>
              <Form.Item
                label="Free Text Search"
                help={
                  <div
                    style={{
                      fontSize: "10px",
                      color: Colors.LightText,
                      marginBottom: "24px",
                      marginTop: "4px",
                    }}
                  >
                    Search any field e.g. address, business names, contacts,
                    reference numbers, commodity information
                  </div>
                }
              >
                <Input
                  placeholder="Enter search terms..."
                  value={query.freeTextQuery ?? undefined}
                  onChange={function (e) {
                    setQuery({
                      ...query,
                      freeTextQuery: e.target.value,
                    });
                  }}
                  allowClear
                />
              </Form.Item>

              <Form.Item label="Include Only Booked?">
                <Select
                  value={query.bookingStatus}
                  style={{ width: 220 }}
                  onChange={function (_bookingStatus) {
                    setQuery({
                      ...query,
                      bookingStatus: _bookingStatus,
                    });
                  }}
                >
                  <Select.Option value={ApolloMapQueryBookingStatus.Booked}>
                    Booked
                  </Select.Option>
                  ;
                  <Select.Option value={ApolloMapQueryBookingStatus.NotBooked}>
                    Just Quoted
                  </Select.Option>
                  ;
                  <Select.Option value={ApolloMapQueryBookingStatus.Any}>
                    Booked &amp; Quoted
                  </Select.Option>
                  ;
                </Select>
              </Form.Item>

              <Form.Item label="Shipment State">
                <ShipmentStateDropdown
                  value={query.shipmentState || undefined}
                  setValue={function (_shipmentState) {
                    setQuery({
                      ...query,
                      shipmentState: _shipmentState,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label="Quote Filter">
                <QuoteFilterDropdown
                  value={query.quoteFilter || undefined}
                  setValue={function (_quoteFilter) {
                    setQuery({
                      ...query,
                      quoteFilter: _quoteFilter,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label="Time Period">
                <DateRangePicker
                  startDate={query.startDate}
                  endDate={query.endDate}
                  onClear={function () {
                    setQuery({
                      ...query,
                      startDate: undefined,
                      endDate: undefined,
                    });
                  }}
                  onChange={function (startDate, endDate) {
                    setQuery({
                      ...query,
                      startDate: startDate,
                      endDate: endDate,
                    });
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Company Options" key="company">
            <Form {...formProps}>
              <Form.Item label="Company">
                <CompaniesDropdown
                  companyId={query.companyId}
                  setCompanyId={function (_companyId) {
                    setQuery({
                      ...query,
                      companyId: _companyId,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label="Shipment Frequency">
                <ShipmentFrequencyDropdown
                  value={query.companyShipmentFrequency || undefined}
                  onChange={function (_frequency) {
                    setQuery({
                      ...query,
                      companyShipmentFrequency: _frequency,
                    });
                  }}
                  allowClear
                />
              </Form.Item>

              <Form.Item label="Company First Quote">
                <DateRangePicker
                  startDate={query.companyFirstQuotedDateStartDate}
                  endDate={query.companyFirstQuotedDateEndDate}
                  allowClear
                  onClear={function () {
                    setQuery({
                      ...query,
                      companyFirstQuotedDateStartDate: undefined,
                      companyFirstQuotedDateEndDate: undefined,
                    });
                  }}
                  onChange={function (startDate, endDate) {
                    setQuery({
                      ...query,
                      companyFirstQuotedDateStartDate: startDate,
                      companyFirstQuotedDateEndDate: endDate,
                    });
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Location" key="location">
            <Form {...formProps}>
              <Form.Item label="Geo Filter">
                <GeoFilterDropdown
                  value={query.geoFilter || undefined}
                  setValue={function (_geoFilter) {
                    setQuery({ ...query, geoFilter: _geoFilter });
                  }}
                />
              </Form.Item>
              <Form.Item label="Pickup Location">
                <LocationDropdown
                  locationFilterIdentifier={
                    query.pickupLocationFilterIdentifier
                  }
                  setLocationFilterIdentifier={function (
                    _locationFilterIdentifier,
                  ) {
                    setQuery({
                      ...query,
                      pickupLocationFilterIdentifier: _locationFilterIdentifier,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Pickup Location Type">
                <LocationTypeDropdown
                  value={query.pickupLocationType || undefined}
                  setValue={function (_locationType) {
                    setQuery({
                      ...query,
                      pickupLocationType: _locationType,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Pickup City">
                <Input
                  value={query.pickupCity ?? undefined}
                  onChange={function (e) {
                    setQuery({
                      ...query,
                      pickupCity: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Delivery Location">
                <LocationDropdown
                  locationFilterIdentifier={
                    query.deliveryLocationFilterIdentifier
                  }
                  setLocationFilterIdentifier={function (
                    _locationFilterIdentifier,
                  ) {
                    setQuery({
                      ...query,
                      deliveryLocationFilterIdentifier:
                        _locationFilterIdentifier,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Delivery Location Type">
                <LocationTypeDropdown
                  value={query.deliveryLocationType || undefined}
                  setValue={function (_locationType) {
                    setQuery({
                      ...query,
                      deliveryLocationType: _locationType,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Delivery City">
                <Input
                  value={query.deliveryCity ?? undefined}
                  onChange={function (e) {
                    setQuery({
                      ...query,
                      deliveryCity: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Shipment Details" key="shipment">
            <Form {...formProps}>
              <Form.Item label="Shipment Weight">
                <HorizontalStack>
                  <div>Min:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={props.query.minimumShipmentWeight}
                    onChange={function (newValue) {
                      setQuery({
                        ...props.query,
                        minimumShipmentWeight: newValue,
                      });
                    }}
                  />

                  <Spacer width={8} />
                  <div>Max:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={props.query.maximumShipmentWeight}
                    onChange={function (newValue) {
                      setQuery({
                        ...props.query,
                        maximumShipmentWeight: newValue,
                      });
                    }}
                  />
                </HorizontalStack>
              </Form.Item>
              <Form.Item label="Handling Units">
                <HorizontalStack>
                  <div>Min:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={props.query.minimumNumberHandlingUnits}
                    onChange={function (newValue) {
                      setQuery({
                        ...props.query,
                        minimumNumberHandlingUnits: newValue,
                      });
                    }}
                  />

                  <Spacer width={8} />
                  <div>Max:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={props.query.maximumNumberHandlingUnits}
                    onChange={function (newValue) {
                      setQuery({
                        ...props.query,
                        maximumNumberHandlingUnits: newValue,
                      });
                    }}
                  />
                </HorizontalStack>
              </Form.Item>
              <Form.Item label="Volume">
                <HorizontalStack>
                  <div>Min:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={props.query.minimumVolume}
                    onChange={function (newValue) {
                      setQuery({
                        ...props.query,
                        minimumVolume: newValue,
                      });
                    }}
                  />

                  <Spacer width={8} />
                  <div>Max:</div>
                  <Spacer width={8} />
                  <InputNum
                    value={props.query.maximumVolume}
                    onChange={function (newValue) {
                      setQuery({
                        ...props.query,
                        maximumVolume: newValue,
                      });
                    }}
                  />
                </HorizontalStack>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Carrier Options" key="carrier">
            <Form {...formProps}>
              <Form.Item label={<>In Carrier Coverage Area</>}>
                <CarrierFilter
                  carrierIdentifier={
                    query.inCarrierCoverageAreaCarrierIdentifier
                  }
                  setCarrierIdentifier={function (_carrierIdentifier) {
                    setQuery({
                      ...query,
                      inCarrierCoverageAreaCarrierIdentifier:
                        _carrierIdentifier,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label={<>Booked Carrier</>}>
                <CarrierFilter
                  carrierIdentifier={query.bookedCarrierIdentifier}
                  setCarrierIdentifier={function (_carrierIdentifier) {
                    setQuery({
                      ...query,
                      bookedCarrierIdentifier: _carrierIdentifier,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label={<>Had quotes from carrier</>}>
                <CarrierFilter
                  carrierIdentifier={query.hadQuotesFromCarrierIdentifier}
                  setCarrierIdentifier={function (_carrierIdentifier) {
                    setQuery({
                      ...query,
                      hadQuotesFromCarrierIdentifier: _carrierIdentifier,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label={<>Lowest Rate Carrier</>}>
                <CarrierFilter
                  carrierIdentifier={query.lowestRateCarrierIdentifier}
                  setCarrierIdentifier={function (_carrierIdentifier) {
                    setQuery({
                      ...query,
                      lowestRateCarrierIdentifier: _carrierIdentifier,
                    });
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Accessorials" key="accessorials">
            <Form {...formProps}>
              <Form.Item label={<>Liftgate Pickup Required</>}>
                <TriStateBoolean
                  value={query.pickupLiftGateRequired || undefined}
                  setValue={function (v) {
                    setQuery({
                      ...query,
                      pickupLiftGateRequired: v,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Liftgate Delivery Required</>}>
                <TriStateBoolean
                  value={query.deliveryLiftGateRequired || undefined}
                  setValue={function (v) {
                    setQuery({
                      ...query,
                      deliveryLiftGateRequired: v,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Inside Pickup Required</>}>
                <TriStateBoolean
                  value={query.pickupInsideRequired || undefined}
                  setValue={function (v) {
                    setQuery({
                      ...query,
                      pickupInsideRequired: v,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Inside Delivery Required</>}>
                <TriStateBoolean
                  value={query.deliveryInsideRequired || undefined}
                  setValue={function (v) {
                    setQuery({
                      ...query,
                      deliveryInsideRequired: v,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Dangerous Goods</>}>
                <TriStateBoolean
                  value={query.dangerous || undefined}
                  setValue={function (v) {
                    setQuery({
                      ...query,
                      dangerous: v,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Protect from Freezing</>}>
                <TriStateBoolean
                  value={query.protectFromFreezingRequired || undefined}
                  setValue={function (v) {
                    setQuery({
                      ...query,
                      protectFromFreezingRequired: v,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label={<>Delivery Appointment Required</>}>
                <TriStateBoolean
                  value={query.deliveryAppointmentRequired || undefined}
                  setValue={function (v) {
                    setQuery({
                      ...query,
                      deliveryAppointmentRequired: v,
                    });
                  }}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
      <Button
        icon={<FilterOutlined />}
        onClick={showModal}
        type="link"
        style={{ paddingLeft: "4px", paddingRight: "4px" }}
      >
        {label()}
      </Button>
    </>
  );
}
