import {
  Note,
  NoteLevel,
} from "@freightsimple/generated-apollo-openapi-client";
import { assertNever } from "../../Helpers/assertNever";
import Colors from "../Colors";

export function colorForNoteIcon(note: Note): string {
  const level = note.noteLevel!;
  switch (level) {
    case NoteLevel.Info:
      return Colors.Blue;
    case NoteLevel.Warning:
      return Colors.Gold;
    case NoteLevel.Error:
      return Colors.Red;
    default:
      assertNever(level);
  }
}
