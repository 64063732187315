import { Spin } from "antd";
import Spacer from "../Spacer";
import Stack from "./Stack";

interface LoadingProps {
  message?: string;
}

export function Loading(props: LoadingProps) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        minHeight: "600px",
      }}
    >
      <Stack align="center">
        {props.message && (
          <>
            <div>{props.message}</div>
            <Spacer height={16} />
          </>
        )}

        <Spin size="large" />
      </Stack>
    </div>
  );
}
