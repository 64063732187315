import { ReactNode } from "react";
import { useRefreshFunction } from "../../Hooks/useRefreshFunction";
import { CallRefreshContext } from "./CallRefreshContext";

export function CallRefreshContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { isRefreshing, setRefresh, refresh } = useRefreshFunction();

  return (
    <CallRefreshContext.Provider value={{ isRefreshing, setRefresh, refresh }}>
      {children}
    </CallRefreshContext.Provider>
  );
}
