import { HTMLAttributes } from "react";
import Colors from "../Colors";

export function NoteTag({
  children,
  style,
  ...rest
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        marginTop: "5px",
        fontSize: "12px",
        paddingBlock: "2px",
        paddingInline: "12px",
        color: Colors.Palette.Green[600],
        background: Colors.Palette.Green[50],
        borderRadius: "5px",
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}
