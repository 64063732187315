import { CurrencyCode } from "@freightsimple/generated-apollo-openapi-client";
import { NumberInput } from "./NumberInput";

interface CurrencyInputProps {
  amount: number;
  setAmount: (_: number) => void;
  currency: CurrencyCode;
}

export function CurrencyInput(props: CurrencyInputProps) {
  return (
    <NumberInput
      prefix={`${props.currency}$`}
      value={props.amount}
      setValue={props.setAmount}
      style={{ width: "250px" }}
    />
  );
}
