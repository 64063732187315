import { Button } from "antd";

import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { UrlMenuItem } from "./UrlMenuItem";
import { ButtonType } from "../Types/AntHelperTypes";

interface ViewCompanyButtonProps {
  companyId: string;
  tab?: string;
  startDate?: string;
  endDate?: string;
  openInNewTab?: boolean;
  type?: ButtonType;
  style?: CSSProperties;
}

export function ViewCompanyButton(props: ViewCompanyButtonProps) {
  let url = `/view-company?companyId=${props.companyId}`;
  if (props.tab) {
    url += `&tab=${props.tab}`;
  }
  if (props.startDate) {
    url += `&startDate=${props.startDate}`;
  }
  if (props.endDate) {
    url += `&endDate=${props.endDate}`;
  }

  function onClick() {
    window.open(url);
  }

  if (props.openInNewTab !== false) {
    return (
      <Button
        type={props.type ?? "primary"}
        onClick={onClick}
        style={props.style}
      >
        🏭 View Company
      </Button>
    );
  } else {
    return (
      <Link to={url}>
        <Button type={props.type ?? "primary"} style={props.style}>
          🏭 View Company
        </Button>
      </Link>
    );
  }
}

export function ViewCompanyMenuItem(props: ViewCompanyButtonProps) {
  const url = `/view-company?companyId=${props.companyId}`;

  return <UrlMenuItem url={url} title="🏭 View Company" />;
}
