import {
  EmailDescription,
  InTransitSupportEmailType,
  ShipmentState,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";

import { message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import { SupportAgent } from "../ViewCompanyScreen";
import { EnumDropdown } from "./EnumDropdown";
import { ExtraTextButton } from "./ExtraTextButton";
import {
  SupportEmailBodyExtraItem,
  SupportEmailButton,
} from "./SupportEmailButton";

export const InTransitSupportEmailTypeDropdown = EnumDropdown<
  InTransitSupportEmailType,
  typeof InTransitSupportEmailType
>(InTransitSupportEmailType, undefined, 300);

interface InTransitSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
}

export function InTransitSupportEmailButton(
  props: InTransitSupportEmailButtonProps,
) {
  const shipmentId = props.data.shipmentId;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");
  const [inTransitSupportEmailType, setInTransitSupportEmailType] = useState(
    InTransitSupportEmailType.CarrierCouldNotContactConsignee,
  );

  const state = props.data.shipment.shipment.state;

  if (state !== ShipmentState.InTransit) {
    return <></>;
  }

  const input = {
    extraText,
    inTransitSupportEmailType,
  };

  async function onFetchPreview(
    supportAgent: SupportAgent,
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewInTransitEmail({
        ...input,
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[],
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendInTransitEmail({
        ...input,
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="In Transit Emails"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={JSON.stringify(input)}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
          <SupportEmailBodyExtraItem label="Type">
            <InTransitSupportEmailTypeDropdown
              value={inTransitSupportEmailType}
              setValue={setInTransitSupportEmailType}
            />
          </SupportEmailBodyExtraItem>
        </>
      }
      shipmentData={props.data}
    />
  );
}
