import {
  Quote,
  Shipment,
} from "@freightsimple/generated-apollo-openapi-client";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Tag, Tooltip } from "antd";
import { LineItemsChangesTable } from "../../Components/changes/LineItemsChangesTable";
import Colors from "../../Components/Colors";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import { WarningComponent } from "../../Components/WarningComponent";
import { describeEquipmentType } from "../../Helpers/describeEquipmentType";
import {
  getQuoteShipmentKeyDifferences,
  QuoteShipmentKeyDifferences,
} from "../../Helpers/getQuoteShipmentKeyDifferences";
import { ObjectDifference } from "../../Helpers/objectDifferences";
import { useDisplay, UseDisplayReturn } from "../../Hooks/useDisplay";
import Spacer from "../../Spacer";
import { describeAccessorials } from "./describeAccessorials";
import { Title } from "../../Types/AntHelperTypes";

interface QuoteShipmentDifferencesProps {
  quote: Quote;
  shipment: Shipment;
  differences: QuoteShipmentKeyDifferences;
  display: UseDisplayReturn;
}

export function QuoteShipmentDifferencesButton({
  shipment,
  quote,
}: {
  shipment: Shipment;
  quote: Quote;
}) {
  const differences = getQuoteShipmentKeyDifferences(quote, shipment);
  const display = useDisplay();
  if (differences.totalDifferencesCount > 0) {
    return (
      <Tooltip title="There are important differences between this quote and the shipment">
        <Button ghost onClick={display.show}>
          <WarningComponent marginRight={4} />
          {differences.totalDifferencesCount}
        </Button>
        <QuoteShipmentDifferencesModal
          quote={quote}
          shipment={shipment}
          differences={differences}
          display={display}
        />
      </Tooltip>
    );
  }
  return null;
}

/**
 * Renders modal showing the differences between a quote and a shipment
 *
 * @param shipment - a Shipment object
 * @param quote - a Quote object
 * @param differences - The differences between the quote and the shipment
 *
 * @example
 * <QuoteShipmentDifferences
 *   shipment={shipment}
 *   quote={quote}
 * />
 */
export function QuoteShipmentDifferencesModal({
  quote,
  shipment,
  differences,
  display,
}: QuoteShipmentDifferencesProps) {
  const {
    pickupDifferences,
    deliveryDifferences,
    generalDifferences,
    lineItemsDifferences: { changes, deletions, additions },
  } = differences;

  const lineItemsChanges = changes.length;
  const lineItemsAdditions = additions.length;
  const lineItemsDeletions = deletions.length;

  const lineItemsKeyChanges =
    lineItemsChanges + lineItemsAdditions + lineItemsDeletions;

  const columns: DataTableColumn<ObjectDifference>[] = [
    {
      title: "",
      render: (row) => (
        <span style={{ fontWeight: "bold" }}>{row.description}</span>
      ),
    },
    {
      title: "Quote",
      render: (row) => {
        if (row.description === "Accessorials") {
          if (Array.isArray(row.left)) {
            return describeAccessorials(row.left);
          }
        }

        if (row.key === "equipmentType") {
          return describeEquipmentType(row.left);
        }

        return <span style={{ color: Colors.Palette.Gray[800] }}>{row.left}</span>;
      },
    },
    {
      title: "Shipment",
      render: (row) => {
        if (row.description === "Accessorials") {
          if (Array.isArray(row.right)) {
            return describeAccessorials(row.right);
          }
        }

        if (row.key === "equipmentType") {
          return describeEquipmentType(row.right);
        }

        return <span style={{ color: Colors.Palette.Gray[800] }}>{row.right}</span>;
      },
    },
  ];

  return (
    <Modal
      width={1800}
      title="Quote VS Shipment"
      open={display.status}
      onOk={display.toggle}
      onCancel={display.hide}
    >
      <div
        style={{
          display: "flex",
          columnGap: "20px",
          marginBottom: "20px",
          flexGrow: 1,
        }}
      >
        {generalDifferences.length > 0 && (
          <Card style={{ width: "50%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Title level={4}>General</Title>
              <span style={{ color: Colors.LightText }}>
                {generalDifferences.length}{" "}
                {generalDifferences.length > 1 ? "differences" : "difference"}
              </span>
            </div>

            <DataTable
              pagination={false}
              columns={columns}
              data={generalDifferences}
            />
          </Card>
        )}

        {pickupDifferences.length > 0 && (
          <Card style={{ width: "50%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Title level={4}>Pickup Location</Title>
              <span style={{ color: Colors.LightText }}>
                {pickupDifferences.length}{" "}
                {pickupDifferences.length > 1 ? "differences" : "difference"}
              </span>
            </div>

            <DataTable
              pagination={false}
              columns={columns}
              data={pickupDifferences}
            />
          </Card>
        )}

        {deliveryDifferences.length > 0 && (
          <Card style={{ width: "50%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Title level={4}>Delivery Location</Title>
              <span style={{ color: Colors.LightText }}>
                {deliveryDifferences.length}{" "}
                {deliveryDifferences.length > 1 ? "differences" : "difference"}
              </span>
            </div>
            <DataTable
              pagination={false}
              columns={columns}
              data={deliveryDifferences}
            />
          </Card>
        )}
      </div>

      {lineItemsKeyChanges > 0 && (
        <Card>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: "10px",
              marginBottom: "10px",
            }}
          >
            <HorizontalStack
              style={{
                columnGap: "10px",
                marginBottom: "10px",
              }}
            >
              <Title level={4} style={{ marginTop: "10px" }}>
                Line Items
              </Title>
              <Spacer width={8} />
              <Tag color="orange">Quote</Tag>
              <CloseOutlined />
              <Tag color="green">Shipment</Tag>
            </HorizontalStack>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                marginBottom: "10px",
              }}
            >
              {lineItemsChanges > 0 && (
                <Tag color="orange">
                  {lineItemsChanges} significant{" "}
                  {lineItemsChanges > 1 ? "changes were" : "change was"} made to
                  the items
                </Tag>
              )}
              {lineItemsDeletions > 0 && (
                <Tag color="red">
                  The shipment now has {lineItemsDeletions} less{" "}
                  {lineItemsDeletions > 1 ? "items" : "item"}
                </Tag>
              )}
              {lineItemsAdditions > 0 && (
                <Tag color="green">
                  The shipment now has {lineItemsAdditions} more{" "}
                  {lineItemsAdditions > 1 ? "items" : "item"}
                </Tag>
              )}
              <span style={{ color: Colors.LightText }}>
                {lineItemsKeyChanges}{" "}
                {lineItemsKeyChanges > 1 ? "differences" : "difference"}
              </span>
            </div>
          </div>
          <LineItemsChangesTable
            inBetween={<CloseOutlined />}
            left={quote.lineItems!}
            right={shipment.lineItems!}
            data={
              shipment.lineItems!.length > quote.lineItems!.length
                ? shipment.lineItems
                : quote.lineItems
            }
          />
        </Card>
      )}
    </Modal>
  );
}
