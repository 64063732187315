import { Select } from "antd";

interface TemperatureDropdownProps {
  value: string | undefined;
  onChange: (_: string | undefined) => void;
  allowClear?: boolean;
  hideCold?: boolean;
  hideDead?: boolean;
}

export function TemperatureDropdown(props: TemperatureDropdownProps) {
  const options = ["🔥 Hot", "🌤 Warm"];
  if (props.hideCold !== true) {
    options.push("🥶 Cold");
  }

  if (props.hideDead !== true) {
    options.push("☠️ Dead");
  }
  return (
    <Select
      style={{ width: "200px" }}
      value={props.value}
      onChange={props.onChange}
      allowClear={props.allowClear}
    >
      {options.map(function (o) {
        return <Select.Option key={o}>{o}</Select.Option>;
      })}
      <Select.Option key={undefined}>Not selected</Select.Option>
    </Select>
  );
}
