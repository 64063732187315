import {
  ChangePickupDateReason,
  InterlineContext,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import { KeyValues } from "../../Components/KeyValues";
import Stack from "../../Components/Stack";
import { assertNever } from "../../Helpers/assertNever";
import { isDateInThePast } from "../../Helpers/isDateInThePast";
import { useDisplay } from "../../Hooks/useDisplay";
import Spacer from "../../Spacer";
import { BookPickupButton } from "./BookPickupButton";
import { CancelShipmentButton } from "./CancelShipmentButton";
import { EditInterlineInfoMenuButton } from "./EditInterlineInfoMenuButton";
import { OptionalEnumDropdown } from "./EnumDropdown";
import { InterlineCarrier } from "./InterlineCarrier";
import { MarkPickupConfirmedMenuItem } from "./MarkPickupConfirmedMenuItem";
import { PreviewPickupEmailMenuItem } from "./PreviewPickupEmailMenuItem";
import { PutShipmentOnHoldButton } from "./PutShipmentOnHoldButton";
import { TabProps } from "./TabProps";
import { ViewCarrierPickupNumberHistoryMenuItem } from "./ViewCarrierPickupNumberHistoryMenuItem";
import { ViewProNumberHistoryMenuItem } from "./ViewProNumberHistoryMenuItem";
import dayjs from "dayjs";

const { Text } = Typography;

function UpdateCarrierPickupNumber(props: TabProps) {
  const createShipmentsApi = useShipmentsApi();
  const [carrierPickupNumber, setCarrierPickupNumber] = useState(
    props.shipmentData.shipment.shipment.carrierPickupNumber,
  );

  useEffect(
    function () {
      setCarrierPickupNumber(
        props.shipmentData.shipment.shipment.carrierPickupNumber,
      );
    },

    [JSON.stringify(props)],
  );

  const [loading, setLoading] = useState(false);
  const isDirty =
    carrierPickupNumber !==
      props.shipmentData.shipment.shipment.carrierPickupNumber &&
    props.shipmentData.shipment.shipment.bookedAt !== undefined;

  async function onUpdate() {
    setLoading(true);
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.changeCarrierPickupNumber({
        shipmentId: props.shipmentData.shipment.shipment.shipmentId!,
        carrierPickupNumber: carrierPickupNumber || "",
      });
      await props.onRefresh();

      message.success(`Carrier Pickup Number changed`);
    } catch {
      message.error("Oops");
    }
    setLoading(false);
  }

  return (
    <Tooltip
      placement="topLeft"
      title="Most carriers will provide a carrier number"
    >
      <Input.Group compact>
        <Input
          style={{ width: "calc(100% - 150px)" }}
          value={carrierPickupNumber}
          onChange={function (e) {
            setCarrierPickupNumber(e.target.value);
          }}
        />
        <Button
          type="primary"
          disabled={!isDirty}
          onClick={onUpdate}
          loading={loading}
        >
          Update
        </Button>
      </Input.Group>
    </Tooltip>
  );
}

function UpdateProNumber(props: TabProps) {
  const createShipmentsApi = useShipmentsApi();
  const [proNumber, setProNumber] = useState(
    props.shipmentData.shipment.shipment.proNumber,
  );

  useEffect(
    function () {
      setProNumber(props.shipmentData.shipment.shipment.proNumber);
    },

    [JSON.stringify(props)],
  );

  const [loading, setLoading] = useState(false);
  const isDirty =
    proNumber !== props.shipmentData.shipment.shipment.proNumber &&
    props.shipmentData.shipment.shipment.bookedAt !== undefined;

  async function onUpdate() {
    setLoading(true);
    try {
      const shipmentsApi = await createShipmentsApi();
      await shipmentsApi.changeProNumber({
        shipmentId: props.shipmentData.shipment.shipment.shipmentId!,
        proNumber: proNumber || "",
      });
      await props.onRefresh();

      message.success(`Pro Number changed`);
    } catch {
      message.error("Oops");
    }
    setLoading(false);
  }

  return (
    <Tooltip
      placement="topLeft"
      title="This is the carrier's main tracking number"
    >
      <Input.Group compact>
        <Input
          style={{ width: "calc(100% - 150px)" }}
          value={proNumber}
          onChange={function (e) {
            setProNumber(e.target.value);
          }}
        />
        <Button
          type="primary"
          disabled={!isDirty}
          onClick={onUpdate}
          loading={loading}
        >
          Update
        </Button>
      </Input.Group>
    </Tooltip>
  );
}

export const ChangePickupDateReasonDropdown = OptionalEnumDropdown<
  ChangePickupDateReason,
  typeof ChangePickupDateReason
>(
  ChangePickupDateReason,
  "Select Reason",
  function (et) {
    switch (et) {
      case ChangePickupDateReason.CarrierMissedPickup:
        return "Carrier's fault - they missed the pickup";
      case ChangePickupDateReason.CustomerRequested:
        return "Customer requested change";
      case ChangePickupDateReason.ShipperNotReady:
        return "Carrier on site but shipper not ready";
      case ChangePickupDateReason.Other:
        return "Any other reason";
      default:
        assertNever(et);
    }
  },
  400,
);

function UpdatePickupDate(props: TabProps) {
  const display = useDisplay();
  const createShipmentsApi = useShipmentsApi();
  const [pickupDate, setPickupDate] = useState(
    props.shipmentData.shipment.shipment.pickupDate,
  );
  const { shipment } = props.shipmentData.shipment;
  const isPickupInThePast = isDateInThePast(pickupDate);
  const notYetPickedup =
    shipment.state === ShipmentState.BookingConfirmed ||
    shipment.state === ShipmentState.Quoted;
  const showError = isPickupInThePast && notYetPickedup;
  const pickupDateStatus = showError ? "error" : "";
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState<ChangePickupDateReason | undefined>(
    undefined,
  );

  const momentPickupDate = dayjs(pickupDate).clone().startOf("day");

  async function onPickupDateChange(newMomentDate: dayjs.Dayjs | null) {
    if (newMomentDate !== null) {
      const newDate = newMomentDate.format("YYYY-MM-DD");
      setPickupDate(newDate);
    }
  }

  async function onDone() {
    try {
      if (pickupDate === undefined) {
        message.warning("Date is not set");
        return;
      }

      if (reason === undefined) {
        message.warning("Reason is not set");
        return;
      }

      if (pickupDate === props.shipmentData.shipment.shipment.pickupDate) {
        message.warning("You did not change the date");
        return;
      }

      setLoading(true);

      // Only update if the date has changed
      if (pickupDate !== props.shipmentData.shipment.shipment.pickupDate) {
        const shipmentsApi = await createShipmentsApi();
        await shipmentsApi.changePickupDate({
          shipmentId: props.shipmentData.shipment.shipment.shipmentId!,
          newPickupDate: pickupDate,
          reason: reason,
        });
        await props.onRefresh();

        message.success(
          `Pickup date changed. NB: This does not communicate the change to the carrier`,
        );
        display.hide();
      } else {
        message.error("Oops");
      }
    } catch {
      message.error("Oops");
    }

    setLoading(false);
  }

  return (
    <>
      <Modal
        title={
          <Stack align="left">
            <div>Change Pickup Date</div>
            <div style={{ color: Colors.LightText, fontSize: "12px" }}>
              This only adjusts the pickup date in our system. It does not
              communicate/request the pickup date change from the carrier. Use
              this if a carrier tells us the pickup date will change.
            </div>
          </Stack>
        }
        visible={display.status}
        onOk={onDone}
        onCancel={display.hide}
        destroyOnClose={true}
        width={1000}
        confirmLoading={loading}
      >
        <Form
          style={{ width: "900px" }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Pickup Date">
            <HorizontalStack verticalAlign="middle">
              <DatePicker
                style={{ width: "140px" }}
                // This is tricky because we need it to represent the date in PST
                // but if you give moment a raw date it assumes that it is GMT
                value={momentPickupDate}
                onChange={onPickupDateChange}
                status={pickupDateStatus}
              />

              <div style={{ width: "40px" }}>
                {pickupDateStatus && (
                  <Text style={{ marginLeft: "16px" }}>❌</Text>
                )}
              </div>
            </HorizontalStack>
          </Form.Item>
          <Form.Item label="Date Change Reason?">
            <ChangePickupDateReasonDropdown
              value={reason}
              setValue={setReason}
            />
          </Form.Item>
        </Form>
      </Modal>
      <HorizontalStack>
        <div
          style={{
            color:
              pickupDateStatus === "error" ? Colors.Red : Colors.NormalText,
          }}
        >
          {momentPickupDate.format("MMM Do YYYY")}
        </div>
        <div>
          {pickupDateStatus && (
            <Tag style={{ marginLeft: "8px" }} color="red">
              Pickup date is in the past ❌
            </Tag>
          )}
        </div>
        <Spacer width={16} />
        <Button type="link" onClick={display.show}>
          Change
        </Button>
      </HorizontalStack>
    </>
  );
}

export function UpdatePickupInfo(props: TabProps) {
  const data: Record<string, any> = {};
  const mainTrackingNumberName =
    props.shipmentData._configuration.mainTrackingNumberName;

  const shipment = props.shipmentData.shipment.shipment;

  data["Pickup Date"] = <UpdatePickupDate {...props} />;
  const isPickupBooked = props.shipmentData.shipment.shipment.isPickupBooked;
  if (!props.shipmentData._configuration.carrierPickupNumberSameAsProNumber) {
    data["Carrier Pickup Number"] = (
      <Stack align="left">
        <UpdateCarrierPickupNumber {...props} />

        {isPickupBooked && (
          <div style={{ color: Colors.LightText, marginTop: "4px" }}>
            ✅ Pickup Confirmed
          </div>
        )}
        {!isPickupBooked && (
          <div style={{ color: Colors.LightText, marginTop: "4px" }}>
            ❌ No Pickup Booked
          </div>
        )}
      </Stack>
    );
    data[mainTrackingNumberName] = <UpdateProNumber {...props} />;
  } else {
    data[mainTrackingNumberName] = (
      <Stack align="left">
        <UpdateProNumber {...props} />
        {isPickupBooked && (
          <div style={{ color: Colors.LightText, marginTop: "4px" }}>
            ✅ Pickup Confirmed
          </div>
        )}
        {!isPickupBooked && (
          <div style={{ color: Colors.LightText, marginTop: "4px" }}>
            ❌ No Pickup Booked
          </div>
        )}
      </Stack>
    );
  }

  if (props.shipmentData.shipment.shipment.advanceInterlineCarrierDisplayName) {
    data["Advance Interline Carrier"] = (
      <InterlineCarrier
        carrierIdentifier={shipment.advanceInterlineCarrierIdentifier}
        carrierDisplayName={shipment.advanceInterlineCarrierDisplayName}
      />
    );
  }
  if (shipment.advanceInterlineProNumber) {
    data["Advance Interline Pro"] = (
      <div>{shipment.advanceInterlineProNumber}</div>
    );
  }
  if (shipment.advanceInterlineCarrierPickupNumber) {
    data["Advance Interline Carrier Pickup Number"] = (
      <div>{shipment.advanceInterlineCarrierPickupNumber}</div>
    );
  }

  if (props.shipmentData.shipment.shipment.beyondInterlineCarrierDisplayName) {
    data["Beyond Interline Carrier"] = (
      <InterlineCarrier
        carrierIdentifier={shipment.beyondInterlineCarrierIdentifier}
        carrierDisplayName={shipment.beyondInterlineCarrierDisplayName}
      />
    );
  }
  if (shipment.beyondInterlineProNumber) {
    data["Beyond Interline Pro"] = (
      <div>{shipment.beyondInterlineProNumber}</div>
    );
  }
  if (shipment.beyondInterlineCarrierPickupNumber) {
    data["Beyond Interline Carrier Pickup Number"] = (
      <div>{shipment.beyondInterlineCarrierPickupNumber}</div>
    );
  }

  return (
    <>
      <KeyValues data={data} />
      <Spacer height={16} />
      <ButtonRow
        extrasMenu={
          <Menu>
            <MarkPickupConfirmedMenuItem
              shipmentData={props.shipmentData}
              onRefresh={props.onRefresh}
              onPatchShipmentData={props.onPatchShipmentData}
            />
            <PreviewPickupEmailMenuItem shipmentData={props.shipmentData} />
            <ViewProNumberHistoryMenuItem
              shipmentId={props.shipmentData.shipmentId}
              onRefresh={props.onRefresh}
            />
            <ViewCarrierPickupNumberHistoryMenuItem
              shipmentId={props.shipmentData.shipmentId}
              onRefresh={props.onRefresh}
            />
            <Menu.Divider />
            <EditInterlineInfoMenuButton
              interlineContext={InterlineContext.Advance}
              {...props}
            />
            <EditInterlineInfoMenuButton
              interlineContext={InterlineContext.Beyond}
              {...props}
            />
          </Menu>
        }
      >
        <BookPickupButton
          shipmentData={props.shipmentData}
          onRefresh={props.onRefresh}
        />
        <PutShipmentOnHoldButton
          shipment={props.shipmentData.shipment.shipment}
          onRefresh={props.onRefresh}
        />
        <CancelShipmentButton
          shipment={props.shipmentData.shipment.shipment}
          onRefresh={props.onRefresh}
        />
      </ButtonRow>
    </>
  );
}
