import {
  CustomerInvoice,
  CustomerInvoiceState,
} from "@freightsimple/generated-apollo-openapi-client";
import dayjs from "dayjs";

export function isCustomerInvoiceOverdue(customerInvoice: CustomerInvoice) {
  const isInThePast = dayjs(customerInvoice.dueDate)
    .startOf("day")
    .isBefore(dayjs().startOf("day"));

  return (
    customerInvoice.customerInvoiceState === CustomerInvoiceState.Issued &&
    isInThePast
  );
}
