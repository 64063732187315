import { message } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { ConfirmButton } from "../../Components/ConfirmButton";
import { TabProps } from "./TabProps";

export function MarkFreightNotMissingButton(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const createShipmentsApi = useShipmentsApi();
  async function onConfirm() {
    const shipmentsApi = await createShipmentsApi();
    try {
      await shipmentsApi.markFreightNotMissing({ shipmentId });
      await props.onRefresh();
      message.success("Marked Not Missing");
    } catch (e) {
      message.error(`Oops. Error ${e}`);
    }
  }

  if (!props.shipmentData.shipment.shipment.freightMissing) {
    return <></>;
  }

  return (
    <ConfirmButton
      onConfirm={onConfirm}
      question={`Are you sure you want to mark this freight as not missing?`}
      okText="Yes, mark not missing"
      tooltip="This will mark freight as nto missing. Closes the Monday task. Adds a tracking line"
    >
      Mark Freight Not Missing
    </ConfirmButton>
  );
}
