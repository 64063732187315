import { Button, Modal } from "antd";

import { useState } from "react";
import remarkGfm from "remark-gfm";
import styled from "styled-components";

import Markdown from "react-markdown";
import { ButtonType } from "../Types/AntHelperTypes";

interface ViewMarkdownButtonProps {
  data: string;
  title: string;
  type?: ButtonType;
}

const StyledMarkdownContainer = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
`;

interface DisplayMarkdownProps {
  data: string;
}

export function DisplayMarkdown(props: DisplayMarkdownProps) {
  return (
    <StyledMarkdownContainer>
      <Markdown remarkPlugins={[remarkGfm]}>{props.data}</Markdown>
    </StyledMarkdownContainer>
  );
}

export function ViewMarkdownButton(props: ViewMarkdownButtonProps) {
  const { title, data } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <DisplayMarkdown data={data} />
      </Modal>
      <Button type={props.type ?? "default"} onClick={showModal}>
        {title}
      </Button>
    </>
  );
}
