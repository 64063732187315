import { assertNever } from "../../../Helpers/assertNever";
import { OptionalEnumDropdown } from "../../../Screens/ViewShipmentScreenComponents/EnumDropdown";
import { SignalFilter } from "./SignalFilter";

export const SignalFilterTypeDropdown = OptionalEnumDropdown<
  SignalFilter,
  typeof SignalFilter
>(SignalFilter, "Do not Filter", function (et) {
  switch (et) {
    case SignalFilter.NotFiredInLastMonth:
      return "Not Fired In Last Month";
    default:
      assertNever(et);
  }
});
