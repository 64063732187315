import { Tag } from "antd";
import Colors from "./Colors";
import { foregroundColorForColor } from "../Helpers/stringToColor";

interface CarrierInvoiceSourceTagProps {
  source: string | undefined;
}

function describe(state: string | undefined) {
  if (!state) {
    return "Unknown";
  }

  switch (state) {
    case "manually_uploaded":
      return "Manually Uploaded";
    case "processed_email":
      return "Processed Email";
    case "website_download":
      return "Website Download";
    case "edi":
      return "EDI";
    case "api":
      return "API";
    default:
      return state;
  }
}

function color(state: string | undefined) {
  if (!state) {
    return Colors.DarkGray;
  }
  switch (state) {
    case "manually_uploaded":
      return Colors.Blue;
    case "processed_email":
      return Colors.Green;
    case "website_downloaded":
      return Colors.Gold;
    case "edi":
      return Colors.Red;
    default:
      return Colors.DarkGray;
  }
}

export function CarrierInvoiceSourceTag(props: CarrierInvoiceSourceTagProps) {
  const c = color(props.source);
  return (
    <Tag color={c}>
      <div style={{ color: foregroundColorForColor(c) }}>
        {describe(props.source)}
      </div>
    </Tag>
  );
}
