import { Button, Modal } from "antd";
import { useState } from "react";
import { Json } from "./Json";

interface ViewJsonButtonProps {
  data: any;
  title: string;
}

export function ViewJsonButton(props: ViewJsonButtonProps) {
  const { title, data } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <Json data={data} />
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
