import {
  Contact,
  CountryCode,
  Location,
} from "@freightsimple/generated-apollo-openapi-client";
import {
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from "antd";

import { useEffect, useState } from "react";
import { useQuotesApi } from "../../Apis/Apis";
import Colors from "../../Components/Colors";
import { DateInput } from "../../Components/DateInput";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { StateOrProvinceDropdown } from "../../Components/StateOrProvinceDropdown";
import { stripNonNumerical } from "../../Helpers/stripNonNumerical";
import { stripSpaces } from "../../Helpers/stripSpaces";
import { LocationTypeDropdown } from "./LocationTypeDropdown";
import dayjs from "dayjs";

const { Title } = Typography;

interface QuoteLocationEditorProps {
  location: Location;
  contact: Contact;
  reference: string;
  booth: string;
  deadline: string | undefined;
  setLocation: (location: Location) => void;
  setContact: (contact: Contact) => void;
  setReference: (reference: string) => void;
  setBooth: (booth: string) => void;
  setDeadline: (booth: string | undefined) => void;
  showAppointment: boolean;
  adjective: string;
}

export function LocationEditor(props: QuoteLocationEditorProps) {
  const { location, contact, reference, booth } = props;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const postalCode = props.location.address?.postalCode!;
  const [postalCodeIsDirty, setPostalCodeIsDirty] = useState(false);
  const city = props.location.address?.city;
  const state = props.location.address?.stateOrProvinceCode;
  const country =
    props.location.address?.countryCode === CountryCode.Ca ? "🇨🇦" : "🇺🇸";
  const cityStateCountry = `${country} ${city}, ${state}, ${props.location.address?.countryCode}`;
  const [lookingUpPostalCode, setLookingUpPostalCode] = useState(false);
  const [showEditCityState, setShowEditCityState] = useState(false);
  const createQuotesApi = useQuotesApi();
  async function setPostalCode(postalCode: string) {
    props.setLocation({
      ...location,
      address: {
        ...location.address,
        postalCode,
      },
    });
    setPostalCodeIsDirty(true);
  }

  async function setCity(city: string) {
    props.setLocation({
      ...location,
      address: {
        ...location.address,
        city,
      },
    });
  }

  async function setStateOrProvinceCode(stateOrProvinceCode: string) {
    props.setLocation({
      ...location,
      address: {
        ...location.address,
        stateOrProvinceCode: stateOrProvinceCode,
      },
    });
  }

  useEffect(() => {
    if (location.locationType !== "DistributionWarehouse") {
      props.setDeadline(undefined);
    }

    if (location.locationType === "DistributionWarehouse") {
      let deadline = props.deadline;

      if (!deadline) {
        deadline = dayjs().format("YYYY-MM-DD");
      }

      props.setDeadline(deadline);
    }
  }, [location.locationType]);

  async function lookupCityState() {
    if (!postalCodeIsDirty) {
      return;
    }
    setLookingUpPostalCode(true);
    try {
      const quotesApi = await createQuotesApi();
      const response = await quotesApi.lookupPostalCode1({ postalCode });

      props.setLocation({
        ...location,
        address: {
          ...location.address,
          addressLine: "",
          addressLine2: "",
          countryCode: response.countryCode,
          stateOrProvinceCode: response.stateOrProvinceCode,
          city: response.city,
        },
        latitudeLongitude: {
          latitude: response.latitude,
          longitude: response.longitude,
        },
      });
      setPostalCodeIsDirty(true);
    } catch {
      message.error("Postal code lookup failed");
    }
    setLookingUpPostalCode(false);
  }

  const suffix = lookingUpPostalCode ? <Spin size="small" /> : cityStateCountry;

  const inside = props.location.accessorials?.includes("LOGISTICS_INSIDE");
  const liftGate = props.location.accessorials?.includes(
    "LOGISTICS_LIFT_GATE_REQUIRED",
  );
  const appointmentRequired = props.location.accessorials?.includes(
    "SCHEDULING_APPOINTMENT_REQUIRED",
  );

  const afterHours = props.location.accessorials?.includes(
    "SCHEDULING_AFTER_HOURS",
  );

  const { setLocation, setContact, setReference, setBooth } = props;

  function onToggle(accessorialCode: string) {
    let updatedAccessorials = [...(props.location.accessorials || [])];

    if (props.location.accessorials?.includes(accessorialCode)) {
      // It's there, so remove it
      updatedAccessorials = updatedAccessorials.filter(
        (a) => a !== accessorialCode,
      );
    } else {
      // It's not there, so add it
      updatedAccessorials.push(accessorialCode);
    }

    props.setLocation({
      ...location,
      accessorials: updatedAccessorials,
    });
  }

  function onToggleLiftGate() {
    onToggle("LOGISTICS_LIFT_GATE_REQUIRED");
  }

  function onToggleInside() {
    onToggle("LOGISTICS_INSIDE");
  }

  function onToggleAppointment() {
    onToggle("SCHEDULING_APPOINTMENT_REQUIRED");
  }

  function onToggleAfterHours() {
    onToggle("SCHEDULING_AFTER_HOURS");
  }

  function setLocationType(type: string) {
    props.setLocation({
      ...location,
      locationType: type,
    });
  }

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16, offset: 1 }}
      layout="horizontal"
      colon={false}
    >
      <Row style={{ width: "100%" }}>
        <Col span={8}>
          <Title level={5}>Location</Title>
          <Form.Item label="Business Name">
            <Input
              value={props.location.businessName!}
              onChange={function (e) {
                setLocation({
                  ...location,
                  businessName: e.target.value,
                });
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item label="Address Line">
            <Input
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              value={props.location.address?.addressLine!}
              onChange={function (e) {
                setLocation({
                  ...location,
                  address: {
                    ...location.address,
                    addressLine: e.target.value,
                  },
                });
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item label="Address Line 2">
            <Input
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              value={props.location.address?.addressLine2!}
              onChange={function (e) {
                setLocation({
                  ...location,
                  address: {
                    ...location.address,
                    addressLine2: e.target.value,
                  },
                });
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item
            label={
              <Stack align="right">
                <div>Postal Code</div>
                <a
                  onClick={function () {
                    setShowEditCityState(!showEditCityState);
                  }}
                  style={{ fontSize: "12px" }}
                >
                  {showEditCityState ? "Hide City/State" : "Edit City/State"}
                </a>
              </Stack>
            }
          >
            <Input
              value={props.location.address?.postalCode}
              onChange={function (e) {
                setPostalCode(stripSpaces(e.target.value));
              }}
              onBlur={lookupCityState}
              suffix={suffix}
              style={{ width: "300px" }}
            />
          </Form.Item>
          {showEditCityState && (
            <div
              style={{
                paddingTop: "24px",
                paddingBottom: "1px",
                marginBottom: "8px",
                backgroundColor: Colors.LightBackground,
              }}
            >
              <Form.Item
                label={
                  props.location.address?.countryCode === CountryCode.Ca
                    ? "Province"
                    : "State"
                }
              >
                <StateOrProvinceDropdown
                  style={{ width: "300px" }}
                  countries={[props.location.address?.countryCode]}
                  value={props.location.address?.stateOrProvinceCode}
                  onChange={function (e) {
                    if (e) {
                      setStateOrProvinceCode(e);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label="City">
                <Input
                  value={props.location.address?.city}
                  onChange={function (e) {
                    setCity(e.target.value);
                  }}
                  placeholder="City"
                  style={{ width: "300px" }}
                />
              </Form.Item>
            </div>
          )}
          <Form.Item label="Location Type">
            <LocationTypeDropdown
              value={location.locationType}
              setValue={setLocationType}
            />
          </Form.Item>
          {props.deadline !== undefined &&
            location.locationType === "DistributionWarehouse" && (
              <Form.Item label="Deadline">
                <DateInput date={props.deadline} setDate={props.setDeadline} />
              </Form.Item>
            )}
          <Form.Item label="From">
            <Input
              value={props.location.hours?.openFrom}
              onChange={function (e) {
                setLocation({
                  ...location,
                  hours: {
                    ...location.hours,
                    openFrom: e.target.value,
                  },
                });
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item label="Until">
            <Input
              value={props.location.hours?.openUntil}
              onChange={function (e) {
                setLocation({
                  ...location,
                  hours: {
                    ...location.hours,
                    openUntil: e.target.value,
                  },
                });
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item label="Notes">
            <Input
              value={props.location.notes!}
              onChange={function (e) {
                setLocation({
                  ...location,
                  notes: e.target.value,
                });
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>

          <Form.Item label="Accessorials">
            <HorizontalStack align="left">
              <Checkbox
                checked={liftGate}
                onChange={function () {
                  onToggleLiftGate();
                }}
              >
                Liftgate
              </Checkbox>
              <Checkbox
                checked={inside}
                onChange={function () {
                  onToggleInside();
                }}
              >
                Inside
              </Checkbox>
              {props.showAppointment && (
                <Checkbox
                  checked={appointmentRequired}
                  onChange={function () {
                    onToggleAppointment();
                  }}
                >
                  Appointment
                </Checkbox>
              )}
              <Checkbox
                checked={afterHours}
                onChange={function () {
                  onToggleAfterHours();
                }}
              >
                After Hours
              </Checkbox>
            </HorizontalStack>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Title level={5}>Contact</Title>
          <Form.Item label="Contact Name">
            <Input
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              value={props.contact?.contactName!}
              onChange={function (e) {
                setContact({
                  ...contact,
                  contactName: e.target.value,
                });
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item label="Phone Number">
            <Input
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              value={props.contact?.phoneNumber!}
              onChange={function (e) {
                setContact({
                  ...contact,
                  phoneNumber: stripNonNumerical(e.target.value),
                });
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item label="Extension">
            <Input
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              value={props.contact?.phoneNumberExtension!}
              onChange={function (e) {
                setContact({
                  ...contact,
                  phoneNumberExtension: e.target.value,
                });
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              value={props.contact?.emailAddress!}
              onChange={function (e) {
                setContact({
                  ...contact,
                  emailAddress: e.target.value,
                });
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Title level={5}>Reference</Title>
          <Form.Item label="Reference">
            <Input
              value={reference!}
              onChange={function (e) {
                setReference(e.target.value);
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Title level={5}>Booth</Title>
          <Form.Item label="Booth">
            <Input
              value={booth!}
              onChange={function (e) {
                setBooth(e.target.value);
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
