import { Select } from "antd";
import { getSalesRepsData } from "./getSalesRepsData";

interface AdminsDropdownProps {
  email: string | undefined;
  setEmail: (_: string | undefined) => void;
}

export function AdminsDropdown(props: AdminsDropdownProps) {
  return (
    <Select
      value={props.email}
      onChange={props.setEmail}
      style={{ width: "150px" }}
    >
      {getSalesRepsData().map(function (sr) {
        return (
          <Select.Option key={sr.email} value={sr.email}>
            {sr.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}
