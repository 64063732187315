import { Tooltip } from "antd";
import { WarningComponent } from "./WarningComponent";
import React from "react";

interface NonAsciiWarningComponentProps {
  text: string;
  style?: React.CSSProperties;
}

function hasNonAsciiCharacters(text: string): boolean {
  const nonAsciiMatches = text.match(/[^\u0020-\u007F]/g);
  const hasNonAscii = nonAsciiMatches !== null;
  return hasNonAscii;
}

export function NonAsciiWarningComponent(props: NonAsciiWarningComponentProps) {
  if (!props.text || !hasNonAsciiCharacters(props.text)) {
    return <></>;
  }

  return (
    <Tooltip title="This field contains non-ASCII characters which may cause issues generating documents. It's usually caused by special characters available on non-Western keyvoards. These are often hard to spot. A quick way to fix is to retype the entire field using your keyboard.">
      <span style={props.style}>
        <WarningComponent
          style={{ marginLeft: 4, cursor: "help", ...props.style }}
        />
      </span>
    </Tooltip>
  );
}
