import React from "react";
import styled, { CSSProperties } from "styled-components";

type VericalAlignOptions = "middle" | "top" | "bottom";
export type AlignOptions = "left" | "center" | "right" | "spread";

interface HorizontalStackProps {
  children: React.ReactNode;
  verticalAlign?: VericalAlignOptions;
  align?: AlignOptions;
  width?: string;

  /** padding */
  padding?: string;

  style?: CSSProperties;
  className?: string;

  spacing?: number;

  id?: string;
}

function verticalAlignToAlignItems(align?: VericalAlignOptions) {
  if (align === undefined) {
    return "center";
  }
  if (align === "middle") {
    return "center";
  }

  if (align === "top") {
    return "flex-start";
  }

  if (align === "bottom") {
    return "flex-end";
  }

  throw new Error(`Invalid verticalAlign value: ${align}`);
}

function alignToJustify(align?: AlignOptions) {
  if (align === undefined) {
    return "flex-start";
  }
  if (align === "left") {
    return "flex-start";
  }

  if (align === "center") {
    return "center";
  }

  if (align === "right") {
    return "flex-end";
  }

  if (align === "spread") {
    return "space-between";
  }

  throw new Error(`Invalid align value: ${align}`);
}

interface StyledStackProps {
  $spacing?: number;
  $verticalAlign?: string;
  $horizontalAlign?: string;
  $width?: string;
  $padding?: string;
}

const StyledStack = styled.div<StyledStackProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: ${(props) => props.$verticalAlign || "center"};
  justify-content: ${(props) => props.$horizontalAlign || "flex-start"};
  width: ${(props) => props.$width || "auto"};
  padding: ${(props) => props.$padding || "0"};

  ${(props) =>
    props.$spacing &&
    `
    padding-left: ${props.$spacing / 2}px;
    padding-right: ${props.$spacing / 2}px;
    
    & > * {
      margin-left: ${props.$spacing / 2}px;
      margin-right: ${props.$spacing / 2}px;
    }
  `}
`;

/**
 *
 * @param verticalAlign (Optional) Vertical alignment of elements in the stack (middle|top|bottom)
 * @param align (Optional) Horizontal alignment of elements in the stack (left|center|right|spread)
 * @param width (Optional) Width of the stack (eg. '520px')
 * @param padding (Optional) Padding on the stack
 * @param spacing (Optional) Spacing between elements
 *
 */
const HorizontalStack: React.FC<HorizontalStackProps> = (
  props: HorizontalStackProps,
) => {
  const verticalAlignValue = verticalAlignToAlignItems(props.verticalAlign);
  const horizontalAlignValue = alignToJustify(props.align);

  return (
    <StyledStack
      $verticalAlign={verticalAlignValue}
      $horizontalAlign={horizontalAlignValue}
      $width={props.width}
      $padding={props.padding}
      $spacing={props.spacing}
      style={props.style}
      className={props.className}
      id={props.id}
    >
      {props.children}
    </StyledStack>
  );
};
export default HorizontalStack;
