import { InvoiceAuditTurn } from "@freightsimple/generated-apollo-openapi-client";

import {
  DatePicker,
  Form,
  Menu,
  MenuItemProps,
  message,
  Modal,
  Tag,
} from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { assertNever } from "../../Helpers/assertNever";
import { OptionalEnumDropdown } from "./EnumDropdown";
import dayjs from "dayjs";

export const InvoiceAuditTurnDropdown = OptionalEnumDropdown<
  InvoiceAuditTurn,
  typeof InvoiceAuditTurn
>(InvoiceAuditTurn, "Neutral", function (et) {
  switch (et) {
    case InvoiceAuditTurn.OurTurn:
      return "Our Turn";
    case InvoiceAuditTurn.TheirTurn:
      return "Their Turn";
    default:
      assertNever(et);
  }
});

interface SetCustomerAuditTurnMenuItemProps extends MenuItemProps {
  invoiceAuditCustomerTurn: InvoiceAuditTurn | undefined;
  invoiceAuditCustomerTurnTime: string | undefined;
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

export function SetCustomerAuditTurnMenuItem(
  props: SetCustomerAuditTurnMenuItemProps,
) {
  const title = `Set Customer Audit Turn`;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [turn, setTurn] = useState<InvoiceAuditTurn | undefined>(
    props.invoiceAuditCustomerTurn,
  );

  function defaultTurnDate(): string {
    if (props.invoiceAuditCustomerTurnTime === undefined) {
      return dayjs().format("YYYY-MM-DD");
    }

    return dayjs(props.invoiceAuditCustomerTurnTime).format("YYYY-MM-DD");
  }

  const [turnDate, setTurnDate] = useState<string | undefined>(
    defaultTurnDate(),
  );
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);
  const shipmentId = props.shipmentId;

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);

    try {
      const shipmentApi = await createShipmentApi();
      await shipmentApi.setInvoiceAuditCustomerTurn({
        shipmentId,
        turn,
        date: turnDate,
      });
      await props.onRefresh();
      message.success("Success");
      setIsModalVisible(false);
    } catch {
      message.error("Oops. Something went wrong");
    }
    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1200}
        confirmLoading={loading}
      >
        <Form
          style={{ width: "1200px" }}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 19, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Last Turn">
            <InvoiceAuditTurnDropdown value={turn} setValue={setTurn} />
          </Form.Item>
          <Form.Item label="Last Turn Date">
            <DatePicker
              style={{ width: "300px" }}
              value={dayjs(turnDate)}
              onChange={async function (newMomentDate: dayjs.Dayjs | null) {
                if (newMomentDate !== null) {
                  const newDate = newMomentDate.format("YYYY-MM-DD");
                  setTurnDate(newDate);
                } else {
                  setTurnDate(undefined);
                }
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        <Tag color="red">Advanced</Tag> {title}
      </Menu.Item>
    </>
  );
}
