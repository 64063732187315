import { ShipmentApiActionMenuItem } from "./ShipmentApiActionButton";

interface ForceTrackingUpdateMenuItemProps {
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

export function ForceTrackingUpdateMenuItem(
  props: ForceTrackingUpdateMenuItemProps,
) {
  const { shipmentId } = props;
  return (
    <ShipmentApiActionMenuItem
      name="Force Tracking Update"
      onClick={async function (shipmentApi) {
        await shipmentApi.forceTrackingUpdate({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={false}
    />
  );
}
