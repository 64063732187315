import {
  CountryCode,
  ShipmentReport,
  ShipmentReportQuoteSummary,
} from "@freightsimple/generated-apollo-openapi-client";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Switch } from "antd";
import { useState } from "react";
import { CarrierFilter } from "./CarrierFilter";
import { ExcelButton, ColumnFormat, SheetConfig } from "./ExcelButton";
import Spacer from "../Spacer";
import HorizontalStack from "./HorizontalStack";
import { getCarrierDisplayNameFromCarrierIdentifier } from "../Helpers/serviceProperties";
import dayjs from "dayjs";

interface DownloadCarrierComparisonButtonProps {
  quotes: ShipmentReport[];
}

interface DownloadCarrierComparisonLinkProps {
  quotes: ShipmentReport[];
  comparisonCarrierIdentifier: string | undefined;
  includeRankedNumber1: boolean;
  includeLowestRankCarrierIdentifier: boolean;
  onOk: () => void;
}

export function DownloadCarrierComparisonLink(
  props: DownloadCarrierComparisonLinkProps,
) {
  function getFilename(): string | undefined {
    if (props.comparisonCarrierIdentifier === undefined) {
      return undefined;
    }

    return `freightsimple ${getCarrierDisplayNameFromCarrierIdentifier(
      props.comparisonCarrierIdentifier,
    )} Rates Comparison ${dayjs().format("YYYY-MM-DD")}.xlsx`;
  }

  function generateSheets() {
    if (props.comparisonCarrierIdentifier === undefined) {
      return [];
    }

    const data = props.quotes.map(function (sr) {
      function carrierPriceFromQuote(srqs: ShipmentReportQuoteSummary): number {
        return srqs.quotedPriceByCarrierWithoutTaxCad!;
      }

      function rateFromQuote(
        srqs: ShipmentReportQuoteSummary | undefined,
      ): number | undefined {
        if (srqs === undefined) {
          return undefined;
        } else {
          return Number(carrierPriceFromQuote(srqs).toFixed(2));
        }
      }

      function rank(): number | undefined {
        const index = sr.quotes!.findIndex(
          (srqs) =>
            srqs.carrierIdentifier === props.comparisonCarrierIdentifier,
        );
        if (index === undefined || index === -1) {
          return undefined;
        }

        return index + 1;
      }

      function delta(
        lowest: ShipmentReportQuoteSummary | undefined,
        carrierQuote: ShipmentReportQuoteSummary | undefined,
      ): number | undefined {
        if (lowest === undefined || carrierQuote === undefined) {
          return undefined;
        }

        return Number(
          (
            carrierPriceFromQuote(carrierQuote) - carrierPriceFromQuote(lowest)
          ).toFixed(2),
        );
      }

      function percentage(
        lowest: ShipmentReportQuoteSummary | undefined,
        carrierQuote: ShipmentReportQuoteSummary | undefined,
      ): number | undefined {
        if (lowest === undefined || carrierQuote === undefined) {
          return undefined;
        }

        return Number(
          (
            100 *
              (carrierPriceFromQuote(carrierQuote) /
                carrierPriceFromQuote(lowest)) -
            100
          ).toFixed(0),
        );
      }

      const o = {} as {
        [key: string]: string | undefined | CountryCode | number;
      };

      const carrierQuote = sr.quotes!.find(
        (srqs) => srqs.carrierIdentifier === props.comparisonCarrierIdentifier,
      );
      o["FSREF"] = sr.billOfLadingNumber;
      o["Pickup City"] = sr.pickupCity;
      o["Pickup State"] = sr.pickupState;
      o["Pickup Country"] = sr.pickupCountry;
      o["Delivery City"] = sr.deliveryCity;
      o["Delivery State"] = sr.deliveryState;
      o["Delivery Country"] = sr.deliveryCountry;
      o["⭐ Lowest Rate"] = rateFromQuote(sr.lowestQuote);
      if (props.includeLowestRankCarrierIdentifier) {
        o["⭐ Lowest Rate Carrier"] = sr.lowestQuote?.carrierIdentifier;
      }

      o[`🚚 ${props.comparisonCarrierIdentifier} Rate`] =
        rateFromQuote(carrierQuote);
      o[`🚚 ${props.comparisonCarrierIdentifier} Rank`] = rank();
      o[`🚚 ${props.comparisonCarrierIdentifier} Delta`] = delta(
        sr.lowestQuote,
        carrierQuote,
      );
      o[`🚚 ${props.comparisonCarrierIdentifier} Percentage Off`] = percentage(
        sr.lowestQuote,
        carrierQuote,
      );

      function formatDimensions() {
        return sr
          .lineItems!.map(
            (li) =>
              `${li.numberHandlingUnits}@${li.length}x${li.width}x${li.height}`,
          )
          .join(", ");
      }

      o["Number Skids"] = sr.totalHandlingUnits;
      o["Total Weight (lb)"] = sr.totalWeight;
      o["Total Volume (cuft)"] = sr.totalVolume;
      o["Linear Feet"] = sr.standardDryVanLinearFeet;
      o["Dimensions"] = formatDimensions();
      o["Density"] = Number(sr.density?.toFixed(1));
      o["Freight Class"] = Number(sr.freightClass);
      o["Residential Pickup"] = sr.needsResidentialPickup ? "Yes" : "-";
      o["Residential Delivery"] = sr.needsResidentialDelivery ? "Yes" : "-";
      o["Liftgate Pickup"] = sr.needsLiftgatePickup ? "Yes" : "-";
      o["Liftgate Delivery"] = sr.needsLiftgateDelivery ? "Yes" : "-";
      o["Inside Pickup"] = sr.needsInsidePickup ? "Yes" : "-";
      o["Inside Delivery"] = sr.needsInsideDelivery ? "Yes" : "-";

      return o;
    });

    const filteredData = data.filter(function (o) {
      if (props.includeRankedNumber1) {
        return true;
      } else {
        return o[`🚚 ${props.comparisonCarrierIdentifier} Rank`] !== 1;
      }
    });

    // Create detailed comparison sheet config
    const detailedSheet: SheetConfig = {
      name: "Shipments",
      data: filteredData,
      headers: Object.keys(filteredData[0]),
      columnFormats: {
        [`🚚 ${props.comparisonCarrierIdentifier} Rate`]: {
          type: "currency",
          decimals: 2,
        },
        "⭐ Lowest Rate": {
          type: "currency",
          decimals: 2,
        },
        [`🚚 ${props.comparisonCarrierIdentifier} Delta`]: {
          type: "currency",
          decimals: 2,
        },
        [`🚚 ${props.comparisonCarrierIdentifier} Percentage Off`]: {
          type: "percentage",
          decimals: 0,
        },
      } satisfies Record<string, ColumnFormat>,
    };

    return [detailedSheet];
  }

  return (
    <ExcelButton
      generateSheets={generateSheets}
      getFilename={getFilename}
      autoWidth
      boldHeaders
      onOk={props.onOk}
    />
  );
}

export function DownloadCarrierComparisonButton(
  props: DownloadCarrierComparisonButtonProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [includeRankedNumber1, setIncludeRankedNumber1] = useState(false);
  const [comparisonCarrierIdentifier, setComparisonCarrierIdentifier] =
    useState<string | undefined>();
  const [
    includeLowestRankCarrierIdentifier,
    setIncludeLowestRankCarrierIdentifier,
  ] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Carrier Comparison Excel"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
        footer={
          <HorizontalStack align="right" width="100%">
            <DownloadCarrierComparisonLink
              {...props}
              comparisonCarrierIdentifier={comparisonCarrierIdentifier}
              includeRankedNumber1={includeRankedNumber1}
              includeLowestRankCarrierIdentifier={
                includeLowestRankCarrierIdentifier
              }
              onOk={handleOk}
            />
          </HorizontalStack>
        }
      >
        <Spacer height={16} />
        <Form
          layout="horizontal"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 15, offset: 1 }}
        >
          <Form.Item label="Compare with carrier">
            <CarrierFilter
              carrierIdentifier={comparisonCarrierIdentifier}
              setCarrierIdentifier={setComparisonCarrierIdentifier}
            />
          </Form.Item>
          <Form.Item label="Include Quotes where carrier was ranked #1?">
            <Switch
              checked={includeRankedNumber1}
              onChange={setIncludeRankedNumber1}
            />
          </Form.Item>
          <Form.Item label="Include Best Rank Carrier Identifier?">
            <Switch
              checked={includeLowestRankCarrierIdentifier}
              onChange={setIncludeLowestRankCarrierIdentifier}
            />
          </Form.Item>
        </Form>
        <Spacer height={16} />
      </Modal>
      <Button
        icon={<DownloadOutlined />}
        onClick={showModal}
        type="link"
        style={{ margin: 0, fontSize: "12px" }}
      >
        Carrier Comparison Report
      </Button>
    </>
  );
}
