import { Modal, Tabs } from "antd";

import { useState } from "react";
import { MenuItem } from "../../Types/AntHelperTypes";
import { Quote } from "@freightsimple/generated-apollo-openapi-client";

interface ViewQuoteExplanationsMenuItemProps {
  quote: Quote;
}

export function ViewQuoteExplanationsMenuItem(
  props: ViewQuoteExplanationsMenuItemProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={`View Quote Explanations`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <Tabs
          items={[
            {
              label: "Internal Version",
              key: "internal",
              children: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.quote.carrierPriceExplanationInternal ?? "",
                  }}
                />
              ),
            },
            {
              label: "External Version",
              key: "external",
              children: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.quote.carrierPriceExplanationExternal ?? "",
                  }}
                />
              ),
            },
          ]}
        ></Tabs>
      </Modal>
      <MenuItem onClick={showModal}>View Quote Explanations</MenuItem>
    </>
  );
}
