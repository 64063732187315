import {
  InvoiceAuditProblemConfidence,
  InvoiceAuditProblemFault,
} from "@freightsimple/generated-apollo-openapi-client";
import { Tag } from "antd";
import Stack from "../../Components/Stack";
import { capitalizeFirstLetter } from "../../Helpers/capitalizeFirstLetter";
import { foregroundColorForColor } from "../../Helpers/stringToColor";
import { emojiForInvoiceAuditProblemFault } from "./emojiForInvoiceAuditProblemFault";
import { colorForInvoiceAuditProblemConfidence } from "./colorForInvoiceAuditProblemConfidence";

interface InvoiceAuditProblemFaultTagProps {
  fault: InvoiceAuditProblemFault;
  confidence: InvoiceAuditProblemConfidence;
}

export function InvoiceAuditProblemFaultTag(
  props: InvoiceAuditProblemFaultTagProps,
) {
  const color = colorForInvoiceAuditProblemConfidence(props.confidence);
  return (
    <Stack align="center">
      <Tag color={color} style={{ margin: 0 }}>
        <div
          style={{
            color: foregroundColorForColor(color),
          }}
        >
          {emojiForInvoiceAuditProblemFault(props.fault!)}{" "}
          {capitalizeFirstLetter(props.fault)}
        </div>
      </Tag>
      <div style={{ fontSize: "7px", marginTop: "2px" }}>
        {capitalizeFirstLetter(props.confidence ?? "Unknown")} Confidence
      </div>
    </Stack>
  );
}
