import {
  DownloadOutlined,
  LeftOutlined,
  LinkOutlined,
  RightOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";
import { Button, Tag } from "antd";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useDocumentsApi } from "../Apis/Apis";
import { Loading } from "../Components/Loading";
import { useOnce } from "../Hooks/useOnce";
import { ExtractPageFromPdfButton } from "./ExtractPageFromPdfButton";
import HorizontalStack from "./HorizontalStack";
import Stack from "./Stack";
import { WarningOutlined } from "@ant-design/icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface PdfProps {
  documentS3Path: string;
  filename: string;
  width?: number;
  companyId?: string;
  shipmentId?: string;
  onRefresh?: () => Promise<void>;
}

export function Pdf(props: PdfProps) {
  const createDocumentsApi = useDocumentsApi();
  const [pdfBase64, setPdfBase64] = useState<string | undefined>();
  const [pdfPage, setPdfPage] = useState(1);
  const [numPdfPages, setNumPdfPages] = useState(1);
  const [rotate, setRotate] = useState(0);

  function onNextPage() {
    if (pdfPage < numPdfPages) {
      setPdfPage(pdfPage + 1);
    }
  }

  function onPreviousPage() {
    if (pdfPage > 1) {
      setPdfPage(pdfPage - 1);
    }
  }

  function onRotate() {
    setRotate((rotate + 90) % 360);
  }

  function onDownload() {
    const linkSource = `data:application/pdf;base64,${pdfBase64}`;
    const downloadLink = document.createElement("a");
    const fileName = props.filename;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  useOnce(async function () {
    const documentsApi = await createDocumentsApi();
    const response = await documentsApi.downloadDocumentByS3Path({
      documentPath: props.documentS3Path,
    });
    setPdfBase64(response.base64EncodedPdf);
  });

  const url = `/view-document?documentS3Path=${props.documentS3Path}&filename=${props.filename}`;

  const width = props.width ?? 1000;

  if (pdfBase64 === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        <Stack>
          <HorizontalStack width={`${width}px`} align="spread">
            <Stack style={{ width: "100%" }}>
              {numPdfPages > 1 && (
                <Tag
                  color="orange"
                  icon={<WarningOutlined />}
                  style={{ fontSize: "14px", padding: "4px 12px" }}
                >
                  Multipage document
                </Tag>
              )}
              <HorizontalStack
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    onClick={onDownload}
                  >
                    Download
                  </Button>
                  <Button
                    type="link"
                    onClick={onRotate}
                    icon={<RotateRightOutlined />}
                  >
                    Rotate
                  </Button>
                  {props.shipmentId && props.companyId && props.onRefresh && (
                    <ExtractPageFromPdfButton
                      documentS3Path={props.documentS3Path}
                      shipmentId={props.shipmentId}
                      companyId={props.companyId}
                      pageNumber={pdfPage}
                      onRefresh={props.onRefresh}
                    />
                  )}
                  <Button type="link" icon={<LinkOutlined />} href={url}>
                    Permalink
                  </Button>
                </div>
                {numPdfPages > 1 && (
                  <HorizontalStack style={{ gap: "32px" }}>
                    <Button
                      type="link"
                      icon={<LeftOutlined />}
                      onClick={onPreviousPage}
                    >
                      Previous
                    </Button>
                    <div>
                      {pdfPage}/{numPdfPages}
                    </div>
                    <Button type="link" onClick={onNextPage}>
                      Next
                      <RightOutlined />
                    </Button>
                  </HorizontalStack>
                )}
              </HorizontalStack>
            </Stack>
          </HorizontalStack>
          <div style={{ border: "1px solid #ddd" }}>
            <Document
              file={`data:application/pdf;base64,${pdfBase64}`}
              onLoadSuccess={(pdf) => setNumPdfPages(pdf.numPages)}
            >
              <Page
                pageNumber={pdfPage}
                width={width}
                rotate={rotate}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          </div>
        </Stack>
      </>
    );
  }
}
