import { EyeTwoTone } from "@ant-design/icons";
import { Button, message } from "antd";
import { useQuotesApi } from "../../Apis/Apis";
import { QuotesTableProps } from "./ManageQuotesTab";

export function MakeEntireQuoteGroupVisibleButton(props: QuotesTableProps) {
  const shipmentId = props.data.shipmentId;
  const createQuotesApi = useQuotesApi();

  async function onMakeGroupVisible() {
    const quotesApi = await createQuotesApi();
    const quoteGroupName = props.quoteGroupName;
    try {
      await quotesApi.markQuoteGroupCustomerVisible({
        shipmentId,
        quoteGroupName,
      });
      await props.onRefresh();
      message.success(`🗑 Quote Group ${quoteGroupName} made visible`);
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  function allVisible(): boolean {
    // Can't make visible if every one is already visible
    return props.quotes.every((q) => !q.hiddenFromCustomer);
  }

  if (allVisible()) {
    return <></>;
  } else {
    return (
      <Button type="link" icon={<EyeTwoTone />} onClick={onMakeGroupVisible}>
        Make Entire Group Visible
      </Button>
    );
  }
}
