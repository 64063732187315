import {
  Quote,
  RunQuoteInputLocation,
} from "@freightsimple/generated-apollo-openapi-client";
import { EyeInvisibleTwoTone, EyeTwoTone } from "@ant-design/icons";
import { Button, Col, message, Modal, Row, Typography } from "antd";
import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { useQuotesApi } from "../../Apis/Apis";
import CarrierLogo from "../../Components/CarrierLogo";
import HorizontalStack from "../../Components/HorizontalStack";
import { Json } from "../../Components/Json";
import { KeyValues } from "../../Components/KeyValues";
import { Loading } from "../../Components/Loading";
import Stack from "../../Components/Stack";
import { ViewHtmlButton } from "../../Components/ViewHtmlButton";
import { ViewMarkdownButton } from "../../Components/ViewMarkdownButton";
import { isPhone } from "../../Helpers/isPhone";
import Spacer from "../../Spacer";
import { LineItemsTable } from "./LineItemsTable";
import { QuoteGeneralInfo } from "./LocationInfo";
import { describeAccessorials } from "./describeAccessorials";
import { ButtonType } from "../../Types/AntHelperTypes";
const { Title } = Typography;
interface ViewQuoteButtonProps {
  quoteId: string;
  text?: string;
  style?: CSSProperties;
  type?: ButtonType;
}

interface ColumnProps {
  children: ReactNode;
}

function Column(props: ColumnProps) {
  if (isPhone) {
    return (
      <div
        style={{
          padding: "0",
          margin: "0",
          marginTop: "64px",
          border: "none",
          borderBottom: "3px solid #444",
          paddingBottom: "4px",
        }}
      >
        {props.children}
      </div>
    );
  } else {
    return (
      <Col span={12}>
        <div
          style={{
            padding: "32px",
            margin: "16px",
            border: "1px solid #ddd",
          }}
        >
          {props.children}
        </div>
      </Col>
    );
  }
}

function renderLines(lines: Array<string | undefined>): Array<ReactNode> {
  return lines
    .filter((line) => line !== undefined && line !== "")
    .map(function (line, index) {
      return <div key={index}>{line}</div>;
    });
}

function describeCityStatePostalCode(
  location: RunQuoteInputLocation,
): Array<ReactNode> {
  const cityStatePostalCode = `${location.city}, ${location.stateOrProvinceCode}, ${location.postalCode}`;

  const lines = [cityStatePostalCode];
  return renderLines(lines);
}

interface LocationInfoForQuoteProps {
  location: RunQuoteInputLocation;
  locationType: string;
  accessorials: string[];
  context: string;
}

function LocationInfoForQuote(props: LocationInfoForQuoteProps) {
  try {
    return (
      <KeyValues
        data={{
          [`${props.context} Location`]: describeCityStatePostalCode(
            props.location,
          ),
          [`${props.context} Accessorials`]: describeAccessorials(
            props.accessorials,
          ),
          [`${props.context} Location Type`]: props.locationType,
        }}
      />
    );
  } catch (e: any) {
    return <div>Oops. Somethign went wrong {e.toString()}</div>;
  }
}

interface QuotePriceInfoProps {
  quote: Quote;
}

export function QuotePriceInfo(props: QuotePriceInfoProps) {
  const data: Record<string, any> = {};

  const quote = props.quote;

  data["Customer Price"] = (
    <Stack align="left">
      <div>{quote.priceCad} CAD</div>
      <div>
        {quote.customerPriceExplanation !== undefined && (
          <ViewMarkdownButton
            title="Explain"
            type="link"
            data={quote.customerPriceExplanation}
          />
        )}
      </div>
    </Stack>
  );
  data["Visible"] = (
    <HorizontalStack>
      <CustomerVisibleIcon
        hiddenFromCustomer={props.quote.hiddenFromCustomer!}
      />
      <Spacer width={4} />
      <div>{props.quote.hiddenFromCustomer ? "Hidden" : "Visible"}</div>
    </HorizontalStack>
  );
  data["Carrier"] = (
    <CarrierLogo
      carrierIdentifier={quote.carrierIdentifier}
      brokeredCarrierIdentifier={quote.brokeredCarrierIdentifier}
      width={60}
      height={40}
    />
  );
  data["Service"] = <>{quote.serviceIdentifier}</>;
  data["Quote Reference"] = <>{quote.carrierQuoteReference}</>;
  // data[""] = <>{quote.</>

  data["Price Quoted By Carrier"] = (
    <Stack align="left">
      <div>{quote.quotedPriceByCarrierCad} CAD</div>
      <div>
        {quote.carrierPriceExplanationInternal !== undefined && (
          <ViewHtmlButton
            title="Explain (Internal)"
            type="link"
            data={quote.carrierPriceExplanationInternal}
          />
        )}
      </div>
      <div>
        {quote.carrierPriceExplanationExternal !== undefined && (
          <ViewHtmlButton
            title="Explain (External)"
            type="link"
            data={quote.carrierPriceExplanationExternal}
          />
        )}
      </div>
      <div>
        {quote.loadingDiagramSvg !== undefined && (
          <ViewHtmlButton
            title="Loading Diagram"
            type="link"
            data={quote.loadingDiagramSvg}
          />
        )}
      </div>
    </Stack>
  );

  data["Price Quoted By Carrier (Without Tax)"] = (
    <>{quote.quotedPriceByCarrierCadWithoutTax} CAD</>
  );
  data["Includes Tax?"] = <>{quote.includesTax ? "Yes" : "No"}</>;
  data["Price Quoted By Carrier (Original Currency)"] = (
    <>
      {quote.quotedPriceByCarrierOriginalCurrency} {quote.originalCurrency}
    </>
  );
  data["Additional Carrier Fees"] = <>{quote.additionalCarrierFeesCad}</>;
  data["Additional Carrier Notes"] = <>{quote.additionalCarrierFeesNotes}</>;
  data["Insurance Price"] = <>{quote.insurancePriceCad} CAD</>;

  // I think this is needed because of a bug in the de-serialization
  // taxLineValueCad_1 doesn't get deserialized into taxLineValueCad1
  const taxLineValue1 = quote["taxLineValueCad_1"];
  const taxLineDescription1 = quote["taxLineDescription_1"];
  const taxLineValue2 = quote["taxLineValueCad_2"];
  const taxLineDescription2 = quote["taxLineDescription_2"];
  data[`Tax Line (${taxLineDescription1})`] = <>{taxLineValue1} CAD</>;
  if (taxLineDescription2) {
    data[`Tax Line 2 (${taxLineDescription2})`] = <>{taxLineValue2} CAD</>;
  }

  return <KeyValues data={data} />;
}

interface CustomerVisibleIconProps {
  hiddenFromCustomer: boolean;
}
function CustomerVisibleIcon(props: CustomerVisibleIconProps) {
  if (props.hiddenFromCustomer) {
    return <EyeInvisibleTwoTone twoToneColor="#ccc" />;
  } else {
    return <EyeTwoTone />;
  }
}

export function ViewQuoteButton(props: ViewQuoteButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { quoteId } = props;
  const [quote, setQuote] = useState<Quote>();
  const createQuotesApi = useQuotesApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  async function refresh() {
    try {
      const quotesApi = await createQuotesApi();
      const response = await quotesApi.getQuoteById({ quoteId });
      setQuote(response);
    } catch {
      message.error("Something bad happened");
    }
  }

  useEffect(
    function () {
      if (isModalVisible) {
        refresh();
      }
    },
    [isModalVisible, quoteId],
  );

  return (
    <>
      <Modal
        title={
          <HorizontalStack>
            <div>Quote Info</div>
          </HorizontalStack>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
      >
        {quote !== undefined && (
          <>
            <Row>
              <Column>
                <HorizontalStack align="spread">
                  <Title level={4}>Price Info</Title>
                </HorizontalStack>
                <QuotePriceInfo quote={quote} />
              </Column>
              <Column>
                <HorizontalStack align="spread">
                  <Title level={4}>General Info</Title>
                </HorizontalStack>
                <QuoteGeneralInfo quote={quote} />
              </Column>
            </Row>
            <Row>
              <Column>
                <HorizontalStack align="spread">
                  <Title level={4}>Pickup</Title>
                </HorizontalStack>
                <LocationInfoForQuote
                  location={quote.pickupLocation!}
                  accessorials={quote.pickupAccessorials!}
                  locationType={quote.pickupLocationType!}
                  context="Pickup"
                />
              </Column>
              <Column>
                <HorizontalStack align="spread">
                  <Title level={4}>Delivery</Title>
                </HorizontalStack>

                <LocationInfoForQuote
                  location={quote.deliveryLocation!}
                  accessorials={quote.deliveryAccessorials!}
                  locationType={quote.deliveryLocationType!}
                  context="Delivery"
                />
              </Column>
            </Row>
            <Row>
              <div
                style={{
                  padding: "32px",
                  margin: "16px",
                  border: "1px solid #ddd",
                }}
              >
                <HorizontalStack width="100%" align="spread">
                  <Title level={4}>Line Items</Title>
                </HorizontalStack>
                <LineItemsTable lineItems={quote.lineItems!} />
              </div>
            </Row>
            <Json data={quote} />
          </>
        )}
        {quote === undefined && <Loading />}
      </Modal>
      <Button type={props.type} style={props.style} onClick={showModal}>
        {props.text ?? `View`}
      </Button>
    </>
  );
}
