import {
  FreightClaim,
  FreightClaimType,
} from "@freightsimple/generated-apollo-openapi-client";
import { sum } from "../../Helpers/sum";

export function calculateFreightClaimTotal(fc: FreightClaim) {
  if (fc.freightClaimLineItems === undefined) {
    return 0;
  }

  const lostOrDamagedFreight = fc.freightClaimLineItems.filter(
    (f) =>
      f.claimType === FreightClaimType.Damage ||
      f.claimType === FreightClaimType.Loss,
  );

  return sum(lostOrDamagedFreight, (li) => li.piecesAffected! * li.unitCost!);
}
