import { Tag, Tooltip } from "antd";
import { getShipmentProblem } from "../Helpers/shipmentProblems";

interface ShipmentProblemTagProps {
  code: string;
}

export function ShipmentProblemTag({ code }: ShipmentProblemTagProps) {
  const problem = getShipmentProblem(code);
  return (
    <Tooltip title={problem.description}>
      <Tag color="magenta">🚩 {problem.name}</Tag>
    </Tooltip>
  );
}
