import {
  CompanyData,
  Invitation,
  User,
} from "@freightsimple/generated-apollo-openapi-client";

import {
  DatePicker,
  Form,
  Menu,
  message,
  Modal,
  Table,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { safeColumns } from "../../Helpers/safeColumns";
import {
  CalendarOutlined,
  StopOutlined,
  WarningFilled,
} from "@ant-design/icons";
import Colors from "../../Components/Colors";
import { isDateInThePast } from "../../Helpers/isDateInThePast";
import { useDisplay } from "../../Hooks/useDisplay";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import dayjs from "dayjs";

interface InvitationsTableProps {
  companyData: CompanyData;
  onRefresh: () => Promise<void>;
}

interface DetailedInvitation {
  invitation: Invitation;
  sentBy: User | undefined;
}

export function InvitationsTable({ companyData }: InvitationsTableProps) {
  const [invitations, setInvitations] = useState<DetailedInvitation[]>([]);
  const createCompaniesApi = useCompaniesApi();

  async function fetchInvitations() {
    if (companyData.company.companyId === undefined) {
      return;
    }
    const api = await createCompaniesApi();
    const invitationsResponse = await api.listInvitationsByCompany({
      companyId: companyData.company.companyId,
    });
    setInvitations(
      invitationsResponse.map((invitation) => ({
        invitation: invitation,
        sentBy: companyData.users.find(
          (u) => u.userId === invitation.invitationSentByUserId,
        ),
      })),
    );
  }

  useEffect(() => {
    fetchInvitations();
  }, [companyData.company.companyId]);

  return (
    <>
      <Table
        columns={safeColumns<DetailedInvitation>([
          {
            title: "Expires At",
            render: (_, detail) => {
              const mDate = dayjs(detail.invitation.expiresAt);
              const isInThePast = isDateInThePast(detail.invitation.expiresAt);
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "16px",
                  }}
                >
                  {isInThePast && (
                    <WarningFilled
                      style={{ fontSize: "32px", color: Colors.Orange }}
                    />
                  )}
                  <div>
                    {isInThePast ? (
                      <Typography.Text strong style={{ color: Colors.Orange }}>
                        Expired {mDate.fromNow()}
                      </Typography.Text>
                    ) : (
                      <Typography.Text>
                        Expires {mDate.fromNow()}
                      </Typography.Text>
                    )}

                    <Typography.Text
                      style={{ display: "block" }}
                      type={isInThePast ? undefined : "secondary"}
                    >
                      {mDate.format("MMMM Do YYYY - HH:mm")}
                    </Typography.Text>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Invitee",
            render: (_, detail) => (
              <div>
                {detail.invitation.name}
                <Typography.Text type="secondary" style={{ display: "block" }}>
                  {detail.invitation.emailAddress}
                </Typography.Text>
              </div>
            ),
          },
          {
            title: "Sent By",
            render: (_, detail) => (
              <div>
                {detail.sentBy?.name}
                <Typography.Text type="secondary" style={{ display: "block" }}>
                  {detail.sentBy?.email}
                </Typography.Text>
              </div>
            ),
          },
          {
            title: "Last Sent At",
            render: (_, detail) => {
              const mDate = dayjs(detail.invitation.lastSentAt);
              return (
                <div>
                  {mDate.format("MMMM Do YYYY")}
                  <Typography.Text
                    type="secondary"
                    style={{ display: "block" }}
                  >
                    {mDate.fromNow()}
                  </Typography.Text>
                </div>
              );
            },
          },
          {
            title: "Actions",
            render: function (_, detail) {
              return (
                <ButtonRow
                  extrasMenu={
                    <Menu>
                      <ChangeExpirationDateMenuItem
                        invitation={detail.invitation}
                        onRefresh={fetchInvitations}
                      />
                      <RevokeInvitationMenuItem
                        invitation={detail.invitation}
                        onRefresh={fetchInvitations}
                      />
                    </Menu>
                  }
                >
                  <></>
                </ButtonRow>
              );
            },
          },
        ])}
        dataSource={invitations}
      />
    </>
  );
}

function ChangeExpirationDateMenuItem({
  invitation,
  onRefresh,
}: {
  invitation: Invitation;
  onRefresh: () => Promise<void>;
}) {
  const display = useDisplay();
  const createCompaniesApi = useCompaniesApi();

  const [date, setDate] = useState(dayjs(invitation.expiresAt!));
  const [loading, setLoading] = useState(false);

  async function handleOk() {
    setLoading(true);
    try {
      const api = await createCompaniesApi();
      const request = {
        expirationDate: date.format("YYYY-MM-DD") + "T23:59:59.000Z",
        invitationToken: invitation.invitationToken!,
      };
      await api.updateInvitationExpirationDate(request);
      onRefresh();
      display.hide();
      message.success("Expiration date updated!");
    } catch {
      message.error("Something went wrong!");
    }
    setLoading(false);
  }

  function handleChange(value: dayjs.Dayjs | null) {
    if (value) {
      setDate(value);
    }
  }

  return (
    <>
      <Modal
        open={display.status}
        onCancel={display.hide}
        onOk={handleOk}
        title="Change expiration date"
        width={600}
        confirmLoading={loading}
      >
        <Form style={{ display: "flex", justifyContent: "center" }}>
          <Form.Item label="Expiration Date">
            <DatePicker value={date} onChange={handleChange} />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item icon={<CalendarOutlined />} onClick={display.show}>
        Change expiration date
      </Menu.Item>
    </>
  );
}

function RevokeInvitationMenuItem({
  invitation,
  onRefresh,
}: {
  invitation: Invitation;
  onRefresh: () => Promise<void>;
}) {
  const display = useDisplay();
  const [loading, setLoading] = useState(false);
  const createCompaniesApi = useCompaniesApi();

  async function handleRevokeInvitation() {
    setLoading(true);
    try {
      const api = await createCompaniesApi();
      await api.revokeInvitation({
        invitationToken: invitation.invitationToken!,
      });
      onRefresh();
      display.hide();
      message.success("Invitation revoked!");
    } catch {
      message.error("Something went wrong!");
    }
    setLoading(false);
  }

  return (
    <ConfirmMenuItem
      loading={loading}
      onConfirm={handleRevokeInvitation}
      question={"Are you sure you want to revoke this invitation?"}
      okText={"Yes"}
      tooltip={
        "This will revoke and delete this invitation, the invitee will not be able to join the company using this invitation"
      }
      icon={<StopOutlined style={{ color: Colors.Red }} />}
    >
      Revoke invitation
    </ConfirmMenuItem>
  );
}
