import { Collapse } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";

interface CollapsibleNoteProps {
  collapseActive: boolean;
  header: ReactNode;
  children: ReactNode;
}

export function CollapsibleNote({
  header,
  children,
  collapseActive = false,
}: CollapsibleNoteProps) {
  const [active, setActive] = useState(false);

  function handleChange(e: string | string[]) {
    setActive(e.length > 0);
  }

  useEffect(() => {
    setActive(collapseActive);
  }, [collapseActive]);

  return (
    <Collapse
      ghost
      expandIconPosition="start"
      onChange={handleChange}
      activeKey={active ? ["1"] : []}
    >
      <Collapse.Panel
        forceRender
        header={
          <div style={{ display: "flex", alignItems: "center" }}>
            {header}
            <RightOutlined
              style={{
                fontSize: "12px",
                marginLeft: "15px",
                transform: active ? "rotate(90deg)" : "",
                transition: "transform 150ms ease",
              }}
            />
          </div>
        }
        key="1"
        className="custom-panel"
        showArrow={false}
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
}
