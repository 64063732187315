import locationTypes from "../GeneratedFixtures/LocationTypes.json";

export function nameLocationType(locationType: string): string {
  return locationTypes.find((l) => l.type === locationType)!.name;
}

export function describeLocationType(locationType: string): string {
  return locationTypes.find((l) => l.type === locationType)!.description;
}

export function getLocationTypes() {
  return locationTypes.map((l) => l.type);
}
