export enum Environment {
  Local = "local",
  Preprod = "preprod",
  Demo = "demo",
  Production = "production",
}

export function getEnvironmentOverride(): Environment | undefined {
  return import.meta.env.VITE_LOCAL_OVERRIDE_ENVIRONMENT;
}

export function getEnvironment(): Environment {
  const environmentOverride = getEnvironmentOverride();

  if (environmentOverride) {
    return environmentOverride as Environment;
  }

  // Try to get environment from meta tag first
  const envMeta = document.querySelector('meta[name="environment"]');
  const metaEnvironment = envMeta?.getAttribute("content");

  if (metaEnvironment === undefined) {
    throw new Error("Meta environment tag not found");
  }

  if (metaEnvironment === "%ENVIRONMENT%") {
    return Environment.Local;
  }

  // Check if the value exists in Environment enum
  if (Object.values(Environment).includes(metaEnvironment as Environment)) {
    return metaEnvironment as Environment;
  }

  throw new Error(
    `Should not be here - problem with meta environment tag: ${metaEnvironment}`,
  );
}
