import { useEffect } from "react";
import { Loading } from "../Components/Loading";
import useQuery from "../Hooks/useQuery";
import { useLookup } from "../Navigation/useLookup";

export function LookupScreen() {
  const query = useQuery();
  const { onSearch, loading } = useLookup();

  const q = query.query as string;

  useEffect(
    function () {
      onSearch(q);
    },

    [q],
  );

  if (loading) {
    return <Loading message="Looking up data" />;
  } else {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          minHeight: "600px",
        }}
      >
        ❌ Sorry, nothing found for "{q}"
      </div>
    );
  }
}
