import {
  AdditionalCharge,
  AdditionalChargeState,
  EmailDescription,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";

import { message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import { SupportAgent } from "../ViewCompanyScreen";
import { ExtraTextButton } from "./ExtraTextButton";
import { SupportEmailButton } from "./SupportEmailButton";

interface WaivedAdditionaChargeSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
  additionalCharge: AdditionalCharge;
}

export function WaivedAdditionaChargeSupportEmailButton(
  props: WaivedAdditionaChargeSupportEmailButtonProps,
) {
  const shipmentId = props.data.shipmentId;
  const additionalCharge = props.additionalCharge;
  const additionalChargeId = additionalCharge.additionalChargeId!;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");

  if (
    additionalCharge.state !== AdditionalChargeState.Proposed &&
    additionalCharge.state !== AdditionalChargeState.Waived
  ) {
    return <></>;
  }

  const hash = JSON.stringify({
    additionalChargeId,
    extraText,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent,
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewWaivedAdditionalChargeEmail({
        shipmentId,
        additionalChargeId,
        extraText,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch {
      message.error(`Oops. Something went wrong`);
      return undefined;
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[],
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendWaivedAdditionalChargeEmail({
        shipmentId,
        additionalChargeId,
        extraText,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="✉️ Waived Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
        </>
      }
      shipmentData={props.data}
    />
  );
}
