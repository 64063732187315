import { ShipmentState } from "@freightsimple/generated-apollo-openapi-client";
import { Alert, Button, message } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { ConfirmButton } from "../../Components/ConfirmButton";
import { WarningComponent } from "../../Components/WarningComponent";
import { RequestPhotosOfMissingFreightSupportEmailButton } from "./RequestPhotosOfMissingFreightSupportEmailButton";
import { TabProps } from "./TabProps";

function MarkAsLostButton(props: TabProps) {
  const createShipmentApi = useShipmentsApi();
  const [loading, setLoading] = useState(false);
  const shipmentId = props.shipmentData.shipmentId;

  async function onConfirm() {
    setLoading(true);
    try {
      const shipmentApi = await createShipmentApi();
      await shipmentApi.markLost({ shipmentId });
      await props.onRefresh();
      message.success(`Marked lost`);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
  }

  return (
    <ConfirmButton
      onConfirm={onConfirm}
      question="Are you sure you want to mark as lost? Do this when we think it is permanently lost, and we don't need to track it any more"
      okText="Mark as lost"
      disabled={
        props.shipmentData.shipment.shipment.state === ShipmentState.Lost
      }
      tooltip="This will mark as lost, to pause Amy/tracking when we think a shipment is permanently lost"
      ghost
      loading={loading}
    >
      😢 Mark as lost
    </ConfirmButton>
  );
}

export function MissingFreightBanner(props: TabProps) {
  if (!props.shipmentData.shipment.shipment.freightMissing) {
    return <></>;
  }

  return (
    <Alert
      type="warning"
      description={
        <>
          <WarningComponent /> Freight is currently marked as missing (as of{" "}
          {props.shipmentData.shipment.shipment.freightMissingDate}). Please
          follow the playbook
        </>
      }
      action={
        <ButtonRow>
          <MarkAsLostButton {...props} />
          <Button
            ghost
            target="_new"
            href="https://www.notion.so/freightsimple/Missing-Lost-Freight-e12c7e7e007b4a839c0b907d7b8753e9?pvs=4"
          >
            View Playbook
          </Button>
          <RequestPhotosOfMissingFreightSupportEmailButton
            data={props.shipmentData}
          />
        </ButtonRow>
      }
    ></Alert>
  );
}
