import {
  Note,
  NoteEmailAddress,
} from "@freightsimple/generated-apollo-openapi-client";
import { NoteHeadline } from "../NotesBase/NoteHeadline";
import { NoteMessage } from "../NotesBase/NoteMessage";
import { ButtonRow } from "../ButtonRow";
import { ViewNoteEmailMenuItem } from "../../Screens/ViewShipmentScreenComponents/ViewNoteEmailMenuItem";
import { stringToColor } from "../../Helpers/stringToColor";
import Spacer from "../../Spacer";

interface EmailNoteElementProps {
  note: Note;
}

function describeEmail(emailAddress: NoteEmailAddress | undefined) {
  if (emailAddress === undefined) {
    return "";
  }
  return emailAddress.name + " <" + emailAddress.emailAddress + ">";
}

function describeEmails(emailAddresses: NoteEmailAddress[] | undefined) {
  if (emailAddresses === undefined) {
    return "";
  }
  return emailAddresses.map(describeEmail).join(", ");
}

function formatEmailBody(body: string | undefined) {
  if (body === undefined) {
    return "";
  }
  return body
    .split("\n")
    .map((line, index) => <div key={index}>{line}</div>)
    .slice(0, 4);
}

export function EmailNoteElement(props: EmailNoteElementProps) {
  const { note } = props;
  return (
    <div
      style={{
        paddingLeft: "8px",
        borderLeft: `4px solid ${stringToColor(note.link ?? "")}`,
      }}
    >
      <NoteHeadline>Subject: {note.emailSubject}</NoteHeadline>
      <NoteHeadline>From: {describeEmail(note.emailFrom)}</NoteHeadline>
      <NoteHeadline>To: {describeEmails(note.emailTo)}</NoteHeadline>
      {note.emailCc && note.emailCc.length > 0 && (
        <NoteHeadline>CC: {describeEmails(note.emailCc)}</NoteHeadline>
      )}
      <NoteMessage>{formatEmailBody(note.emailBodyCleanedUp)}</NoteMessage>
      <Spacer height={16} />
      <ButtonRow>
        <ViewNoteEmailMenuItem note={note} />
      </ButtonRow>
    </div>
  );
}
