import { Note, NoteType } from "@freightsimple/generated-apollo-openapi-client";
import { ShipmentModifiedNote } from "../NotesBase/shipment/ShipmentModifiedNote";
import { ReferenceNumberChangedNote } from "../NotesBase/shipment/ReferenceNumberChangedNote";
import { ApolloActionNote } from "../NotesBase/shipment/ApolloActionNote";
import { EmailNoteElement } from "./EmailNoteElement";
import { DialpadNoteElement } from "./DialpadNoteElement";
import { IntercomNoteElement } from "./IntercomNoteElement";
import { BaseNote } from "./BaseNote";

interface NoteElementProps {
  note: Note;
}

export function NoteElement(props: NoteElementProps) {
  const { note } = props;

  switch (note.noteType) {
    case NoteType.Email:
      return <EmailNoteElement note={note} />;
    case NoteType.Dialpad:
      return <DialpadNoteElement note={note} />;
    case NoteType.Intercom:
      return <IntercomNoteElement note={note} />;
    case NoteType.ShipmentModified:
      return <ShipmentModifiedNote note={note} />;
    case NoteType.ReferenceNumberChanged:
      return <ReferenceNumberChangedNote note={note} />;
    case NoteType.ApolloAction:
      return <ApolloActionNote note={note} />;
    default:
      return <BaseNote note={note} />;
  }
}
