import {
  CompanyData,
  CompanyNote,
  CompanyNoteType,
} from "@freightsimple/generated-apollo-openapi-client";

import { TimelineItemProps, Typography } from "antd";
import Colors from "../../../Components/Colors";
import Stack from "../../../Components/Stack";
import { groupBy } from "../../../Helpers/groupBy";
import {
  PhoneOutlined,
  RocketOutlined,
  TeamOutlined,
  UserOutlined,
  FileSearchOutlined,
  CreditCardOutlined,
  CloudServerOutlined,
  MessageOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import {
  groupCollapsibleElements,
  GroupedShowGroups,
} from "../../../Helpers/groupCollapsibleElements";
import { CSSProperties } from "react";
import Spacer from "../../../Spacer";
import { LeftAlignedTimeline } from "../../../Components/LeftAlignedTimeline";
import { assertNever } from "../../../Helpers/assertNever";
import dayjs from "dayjs";
import { ErrorCompanyNoteElement } from "../ErrorCompanyNoteElement";
import { FallbackErrorBoundary } from "../../../Components/FallbackErrorBoundary";
import { CompanyNoteElement } from "../CompanyNote";
import { useGroupedCollapsibleTimelineItems } from "../../../Components/Timeline/useGroupedCollapsibleTimelineItems";
import { TimelineCollapseHeader } from "../../../Components/Timeline/TimelineCollapseHeader";

const { Title } = Typography;
export type CompanyNotesTimelineOrigin =
  | "sales-call-list"
  | "company-notes-tab";
interface CompanyNotesTimelineProps {
  data?: CompanyData;
  noteTypeFilter: CompanyNoteType | CompanyNoteType[] | undefined;
  noteAuthorFilter: string | string[] | undefined;
  significantFilter: boolean | undefined;
  freeFilter: string | undefined;
  notes: CompanyNote[];
  includeViewCompanyLinks?: boolean;
  onRefresh: () => Promise<void>;
  collapse: boolean;
  origin: CompanyNotesTimelineOrigin;
}

export function CompanyNotesTimeline(props: CompanyNotesTimelineProps) {
  function filterByNoteType(note: CompanyNote): boolean {
    if (
      props.noteTypeFilter === undefined ||
      props.noteTypeFilter.length === 0
    ) {
      return true;
    }

    return note.type ? props.noteTypeFilter.includes(note.type) : false;
  }

  function filterBySignificant(note: CompanyNote): boolean {
    if (props.significantFilter === undefined) {
      return true;
    }

    return note.significant === props.significantFilter;
  }

  function filterByAuthor(note: CompanyNote): boolean {
    if (
      props.noteAuthorFilter === undefined ||
      props.noteAuthorFilter.length === 0
    ) {
      return true;
    }

    return note.author ? props.noteAuthorFilter.includes(note.author) : false;
  }

  function applyFreeFilter(note: CompanyNote): boolean {
    if (props.freeFilter === undefined || props.freeFilter.trim() === "") {
      return true;
    }

    return JSON.stringify(note)
      .toLowerCase()
      .includes(props.freeFilter.toLowerCase());
  }

  function filterNotes() {
    return props.notes
      .filter(filterByNoteType)
      .filter(filterByAuthor)
      .filter(filterBySignificant)
      .filter(applyFreeFilter);
  }

  function sortByDate(a: CompanyNote, b: CompanyNote) {
    return dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf();
  }

  function groupByCreationDate(note: CompanyNote) {
    const m = dayjs.utc(note.createdAt).tz("America/Vancouver");
    return m.format("MMMM YYYY");
  }

  /**
   * Notes to show after applying the filters
   */
  const noteToShow = filterNotes();

  const groupedByDate = groupBy(
    props.notes.sort(sortByDate),
    groupByCreationDate,
  );

  const showGroupsByDate = groupCollapsibleElements(groupedByDate, (e) =>
    noteToShow.includes(e),
  );

  function renderItem(e: CompanyNote): TimelineItemProps {
    return {
      dot: <IconForCompanyNote note={e} />,
      label: <NoteTimelineLabel note={e} />,
      children: (
        <div style={{ marginLeft: 24, marginBottom: 16 }}>
          <FallbackErrorBoundary
            fallback={
              <ErrorCompanyNoteElement
                origin={props.origin}
                note={e}
                onRefresh={async () => {}}
                collapseActive={false}
              />
            }
          >
            <CompanyNoteElement
              origin={props.origin}
              data={props.data}
              note={e}
              onRefresh={props.onRefresh}
              collapseActive={props.collapse}
            />
          </FallbackErrorBoundary>
        </div>
      ),
    };
  }

  function renderGroupHeader(
    group: GroupedShowGroups<string, CompanyNote>,
  ): TimelineItemProps {
    return {
      dot: (
        <Title level={2} style={{ margin: 0 }}>
          {group.key}
        </Title>
      ),
      children: <Spacer height={50} />,
    };
  }

  const items = useGroupedCollapsibleTimelineItems({
    groups: showGroupsByDate,
    renderItem,
    renderGroupHeader,
    renderShowGroupHeader: (group, i, states, setState) => ({
      dot: (
        <TimelineCollapseHeader
          active={states[i]}
          onClick={() => setState(i, !states[i])}
        >
          {group.elements.length}
          {group.elements.length > 1 ? " notes" : " note"} hidden{" "}
        </TimelineCollapseHeader>
      ),
      children: <Spacer height={60} />,
    }),
  });

  return <LeftAlignedTimeline items={items} />;
}

export function NoteTimelineLabel({ note }: { note: CompanyNote }) {
  const date = dayjs(note.createdAt);
  return (
    <Stack align="right" style={{ marginRight: "20px" }}>
      <div style={{ fontSize: "14px", fontWeight: "500" }}>
        {date.format("dddd, Do")}
      </div>

      {note.origin && (
        <div style={{ fontSize: "12px", color: Colors.LightText }}>
          {note.origin}
        </div>
      )}

      <span style={{ fontSize: "12px", color: Colors.LightText }}>
        {date.format("h:mm a")}
      </span>
    </Stack>
  );
}

function IconForCompanyNote({ note }: { note: CompanyNote }) {
  const noteStyle: CSSProperties = {
    color: Colors.Blue,
    fontSize: "24px",
    marginRight: "8px",
    marginLeft: "8px",
  };
  const type = note.type!;
  switch (type) {
    case CompanyNoteType.DialpadRecording:
      return <AudioOutlined style={noteStyle} />;
    case CompanyNoteType.Call:
      return <PhoneOutlined style={noteStyle} />;
    case CompanyNoteType.ApolloAction:
      return <RocketOutlined style={noteStyle} />;
    case CompanyNoteType.UserAction:
      return <UserOutlined style={noteStyle} />;
    case CompanyNoteType.ShipmentBooking:
      return <TeamOutlined style={noteStyle} />;
    case CompanyNoteType.QuoteRun:
      return <FileSearchOutlined style={noteStyle} />;
    case CompanyNoteType.CreditModified:
      return <CreditCardOutlined style={noteStyle} />;
    case CompanyNoteType.SystemEvent:
      return <CloudServerOutlined style={noteStyle} />;
    case CompanyNoteType.InternalNote:
      return <MessageOutlined style={noteStyle} />;
    default:
      assertNever(type);
  }
  return <></>;
}
