export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const result = reader.result;
      if (result == null) {
        return reject(new Error("No result"));
      }

      if (typeof result !== "string") {
        return reject(new TypeError("Result is not a string"));
      }

      const base64String = result.replace("data:", "").replace(/^.+,/, "");

      // Logs wL2dvYWwgbW9yZ...
      resolve(base64String);
    };
    reader.readAsDataURL(blob);
  });
}
