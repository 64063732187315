import { Alert, Button, message } from "antd";

import { useShipmentsApi } from "../../Apis/Apis";
import { TabProps } from "./TabProps";
import dayjs from "dayjs";

export function AppointmentBanner(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const createShipmentsApi = useShipmentsApi();
  if (props.shipmentData.shipment.shipment.appointmentDate === undefined) {
    return <></>;
  }

  const m = dayjs(props.shipmentData.shipment.shipment.appointmentDate);

  const isAppointmentDateInThePast = m
    .startOf("day")
    .isBefore(dayjs().startOf("day"));

  if (isAppointmentDateInThePast) {
    return <></>;
  }

  async function onClearAppointment() {
    try {
      const shipmentsApi = await createShipmentsApi();

      await shipmentsApi.unsetAppointmentDate({ shipmentId });

      await props.onRefresh();
      message.success(`Appointment unset`);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  return (
    <Alert
      type="warning"
      description={`⏸ Appointment set ${m.format(
        "dddd, MMMM Do YYYY",
      )} (emails paused until then)`}
      action={
        <Button onClick={onClearAppointment} ghost>
          Clear appointment
        </Button>
      }
    ></Alert>
  );
}
