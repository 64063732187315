import JSONPretty from "react-json-pretty";

interface JsonProps {
  data: any;
}

export function Json(props: JsonProps) {
  return (
    <JSONPretty
      id="json-pretty"
      data={props.data}
      theme={{
        main: "font-size:1.2em;line-height:1.3;color:#111;overflow:auto;",
        error: "line-height:1.3;color:#66d9ef;overflow:auto;",
        key: "color:#6307AF;",
        string: "color:#53AF07;",
        value: "color:#AF07A7;",
        boolean: "color:#0F07AF;",
      }}
    ></JSONPretty>
  );
}
