import {
  CarrierServiceType,
  EmailDescription,
  EmailQuoteRequestAndResponses,
  PreviewRequestQuoteEmailRequest,
  RequestQuoteViaEmailRequest,
} from "@freightsimple/generated-apollo-openapi-client";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "antd";
import {
  ChangeEvent,
  Dispatch,
  Key,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useQuotesApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import HorizontalStack from "../../Components/HorizontalStack";
import { isDateInThePast } from "../../Helpers/isDateInThePast";
import { TabProps } from "./TabProps";
import CarrierLogo from "../../Components/CarrierLogo";

import type { TableColumnType } from "antd";
import Stack from "../../Components/Stack";
import { TableRowSelection } from "../../Types/AntHelperTypes";
import { useDisplay } from "../../Hooks/useDisplay";
import { PlusOutlined } from "@ant-design/icons";
import Spacer from "../../Spacer";
import { useDebouncedCallback } from "use-debounce";
import Colors from "../../Components/Colors";
import { isBlank } from "@freightsimple/shared";
import { produce } from "immer";

const { Text } = Typography;

interface EmailDescriptionWithExtra extends EmailDescription {
  extra: string;
}

type QuotingEmailRequest = {
  key: string;
  extraText: string;
  generalExtraText: string;
  carrier?: CarrierServiceType;
  addresses?: string[];
  email?: EmailDescription;
};

function usePreviewQuotingEmail() {
  const createQuotesApi = useQuotesApi();

  async function fetchPreview(
    shipmentId: string,
    row: QuotingEmailRequest,
  ): Promise<EmailDescriptionWithExtra> {
    const request: PreviewRequestQuoteEmailRequest = {
      shipmentId,
      extraText: row.generalExtraText + " " + row.extraText,
      carrierIdentifier: row.carrier?.carrierIdentifier,
      serviceIdentifier: row.carrier?.serviceIdentifier,
      addresses: row.addresses,
    };
    const quotesApi = await createQuotesApi();
    const response = await quotesApi.previewRequestQuoteEmail(request);
    return { ...response, extra: request.extraText };
  }

  return { fetchPreview };
}

export function EmailForQuoteButton(props: TabProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!;
  const display = useDisplay();
  const createQuotesApi = useQuotesApi();
  const previewQuotingEmail = usePreviewQuotingEmail();

  const [ignoreDimensionChecks, setIgnoreDimensionChecks] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [requests, setRequests] = useState<QuotingEmailRequest[]>([]);
  const [customAddress, setCustomAddress] = useState("");
  const [generalExtraText, setGeneralExtraText] = useState("");
  const [sending, setSending] = useState(false);

  async function fetchCarriersThatProvideEmailQuotes(
    shipmentId: string,
    ignoreDimensionChecks: boolean,
  ): Promise<CarrierServiceType[]> {
    const quotesApi = await createQuotesApi();

    const response = await quotesApi.fetchCarriersThatProvideEmailQuotes({
      shipmentId,
      ignoreDimensionChecks,
    });
    return response;
  }

  function filterPotentialCarriers(
    carriers: CarrierServiceType[],
    emailQuotesRequests: EmailQuoteRequestAndResponses[],
  ): CarrierServiceType[] {
    return carriers.filter(function (o) {
      return !emailQuotesRequests.some(
        (eqr) =>
          o.carrierIdentifier === eqr.request.carrierIdentifier &&
          o.serviceIdentifier === eqr.request.serviceIdentifier,
      );
    });
  }

  async function fetchPotentialCarriers() {
    const carriers = await fetchCarriersThatProvideEmailQuotes(
      shipmentId,
      ignoreDimensionChecks,
    );

    const requestsAlreadySent =
      props.shipmentData.manageQuotesTab.emailQuoteRequests;

    const potentialCarriers = filterPotentialCarriers(
      carriers,
      requestsAlreadySent,
    );

    const requests: QuotingEmailRequest[] = potentialCarriers.map((r) => ({
      key: r.carrierIdentifier + r.serviceIdentifier,
      carrier: r,
      extraText: "",
      generalExtraText,
    }));

    const requestsWithPreviews = await fetchPreviews(requests);

    setRequests(requestsWithPreviews);
    setSelectedRowKeys([]);
  }

  useEffect(() => {
    if (display.status) {
      fetchPotentialCarriers();
    }
  }, [display.status, ignoreDimensionChecks, shipmentId]);

  const showModal = () => {
    const pickupDate = props.shipmentData.shipment.shipment.pickupDate;
    const isPickupInThePast = isDateInThePast(pickupDate);
    if (isPickupInThePast) {
      message.error(`Pickup date is in the past. Please correct first`);
      return;
    }
    display.show();
  };

  async function handleOk() {
    setSending(true);
    try {
      const selected = requests.filter((e) => selectedRowKeys.includes(e.key));

      const sendEmailsRequests: RequestQuoteViaEmailRequest[] = selected.map(
        (s) => ({
          shipmentId,
          extraText: s.generalExtraText + " " + s.extraText,
          carrierIdentifier: s.carrier?.carrierIdentifier,
          serviceIdentifier: s.carrier?.serviceIdentifier,
          addresses: s.addresses,
        }),
      );

      const quotesApi = await createQuotesApi();

      const result = await Promise.allSettled(
        sendEmailsRequests.map((r) => {
          return quotesApi.requestQuoteViaEmail(r);
        }),
      );

      if (result.some((r) => r.status === "rejected")) {
        message.warning(
          "Something went wrong sending some emails, please try later",
        );
        console.error(result);
      } else {
        message.success(
          "Quoting emails sent. Monday Item potentially created.",
        );
      }
      await props.onRefresh();
      display.hide();
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setSending(false);
  }

  function handleCancel() {
    display.hide();
  }

  function isSelected(request: QuotingEmailRequest): boolean {
    return selectedRowKeys.includes(request.key);
  }

  async function handleAddCustomEmailAddress() {
    if (isBlank(customAddress)) {
      message.warning("Empty email address");
      return;
    }
    const row: QuotingEmailRequest = {
      key: customAddress,
      extraText: "",
      addresses: [customAddress],
      generalExtraText,
    };

    row.email = await previewQuotingEmail.fetchPreview(shipmentId, row);

    setRequests((prev) => prev.concat(row));
    setSelectedRowKeys((prev) => prev.concat(row.key));
    setCustomAddress("");
  }

  async function handleGeneralExtraChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;
    setGeneralExtraText(value);
    setRequests((prev) => prev.map((r) => ({ ...r, generalExtraText: value })));
  }

  async function fetchPreviews(
    toUpdate: QuotingEmailRequest[],
  ): Promise<QuotingEmailRequest[]> {
    const emailPromises = await Promise.allSettled(
      toUpdate.map(async (row) => {
        const email = await previewQuotingEmail.fetchPreview(shipmentId, row);
        return {
          ...row,
          email,
        };
      }),
    );

    const updated = emailPromises
      .filter((r) => r.status === "fulfilled")
      .map((r) => r.value);

    return updated;
  }

  async function onChange(selectedRowKeys: Key[]) {
    setSelectedRowKeys(selectedRowKeys);
  }

  const rowSelection: TableRowSelection<QuotingEmailRequest> = {
    selectedRowKeys,
    onChange,
    hideSelectAll: false,
  };

  const columns: TableColumnType<QuotingEmailRequest>[] = [
    {
      title: "Carrier",
      render: (r: QuotingEmailRequest) =>
        r.carrier ? (
          <HorizontalStack verticalAlign="middle">
            <CarrierLogo
              notClickable
              carrierIdentifier={r.carrier.carrierIdentifier}
              brokeredCarrierIdentifier={undefined}
              width={50}
              height={50}
            />
            <Stack align="left" style={{ marginInline: 8 }}>
              <span style={{ fontWeight: 600 }}>
                {r.carrier.carrierDisplayName}
              </span>
              <span title="Service Type">{r.carrier.serviceDisplayName} </span>
              <Typography.Text type="secondary" title="Service Type Identifier">
                {r.carrier.serviceIdentifier}
              </Typography.Text>
            </Stack>
          </HorizontalStack>
        ) : (
          <Typography.Text>Custom Email Address</Typography.Text>
        ),
    },
    {
      title: "Email Addresses",
      render: (r: QuotingEmailRequest) => (
        <span
          style={{
            color: isSelected(r) ? Colors.NormalText : Colors.LightText,
          }}
        >
          {r.email?.addresses?.join(", ") ?? "Not loaded"}
        </span>
      ),
    },
    {
      title: "Extra text",
      render: (r: QuotingEmailRequest) => (
        <span
          style={{
            color: isSelected(r) ? Colors.NormalText : Colors.LightText,
          }}
        >
          {r.generalExtraText} {r.extraText}
        </span>
      ),
    },
    {
      title: "",
      render: (r: QuotingEmailRequest) =>
        isSelected(r) && (
          <PreviewEmailModal
            currentRequest={r}
            setRequests={setRequests}
            requests={requests}
            {...props}
          />
        ),
    },
  ];

  const rows = requests.toSorted((a, b) => a.key.localeCompare(b.key));

  return (
    <>
      <Modal
        title={
          <HorizontalStack align="spread" style={{ marginRight: "32px" }}>
            <div>✉️ Email for quote</div>
            <ButtonRow>
              <Form colon={false}>
                <Form.Item label="Ignore Dimension Checks">
                  <Switch
                    checked={ignoreDimensionChecks}
                    onChange={setIgnoreDimensionChecks}
                  />
                </Form.Item>
              </Form>
            </ButtonRow>
          </HorizontalStack>
        }
        open={display.status}
        footer={
          <>
            <Button type="default" onClick={handleCancel}>
              Cancel
            </Button>
            {selectedRowKeys.length > 0 && (
              <Button onClick={handleOk} type="primary" loading={sending}>
                ✉️ Send {selectedRowKeys.length}{" "}
                {selectedRowKeys.length > 1 ? "emails" : "email"}
              </Button>
            )}
          </>
        }
        onCancel={handleCancel}
        width={1450}
        destroyOnClose
      >
        <Form colon={false}>
          <HorizontalStack verticalAlign="top">
            <Form.Item label="Custom Email Address">
              <Input
                style={{ width: 300 }}
                value={customAddress}
                onChange={(e) => setCustomAddress(e.target.value)}
              />
            </Form.Item>
            <Spacer width={8} />
            <Button
              icon={<PlusOutlined />}
              onClick={handleAddCustomEmailAddress}
            >
              Add
            </Button>
            <Spacer width={32} />
            <Form.Item label="General extra text">
              <Input.TextArea
                value={generalExtraText}
                onChange={handleGeneralExtraChange}
                rows={1}
                style={{ width: 600 }}
              />
            </Form.Item>
          </HorizontalStack>
        </Form>
        <Table
          columns={columns}
          dataSource={rows}
          rowSelection={rowSelection}
          pagination={false}
        />
        <Spacer height={16} />
      </Modal>
      <Button onClick={showModal} type="link">
        ✉️ Email for Quote
      </Button>
    </>
  );
}

interface PreviewEmailProps extends TabProps {
  currentRequest: QuotingEmailRequest;
  requests: QuotingEmailRequest[];
  setRequests: Dispatch<SetStateAction<QuotingEmailRequest[]>>;
}

function PreviewEmailModal(props: PreviewEmailProps) {
  const { currentRequest, setRequests } = props;
  const email = currentRequest.email;
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!;

  const display = useDisplay();
  const preview = usePreviewQuotingEmail();
  const [extra, setExtra] = useState(currentRequest.extraText);
  const [emailAddress, setEmailAddress] = useState(
    currentRequest.addresses?.at(0) ?? "",
  );

  async function fetchPreview(patches?: Partial<QuotingEmailRequest>) {
    const patched = {
      ...currentRequest,
      extraText: patches?.extraText ?? currentRequest.extraText,
      addresses: patches?.addresses ?? currentRequest.addresses,
    };

    const email = await preview.fetchPreview(shipmentId, patched);

    const updated = produce(patched, (draft) => {
      draft.email = email;
    });

    setRequests((prev) =>
      prev.map((r) => {
        if (r === currentRequest) {
          return updated;
        }
        return r;
      }),
    );
  }

  async function handleClick() {
    await fetchPreview();
    display.show();
  }
  const updatePreview = useDebouncedCallback(fetchPreview, 600);

  async function handleExtraChange(e: ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;
    setExtra(value);
    await updatePreview({ extraText: value });
  }

  async function handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setEmailAddress(value);
    await updatePreview({ addresses: [value] });
  }

  return (
    <>
      <Modal
        title={"✉️ Preview Email"}
        open={display.status}
        width={1450}
        onOk={display.hide}
        onCancel={display.hide}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 12, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Email Addresses">
            {currentRequest.carrier ? (
              <Text>{email?.addresses.join(", ")}</Text>
            ) : (
              <Input value={emailAddress} onChange={handleEmailChange} />
            )}
          </Form.Item>
          <Form.Item label="Subject">
            <Text>{email?.subject}</Text>
          </Form.Item>
          <Form.Item label="Extra Text">
            <Input.TextArea value={extra} onChange={handleExtraChange} />
          </Form.Item>
          <Form.Item label="Email Body">
            <div style={{ border: "1px solid #eee", padding: "16px" }}>
              {email && (
                <>
                  <div
                    title="Email Preview"
                    dangerouslySetInnerHTML={{
                      __html: email.emailBody,
                    }}
                  ></div>
                </>
              )}
              {!email && <>No preview</>}
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Tooltip title="Preview and edit the email, add extra text, or update the custom email address.">
        <Button type="default" onClick={handleClick}>
          Preview Email
        </Button>
      </Tooltip>
    </>
  );
}
