import {
  CarrierInvoice,
  CarrierInvoiceState,
} from "@freightsimple/generated-apollo-openapi-client";
import { message, Tag, Tooltip } from "antd";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { TabProps } from "./TabProps";
import { MenuItem } from "../../Types/AntHelperTypes";

interface CheckIfCarrierInvoiceIsPaidMenuItemProps extends TabProps {
  carrierInvoice: CarrierInvoice;
}

export function CheckIfCarrierInvoiceIsPaidMenuItem(
  props: CheckIfCarrierInvoiceIsPaidMenuItemProps,
) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  async function onConfirm() {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.checkIfCarrierInvoiceIsPaid({
        carrierInvoiceId,
      });
      message.success(
        "Checked and refreshing. Will be marked as paid now if it was paid",
      );
      props.onRefresh();
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  if (
    props.carrierInvoice.invoiceState !== CarrierInvoiceState.PaymentPending
  ) {
    return <></>;
  }

  return (
    <Tooltip
      placement="left"
      title="This will compare this invoice against the matching invoice in Xero. If the Xero invoice is paid, then this invoice will be marked as paid. There should not be a need to do this, as a background process runs hourly to do this automatically"
    >
      <MenuItem onClick={onConfirm}>
        <Tag color="red">Advanced</Tag> Check if invoice is paid in Xero
      </MenuItem>
    </Tooltip>
  );
}
