import { TimelineItemProps } from "antd";
import {
  GroupedShowGroups,
  ShowGroup,
} from "../../Helpers/groupCollapsibleElements";
import { useState, useMemo, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TimeLineItemProps } from "antd/es/timeline/TimelineItem";
import Spacer from "../../Spacer";

interface UseGroupedCollapsibleTimelineItemsProps<K, T> {
  groups: GroupedShowGroups<K, T>[];
  renderItem: (element: T) => TimelineItemProps;
  renderShowGroupHeader: (
    group: ShowGroup<T>,
    groupIndex: number,
    states: boolean[],
    setStates: (index: number, value: boolean) => void,
  ) => TimelineItemProps;
  renderGroupHeader: (group: GroupedShowGroups<K, T>) => TimelineItemProps;
}

type StateManager<K> = { key: K; states: boolean[] };
type StateManagers<K> = StateManager<K>[];

export function useGroupedCollapsibleTimelineItems<K, E>({
  groups,
  renderItem,
  renderShowGroupHeader,
  renderGroupHeader,
}: UseGroupedCollapsibleTimelineItemsProps<K, E>): TimelineItemProps[] {
  const [states, setStates] = useState<StateManagers<K>>(
    groups.map((g) => ({
      key: g.key,
      states: new Array(g.showGroups.length).fill(false),
    })),
  );

  const findStateManager = useCallback(
    (key: K) => states.find((s) => s.key === key) ?? { key, states: [] },
    [states],
  );

  const getCollapsibleState = useCallback(
    (key: K, index: number) => findStateManager(key).states[index] ?? false,
    [findStateManager],
  );

  const getCollapsibleStates = useCallback(
    (key: K) => findStateManager(key).states,
    [findStateManager],
  );

  const updateStateManagers = useCallback(
    (key: K, index: number, value: boolean) =>
      states.map((p) =>
        p.key === key
          ? {
              ...p,
              states: [
                ...p.states.slice(0, index),
                value,
                ...p.states.slice(index + 1),
              ],
            }
          : p,
      ),
    [states],
  );

  const setState = useCallback(
    (key: K) => (index: number, value: boolean) => {
      setStates(updateStateManagers(key, index, value));
    },
    [updateStateManagers],
  );

  const styleHiddenItems = useCallback(
    (e: E): TimeLineItemProps => ({
      ...renderItem(e),
      style: { opacity: 0.6 },
      children: (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 0.6, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            style={{ overflow: "hidden" }}
          >
            {renderItem(e).children}
          </motion.div>
        </AnimatePresence>
      ),
    }),
    [renderItem],
  );

  const items: TimelineItemProps[] = useMemo(() => {
    const timelineItems: TimelineItemProps[] = [];

    groups.forEach((group) => {
      // Push group header
      timelineItems.push(renderGroupHeader(group));

      group.showGroups.forEach((showGroup, groupIndex) => {
        if (showGroup.show) {
          showGroup.elements.forEach((element) => {
            timelineItems.push(renderItem(element));
          });
          return;
        }
        // Push collapse header "2 notes hidden"
        timelineItems.push(
          renderShowGroupHeader(
            showGroup,
            groupIndex,
            getCollapsibleStates(group.key),
            setState(group.key),
          ),
        );

        // If collapse is open push the items
        if (getCollapsibleState(group.key, groupIndex)) {
          showGroup.elements.forEach((element) => {
            timelineItems.push(styleHiddenItems(element));
          });
        }
      });

      // Add a space
      timelineItems.push({
        dot: (
          <div
            style={{
              borderInlineStart: "2px solid rgba(5, 5, 5, 0.06)",
            }}
          ></div>
        ),
        children: <Spacer height={4} />,
      });
    });

    return timelineItems;
  }, [
    groups,
    renderItem,
    renderShowGroupHeader,
    renderGroupHeader,
    getCollapsibleState,
    getCollapsibleStates,
    setState,
    styleHiddenItems,
  ]);

  return items;
}
