import { Select } from "antd";
import { PeriodFormats } from "../Screens/FinanceComponents/generatePeriodInfo";
import dayjs from "dayjs";

interface YearSelectProps {
  year: string | undefined;
  setYear: (_: string | undefined) => void;
}

export function YearSelect(props: YearSelectProps) {
  const { year, setYear } = props;

  const startingMonth = dayjs().startOf("year");

  const months = [
    startingMonth.clone(),
    startingMonth.clone().subtract(1, "year"),
    startingMonth.clone().subtract(2, "year"),
    startingMonth.clone().subtract(3, "year"),
    startingMonth.clone().subtract(4, "year"),
  ];

  return (
    <Select
      allowClear
      value={year}
      style={{ width: 220 }}
      onChange={function (e) {
        setYear(e);
      }}
      placeholder="Filter by year"
    >
      {months.map(function (m) {
        return (
          <Select.Option
            key={m.format(PeriodFormats.Yearly)}
            value={m.format(PeriodFormats.Yearly)}
          >
            {m.format(PeriodFormats.Yearly)}
          </Select.Option>
        );
      })}
    </Select>
  );
}
