import { Tag, Tooltip } from "antd";

interface CompanyDomainTagProps {
  domain: string | undefined | null;
}

export function CompanyDomainTag(props: CompanyDomainTagProps) {
  const displayName = props.domain;

  return (
    <Tooltip title={`Domain : ${displayName}`}>
      <Tag color="blue">🖥️ {displayName}</Tag>
    </Tooltip>
  );
}

export function NonProfessionalDomainTag() {
  return (
    <Tooltip title={`Domain : Non-Professional`}>
      <Tag color="blue">🖥️ Non-Professional</Tag>
    </Tooltip>
  );
}
