import { isBlank } from "@freightsimple/shared";
import { ErrorMessageType } from "../Types/ErrorMessageType";

export function errorMessageForInvoiceIdentifier(
  invoiceIdentifier: string | undefined,
): ErrorMessageType {
  if (invoiceIdentifier === undefined || isBlank(invoiceIdentifier)) {
    return "No invoice identifier";
  }

  if (invoiceIdentifier.length < 4) {
    return "Too short. Add zeros to the front if really that short.";
  }

  return "";
}
