import { Form, Input, Menu, message, Modal } from "antd";
import { useState } from "react";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { TabProps } from "./TabProps";
import { CarrierInvoice } from "@freightsimple/generated-apollo-openapi-client";
import { errorMessageForInvoiceIdentifier } from "../../Helpers/errorMessageForInvoiceIdentifier";

interface UpdateCarrierInvoiceInvoiceIdentifierMenuItemProps extends TabProps {
  carrierInvoice: CarrierInvoice;
}

export function UpdateCarrierInvoiceInvoiceIdentifierMenuItem(
  props: UpdateCarrierInvoiceInvoiceIdentifierMenuItemProps,
) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceIdentifier, setInvoiceIdentifier] = useState<
    string | undefined
  >(props.carrierInvoice.invoiceIdentifier);
  const [loading, setLoading] = useState(false);
  const createCarrierInvoiceApi = useCarrierInvoiceApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (invoiceIdentifier === undefined) {
      message.warning("Invoice Identifier is undefined");
      return;
    }

    const invoiceIdentifierErrorMessage =
      errorMessageForInvoiceIdentifier(invoiceIdentifier);

    if (invoiceIdentifierErrorMessage) {
      message.warning(invoiceIdentifierErrorMessage);
      return;
    }

    setLoading(true);
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.updateCarrierInvoiceInvoiceIdentifier({
        carrierInvoiceId,
        invoiceIdentifier,
      });
      await props.onRefresh();
      message.success(`Successfully updated!`);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }

    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Update Invoice Identifier"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        confirmLoading={loading}
        width={700}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="New Invoice Identifier">
            <Input
              value={invoiceIdentifier}
              onChange={function (e) {
                setInvoiceIdentifier(e.target.value);
              }}
              style={{ width: "300px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        Update Invoice Identifier
      </Menu.Item>
    </>
  );
}
