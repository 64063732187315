export function describeAccessorial(accessorial: string): string {
  switch (accessorial) {
    case "LOGISTICS_LIFT_GATE_REQUIRED":
      return `Lift Gate Required`;
    case "LOGISTICS_INSIDE":
      return `Inside`;
    case "SCHEDULING_APPOINTMENT_REQUIRED":
      return `Appointment Required`;
    case "SCHEDULING_AFTER_HOURS":
      return `After Hours`;
    default:
      return accessorial;
  }
}
