import { ReactNode, useState } from "react";
import { ListComparatorContext } from "./ListComparatorContext";

interface ListComparatorContextProviderProps<T> {
  left: T[];
  right: T[];
  changes?: number;
  deletions?: number;
  additions?: number;
  children: ReactNode;
}

export function ListComparatorContextProvider<T>(
  context: ListComparatorContextProviderProps<T>,
) {
  const [changes, setChanges] = useState<number>(context.changes ?? 0);
  const [deletions, setDeletions] = useState<number>(context.deletions ?? 0);
  const [additions, setAdditions] = useState<number>(context.additions ?? 0);

  return (
    <ListComparatorContext.Provider
      value={{
        leftList: context.left,
        rightList: context.right,
        changes: changes,
        setChanges: setChanges,
        deletions: deletions,
        setDeletions: setDeletions,
        additions: additions,
        setAdditions: setAdditions,
      }}
    >
      {context.children}
    </ListComparatorContext.Provider>
  );
}
