import { HTMLAttributes, ReactNode } from "react";
import Colors from "../Colors";
import { NoteAuthor } from "./NoteAuthor";

export function NoteHeadline({
  children,
  style,
  ...rest
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        fontSize: "14px",
        fontWeight: "500",
        color: Colors.NormalText,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export function NoteAuthorHeadline({
  headline,
  author,
}: {
  headline: ReactNode | undefined;
  author: ReactNode | undefined;
}) {
  return (
    <NoteHeadline>
      {headline}
      <NoteAuthor>{author}</NoteAuthor>
    </NoteHeadline>
  );
}
