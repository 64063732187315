import { ReactNode } from "react";
import HorizontalStack from "./HorizontalStack";
interface StatsRowProps {
  children: ReactNode;
}

export function StatsRow(props: StatsRowProps) {
  return (
    <HorizontalStack
      spacing={16}
      verticalAlign="middle"
      style={{ marginLeft: "-16px" }}
    >
      {props.children}
    </HorizontalStack>
  );
}
