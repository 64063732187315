import {
  CustomerInvoice,
  CustomerInvoiceState,
  CustomerInvoiceType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, message, Popconfirm, Tooltip } from "antd";
import { useCustomerInvoiceApi } from "../../Apis/Apis";

interface VoidCustomerInvoiceButtonProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

export function VoidCustomerInvoiceButton(
  props: VoidCustomerInvoiceButtonProps,
) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!;
  async function confirm() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.voidOrRefundFullInvoice({
        customerInvoiceId,
      });
      message.success("Invoice voided");
      await props.onRefresh();
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  const enabled =
    props.customerInvoice.customerInvoiceState === CustomerInvoiceState.Issued;

  const isAdditionalCharge =
    props.customerInvoice.invoiceType === CustomerInvoiceType.AdditionalCharge;

  if (!enabled) {
    return <></>;
  }

  const tooltip = isAdditionalCharge
    ? "Use this if you need to void entire additional charge invoice."
    : "Use this if you need to void entire invoice. If you are trying to cancel the shipment, it is better to cancel the shipment which will also process a refund from the Tracking Tab";

  return (
    <Popconfirm
      title="Are you sure to void this invoice?"
      onConfirm={confirm}
      okText="Yes, void this full invoice"
      cancelText="Cancel"
      disabled={!enabled}
    >
      <Tooltip placement="topLeft" title={tooltip}>
        <Button disabled={!enabled} danger>
          🚫 Void Invoice
        </Button>
      </Tooltip>
    </Popconfirm>
  );
}
