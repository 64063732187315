import {
  Note,
} from "@freightsimple/generated-apollo-openapi-client";
import { ShipmentModifiedMetadata } from "../../../Types/ShipmentModifiedMetadata";
import { CollapsibleNote } from "../CollapseNote";
import { ModifiedGenericNote } from "../ModifiedGenericNote";
import { NoteAuthorHeadline, NoteHeadline } from "../NoteHeadline";
import { NoteMessage } from "../NoteMessage";
import { ShipmentModifiedLineItemsNote } from "./ShipmentModifiedLineItemsNote";
import { shipmentValuesDisplay } from "../../../Helpers/shipmentValuesDisplay";

interface ShipmentModifiedNoteProps {
  note: Note;
}

export function ShipmentModifiedNote({ note }: ShipmentModifiedNoteProps) {
  if (note.beforeMetadata === undefined && note.afterMetadata === undefined) {
    return (
      <>
        <NoteHeadline>{note.headline}</NoteHeadline>
        <NoteMessage>{note.message}</NoteMessage>
      </>
    );
  }

  const beforeMetadata = JSON.parse(
    note.beforeMetadata!,
  ) as ShipmentModifiedMetadata;
  const afterMetadata = JSON.parse(
    note.afterMetadata!,
  ) as ShipmentModifiedMetadata;

  if (beforeMetadata.type === "UpdatedLineItems") {
    return (
      <ShipmentModifiedLineItemsNote
        note={note}
        beforeData={beforeMetadata.data}
        afterData={afterMetadata.data}
      />
    );
  }

  const beforeData = JSON.parse(beforeMetadata.data);
  const afterData = JSON.parse(afterMetadata.data);

  return (
    <CollapsibleNote
      collapseActive={false}
      header={
        <NoteAuthorHeadline headline={note.headline} author={note.author} />
      }
    >
      <ModifiedGenericNote
        beforeData={beforeData}
        afterData={afterData}
        customValueDisplay={shipmentValuesDisplay}
      />
    </CollapsibleNote>
  );
}
