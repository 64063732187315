import { Note } from "@freightsimple/generated-apollo-openapi-client";
import { NoteHeadline } from "../NoteHeadline";
import { NoteMessage } from "../NoteMessage";

interface ReferenceNumberChangedNoteProps {
  note: Note;
}

export function ReferenceNumberChangedNote({
  note,
}: ReferenceNumberChangedNoteProps) {
  return (
    <>
      <NoteHeadline>{note.headline}</NoteHeadline>
      <NoteMessage>{note.message}</NoteMessage>
    </>
  );
}
