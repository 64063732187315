import { FreightClaimReadModel } from "@freightsimple/generated-apollo-openapi-client";
import { Tooltip } from "antd";
import CarrierLogo from "../../../Components/CarrierLogo";
import { CreatedAt } from "../../../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../../../Components/DataTable";
import { TabProps } from "../TabProps";
import { FreightClaimStateTag } from "./FreightClaimStateTag";
import { ViewClaimButton } from "../ViewClaimButton";

interface FreightClaimsTableProps extends TabProps {
  freightClaims: FreightClaimReadModel[];
}

export function FreightClaimsTable(props: FreightClaimsTableProps) {
  const { freightClaims } = props;

  const columns: DataTableColumn<FreightClaimReadModel>[] = [
    {
      width: 100,
      title: "State",
      render: (o) => <FreightClaimStateTag state={o.freightClaimState} />,
    },
    {
      title: "Created At",
      render: (o) => <CreatedAt timestamp={o.createdAt} />,
    },
    {
      width: "35%",
      ellipsis: { showTitle: false },
      title: "Description",
      render: (o) => (
        <Tooltip title={o.claimDescription} placement="left">
          {o.claimDescription}
        </Tooltip>
      ),
    },
    {
      width: 120,
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier!}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Pro Number",
      render: (o) => <>{o.proNumber}</>,
    },
    {
      width: 180,
      title: "Actions",
      render: (o) => (
        <ViewClaimButton freightClaim={o} data={props.shipmentData} />
      ),
    },
  ];

  return (
    <DataTable pagination={false} columns={columns} data={freightClaims} />
  );
}
