import {
  ListCompaniesRow,
  ShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import { Menu, Tag } from "antd";
import { useEffect, useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import {
  DataTable,
  DataTableColumn,
  DataTableCsvColumn,
} from "../../Components/DataTable";
import Stack from "../../Components/Stack";
import { ViewCompanyMenuItem } from "../../Components/ViewCompanyButton";
import { assertNever } from "../../Helpers/assertNever";
import { truncate } from "../../Helpers/truncated";
import { Period } from "../ViewShipmentScreenComponents/Period";
import { DeltaElement } from "./DeltaElement";
import { ReportType } from "./ReportType";
import { ValueElement } from "./ValueElement";
import {
  CellValue,
  CompiledRecentCompanySalesReportRow,
  generateCompiledData,
  valueForCell,
} from "./generateCompiledData";
import { PeriodInfo } from "./generatePeriodInfo";

interface RecentCompanySalesReportProps {
  report: ShipmentReport[];
  companies: ListCompaniesRow[];
  companyIdFilter: string | undefined;
  leadSourceFilter: string | undefined;
  shipmentFrequencyFilter: string | undefined;
  onlyShowLost: boolean;
  periodSelected: Period;
  companyCreationFilter: string | undefined;
  sortByDeltas: boolean;
  reportType: ReportType;
}

export function RecentCompanySalesReport(props: RecentCompanySalesReportProps) {
  // We need an overall list for the totals (but we still want the filter rules applied)
  const {
    reportType,
    report,
    companyIdFilter,
    leadSourceFilter,
    shipmentFrequencyFilter,
    onlyShowLost,
    periodSelected,
    companyCreationFilter,
    companies,
  } = props;

  interface RecentCompanySalesReportData {
    rows: CompiledRecentCompanySalesReportRow[];
    months: PeriodInfo[];
    quarters: PeriodInfo[];
    years: PeriodInfo[];
    monthTotals: CellValue[];
    quarterTotals: CellValue[];
    yearTotals: CellValue[];
    overallTotalForReport: CellValue;
    overallLast3MonthsForReport: CellValue;
  }

  const [data, setData] = useState<RecentCompanySalesReportData>();

  useEffect(
    function () {
      const _data = generateCompiledData(
        report,
        companyIdFilter,
        leadSourceFilter,
        shipmentFrequencyFilter,
        onlyShowLost,
        periodSelected,
        companyCreationFilter,
        companies,
      );

      setData(_data);
    },

    [
      report,
      companyIdFilter,
      leadSourceFilter,
      shipmentFrequencyFilter,
      onlyShowLost,
      companyCreationFilter,
      companies,
    ],
  );

  if (data === undefined) {
    return <></>;
  }

  const {
    rows,
    months,
    quarters,
    years,
    monthTotals,
    quarterTotals,
    yearTotals,
    overallTotalForReport,
    overallLast3MonthsForReport,
  } = data;

  const monthlyColumns = buildColumns(Period.Monthly);
  const quarterlyColumns = buildColumns(Period.Quarterly);
  const yearlyColumns = buildColumns(Period.Yearly);
  const monthlyCsvColumns = buildCsvColumns(Period.Monthly);
  const quarterlyCsvColumns = buildCsvColumns(Period.Quarterly);
  const yearlyCsvColumns = buildCsvColumns(Period.Yearly);

  function getColumns() {
    switch (periodSelected) {
      case Period.Monthly:
        return monthlyColumns;
      case Period.Quarterly:
        return quarterlyColumns;
      case Period.Yearly:
        return yearlyColumns;
      default:
        assertNever(periodSelected);
    }
  }

  function getCsvColumns() {
    switch (periodSelected) {
      case Period.Monthly:
        return monthlyCsvColumns;
      case Period.Quarterly:
        return quarterlyCsvColumns;
      case Period.Yearly:
        return yearlyCsvColumns;
      default:
        assertNever(periodSelected);
    }
  }

  function buildCsvColumns(periodToRender: Period) {
    function getPeriods() {
      switch (periodToRender) {
        case Period.Yearly:
          return years;
        case Period.Quarterly:
          return quarters;
        case Period.Monthly:
          return months;
        default:
          assertNever(periodToRender);
      }
    }

    const periods = getPeriods();

    const csvColumns: DataTableCsvColumn<CompiledRecentCompanySalesReportRow>[] =
      [];
    csvColumns.push({
      title: "Company Name",
      render: function (o) {
        return o.companyName;
      },
    });

    csvColumns.push({
      title: "Lead Source",
      render: function (o) {
        return o.company.leadSource;
      },
    });

    for (let i = 1; i < periods.length; i++) {
      const period = periods[i];
      const periodDisplayName = period.displayName;

      csvColumns.push({
        title: periodDisplayName,
        render: function (o) {
          const oPerPeriodValues = getPerPeriodValues(o);
          const thisPeriodValue = valueForCell(reportType, oPerPeriodValues[i]);
          return thisPeriodValue;
        },
      });
    }

    return csvColumns;
  }

  const columns = getColumns();
  const csvColumns = getCsvColumns();

  function buildColumns(periodToRender: Period) {
    function getPeriods() {
      switch (periodToRender) {
        case Period.Yearly:
          return years;
        case Period.Quarterly:
          return quarters;
        case Period.Monthly:
          return months;
        default:
          assertNever(periodToRender);
      }
    }

    function getPeriodTotals() {
      switch (periodToRender) {
        case Period.Yearly:
          return yearTotals;
        case Period.Monthly:
          return monthTotals;
        case Period.Quarterly:
          return quarterTotals;
        default:
          assertNever(periodToRender);
      }
    }

    const periods = getPeriods();
    const periodTotals = getPeriodTotals();

    const columns: DataTableColumn<CompiledRecentCompanySalesReportRow>[] = [];

    columns.push({
      title: `Company (${rows.length})`,
      render: (o) => (
        <Stack align="left">
          {o.companyName}
          <Tag color="orange" style={{ marginTop: "4px" }}>
            {truncate(o.company.leadSource ?? "Unknown", 20)}
          </Tag>
          <Tag color="purple" style={{ marginTop: "4px" }}>
            {o.company.shipmentFrequency ?? "Unknown"}
          </Tag>

          {o.companyLast3Months.revenue === 0 && (
            <Tag color="red" style={{ marginTop: "4px" }}>
              Lost
            </Tag>
          )}
        </Stack>
      ),
      fixed: "left",
    });

    for (let i = 1; i < periods.length; i++) {
      const period = periods[i];
      const periodDisplayName = period.displayName;

      const periodTotal = periodTotals[i];

      columns.push({
        title: (
          <Stack align="right">
            <div>{periodDisplayName}</div>
            <div style={{ color: Colors.LightText, fontSize: "11px" }}>
              {formatOverall(periodTotal)}
            </div>
          </Stack>
        ),
        render: function (o) {
          const oPerPeriodValues = getPerPeriodValues(o);
          const previousPeriodValue = valueForCell(
            reportType,
            oPerPeriodValues[i - 1],
          );
          const thisPeriodValue = valueForCell(reportType, oPerPeriodValues[i]);

          return (
            <Stack align="right">
              <ValueElement
                reportType={reportType}
                delta={thisPeriodValue - previousPeriodValue}
                cellValue={oPerPeriodValues[i]}
              >
                {thisPeriodValue}
              </ValueElement>
              {previousPeriodValue !== undefined && previousPeriodValue > 0 && (
                <div
                  style={{
                    fontSize: "10px",
                    position: "relative",
                    top: "-2px",
                  }}
                >
                  <DeltaElement reportType={reportType}>
                    {thisPeriodValue - previousPeriodValue}
                  </DeltaElement>
                </div>
              )}
            </Stack>
          );
        },
        // Need to capture the month value as it changes in the loop
        sorter: function (a, b) {
          const aPerPeriodValues = getPerPeriodValues(a);
          const bPerPeriodValues = getPerPeriodValues(b);
          if (props.sortByDeltas) {
            return (
              lookupValue(aPerPeriodValues[i]) -
              lookupValue(aPerPeriodValues[i - 1]) -
              (lookupValue(bPerPeriodValues[i]) -
                lookupValue(bPerPeriodValues[i - 1]))
            );
          } else {
            return (
              lookupValue(aPerPeriodValues[i]) -
              lookupValue(bPerPeriodValues[i])
            );
          }
        },
      });
    }

    // This column has the various totals for the company
    columns.push({
      title: (
        <Stack align="right">
          <div>Last 3 Months</div>
          <div style={{ color: Colors.LightText, fontSize: "11px" }}>
            {formatOverall(overallLast3MonthsForReport)}
          </div>
        </Stack>
      ),
      render: function (o) {
        return (
          <ValueElement
            delta={undefined}
            reportType={reportType}
            cellValue={o.companyLast3Months}
          >
            {lookupValue(o.companyLast3Months)}
          </ValueElement>
        );
      },
      sorter: function (a, b) {
        return (
          (lookupValue(a.companyLast3Months) ?? 0) -
          (lookupValue(b.companyLast3Months) ?? 0)
        );
      },
    });

    // This column has the various totals for the company
    columns.push({
      title: (
        <Stack align="right">
          <div>Total</div>
          <div style={{ color: Colors.LightText, fontSize: "11px" }}>
            {formatOverall(overallTotalForReport)}
          </div>
        </Stack>
      ),
      render: function (o) {
        return (
          <ValueElement
            delta={undefined}
            reportType={reportType}
            cellValue={o.companyTotal}
          >
            {lookupValue(o.companyTotal)}
          </ValueElement>
        );
      },
      sorter: function (a, b) {
        return (
          (lookupValue(a.companyTotal) ?? 0) -
          (lookupValue(b.companyTotal) ?? 0)
        );
      },
    });

    columns.push({
      title: "Actions",
      render: function (o) {
        return (
          <ButtonRow
            extrasMenu={
              <Menu>
                <ViewCompanyMenuItem companyId={o.companyId} />
              </Menu>
            }
          >
            <></>
          </ButtonRow>
        );
      },
    });

    return columns;
  }

  function lookupValue(cell: CellValue) {
    return valueForCell(reportType, cell);
  }

  function formatOverall(cell: CellValue) {
    const value = lookupValue(cell);

    switch (reportType) {
      case ReportType.Revenue:
        return value.toLocaleString("en-CA", {
          style: "currency",
          currency: "CAD",
        });
      case ReportType.ShipmentsBooked:
        return value.toString();
      case ReportType.Quotes:
        return value.toString();
      case ReportType.Acceptance:
        return value.toFixed(1) + "%";
      default:
        assertNever(reportType);
    }
  }

  function getPerPeriodValues(
    o: CompiledRecentCompanySalesReportRow,
  ): CellValue[] {
    switch (periodSelected) {
      case Period.Monthly:
        return o.perMonthValues;
      case Period.Quarterly:
        return o.perQuarterValues;
      case Period.Yearly:
        return o.perYearValues;
      default:
        assertNever(periodSelected);
    }
  }

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <DataTable
        pagination={false}
        columns={columns}
        data={rows}
        scroll={{ x: "max-content" }}
        csvColumns={csvColumns}
      />
    </div>
  );
}
