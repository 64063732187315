import { ShipmentReportSettlementStatus } from "@freightsimple/generated-apollo-openapi-client";
import { Tag } from "antd";
import { ReactNode } from "react";
import { assertNever } from "../Helpers/assertNever";

export function describeSettlementStatus(
  status: ShipmentReportSettlementStatus,
): ReactNode {
  switch (status) {
    case ShipmentReportSettlementStatus.Approved:
      return <Tag color="blue">Approved</Tag>;
    case ShipmentReportSettlementStatus.Open:
      return <Tag color="orange">Open</Tag>;
    case ShipmentReportSettlementStatus.Projected:
      return <Tag color="red">Projected</Tag>;
    case ShipmentReportSettlementStatus.Settled:
      return <Tag color="green">Settled</Tag>;
    default:
      assertNever(status);
  }
}
