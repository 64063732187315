import { Select } from "antd";
import HorizontalStack from "../../Components/HorizontalStack";
import Admins from "../../GeneratedFixtures/Admins.json";
import {
  stringToColor,
  stringToForegroundColor,
} from "../../Helpers/stringToColor";

interface AdminDropdownProps {
  value: string | undefined;
  setValue: (_: string) => void;
  allowClear?: boolean;
  counter: ((_: string) => number) | undefined;
}

export function AdminDropdown(props: AdminDropdownProps) {
  return (
    <>
      <Select
        value={props.value}
        onChange={props.setValue}
        style={{ width: "250px" }}
        allowClear={props.allowClear}
        showSearch
      >
        {Admins.map(function (ia) {
          const count = props.counter
            ? `(${props.counter(ia.emailAddress)})`
            : "";
          return (
            <Select.Option key={ia.emailAddress} value={ia.emailAddress}>
              <HorizontalStack>
                <div
                  style={{
                    backgroundColor: stringToColor(ia.emailAddress),
                    color: stringToForegroundColor(ia.emailAddress),
                    padding: "8px",
                    minWidth: "16px",
                    marginRight: "8px",
                  }}
                ></div>
                <div style={{}}>
                  {ia.fullName} {count}
                </div>
              </HorizontalStack>
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
}
