import { DocumentType } from "@freightsimple/generated-apollo-openapi-client";
import { Tag } from "antd";
import Colors from "../../Components/Colors";
import { foregroundColorForColor } from "../../Helpers/stringToColor";
import { describeDocumentType } from "../../Helpers/describeDocumentType";

interface DocumentTypeProps {
  documentType: DocumentType;
}

function color(documentType: DocumentType) {
  switch (documentType) {
    case DocumentType.BillOfLading:
      return Colors.Blue;
    case DocumentType.CarrierScannedBillOfLading:
      return Colors.DarkBlue;
    case DocumentType.ProofOfDelivery:
      return Colors.Green;
    case DocumentType.CarrierWeightReport:
      return Colors.LightGold;
    case DocumentType.CustomsDocument:
      return Colors.Red;
    case DocumentType.InsuranceCertificate:
      return Colors.DarkGreen;
    case DocumentType.EmailAttachment:
      return Colors.Gold;
    case DocumentType.PickupPackage:
      return Colors.Orange;
    case DocumentType.ShipperPhoto:
      return Colors.Red;
    case DocumentType.CustomerEmailAttachment:
      return Colors.LightRed;
    case DocumentType.CarrierEmailAttachment:
      return Colors.LightGreen;
    case DocumentType.SentEmailAttachment:
      return Colors.VeryLightGray;
    case DocumentType.Unknown:
      return Colors.Palette.Purple[800];
    default:
      return Colors.DarkGray;
  }
}

export function DocumentTypeTag(props: DocumentTypeProps) {
  return (
    <Tag color={color(props.documentType)} style={{ margin: 0 }}>
      <div
        style={{ color: foregroundColorForColor(color(props.documentType)) }}
      >
        {describeDocumentType(props.documentType)}
      </div>
    </Tag>
  );
}
