import { ReactNode } from "react";
import { CallDataContextProvider } from "./CallDataContextProvider";
import { CallRefreshContextProvider } from "./CallRefreshContextProvider";

export function CallContextProvider({ children }: { children: ReactNode }) {
  return (
    <CallRefreshContextProvider>
      <CallDataContextProvider>{children}</CallDataContextProvider>
    </CallRefreshContextProvider>
  );
}
