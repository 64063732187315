import { calculateMargin } from "../../Helpers/calculateMargin";
import { sum } from "../../Helpers/sum";
import { ProfitLossGroupedType } from "./ProfitLossGroupByTable";

export function sumMoneyIn(item: ProfitLossGroupedType) {
  return sum(item.value, (l) => l.moneyInCad!);
}

export function sumMoneyOut(item: ProfitLossGroupedType) {
  return sum(item.value, (l) => l.moneyOutCad!);
}

export function sumProfit(item: ProfitLossGroupedType) {
  return sum(item.value, (l) => l.profit!);
}

export function margin(item: ProfitLossGroupedType) {
  return calculateMargin(sumMoneyIn(item), sumMoneyOut(item));
}
