import { ViewShipmentDataForApollo } from "@freightsimple/generated-apollo-openapi-client";
import { AutoComplete } from "antd";

interface QuoteGroupSelectorProps {
  value: string;
  onChange: (_: string) => void;
  shipmentData: ViewShipmentDataForApollo;
}

export function QuoteGroupSelector(props: QuoteGroupSelectorProps) {
  const quotes = props.shipmentData.manageQuotesTab.quotes;
  const options = Object.entries(quotes).map((o) => ({
    value: o[0],
  }));

  return (
    <AutoComplete
      value={props.value}
      options={options}
      onChange={props.onChange}
    />
  );
}
