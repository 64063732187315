import { AdditionalChargeState } from "@freightsimple/generated-apollo-openapi-client";
import { Tag } from "antd";
import { assertNever } from "../Helpers/assertNever";
import Colors from "./Colors";

interface AdditionalChargeStateTagProps {
  status: AdditionalChargeState;
}

function describeAdditionalChargeState(state: AdditionalChargeState): string {
  switch (state) {
    case AdditionalChargeState.Approved:
      return "Approved";
    case AdditionalChargeState.Paid:
      return "Paid";
    case AdditionalChargeState.Proposed:
      return "Proposed";
    case AdditionalChargeState.Waived:
      return "Waived";
    default:
      assertNever(state);
  }
}

function colorForAdditionalChargeState(state: AdditionalChargeState) {
  switch (state) {
    case AdditionalChargeState.Approved:
      return Colors.Red;
    case AdditionalChargeState.Paid:
      return Colors.DarkGreen;
    case AdditionalChargeState.Proposed:
      return Colors.Blue;
    case AdditionalChargeState.Waived:
      return Colors.MidGray;
    default:
      assertNever(state);
  }
}

function foregroundColorForAdditionalChargeState(state: AdditionalChargeState) {
  switch (state) {
    case AdditionalChargeState.Approved:
      return "white";
    case AdditionalChargeState.Paid:
      return "white";
    case AdditionalChargeState.Proposed:
      return "white";
    case AdditionalChargeState.Waived:
      return "black";
    default:
      assertNever(state);
  }
}

export function AdditionalChargeStateTag(props: AdditionalChargeStateTagProps) {
  return (
    <Tag color={colorForAdditionalChargeState(props.status)}>
      <div
        style={{ color: foregroundColorForAdditionalChargeState(props.status) }}
      >
        {describeAdditionalChargeState(props.status)}
      </div>
    </Tag>
  );
}
