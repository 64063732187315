import { KpiGraphsReport } from "@freightsimple/generated-apollo-openapi-client";
import { Row } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useProfitLossApi } from "../Apis/Apis";
import Colors from "../Components/Colors";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { KpiSection } from "../Components/KpiSection";

const Subtext = styled.div`
  font-size: 12px;
  color: ${Colors.LightText};
`;

export function KpisScreen() {
  const [report, setReport] = useState<KpiGraphsReport | undefined>();
  const [loading, setLoading] = useState(false);
  const createProfitLossApi = useProfitLossApi();

  async function refresh() {
    setLoading(true);

    const profitLossApi = await createProfitLossApi();
    const response = await profitLossApi.generateKpiGraphs({});

    setReport(response);
    setLoading(false);
  }

  useEffect(
    function () {
      refresh();
    },

    [],
  );

  if (report === undefined || loading) {
    return (
      <Loading message="Please wait. This takes around 10 seconds to load" />
    );
  } else {
    return (
      <Page title={`KPIs`} tags={[]} extra={[]}>
        <PageTitle>KPIs</PageTitle>
        <Row style={{ width: "100%" }}>
          <Stack align="left" style={{ width: "100%" }}>
            <Subtext>
              Interesting = Excludes Personal, Brokers, and OCCASSIONAL or
              Single Shipment. This refers to only interesting companies unless
              marked overall
            </Subtext>

            <KpiSection
              title="Overall Revenue"
              data={report.revenue}
              maxValue={12000}
            />
            <KpiSection
              title="Overall Shipments Quoted"
              data={report.shipmentsQuoted}
              maxValue={100}
            />
            <KpiSection
              title="Overall Shipments Booked"
              data={report.shipmentsBooked}
              maxValue={30}
            />
            <KpiSection
              title="Daily/Weekly Shipper Revenue"
              data={report.interestingRevenue}
              maxValue={12000}
            />
            <KpiSection
              title="Daily/Weekly Shipper Quotes"
              data={report.interestingShipmentsQuoted}
              maxValue={100}
            />
            <KpiSection
              title="Daily/Weekly Shippers Booked"
              data={report.interestingShipmentsBooked}
              maxValue={30}
            />

            <KpiSection
              title="Number Daily/Weekly Companies Quoted"
              data={report.companiesQuoted}
              useTimeGraph
            />

            <KpiSection
              title="Existing Daily/Weekly Companies Quoted"
              subtitle="Companies where this wasn't the first period they quoted"
              data={report.companiesNotFirstQuote}
              useTimeGraph
            />

            <KpiSection
              title="Daily/Weekly Companies First Quoted"
              subtitle="Companies where this was the first period they quoted"
              data={report.companiesFirstQuote}
              maxValue={12}
            />

            <KpiSection
              title="Daily/Weekly Companies Booked"
              data={report.companiesBooked}
              useTimeGraph
            />
            <KpiSection
              title="Daily/Weekly Companies First Booked"
              subtitle="Companies where this was the first period they booked"
              data={report.companiesFirstBooking}
              maxValue={12}
            />
            <KpiSection
              title="Existing Daily/Weekly Companies Booked"
              subtitle="Companies where this wasn't the first period they booked"
              data={report.companiesNotFirstBooking}
              useTimeGraph
            />
            <KpiSection
              title="Significant Activities"
              data={report.significantCalls}
              maxValue={30}
            />
          </Stack>
        </Row>
      </Page>
    );
  }
}
