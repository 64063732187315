import { ReactNode } from "react";
import { WithClipboardLink } from "../../Components/WithClipboardLink";
import { describeAccessorial } from "./describeAccessorial";

export function describeAccessorials(accessorials: Array<string>): ReactNode {
  if (accessorials.length === 0) {
    return <>-</>;
  }

  const lines = accessorials.map(function (accessorial) {
    return describeAccessorial(accessorial);
  });
  const nodes = lines.map(function (line, index) {
    return <div key={index}>{line}</div>;
  });
  const copyText = lines.join(", ");

  return <WithClipboardLink copyText={copyText}>{nodes}</WithClipboardLink>;
}
