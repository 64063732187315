import { Tag, Tooltip } from "antd";
import { useStarredCompanies } from "../../Helpers/starredCompanies";
import { truncate } from "../../Helpers/truncated";

interface CompanyStarredTagProps {
  companyId: string;
}

export function CompanyStarredTag(props: CompanyStarredTagProps) {
  const starredCompanies = useStarredCompanies();
  const company = starredCompanies.find((o) => o.companyId === props.companyId);

  if (company === undefined) {
    return <></>;
  }

  return (
    <Tooltip title={`Starred : ${company.reason}`}>
      <Tag color="blue">⭐️ {truncate(company.reason, 40)}</Tag>
    </Tooltip>
  );
}
