import { ShipmentState } from "@freightsimple/generated-apollo-openapi-client";
import { message } from "antd";
import { useShipmentsApi } from "../../Apis/Apis";
import { ConfirmButton } from "../../Components/ConfirmButton";
import { WarningComponent } from "../../Components/WarningComponent";
import { TabProps } from "./TabProps";

export function MarkFreightMissingButton(props: TabProps) {
  const shipmentId = props.shipmentData.shipmentId;
  const createShipmentsApi = useShipmentsApi();
  async function onConfirm() {
    const shipmentsApi = await createShipmentsApi();
    try {
      await shipmentsApi.markFreightMissing({ shipmentId });
      await props.onRefresh();
      message.success("Marked Missing");
    } catch (e) {
      message.error(`Oops. Error ${e}`);
    }
  }

  if (props.shipmentData.shipment.shipment.state !== ShipmentState.InTransit) {
    return <></>;
  }

  if (props.shipmentData.shipment.shipment.freightMissing) {
    return <></>;
  }

  return (
    <ConfirmButton
      onConfirm={onConfirm}
      question={`Are you sure you want to mark this freight as missing?`}
      okText="Yes, mark missing"
      tooltip="This will mark freight as missing. Open a Monday task to track it. In the future Amy will auto-email about this"
    >
      <WarningComponent /> Mark Freight Missing
    </ConfirmButton>
  );
}
