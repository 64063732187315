import { createContext } from "react";

export interface RefreshContextProps {
  isRefreshing: boolean;
  refresh: () => Promise<any>;
  setRefresh: (fn: () => Promise<any>) => void;
}

export const initRefreshContext: RefreshContextProps = {
  isRefreshing: false,
  refresh: async () => {},
  setRefresh: () => {},
};

export const RefreshContext = createContext<RefreshContextProps>({
  ...initRefreshContext,
});
