import { Button, Menu, MenuItemProps, Tooltip } from "antd";
import { TabProps } from "./TabProps";
import { BellFilled, BellOutlined } from "@ant-design/icons";
import { ReactNode } from "react";
import { useChangePauseCustomsDocsReminders } from "./useChangePauseCustomsDocsReminders";

export function PauseCustomsDocsRemindersMenuItem(
  props: TabProps & MenuItemProps,
) {
  const { onClick, pause, allowOption } =
    useChangePauseCustomsDocsReminders(props);

  if (!allowOption) {
    return null;
  }

  return (
    <PauseCustomsDocsRemindersTooltip pause={pause}>
      <Menu.Item
        onClick={onClick}
        icon={pause ? <BellFilled /> : <BellOutlined />}
        {...props}
      >
        {pause ? "Pause" : "Activate"} Customs Documents Reminders
      </Menu.Item>
    </PauseCustomsDocsRemindersTooltip>
  );
}

export function PauseCustomsDocsRemindersButton(props: TabProps) {
  const { onClick, pause, allowOption } =
    useChangePauseCustomsDocsReminders(props);

  if (!allowOption) {
    return null;
  }
  return (
    <PauseCustomsDocsRemindersTooltip pause={pause}>
      <Button
        onClick={onClick}
        icon={pause ? <BellFilled /> : <BellOutlined />}
      >
        {pause ? "Pause" : "Activate"} Customs Documents Reminders
      </Button>
    </PauseCustomsDocsRemindersTooltip>
  );
}

interface PauseCustomsDocsRemindersTooltipProps {
  pause: boolean;
  children: ReactNode;
}
function PauseCustomsDocsRemindersTooltip(
  props: PauseCustomsDocsRemindersTooltipProps,
) {
  const { pause, children } = props;

  return (
    <>
      <Tooltip
        title={
          pause ? (
            <>
              Currently active.
              <br /> Pause automatic emails requesting customs documents from
              the customer.
            </>
          ) : (
            <>
              Currently paused.
              <br /> Activate automatic emails requesting customs documents from
              the customer.
            </>
          )
        }
      >
        {children}
      </Tooltip>
    </>
  );
}
