import { ViewShipmentDataForApollo } from "@freightsimple/generated-apollo-openapi-client";

import { Button, Form, message, Modal, Typography } from "antd";
import { produce } from "immer";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { DisplayMarkdown } from "../../Components/ViewMarkdownButton";
import { TextArea } from "../../Types/AntHelperTypes";

const { Title } = Typography;

interface EditCompanyInvoiceAuditNotesButtonProps {
  shipmentData: ViewShipmentDataForApollo;
  onPatchShipmentData: (_: ViewShipmentDataForApollo) => void;
}

export function EditCompanyInvoiceAuditNotesButton(
  props: EditCompanyInvoiceAuditNotesButtonProps,
) {
  const { shipmentData } = props;
  const company = shipmentData.shipment.company;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceAuditNotes, setInvoiceAuditNotes] = useState(
    company.invoiceAuditNotes ?? "",
  );

  const [saving, setSaving] = useState(false);
  const createCompaniesApi = useCompaniesApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setSaving(true);

    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.editInvoiceAuditNotes({
        editInvoiceAuditNotesInput: {
          companyId: company.companyId!,
          invoiceAuditNotes,
        },
      });

      const updatedShipmentData = produce(props.shipmentData, (draft) => {
        draft.shipment.company.invoiceAuditNotes = invoiceAuditNotes;
      });
      await props.onPatchShipmentData(updatedShipmentData);
      message.success("Saved!");
    } catch {
      message.error("Eek. Something went wrong");
    }
    setSaving(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <Stack align="left">
            <div>Edit Invoice Audit Notes</div>
            <div style={{ color: Colors.LightText, fontSize: "12px" }}>
              Use markdown format to apply formatting like bullet points and
              links to these notes. You can learn about this here:{" "}
              <a
                href="https://www.markdownguide.org/basic-syntax/"
                target="_blank"
                rel="noreferrer"
              >
                View Markdown Guide
              </a>
            </div>
          </Stack>
        }
        visible={isModalVisible}
        onOk={handleOk}
        okText="Save"
        confirmLoading={saving}
        onCancel={handleCancel}
        width={1400}
      >
        <HorizontalStack verticalAlign="top">
          <Form
            style={{ width: "50%" }}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            <Form.Item label="Invoice Audit Notes">
              <TextArea
                style={{ width: "600px", height: "100px" }}
                value={invoiceAuditNotes}
                onChange={function (e) {
                  setInvoiceAuditNotes(e.target.value);
                }}
              />
            </Form.Item>
          </Form>
          <div
            style={{ width: "50%", border: "1px solid #ccc", padding: "32px" }}
          >
            <Title level={4}>Preview</Title>
            <div>
              <DisplayMarkdown data={invoiceAuditNotes} />
            </div>
          </div>
        </HorizontalStack>
      </Modal>
      <Button onClick={showModal} {...props} type="link">
        Edit
      </Button>
    </>
  );
}
