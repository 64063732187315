// We need to go into outlook admin and enable 'Send As' under delegation for each users
// And mark Amy as able to send emails as them
// 'Send as' is the first one of the delegation options
// It takes maybe 20 minutes for it to become active - so might fail inititally for a bit
export const emailSenders = [
  {
    email: "natasha@freightsimple.com",
    firstName: "Natasha",
    lastName: "Ross",
    phoneNumber: "(780) 660-4807",
  },
  {
    email: "chris@freightsimple.com",
    firstName: "Chris",
    lastName: "Stott",
    phoneNumber: "(778) 668-5574",
  },
  {
    email: "enma@freightsimple.com",
    firstName: "Enma",
    lastName: "Leon",
    phoneNumber: "(604) 921-3915",
  },
];
