import { ContactTabData } from "@freightsimple/generated-apollo-openapi-client";
import { Col, Row, Typography } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { EmailLink } from "../../Components/EmailLink";
import { KeyValues } from "../../Components/KeyValues";
import { Loading } from "../../Components/Loading";
import { PhoneLink } from "../../Components/PhoneLink";
import { isNotBlank } from "@freightsimple/shared";
import { CarrierContacts, CarrierPhoneContacts } from "./CarrierContacts";
import { CustomerContacts } from "./CustomerContacts";
import { GenericSupportEmailButton } from "./GenericSupportEmailButton";
import { TabProps } from "./TabProps";

const { Title } = Typography;

export function ContactsTab(props: TabProps) {
  const [data, setData] = useState<ContactTabData | undefined>();
  const createShipmentsApi = useShipmentsApi();

  async function refresh() {
    const shipmentId = props.shipmentData.shipmentId;
    const shipmentsApi = await createShipmentsApi();
    const response = await shipmentsApi.getShipmentContactsTabById({
      shipmentId,
    });
    setData(response);
  }

  useEffect(
    function () {
      refresh();
    },

    [props.shipmentData.shipmentId],
  );

  if (data === undefined) {
    return <Loading />;
  }

  function generateDataForPhoneNumber(phoneNumber: string) {
    const output: { [key: string]: string[] } = {};
    if (data !== undefined) {
      output["Customer Service"] = [phoneNumber];
    }

    return output;
  }

  function generateDataForCarrierPhoneNumbers() {
    const output: { [key: string]: string[] } = {};
    if (data !== undefined) {
      output["Customer Service"] = [data.carrierCustomerServiceNumber!];
    }
    if (isNotBlank(props.shipmentData.shipment.quote.pickupTerminalPhone)) {
      output["Pickup Terminal Phone"] = [
        props.shipmentData.shipment.quote.pickupTerminalPhone!,
      ];
    }
    if (isNotBlank(props.shipmentData.shipment.quote.deliveryTerminalPhone)) {
      output["Delivery Terminal Phone"] = [
        props.shipmentData.shipment.quote.deliveryTerminalPhone!,
      ];
    }
    return output;
  }

  return (
    <>
      <ButtonRow>
        <GenericSupportEmailButton data={props.shipmentData} />
      </ButtonRow>
      <Row>
        <Col span={12}>
          <Frame>
            <Title level={4}>Customer Contacts</Title>
            <CustomerContacts
              data={data.customerContacts}
              shipmentData={props.shipmentData}
              onRefresh={async function () {
                await refresh();
                await props.onRefresh();
              }}
              onPatchShipmentData={props.onPatchShipmentData}
            />
          </Frame>
        </Col>
        {props.shipmentData.shipment.shipment.bookedAt && (
          <Col span={12}>
            <Frame>
              <Title level={4}>Carrier Phone Numbers</Title>
              <CarrierPhoneContacts
                data={generateDataForCarrierPhoneNumbers()}
                shipmentData={props.shipmentData}
              />
            </Frame>
            <Frame>
              <Title level={4}>Carrier Rep</Title>
              <KeyValues
                data={{
                  Name: data.carrierRepInformation?.name,
                  Email: (
                    <EmailLink
                      email={data.carrierRepInformation?.email ?? ""}
                      data={props.shipmentData}
                    />
                  ),
                  Phone: (
                    <>
                      {isNotBlank(data.carrierRepInformation?.phoneNumber) && (
                        <PhoneLink
                          phone={data.carrierRepInformation?.phoneNumber ?? ""}
                          shipmentId={props.shipmentData.shipmentId}
                          description={"Phone"}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </Frame>
            <Frame>
              <Title level={4}>Carrier Shipping Contacts</Title>
              <CarrierContacts
                data={data.carrierShippingContacts}
                shipmentData={props.shipmentData}
              />
            </Frame>
            <Frame>
              <Title level={4}>Carrier Finance Contacts</Title>
              <CarrierContacts
                data={data.carrierBillingContacts}
                shipmentData={props.shipmentData}
              />
            </Frame>
            {data.advanceShippingContacts && (
              <>
                <Frame>
                  <Title level={4}>
                    {
                      props.shipmentData.shipment.shipment
                        .advanceInterlineCarrierDisplayName
                    }{" "}
                    Phone Number
                  </Title>
                  <CarrierPhoneContacts
                    data={generateDataForPhoneNumber(
                      data.advanceCustomerServiceNumber ?? "-",
                    )}
                    shipmentData={props.shipmentData}
                  />
                </Frame>
                <Frame>
                  <Title level={4}>
                    {
                      props.shipmentData.shipment.shipment
                        .advanceInterlineCarrierDisplayName
                    }{" "}
                    Carrier Contacts
                  </Title>
                  <CarrierContacts
                    data={data.advanceShippingContacts}
                    shipmentData={props.shipmentData}
                  />
                </Frame>
              </>
            )}
            {data.beyondShippingContacts && (
              <>
                <Frame>
                  <Title level={4}>
                    {
                      props.shipmentData.shipment.shipment
                        .beyondInterlineCarrierDisplayName
                    }{" "}
                    Phone Number
                  </Title>
                  <CarrierPhoneContacts
                    data={generateDataForPhoneNumber(
                      data.beyondCustomerServiceNumber ?? "-",
                    )}
                    shipmentData={props.shipmentData}
                  />
                </Frame>
                <Frame>
                  <Title level={4}>
                    {
                      props.shipmentData.shipment.shipment
                        .beyondInterlineCarrierDisplayName
                    }{" "}
                    Carrier Contacts
                  </Title>
                  <CarrierContacts
                    data={data.beyondShippingContacts}
                    shipmentData={props.shipmentData}
                  />
                </Frame>
              </>
            )}
          </Col>
        )}
      </Row>
    </>
  );
}

function Frame({ children }: { children: ReactNode }) {
  return (
    <div
      style={{
        padding: "32px",
        margin: "16px",
        border: "1px solid #ddd",
      }}
    >
      {children}
    </div>
  );
}
