import { Button, Modal, Typography } from "antd";
import { useState } from "react";
import Spacer from "../../Spacer";
import { TextArea } from "../../Types/AntHelperTypes";
const { Text } = Typography;

interface ExtraTextButtonProps {
  extraText: string;
  setExtraText: (_: string) => void;
  title?: string;
}

export function ExtraTextButton(props: ExtraTextButtonProps) {
  const [text, setText] = useState(props.extraText);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const title = props.title ?? "Extra Text";

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    props.setExtraText(text);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        okText={`Enter ${title}`}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={600}
        closable={false}
      >
        <Text style={{ fontSize: "18px" }}>Enter {title}</Text>
        <Spacer height={32} />
        <TextArea
          style={{ width: "600px", height: "100px" }}
          value={text}
          onChange={function (e) {
            setText(e.target.value);
          }}
        />
      </Modal>
      <Button ghost onClick={showModal}>
        Add {title}
      </Button>
    </>
  );
}
