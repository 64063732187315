import {
  CalendarDataPoint,
  ShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import dayjs from "dayjs";
import { groupBy } from "./groupBy";

export function countYears(d: CalendarDataPoint[]): number {
  const grouped = groupBy(d, function (point) {
    // extract the year from YYYY-MM-DD date
    return point.day.split("-")[0];
  });
  return grouped.length;
}

export function countMonths(d: ShipmentReport[]): number {
  const grouped = groupBy(d, function (point) {
    // extract the year from YYYY-MM-DD date
    return dayjs(point.bookedAt).startOf("day").format("YYYY-MM");
  });
  return grouped.length;
}
