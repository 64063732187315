import { ErrorMessageType } from "../../Types/ErrorMessageType";

export function recommendFreightClassFromDimensions(
  weight: number,
  length: number,
  width: number,
  height: number,
): { freightClass: string | undefined; errorMessage: ErrorMessageType } {
  if (length === 0 || width === 0 || height === 0) {
    return {
      freightClass: undefined,
      errorMessage: "Dimension must not be zero",
    };
  }

  const volumeInCubicInches = length * width * height;
  const volumeInCubicFeet = volumeInCubicInches / (12 * 12 * 12);
  const density = weight / volumeInCubicFeet;

  function fc(freightClass: string) {
    return { freightClass, errorMessage: undefined };
  }

  // See: https://www.fedex.com/en-us/shipping/freight/class-calculator.html
  if (density >= 50) {
    return fc("50");
  }

  if (density >= 35) {
    return fc("55");
  }

  if (density >= 30) {
    return fc("60");
  }

  if (density >= 22.5) {
    return fc("65");
  }

  if (density >= 15) {
    return fc("70");
  }

  if (density >= 13.5) {
    return fc("77.5");
  }

  if (density >= 12) {
    return fc("85");
  }

  if (density >= 10.5) {
    return fc("92.5");
  }

  if (density >= 9) {
    return fc("100");
  }

  if (density >= 8) {
    return fc("110");
  }

  if (density >= 7) {
    return fc("125");
  }

  if (density >= 6) {
    return fc("150");
  }

  if (density >= 5) {
    return fc("175");
  }

  if (density >= 4) {
    return fc("200");
  }

  if (density >= 3) {
    return fc("250");
  }

  if (density >= 2) {
    return fc("300");
  }

  if (density >= 1) {
    return fc("400");
  }

  return fc("500");
}
