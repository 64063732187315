import {
  CustomerInvoice,
  CustomerInvoiceState,
  PaymentMethod,
} from "@freightsimple/generated-apollo-openapi-client";
import { Form, Input, Menu, message, Modal, Select, Tooltip } from "antd";
import { useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";

const { Option } = Select;

interface MarkCustomerInvoicePaymentPendingMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
  onCloseParent: () => void;
}

export function MarkCustomerInvoicePaymentPendingMenuItem(
  props: MarkCustomerInvoicePaymentPendingMenuItemProps,
) {
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.Cheque,
  );
  const [loading, setLoading] = useState(false);
  const [paymentReference, setPaymentReference] = useState("");
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setLoading(true);
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.markInvoiceSettlementPendingByOtherMethod({
        customerInvoiceId,
        paymentMethod,
        paymentReference,
      });
      await props.onRefresh();
      message.success("Invoice marked settlement pending");
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
    props.onCloseParent();
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (
    props.customerInvoice.customerInvoiceState !== CustomerInvoiceState.Issued
  ) {
    return <></>;
  }

  return (
    <>
      <Modal
        title="Mark Customer Invoice Settlement Pending"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        confirmLoading={loading}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Payment Method">
            <Select
              value={paymentMethod}
              style={{ width: 266 }}
              onChange={setPaymentMethod}
            >
              <Option value={PaymentMethod.Cash}>Cash</Option>
              <Option value={PaymentMethod.Cheque}>Cheque</Option>
              <Option value={PaymentMethod.Wire}>Wire</Option>
              <Option value={PaymentMethod.Eft}>EFT</Option>
              <Option value={PaymentMethod.InteracEtransfer}>
                Interac eTransfer
              </Option>
            </Select>
          </Form.Item>
          <Form.Item label="Payment Reference">
            <Input
              value={paymentReference}
              onChange={function (e) {
                setPaymentReference(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Tooltip
        placement="left"
        title="If the customer has told us they have sent payment (eg. via cheque, wire, EFT, eTransfer), then we mark the payment as settlement pending"
      >
        <Menu.Item onClick={showModal}>
          Mark Customer Invoice Settlement Pending
        </Menu.Item>
      </Tooltip>
    </>
  );
}
