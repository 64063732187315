import { Button, Form, Modal, message } from "antd";
import { useState } from "react";
import { TextListInput } from "../../Components/TextListInput";
import { isValidEmail } from "../../Helpers/isValidEmail";
import Spacer from "../../Spacer";

interface ManageRecipientsButtonProps {
  toAddresses: string[];
  setToAddresses: (_: string[]) => void;
}

export function ManageRecipientsButton(props: ManageRecipientsButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const title = `Manage Recipients`;

  const [toAddresses, setToAddresses] = useState(props.toAddresses);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (toAddresses.some((address) => !isValidEmail(address))) {
      message.warning("Not valid email");
      return;
    }

    props.setToAddresses(toAddresses);

    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={720}
      >
        <Spacer height={16} />
        <Form style={{ width: "800px" }} layout="horizontal" colon={false}>
          <Form.Item label="To Addresses">
            <TextListInput list={toAddresses} setList={setToAddresses} />
          </Form.Item>
        </Form>
      </Modal>
      <Button ghost onClick={showModal}>
        {title}
      </Button>
    </>
  );
}
