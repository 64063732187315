import { Typography } from "antd";
import { CollapsibleNote } from "../../../Components/NotesBase/CollapseNote";
import { NoteDetailsBox } from "../../../Components/NotesBase/NoteDetailsBox";
import { NoteAuthorHeadline } from "../../../Components/NotesBase/NoteHeadline";
import {
  BasicCompanyNoteElement,
  CompanyNoteElementProps,
} from "../CompanyNote";

export function UserActionCompanyNoteElement(props: CompanyNoteElementProps) {
  const { note } = props;

  const metadata = JSON.parse(note.metadata!);
  const identifier = metadata.identifier;
  const data = metadata.data;

  if (identifier === "user-joined-invitation") {
    return (
      <CollapsibleNote
        collapseActive={props.collapseActive}
        header={
          <NoteAuthorHeadline headline={note.subject} author={note.author} />
        }
      >
        <div style={{ display: "flex", columnGap: "20px" }}>
          <NoteDetailsBox>
            <Typography.Title level={4}>Invitee</Typography.Title>
            <NoteDetailsBox.LeftRightDetails
              left={"Name"}
              right={data.invitee.name}
            />
            <NoteDetailsBox.LeftRightDetails
              left={"Email"}
              right={data.invitee.email}
            />
            <NoteDetailsBox.LeftRightDetails
              left={"Phone"}
              right={data.invitee.phoneNumber}
            />
            <NoteDetailsBox.LeftRightDetails
              left={"Phone Extension"}
              right={data.invitee.phoneNumberExtension}
            />
          </NoteDetailsBox>
          <NoteDetailsBox>
            <Typography.Title level={4}>Sent by</Typography.Title>
            <NoteDetailsBox.LeftRightDetails
              left={"Name"}
              right={data.sendBy.user_metadata.name}
            />
            <NoteDetailsBox.LeftRightDetails
              left={"Email"}
              right={data.sendBy.user_metadata.email}
            />
            <NoteDetailsBox.LeftRightDetails
              left={"Phone"}
              right={data.sendBy.user_metadata.phoneNumber}
            />
            <NoteDetailsBox.LeftRightDetails
              left={"Phone Extension"}
              right={data.sendBy.user_metadata.phoneNumberExtension}
            />
          </NoteDetailsBox>
        </div>
      </CollapsibleNote>
    );
  }

  return <BasicCompanyNoteElement {...props} />;
}
