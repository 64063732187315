import {
  InvoiceAuditProblem,
  InvoiceAuditProblemType,
  InvoiceChallengeType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Select } from "antd";
import { assertNever } from "../../Helpers/assertNever";
import { isNotBlank } from "@freightsimple/shared";

interface InvoiceChallengeTypeDropdownProps {
  onSelect: (invoiceChallengeType: InvoiceChallengeType) => void;
  invoiceChallengeType: InvoiceChallengeType;
  invoiceAuditProblems: InvoiceAuditProblem[];
  carrierQuoteReference: string | undefined;
}

function describeInvoiceChallengeType(
  invoiceChallengeType: InvoiceChallengeType,
) {
  switch (invoiceChallengeType) {
    case InvoiceChallengeType.ApplyQuote:
      return "Apply Quote";
    case InvoiceChallengeType.ApplyRate:
      return "Apply Rate";
    case InvoiceChallengeType.IncorrectFreightClass:
      return "Incorrect Freight Class";
    case InvoiceChallengeType.JustifyAdditionalWeight:
      return "Justify Additional Weight";
    case InvoiceChallengeType.ProvidePhotos:
      return "Provide Photos";
    case InvoiceChallengeType.RemoveDeliveryAppointmentFee:
      return "Remove Delivery Appointment Fee";
    case InvoiceChallengeType.RemoveNotifyPriorToDeliveryFee:
      return "Remove Notify Prior To Delivery Fee";
    case InvoiceChallengeType.JustifyDimensions:
      return "Justify Dimensions";
    case InvoiceChallengeType.RemoveResidentialFee:
      return "Remove Residential Fee";
    case InvoiceChallengeType.RemovePickupLiftgateFee:
      return "Remove Liftgate Pickup Fee";
    case InvoiceChallengeType.RemoveDeliveryLiftgateFee:
      return "Remove Liftgate Delivery Fee";
    case InvoiceChallengeType.RemoveInsidePickupFee:
      return "Remove Inside Pickup Fee";
    case InvoiceChallengeType.RemoveInsideDeliveryFee:
      return "Remove Inside Delivery Fee";
    case InvoiceChallengeType.RemoveLimitedAccessPickupFee:
      return "Remove Limited Access Pickup Fee";
    case InvoiceChallengeType.RemoveLimitedAccessDeliveryFee:
      return "Remove Limited Access Delivery Fee";
    case InvoiceChallengeType.RemoveBondFee:
      return "Remove Bond Fee";
    case InvoiceChallengeType.RemoveStorageFee:
      return "Remove Storage Fee";
    case InvoiceChallengeType.RemoveRedeliveryFee:
      return "Remove Redelivery";
    case InvoiceChallengeType.RemoveDetentionFee:
      return "Remove Detention Fee";
    case InvoiceChallengeType.CarrierRequestAudit:
      return "Send Carrier Request Audit";
    case InvoiceChallengeType.Generic:
      return "Generic";
    default:
      assertNever(invoiceChallengeType);
  }
}

function generateApplyInvoiceChallengeTypeFilter(
  invoiceAuditProblems: InvoiceAuditProblem[],
  carrierQuoteReference: string | undefined,
) {
  const hasQuoteNumber = isNotBlank(carrierQuoteReference);

  return function (invoiceChallengeType: InvoiceChallengeType) {
    switch (invoiceChallengeType) {
      case InvoiceChallengeType.ApplyQuote:
        return hasQuoteNumber;
      case InvoiceChallengeType.ApplyRate:
        return !hasQuoteNumber;
      case InvoiceChallengeType.IncorrectFreightClass:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.FreightClassChange,
        );
      case InvoiceChallengeType.JustifyAdditionalWeight:
        return true;
      case InvoiceChallengeType.ProvidePhotos:
        return true;
      case InvoiceChallengeType.RemoveDeliveryAppointmentFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.DeliveryAppointment,
        );
      case InvoiceChallengeType.RemoveNotifyPriorToDeliveryFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.DeliveryNotificationFee,
        );
      case InvoiceChallengeType.JustifyDimensions:
        return true;
      case InvoiceChallengeType.RemoveResidentialFee:
        return invoiceAuditProblems.some(
          (iap) =>
            iap.type === InvoiceAuditProblemType.ResidentialPickup ||
            iap.type === InvoiceAuditProblemType.ResidentialDelivery,
        );
      case InvoiceChallengeType.RemovePickupLiftgateFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.LiftgateAtPickup,
        );
      case InvoiceChallengeType.RemoveDeliveryLiftgateFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.LiftgateAtDelivery,
        );
      case InvoiceChallengeType.RemoveInsidePickupFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.InsidePickup,
        );
      case InvoiceChallengeType.RemoveInsideDeliveryFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.InsideDelivery,
        );
      case InvoiceChallengeType.RemoveLimitedAccessPickupFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.LimitedAccessPickup,
        );
      case InvoiceChallengeType.RemoveLimitedAccessDeliveryFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.LimitedAccessDelivery,
        );
      case InvoiceChallengeType.RemoveBondFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.CustomsCharge,
        );
      case InvoiceChallengeType.RemoveStorageFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.Storage,
        );
      case InvoiceChallengeType.RemoveRedeliveryFee:
        return invoiceAuditProblems.some(
          (iap) => iap.type === InvoiceAuditProblemType.Redelivery,
        );
      case InvoiceChallengeType.RemoveDetentionFee:
        return invoiceAuditProblems.some(
          (iap) =>
            iap.type === InvoiceAuditProblemType.DetentionPickup ||
            iap.type === InvoiceAuditProblemType.DetentionDelivery,
        );
      case InvoiceChallengeType.CarrierRequestAudit:
        return true;
      case InvoiceChallengeType.Generic:
        return true;
      default:
        assertNever(invoiceChallengeType);
    }
  };
}

export function InvoiceChallengeTypeDropdown(
  props: InvoiceChallengeTypeDropdownProps,
) {
  return (
    <Select
      value={props.invoiceChallengeType}
      style={{ width: 300 }}
      onChange={props.onSelect}
      showSearch
    >
      {Object.entries(InvoiceChallengeType)
        .filter(function (value) {
          const v = value[1];
          return generateApplyInvoiceChallengeTypeFilter(
            props.invoiceAuditProblems,
            props.carrierQuoteReference,
          )(v);
        })
        .map(function (value) {
          const v = value[1];
          const description = describeInvoiceChallengeType(v);
          return (
            <Select.Option key={v} value={v}>
              {description}
            </Select.Option>
          );
        })}
    </Select>
  );
}
