import { Button } from "antd";
import { useAttach } from "./useAttach";
interface AttachButtonProps {
  companyId: string;
}

export function AttachButton(props: AttachButtonProps) {
  const { attach } = useAttach();
  function onAttach() {
    attach(props.companyId);
  }
  return (
    <Button onClick={onAttach} key="attach">
      Attach
    </Button>
  );
}
