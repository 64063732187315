function stripNonNumeric(phoneNumber: string): string {
  return phoneNumber.replace(/\D+/g, "");
}

export function useTelWithShipmentId(
  phone: string,
  shipmentId: string,
  numberDescription: string,
) {
  const strippedPhoneNumber = stripNonNumeric(phone);
  return function (e?: React.MouseEvent | React.TouchEvent) {
    const customData = JSON.stringify({
      shipmentId,
      calleeDescription: numberDescription,
    });
    const dialpadUrl = `dialpad:${strippedPhoneNumber}?launchMinimode=1&confirmPhone=0&customData=${encodeURIComponent(
      customData,
    )}`;

    window.location.href = dialpadUrl;
    e?.preventDefault?.();
  };
}

export function useTelWithCompanyId(
  phone: string,
  companyId: string,
  salesContactId: string | undefined,
  numberDescription: string,
) {
  const strippedPhoneNumber = stripNonNumeric(phone);
  return function (
    e?: React.MouseEvent | React.TouchEvent,
    companyNoteId?: string,
  ) {
    const customData = JSON.stringify({
      companyId,
      salesContactId,
      calleeDescription: numberDescription,
      companyNoteId,
    });
    const dialpadUrl = `dialpad:${strippedPhoneNumber}?launchMinimode=1&confirmPhone=0&customData=${encodeURIComponent(
      customData,
    )}`;

    window.location.href = dialpadUrl;
    e?.preventDefault?.();
  };
}
