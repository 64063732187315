/**
 * Gets all unique keys from an array of objects
 * @param data Array of objects to extract keys from
 * @returns Array of unique keys
 */
export function getAllUniqueKeys<T extends Record<string, unknown>>(
  data: T[],
): string[] {
  return Array.from(
    new Set(
      data.reduce<string[]>(
        (allKeys, row) => allKeys.concat(Object.keys(row)),
        [],
      ),
    ),
  );
}
