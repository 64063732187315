import {
  ApolloMapQuery,
  ApolloMapQueryBookingStatus,
  ShipmentReport,
} from "@freightsimple/generated-apollo-openapi-client";
import { Col, Row, Typography } from "antd";
import HorizontalStack from "../../Components/HorizontalStack";
import { groupBy } from "../../Helpers/groupBy";
import Spacer from "../../Spacer";

interface AllShipmentsScreenStatsProps {
  shipments: ShipmentReport[];
  query: ApolloMapQuery;
}

export function AllShipmentsScreenStats(props: AllShipmentsScreenStatsProps) {
  const { Text } = Typography;
  const labelStyle = {
    fontSize: "11px",
    color: "rgba(0, 0, 0, 0.85)",
    fontWeight: 500,
  };
  const query = props.query;
  const valueStyle = { fontSize: "11px", color: "rgba(0, 0, 0, 0.65)" };

  const shipments = props.shipments;
  const numberBooked = shipments.filter((o) => o.booked).length;
  const uniqueShipments = groupBy(
    shipments,
    (q) => q.shipmentHash ?? "-",
  ).length;
  const totalShipments = shipments.length;
  const numberCompaniesQuoted = groupBy(shipments, (o) => o.companyName).length;
  const numberCompaniesBooked = groupBy(
    shipments.filter((o) => o.booked),
    (o) => o.companyName,
  ).length;

  const conversion = ((numberBooked / uniqueShipments) * 100).toFixed(0);

  return (
    <Row>
      <Col span={24}>
        <HorizontalStack spacing={8}>
          <Text style={labelStyle}>Shipments:</Text>
          {query.bookingStatus !== ApolloMapQueryBookingStatus.Booked && (
            <>
              <Text style={valueStyle}>Unique: {uniqueShipments}</Text>
              <Text style={valueStyle}>Quotes: {totalShipments}</Text>
            </>
          )}

          <Text style={valueStyle}>Booked: {numberBooked}</Text>
          {query.bookingStatus !== ApolloMapQueryBookingStatus.Booked && (
            <>
              <Text style={valueStyle}>Acceptance: {conversion}%</Text>
            </>
          )}
          <Spacer width={8} />
          <Text style={labelStyle}>Companies:</Text>
          {query.bookingStatus !== ApolloMapQueryBookingStatus.Booked && (
            <>
              <Text style={valueStyle}>Quoted: {numberCompaniesQuoted}</Text>
            </>
          )}
          <Text style={valueStyle}>Booked: {numberCompaniesBooked}</Text>
        </HorizontalStack>
      </Col>
    </Row>
  );
}
