import {
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Checkbox, Form, message, Modal, Typography } from "antd";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import { InfoBubble } from "../../Components/InfoBubble";
import Stack from "../../Components/Stack";
import { isBlank } from "@freightsimple/shared";
import Spacer from "../../Spacer";
import { WarningFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { TextArea } from "../../Types/AntHelperTypes";

interface CancelShipmentButtonProps {
  shipment: Shipment;
  onRefresh: () => Promise<void>;
}

const { Text } = Typography;

const { confirm } = Modal;

export function CancelShipmentButton(props: CancelShipmentButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentApi = useShipmentsApi();
  const [doNotCancelPickup, setDoNotCancelPickup] = useState(
    props.shipment.state === ShipmentState.OnHold,
  );
  const [simplyMarkAsCancelled, setSimplyMarkAsCancelled] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [reason, setReason] = useState("");

  const showModal = () => {
    const pickupDate = dayjs(props.shipment.pickupDate);
    const isToday = pickupDate.isSame(dayjs(), "day");
    if (isToday) {
      confirm({
        width: 600,
        title: "Pickup is Scheduled for Today",
        icon: <WarningFilled />,
        content:
          "Please confirm that you've checked whether the shipment has been picked up before proceeding.",
        okText: "Yes, I've checked",
        onOk() {
          setIsModalVisible(true);
        },
      });
    } else {
      setIsModalVisible(true);
    }
  };

  async function handleOk() {
    if (isBlank(reason)) {
      message.warning("You need to describe a reason!");
      return;
    }

    setCancelling(true);
    try {
      const shipmentId = props.shipment.shipmentId!;

      const shipmentApi = await createShipmentApi();

      if (simplyMarkAsCancelled) {
        await shipmentApi.markCancelled({ shipmentId, reason });
      } else {
        await shipmentApi.cancelShipment({
          shipmentId,
          doNotCancelPickup,
          reason,
        });
      }

      await props.onRefresh();

      if (simplyMarkAsCancelled) {
        message.success("Shipment marked as cancelled. No other actions taken");
      } else {
        if (doNotCancelPickup) {
          message.success("Shipment Cancelled. Pickup was not cancelled.");
        } else {
          message.success("Shipment Cancelled. Pickup cancellation requested.");
        }
      }
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
    setCancelling(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (
    props.shipment.state !== ShipmentState.BookingConfirmed &&
    props.shipment.state !== ShipmentState.OnHold
  ) {
    return <></>;
  }

  function okText() {
    if (simplyMarkAsCancelled) {
      return "Yes, mark as cancelled";
    } else {
      if (doNotCancelPickup) {
        return "Yes, cancel this shipment (keep pickup as is)";
      } else {
        return "Yes, cancel this shipment";
      }
    }
  }

  const willCancelPickup =
    !doNotCancelPickup && props.shipment.state !== ShipmentState.OnHold;

  function onHoldNote() {
    if (props.shipment.state === ShipmentState.OnHold) {
      return ` (This is on hold, so probably doesn't make sense to send an email) `;
    } else {
      return ``;
    }
  }

  return (
    <>
      <Modal
        title="🚫 Cancel Shipment"
        visible={isModalVisible}
        onOk={handleOk}
        okText={okText()}
        onCancel={handleCancel}
        confirmLoading={cancelling}
        width={600}
        destroyOnClose
      >
        <Stack align="left">
          <HorizontalStack>
            <Checkbox
              checked={doNotCancelPickup}
              onChange={function (e) {
                setDoNotCancelPickup(e.target.checked);
              }}
            >
              <div>
                ✉️ Do not send cancel pickup email {onHoldNote()}
                <InfoBubble overlay="Normally cancelling the shipment will email the carrier asking them to cancel the pickup. However if you have already phoned/emailed to cancel, then check this to avoid sending another email." />
              </div>
            </Checkbox>
          </HorizontalStack>
          <Spacer height={8} />
          <HorizontalStack>
            <Checkbox
              checked={simplyMarkAsCancelled}
              onChange={function (e) {
                setSimplyMarkAsCancelled(e.target.checked);
              }}
            >
              <div>
                Simply mark as cancelled?{" "}
                <InfoBubble overlay="Normally cancelling the shipment emails to cancel the pickup, processes a refund, etc. Once in a while you might just need to mark it as cancelled. eg. if this shipment was consolidated with another." />
              </div>
            </Checkbox>
          </HorizontalStack>
          <Spacer height={32} />
          {!simplyMarkAsCancelled && (
            <>
              <Text>
                This will cancel the shipment. It does the following steps
              </Text>
              <ol>
                <li>Creates a monday task</li>
                <li>Mark the shipment as cancelled</li>
                {willCancelPickup && <li>Emails the carrier</li>}
                {!willCancelPickup && (
                  <li>
                    <s>Emails the carrier</s>
                  </li>
                )}
                <li>
                  Refunds the credit card / bank payment or voids the shipment
                  invoice
                </li>
                <li>Closes the monday task if everything was successful</li>
              </ol>
            </>
          )}
          {simplyMarkAsCancelled && (
            <Text>This will mark the shipment as cancelled. Nothing else.</Text>
          )}
        </Stack>
        <Spacer height={32} />
        <HorizontalStack>
          <Form.Item label="Reason">
            <TextArea
              cols={55}
              value={reason}
              onChange={(event) => setReason(event.target.value)}
            />
          </Form.Item>
        </HorizontalStack>
      </Modal>
      <Button onClick={showModal}>🚫 Cancel Shipment</Button>
    </>
  );
}
