import { CustomerInvoice } from "@freightsimple/generated-apollo-openapi-client";

export function findInvoiceSubset(
  invoices: CustomerInvoice[],
  targetSum: number,
): CustomerInvoice[] {
  const PRECISION = 100; // Adjust this based on your required precision
  const scaledInvoices = invoices.map((inv) => ({
    amount: Math.round(inv.amount! * PRECISION),
  }));
  const scaledTargetSum = Math.round(targetSum * PRECISION);

  const n = scaledInvoices.length;
  const dp: boolean[][] = Array(n + 1)
    .fill(null)
    .map(() => Array(scaledTargetSum + 1).fill(false));

  // Initialize base cases
  for (let i = 0; i <= n; i++) {
    dp[i][0] = true;
  }

  // Fill the dp table
  for (let i = 1; i <= n; i++) {
    for (let j = 1; j <= scaledTargetSum; j++) {
      if (j < scaledInvoices[i - 1].amount) {
        dp[i][j] = dp[i - 1][j];
      } else {
        dp[i][j] = dp[i - 1][j] || dp[i - 1][j - scaledInvoices[i - 1].amount];
      }
    }
  }

  // If no solution exists
  if (!dp[n][scaledTargetSum]) {
    return [];
  }

  // Reconstruct the solution
  const result: CustomerInvoice[] = [];
  let i = n,
    j = scaledTargetSum;
  while (i > 0 && j > 0) {
    if (dp[i][j] !== dp[i - 1][j]) {
      result.push(invoices[i - 1]);
      j -= scaledInvoices[i - 1].amount;
    }
    i--;
  }

  return result;
}
