import { CustomerInvoiceState } from "@freightsimple/generated-apollo-openapi-client";
import { assertNever } from "./assertNever";

export function foregroundColorForCustomerInvoiceState(
  state: CustomerInvoiceState,
) {
  switch (state) {
    case CustomerInvoiceState.Voided:
      return "white";
    case CustomerInvoiceState.Issued:
      return "black";
    case CustomerInvoiceState.SettlementPending:
      return "white";
    case CustomerInvoiceState.Settled:
      return "black";
    case CustomerInvoiceState.Reconciled:
      return "white";
    case CustomerInvoiceState.Deleted:
      return "white";
    default:
      assertNever(state);
  }
}
