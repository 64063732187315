import { LookupResultType } from "@freightsimple/generated-apollo-openapi-client";

import { message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLookupApi } from "../Apis/Apis";

export function useLookup() {
  const createLookupApi = useLookupApi();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  async function onSearch(query: string) {
    setLoading(true);
    try {
      const lookupApi = await createLookupApi();
      const result = await lookupApi.lookup({ query });

      if (result.resultType === LookupResultType.Shipment) {
        navigate(`/view-shipment?shipmentId=${result.resultId}`);
      } else if (result.resultType === LookupResultType.Company) {
        navigate(`/view-company?companyId=${result.resultId}`);
      }
    } catch {
      message.error(`Nothing found`);
    }
    setLoading(false);
  }

  return { onSearch, loading };
}
