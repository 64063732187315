import {
  EmailDescription,
  ViewShipmentDataForApollo,
} from "@freightsimple/generated-apollo-openapi-client";
import { message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import { SupportAgent } from "../ViewCompanyScreen";
import { ExtraTextButton } from "./ExtraTextButton";
import { SupportEmailButton } from "./SupportEmailButton";

interface CarrierClaimSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
  freightClaimId: string;
}

export function CarrierClaimSupportEmailButton(
  props: CarrierClaimSupportEmailButtonProps,
) {
  const shipmentId = props.data.shipmentId;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");

  const freightClaimId = props.freightClaimId;

  const input = {
    extraText,
    freightClaimId,
  };

  async function onFetchPreview(
    supportAgent: SupportAgent,
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewCarrierClaimEmail({
        ...input,
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch {
      message.error(`Oops. Something went wrong`);
      return undefined;
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[],
  ): Promise<boolean> {
    if (attachmentDocumentIds.length === 0) {
      message.warning(
        `No attachments. Please attach the claim/invoice/any other relevant docs`,
      );
      return false;
    }

    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendCarrierClaimEmail({
        ...input,
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="✉️ Email Claim to Carrier"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={JSON.stringify(input)}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
        </>
      }
      shipmentData={props.data}
    />
  );
}
