import {
  NoteCategory,
  NoteType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Checkbox, Form, Input } from "antd";
import { useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import { NotesTable } from "../../Components/NotesTable";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { AddInternalNoteButton } from "./AddInternalNoteButton";
import { NoteAuthorDropdown } from "./NoteAuthorDropdown";
import { OptionalNoteCategoryDropdown } from "./NoteCategoryDropdown";
import { NoteTypeDropdown } from "./NoteTypeDropdown";
import { TabProps } from "./TabProps";
import { ViewEmailConversationsButton } from "./ViewEmailConversationsButton";

export function NotesTab(props: TabProps) {
  const [noteTypeFilter, setNoteTypeFilter] = useState<NoteType>();
  const [noteCategoryFilter, setNoteCategoryFilter] = useState<NoteCategory>();
  const [noteAuthorFilter, setNoteAuthorFilter] = useState<string>();
  const [freeFilter, setFreeFilter] = useState<string>();
  const [showSystemErrors, setShowSystemErrors] = useState<boolean>(false);

  return (
    <Stack align="left">
      <ButtonRow>
        <Form.Item label="Filter">
          <Input
            value={freeFilter}
            onChange={function (e) {
              setFreeFilter(e.currentTarget.value);
            }}
          ></Input>
        </Form.Item>
        <Form.Item label="by type">
          <NoteTypeDropdown
            mode="multiple"
            value={noteTypeFilter}
            setValue={setNoteTypeFilter}
          />
        </Form.Item>
        <Form.Item label="by category">
          <OptionalNoteCategoryDropdown
            mode="multiple"
            value={noteCategoryFilter}
            setValue={setNoteCategoryFilter}
          />
        </Form.Item>
        <Form.Item label="by author">
          <NoteAuthorDropdown
            value={noteAuthorFilter}
            setValue={setNoteAuthorFilter}
            notes={props.shipmentData.notesTab.notes}
          />
        </Form.Item>
        <Form.Item label="Actions">
          <ButtonRow>
            <ViewEmailConversationsButton {...props} />
            <AddInternalNoteButton {...props} />
          </ButtonRow>
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={showSystemErrors}
            onChange={(e) => setShowSystemErrors(e.target.checked)}
          >
            Show System Errors
          </Checkbox>
        </Form.Item>
      </ButtonRow>
      <Spacer height={16} />
      <NotesTable
        showSystemErrors={showSystemErrors}
        notes={props.shipmentData.notesTab.notes}
        noteTypeFilter={noteTypeFilter}
        noteAuthorFilter={noteAuthorFilter}
        noteCategoryFilter={noteCategoryFilter}
        freeFilter={freeFilter}
      />
    </Stack>
  );
}
