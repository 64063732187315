import { SalesContact } from "@freightsimple/generated-apollo-openapi-client";
import { Button, Form, Input, message, Modal, Switch } from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { isNotBlank } from "@freightsimple/shared";

interface AddSalesContactButtonProps {
  companyId: string;
  onContactAdded: (newSalesContact: SalesContact) => Promise<void>;
}

export function AddSalesContactButton(props: AddSalesContactButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const title = "Add Contact";
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneExtension, setPhoneExtension] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [makePrimary, setMakePrimary] = useState(false);
  const [saving, setSaving] = useState(false);
  const createCompaniesApi = useCompaniesApi();
  const { companyId } = props;

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (
      name === "" &&
      phoneNumber === "" &&
      email === "" &&
      mobileNumber === ""
    ) {
      message.warning("Please enter some info");
      return;
    }

    if (isNotBlank(email) && !email.includes("@")) {
      message.warning(`Email doesn't seem valid`);
      return;
    }

    setSaving(true);
    try {
      const companiesApi = await createCompaniesApi();
      const response = await companiesApi.createSalesContact({
        createSalesContactInput: {
          companyId,
          name,
          phoneNumber,
          phoneExtension,
          mobileNumber,
          email,
          description,
          makePrimary,
        },
      });
      const salesContactId = response.salesContactId;
      await props.onContactAdded({
        salesContactId,
        name,
        phone: phoneNumber,
        mobilePhone: mobileNumber,
        phoneExtension,
        email,
        description,
      });
      message.success(`Success`);
      setIsModalVisible(false);
    } catch {
      message.error(`Eek. Something went wrong`);
    }

    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        confirmLoading={saving}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
      >
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Name">
            <Input
              value={name}
              onChange={function (e) {
                setName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Phone Number">
            <Input
              value={phoneNumber}
              onChange={function (e) {
                setPhoneNumber(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Phone Extension">
            <Input
              value={phoneExtension}
              onChange={function (e) {
                setPhoneExtension(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Mobile Number">
            <Input
              value={mobileNumber}
              onChange={function (e) {
                setMobileNumber(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              value={description}
              onChange={function (e) {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              value={email}
              onChange={function (e) {
                setEmail(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Make Primary">
            <Switch checked={makePrimary} onChange={setMakePrimary} />
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}
