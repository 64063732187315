import {
  CarrierCreditNote,
  CarrierCreditNoteState,
  CarrierInvoice,
  CarrierInvoiceState,
  CompanyShipmentQuote,
  CustomerInvoice,
  CustomerInvoiceState,
  InvoiceAuditProblemConfidence,
  InvoiceAuditProblemFault,
  InvoiceAuditProblemResolutionState,
  InvoiceAuditProblemResolutionType,
  InvoiceAuditTurn,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";
import { daysAgo } from "../Helpers/daysAgo";

export enum RequiresActionType {
  NewAudit = "NewAudit",
  NewInvoice = "NewInvoice",
  ReadCarrierReply = "ReadCarrierReply",
  ReadCustomerReply = "ReadCustomerReply",
  PingCarrier = "PingCarrier",
  EstablishFault = "EstablishFault",
  SendChallengeToCarrier = "SendChallengeToCarrier",
  PingCustomer = "PingCustomer",
  Other = "Other",
  UnknownNextStep = "UnknownNextStep",
  CloseAudit = "CloseAudit",
}

interface NextStep {
  requiresActionType: RequiresActionType | undefined;
  requiresAction: boolean;
  description: string;
  allowedToCloseAudit: boolean;
}

function nextStepWithAction(
  requiresActionType: RequiresActionType,
  description: string,
): NextStep {
  return {
    requiresActionType,
    requiresAction: true,
    description,
    allowedToCloseAudit: false,
  };
}

function closeAudit(): NextStep {
  return {
    requiresActionType: RequiresActionType.CloseAudit,
    description: "Close Audit. Everything approved.",
    requiresAction: true,
    allowedToCloseAudit: true,
  };
}

function nextStepWithNoAction(description: string): NextStep {
  return {
    requiresActionType: undefined,
    requiresAction: false,
    description,
    allowedToCloseAudit: false,
  };
}

interface AllowedToCloseAuditOutput {
  allowedToCloseAudit: boolean;
  reason: string;
}

function allowedToCloseAudit(
  csq: CompanyShipmentQuote,
  carrierInvoices: CarrierInvoice[],
  carrierCreditNotes: CarrierCreditNote[],
  customerInvoices: CustomerInvoice[],
): AllowedToCloseAuditOutput {
  const issuedAdditionalCharges = customerInvoices.filter(
    (ci) =>
      ci.additionalChargeId !== undefined &&
      ci.customerInvoiceState === CustomerInvoiceState.Issued,
  );
  const issuedCarrierInvoices = carrierInvoices.filter(
    (ci) => ci.invoiceState === CarrierInvoiceState.Issued,
  );
  const nonVoidedCarrierInvoices = carrierInvoices.filter(
    (ci) => ci.invoiceState !== CarrierInvoiceState.Voided,
  );
  const approvedCarrierCreditNotes = carrierCreditNotes.filter(
    (ccn) => ccn.carrierCreditNoteState === CarrierCreditNoteState.Approved,
  );

  function reason(_reason: string): AllowedToCloseAuditOutput {
    return {
      allowedToCloseAudit: false,
      reason: _reason,
    };
  }

  // Unpaid additional charges = cannot close
  // TODO: We should probably tweak this to 'unapproved' later
  if (
    issuedAdditionalCharges.length > 0 &&
    !issuedAdditionalCharges.every((ac) => daysAgo(ac.dueDate) >= 0) &&
    csq.company.creditLimit === 0
  ) {
    // If it's past the due date, we take it as approved
    // Don't worry if it's issued when they're on credit
    return reason("There are issued additional charges");
  }

  // If there are any unresolved problems - we can't resolve
  if (
    csq.shipment.invoiceAuditProblems?.some(
      (ci) =>
        ci.resolutionState !== InvoiceAuditProblemResolutionState.Resolved,
    )
  ) {
    return reason("There are unresolved problems");
  }

  if (issuedCarrierInvoices.length > 0) {
    return reason("There are issued carrier invoices");
  }

  if (
    nonVoidedCarrierInvoices.length === 0 &&
    csq.shipment.state !== ShipmentState.Cancelled
  ) {
    return reason("There are no non-voided carrier invoices");
  }

  const approvedCarrierCreditNotesWithoutRefundRequest =
    approvedCarrierCreditNotes.filter((o) => !o.refundRequested);
  if (approvedCarrierCreditNotesWithoutRefundRequest.length > 0) {
    return reason(
      "There are carrier credit notes where refund has not been requested",
    );
  }

  return { allowedToCloseAudit: true, reason: "All good" };
}

export function suggestNextStepForInvoiceAudit(
  csq: CompanyShipmentQuote,
  carrierInvoices: CarrierInvoice[],
  carrierCreditNotes: CarrierCreditNote[],
  customerInvoices: CustomerInvoice[],
): NextStep {
  const nonVoidedCarrierInvoices = carrierInvoices.filter(
    (ci) => ci.invoiceState !== CarrierInvoiceState.Voided,
  );
  const CARRIER_REQUIRES_PING_DAYS = 5;

  // One thing we want to be careful about is returning with a 'no action' earlier than we've tested
  // for a possible action. Like maybe we are 'waiting for carrier' (no action), but we need to 'make additional charge to customer' (action)
  // We don't want to miss taking the action because we also need to wait.
  // So in general most actions should come before no actions

  const allProblems = csq.shipment.invoiceAuditProblems!;
  const unresolvedCustomerFaultProblems =
    csq.shipment.invoiceAuditProblems!.filter(
      (p) =>
        p.fault === InvoiceAuditProblemFault.Customer &&
        p.resolutionState === InvoiceAuditProblemResolutionState.Unresolved,
    );
  const unresolvedCarrierFaultProblems =
    csq.shipment.invoiceAuditProblems!.filter(
      (p) =>
        p.fault === InvoiceAuditProblemFault.Carrier &&
        p.resolutionState === InvoiceAuditProblemResolutionState.Unresolved,
    );
  const pendingCustomerFaultProblems =
    csq.shipment.invoiceAuditProblems!.filter(
      (p) =>
        p.fault === InvoiceAuditProblemFault.Customer &&
        p.resolutionState === InvoiceAuditProblemResolutionState.Pending,
    );
  const disputedCustomerInvoices = customerInvoices.filter(
    (ci) =>
      ci.disputed && ci.customerInvoiceState === CustomerInvoiceState.Issued,
  );
  const issuedAdditionalCharges = customerInvoices.filter(
    (ci) =>
      ci.additionalChargeId !== undefined &&
      ci.customerInvoiceState === CustomerInvoiceState.Issued,
  );
  const additionalCharges = customerInvoices.filter(
    (ci) => ci.additionalChargeId !== undefined,
  );

  ///////////////////////////////////////////////////////
  // Start with a few trivial resolutions
  if (
    csq.shipment.invoiceAuditSnoozedUntilDate !== undefined &&
    daysAgo(csq.shipment.invoiceAuditSnoozedUntilDate) < 0
  ) {
    return nextStepWithNoAction("Currently snoozed");
  }

  if (csq.shipment.invoiceAuditProblems!.length === 0) {
    return nextStepWithAction(
      RequiresActionType.NewAudit,
      "New Audit: Identify some problems",
    );
  }

  if (nonVoidedCarrierInvoices.some((o) => !o.problemsIdentified)) {
    return nextStepWithAction(RequiresActionType.NewInvoice, "New invoice");
  }

  if (
    csq.shipment.invoiceAuditProblems!.some(
      (p) => p.fault === InvoiceAuditProblemFault.Unknown,
    )
  ) {
    if (csq.shipment.invoiceAuditCarrierTurn === undefined) {
      return nextStepWithAction(
        RequiresActionType.EstablishFault,
        "Establish fault",
      );
    }
  }

  ///////////////////////////////////////////////////////
  // Now get into handling moving conversations forward
  if (csq.shipment.invoiceAuditCarrierTurn === InvoiceAuditTurn.OurTurn) {
    return nextStepWithAction(
      RequiresActionType.ReadCarrierReply,
      "Read carrier reply",
    );
  }

  if (csq.shipment.invoiceAuditCustomerTurn === InvoiceAuditTurn.OurTurn) {
    return nextStepWithAction(
      RequiresActionType.ReadCustomerReply,
      "Read customer reply",
    );
  }

  if (csq.shipment.invoiceAuditCarrierTurnTime !== undefined) {
    if (
      csq.shipment.invoiceAuditCarrierTurn === InvoiceAuditTurn.TheirTurn &&
      daysAgo(csq.shipment.invoiceAuditCarrierTurnTime) >
        CARRIER_REQUIRES_PING_DAYS
    ) {
      return nextStepWithAction(RequiresActionType.PingCarrier, "Ping carrier");
    }
  }

  if (
    carrierCreditNotes.some(
      (ccn) =>
        ccn.carrierCreditNoteState === CarrierCreditNoteState.Approved &&
        ccn.refundRequested === false,
    )
  ) {
    return nextStepWithAction(
      RequiresActionType.Other,
      "Get refund from carrier for credit note",
    );
  }

  if (unresolvedCarrierFaultProblems.length > 0) {
    if (
      nonVoidedCarrierInvoices.every(
        (ci) => ci.invoiceState === CarrierInvoiceState.Approved,
      )
    ) {
      return nextStepWithAction(
        RequiresActionType.SendChallengeToCarrier,
        "All Carrier Invoices Approved should resolve problems",
      );
    }

    if (csq.shipment.invoiceAuditCarrierTurn === undefined) {
      return nextStepWithAction(
        RequiresActionType.SendChallengeToCarrier,
        "Send challenge to carrier",
      );
    }
  }

  if (disputedCustomerInvoices.length > 0) {
    if (csq.shipment.invoiceAuditCustomerTurnTime !== undefined) {
      if (
        csq.shipment.invoiceAuditCustomerTurn === InvoiceAuditTurn.TheirTurn &&
        daysAgo(csq.shipment.invoiceAuditCustomerTurnTime) > 3
      ) {
        return nextStepWithAction(
          RequiresActionType.PingCustomer,
          "Ping customer or resolve dispute",
        );
      }
    }
  }

  const unresolvedMediumOrHighCustomerFaultProblems =
    unresolvedCustomerFaultProblems.filter(
      (p) =>
        p.confidence === InvoiceAuditProblemConfidence.High ||
        p.confidence === InvoiceAuditProblemConfidence.Medium,
    );

  if (
    unresolvedMediumOrHighCustomerFaultProblems.length > 0 &&
    issuedAdditionalCharges.length === 0
  ) {
    return nextStepWithAction(
      RequiresActionType.Other,
      "Consider additional charge?",
    );
  }

  if (
    unresolvedCustomerFaultProblems.length > 0 &&
    issuedAdditionalCharges.length > 0
  ) {
    return nextStepWithAction(
      RequiresActionType.Other,
      "Consider marking customer problem as pending if an additional charge has been issued",
    );
  }

  if (
    pendingCustomerFaultProblems.length > 0 &&
    pendingCustomerFaultProblems.every(
      (p) =>
        p.resolutionType ===
        InvoiceAuditProblemResolutionType.AdditionalChargeIssued,
    )
  ) {
    if (
      additionalCharges.length > 0 &&
      additionalCharges.every(
        (ac) =>
          ac.customerInvoiceState === CustomerInvoiceState.Settled ||
          ac.customerInvoiceState === CustomerInvoiceState.SettlementPending ||
          ac.customerInvoiceState === CustomerInvoiceState.Reconciled,
      )
    ) {
      // Every audit problem is marked as waiting for additional charge, but every additional charge is paid
      return nextStepWithAction(
        RequiresActionType.Other,
        "Additional charges paid. Mark customer problems as resolved",
      );
    }
  }

  if (
    csq.shipment.invoiceAuditProblems!.every(
      (p) =>
        p.resolutionState === InvoiceAuditProblemResolutionState.Pending &&
        p.resolutionType ===
          InvoiceAuditProblemResolutionType.AdditionalChargeIssued,
    )
  ) {
    if (
      issuedAdditionalCharges.length > 0 &&
      issuedAdditionalCharges.every(
        (ac) => daysAgo(ac.dueDate) > 0 && !ac.disputed,
      )
    ) {
      // Every audit problem is marked as waiting for additional charge
      return nextStepWithAction(
        RequiresActionType.Other,
        "Additional charge grace period over. Mark customer problems as resolved",
      );
    }
  }

  ///////////////////////////////////////////////////////////////
  // Most of the actions are done - so various types of waiting

  if (csq.shipment.invoiceAuditCarrierTurnTime !== undefined) {
    if (
      csq.shipment.invoiceAuditCarrierTurn === InvoiceAuditTurn.TheirTurn &&
      daysAgo(csq.shipment.invoiceAuditCarrierTurnTime) <=
        CARRIER_REQUIRES_PING_DAYS
    ) {
      return nextStepWithNoAction("Wait for carrier");
    }
  }

  const customerProblems = csq.shipment.invoiceAuditProblems!.filter(
    (p) => p.fault === InvoiceAuditProblemFault.Customer,
  );

  if (
    customerProblems.every(
      (p) =>
        (p.resolutionState === InvoiceAuditProblemResolutionState.Pending &&
          p.resolutionType ===
            InvoiceAuditProblemResolutionType.AdditionalChargeIssued) ||
        p.resolutionState === InvoiceAuditProblemResolutionState.Resolved,
    )
  ) {
    const everyCustomerProblemIsResolved = customerProblems.every(
      (cp) =>
        cp.resolutionState === InvoiceAuditProblemResolutionState.Resolved,
    );
    if (
      !everyCustomerProblemIsResolved &&
      issuedAdditionalCharges.length > 0 &&
      issuedAdditionalCharges.every((ac) => daysAgo(ac.dueDate) <= 0)
    ) {
      // Every audit problem is marked as waiting for additional charge
      return nextStepWithNoAction("In additional charge grace period");
    }
  }

  if (disputedCustomerInvoices.length > 0) {
    if (csq.shipment.invoiceAuditCarrierTurnTime !== undefined) {
      if (
        csq.shipment.invoiceAuditCustomerTurn === InvoiceAuditTurn.TheirTurn &&
        daysAgo(csq.shipment.invoiceAuditCustomerTurnTime) <= 3
      ) {
        return nextStepWithNoAction("Wait for customer");
      }
    }
  }

  if (
    allProblems.length > 0 &&
    allProblems.every(
      (p) => p.resolutionState === InvoiceAuditProblemResolutionState.Resolved,
    )
  ) {
    if (
      carrierInvoices.some(
        (ci) => ci.invoiceState === CarrierInvoiceState.Issued,
      )
    ) {
      return nextStepWithAction(
        RequiresActionType.Other,
        "Approve invoices. All problems resolved.",
      );
    }
  }

  const allowedToCloseAuditOutput = allowedToCloseAudit(
    csq,
    carrierInvoices,
    carrierCreditNotes,
    customerInvoices,
  );
  if (allowedToCloseAuditOutput.allowedToCloseAudit) {
    return closeAudit();
  }

  // TODO: How do we make sure an additional charge has been issued if needed
  // TODO: What do we do if an invoice is waiting for verify?

  return nextStepWithAction(
    RequiresActionType.UnknownNextStep,
    `TODO. Unknown next step (not allowed to close audit : ${allowedToCloseAuditOutput.reason}`,
  );
}
