import { LineItem } from "@freightsimple/generated-apollo-openapi-client";
import { Button, Modal, message } from "antd";
import { useEffect, useState } from "react";
import { errorMessageForLineItem } from "../../Helpers/errorMessageForLineItem";
import { EditLineItem } from "./EditLineItem";

interface EditLineItemButtonProps {
  index: number;
  updateLineItems: (lineItems: LineItem[]) => void;
  lineItems: LineItem[];
}

export function EditLineItemButton(props: EditLineItemButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lineItem, setLineItem] = useState(props.lineItems[props.index]);

  useEffect(
    function () {
      if (isModalVisible) {
        // Refresh when the modal is reopened
        setLineItem(props.lineItems[props.index]);
      }
    },

    [isModalVisible],
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    const lineItemErrorMessage = errorMessageForLineItem(lineItem);
    if (lineItemErrorMessage !== undefined) {
      message.error(lineItemErrorMessage);
      return;
    }

    const updated = props.lineItems.map(function (_lineItem, index) {
      if (index === props.index) {
        return lineItem;
      } else {
        return _lineItem;
      }
    });
    props.updateLineItems(updated);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Modal
        title="Edit Line Item"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1300}
      >
        <EditLineItem lineItem={lineItem} setLineItem={setLineItem} />
      </Modal>
      <Button onClick={showModal}>Edit</Button>
    </>
  );
}
