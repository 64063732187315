import { Note } from "@freightsimple/generated-apollo-openapi-client";
import { Modal } from "antd";
import { useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import { LinkButton } from "../../Components/LinkButton";
import Spacer from "../../Spacer";

interface ViewNoteEmailMenuItemProps {
  note: Note;
}

export function ViewNoteEmailMenuItem(props: ViewNoteEmailMenuItemProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="View Email"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
      >
        <div
          title="Email Preview"
          dangerouslySetInnerHTML={{
            __html: props.note.bodyHtml ?? "",
          }}
        ></div>
      </Modal>
      <ButtonRow>
        <LinkButton onClick={showModal}>View Full Message</LinkButton>
        <Spacer width={16} />
        <a
          style={{ color: "#1890ff" }}
          target="_blank"
          href={props.note.link}
          rel="noreferrer"
        >
          View in Front
        </a>
      </ButtonRow>
    </>
  );
}

export function ViewNoteIntercomMenuItem(props: ViewNoteEmailMenuItemProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="View Full Message"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={800}
      >
        <div
          title="Full Message"
          dangerouslySetInnerHTML={{
            __html: props.note.bodyHtml ?? "",
          }}
        ></div>
      </Modal>
      <LinkButton onClick={showModal}>View Full Message</LinkButton>
    </>
  );
}
