import { Note } from "@freightsimple/generated-apollo-openapi-client";
import { NoteHeadline } from "../NotesBase/NoteHeadline";
import { NoteMessage } from "../NotesBase/NoteMessage";
import { ButtonRow } from "../ButtonRow";
import { Json } from "../Json";

interface ErrorNoteElementProps {
  note: Note;
  metadataError?: boolean;
}

export function ErrorNoteElement(props: ErrorNoteElementProps) {
  const { note, metadataError } = props;

  return (
    <>
      <NoteHeadline>Error displaying note</NoteHeadline>
      <NoteMessage>
        There was an error displaying this note. The raw note data is shown
        below.
      </NoteMessage>
      {metadataError && note.beforeMetadata && (
        <>
          <NoteHeadline>Before Metadata</NoteHeadline>
          <Json data={note.beforeMetadata} />
        </>
      )}
      {metadataError && note.afterMetadata && (
        <>
          <NoteHeadline>After Metadata</NoteHeadline>
          <Json data={note.afterMetadata} />
        </>
      )}
      <ButtonRow>
        <Json data={note} />
      </ButtonRow>
    </>
  );
}
