import { InvoiceAuditCarrierNote } from "@freightsimple/generated-apollo-openapi-client";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import { useCarrierInvoiceAuditApi } from "../../Apis/Apis";
import CarrierLogo from "../../Components/CarrierLogo";
import HorizontalStack from "../../Components/HorizontalStack";
import { DisplayMarkdown } from "../../Components/ViewMarkdownButton";
import { WarningComponent } from "../../Components/WarningComponent";
import { getCarrierDisplayNameFromCarrierIdentifier } from "../../Helpers/serviceProperties";
import Spacer from "../../Spacer";
import { EditInvoiceAuditCarrierNoteButton } from "../ViewCompanyScreenComponents/EditInvoiceAuditCarrierNoteButton";

const { Title } = Typography;

interface CommonCarrierProblemsSectionProps {
  carrierIdentifier: string;
}

export function CommonCarrierProblemsSection(
  props: CommonCarrierProblemsSectionProps,
) {
  const { carrierIdentifier } = props;
  const [notes, setNotes] = useState<InvoiceAuditCarrierNote[]>();
  const createCarrierInvoiceAuditApi = useCarrierInvoiceAuditApi();

  async function update() {
    const carrierInvoiceAuditApi = await createCarrierInvoiceAuditApi();
    const response = await carrierInvoiceAuditApi.getAllInvoiceCarrierNotes();
    setNotes(response);
  }

  useEffect(
    function () {
      update();
    },
    [props.carrierIdentifier],
  );

  if (notes === undefined) {
    return <></>;
  }

  const noteForThisCarrier = notes.find(
    (n) => n.carrierIdentifier === carrierIdentifier,
  )?.notes;

  return (
    <>
      <div style={{ border: "1px solid #ccc", padding: "32px" }}>
        <CarrierLogo
          carrierIdentifier={carrierIdentifier}
          width={60}
          brokeredCarrierIdentifier={undefined}
          height={40}
        />
        <Spacer height={16} />
        <HorizontalStack
          align="spread"
          verticalAlign="top"
          style={{ width: "100%" }}
        >
          <Title level={4}>
            <WarningComponent /> Common Problems for carrier{" "}
            {getCarrierDisplayNameFromCarrierIdentifier(carrierIdentifier)}
          </Title>
          <EditInvoiceAuditCarrierNoteButton
            carrierIdentifier={props.carrierIdentifier}
            notes={noteForThisCarrier ?? ""}
            onRefreshCarrierNotes={update}
          />
        </HorizontalStack>
        <DisplayMarkdown data={noteForThisCarrier ?? "No notes"} />
      </div>
      <Spacer height={32} />
    </>
  );
}
