import {
  CarrierInvoice,
  CarrierInvoiceState,
} from "@freightsimple/generated-apollo-openapi-client";
import { message, Tag } from "antd";
import { useCarrierInvoiceApi } from "../../Apis/Apis";
import { ConfirmMenuItemWithTextArea } from "../../Components/ConfirmButtonWithTextArea";
import { TabProps } from "./TabProps";

interface VoidPaidCarrierInvoiceMenuItemProps extends TabProps {
  carrierInvoice: CarrierInvoice;
}

export function VoidPaidCarrierInvoiceMenuItem(
  props: VoidPaidCarrierInvoiceMenuItemProps,
) {
  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!;
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  async function onConfirm(reason: string) {
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      await carrierInvoiceApi.markPaymentConfirmedCarrierInvoiceVoided({
        carrierInvoiceId,
        reason,
      });
      message.success("Invoice voided");
      props.onRefresh();
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }
  }

  if (
    props.carrierInvoice.invoiceState !== CarrierInvoiceState.PaymentConfirmed
  ) {
    return <></>;
  }

  return (
    <ConfirmMenuItemWithTextArea
      onConfirm={onConfirm}
      placeholder="Enter reason you are voiding this"
      question="Are you sure you want to void this paid invoice? This should only ever be necessary if this invoice was reconciled with the wrong payment in Xero, maybe because of USD/CAD confusion. Make sure you have already done 'Remove & Redo' in Xero - or voiding the invoice in Xero will fail because payments have been made against it"
      okText={"Yes, void this carrier invoice"}
      tooltip={
        "If there was a reconciliation issue in Xero and this invoice was incorrectly reconciled against a different amount, and we need to fix it"
      }
    >
      <Tag color="red">Advanced</Tag> Void Paid Carrier Invoice
    </ConfirmMenuItemWithTextArea>
  );
}
