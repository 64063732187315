import { Row, Typography } from "antd";
import { Fragment, ReactNode } from "react";
import { isPhone } from "../Helpers/isPhone";
import Spacer from "../Spacer";
import Colors from "./Colors";
import HorizontalStack from "./HorizontalStack";
import Stack from "./Stack";

const { Title, Text } = Typography;
interface PageProps {
  title: ReactNode;
  subtitle?: ReactNode;
  tags: ReactNode[];
  extra: ReactNode[];
  children: ReactNode;
  stats?: ReactNode;
  widget?: ReactNode;
}

export function Page(props: PageProps) {
  return (
    <div style={{ marginBottom: "128px" }}>
      <Row
        style={{
          width: "100%",
          padding: isPhone ? "0" : "16px",
          backgroundColor: "#ebedf9",
        }}
      >
        {!isPhone && (
          <HorizontalStack width="100%" align="spread">
            <HorizontalStack>
              <Stack align="left">
                <Title
                  style={{
                    marginBottom: "0",
                    marginTop: "0",
                  }}
                  level={3}
                >
                  {props.title}
                </Title>
                {props.subtitle && (
                  <>
                    <Spacer width={16} />
                    <Text style={{ color: Colors.LightText }}>
                      {props.subtitle}
                    </Text>
                  </>
                )}
                {props.tags.length > 0 && (
                  <>
                    <Spacer height={8} />
                    <HorizontalStack spacing={8} style={{ marginLeft: "-8px" }}>
                      {props.tags.map((tag, index) => (
                        <Fragment key={index}>{tag}</Fragment>
                      ))}
                    </HorizontalStack>
                  </>
                )}
              </Stack>
            </HorizontalStack>

            <HorizontalStack spacing={8}>
              {props.extra.map((extra, index) => (
                <Fragment key={index}>{extra}</Fragment>
              ))}
            </HorizontalStack>
          </HorizontalStack>
        )}
        {isPhone && (
          <Stack align="left" style={{ paddingLeft: "6px" }}>
            <Title style={{ marginBottom: "0", marginTop: "2px" }} level={5}>
              {props.title}
            </Title>
            {props.subtitle && (
              <>
                <Spacer width={4} />
                <Text style={{ color: Colors.LightText }}>
                  {props.subtitle}
                </Text>
              </>
            )}
            <Spacer width={4} />
            <HorizontalStack spacing={8} style={{ marginLeft: "-8px" }}>
              {props.tags}
            </HorizontalStack>
            <Stack align="left">{props.extra}</Stack>
          </Stack>
        )}
      </Row>
      {props.stats && (
        <Row style={{ padding: "16px", backgroundColor: "#F6F7FD" }}>
          {props.stats}
        </Row>
      )}
      {props.widget}
      <Row style={{ width: "100%", padding: "16px", backgroundColor: "white" }}>
        {props.children}
      </Row>
    </div>
  );
}
