import {
  Shipment,
  ShipmentState,
} from "@freightsimple/generated-apollo-openapi-client";
import dayjs from "dayjs";

export function isInDeliveryWindow(shipment: Shipment): boolean {
  const latest = dayjs(shipment.latestPredictedDeliveryDateAtPickup).startOf(
    "day",
  );

  if (shipment.state === ShipmentState.Delivered) {
    const actualDeliveryDate = dayjs(shipment.actualDeliveryDate).startOf(
      "day",
    );
    return actualDeliveryDate.isSameOrBefore(latest);
  } else {
    const estimatedDeliveryDate = dayjs(shipment.expectedDeliveryDate).startOf(
      "day",
    );
    const today = dayjs().startOf("day");

    if (estimatedDeliveryDate.isBefore(today)) {
      return today.isSameOrBefore(latest);
    } else {
      return estimatedDeliveryDate.isSameOrBefore(latest);
    }
  }
}
