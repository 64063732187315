import { ReactNode } from "react";
import { isEmpty } from "../Helpers/isEmpty";

interface TextRendererProps {
  value: any;
  keyName: string;
  customValueDisplay?: (value: any, key: string) => React.ReactNode;
}

export function ValueDisplay(props: TextRendererProps) {
  const { value, keyName, customValueDisplay } = props;

  let render: ReactNode = String(value);

  if (isEmpty(value)) {
    render = "Empty";
  }

  if (typeof value === "boolean") {
    render = value ? "Yes" : "No";
  }

  if (Array.isArray(value)) {
    render = value.join(", ");
  }

  if (customValueDisplay) {
    const result = customValueDisplay(value, keyName);
    if (result) {
      render = result;
    }
  }

  return <>{render}</>;
}
