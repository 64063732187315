import { Select } from "antd";

interface CompanyNotesSignificantFilterProps {
  value: boolean | undefined;
  setValue: (value: boolean | undefined) => void;
}

export function CompanyNotesSignificantFilter({
  value,
  setValue,
}: CompanyNotesSignificantFilterProps) {
  return (
    <Select
      style={{ width: 220 }}
      value={value}
      onChange={setValue}
      placeholder={"Do not filter"}
      allowClear
    >
      <Select.Option value={true}> Significant </Select.Option>
      <Select.Option value={false}> Not Significant </Select.Option>
    </Select>
  );
}
