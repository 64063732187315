import { Note } from "@freightsimple/generated-apollo-openapi-client";
import dayjs from "dayjs";
import { capitalizeFirstLetter } from "../../Helpers/capitalizeFirstLetter";
import Colors from "../Colors";
import Stack from "../Stack";
import { Tooltip } from "antd";

interface NoteTimelineLabelProps {
  note: Note;
}

export function NoteTimelineLabel(props: NoteTimelineLabelProps) {
  const date = dayjs(props.note.createdAt);
  const delta = capitalizeFirstLetter(date.fromNow());
  return (
    <Stack align="right" style={{ marginRight: "36px" }}>
      <Tooltip overlay={date.format("dddd, MMMM D YYYY, h:mm:ss A z")}>
        <div style={{ fontSize: "14px", fontWeight: "600" }}>
          {date.format("h:mm a")}
        </div>
        <div style={{ fontSize: "12px", color: Colors.LightText }}>{delta}</div>
      </Tooltip>
    </Stack>
  );
}
